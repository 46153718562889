/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > 입고검사 성적서 (Q-Point)
 * 02. 프로그램ID : QmsDetailInspectList.tsx
 * 03. 프로그램명 : 입고검사 성적서 작성 (Q-Point 탭)
 * 04. 화면설명 : 입고검사 성적서 작성
 * 05. 작성일 : 2023.08.24
 * 06. 작성자 : 채보람
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-08-24     채보람    최초 작성
 **************************************************************************************************
 */

import React, {useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import QmsDetailInspectDetail from './QmsQpointDetail';
import useGlobalData from "../../../../hooks/useGlobalData";
import {STORAGE_NAME} from "../../../../components/CommonConstants";
import {useDataset} from "../../../../components/contexts/CommonContext";
import usePageMove from "../../../../hooks/usePageMove";

export default function QmsQpoint({state, nextTab}: {state: any, nextTab: (name: string) => void}) {
    const {getGlobalSetting, setGlobalSetting} = useGlobalData();
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const {dataset, sessionValues} = useDataset();
    const {getDomain} = useGlobalData();
    const {pageMove} = usePageMove();

    const [showSwiper, setShowSwiper] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
    const [ds_Spec, setDs_Spec] = useState(state.params.get('pv_spec'));
    const [ds_qPointList, setDs_qPointList] = useState(state.params.get('pv_ds_qPointList'));
    const [ds_Part, setDs_Part] = useState(state.params.get('pv_data'));

    const goDetail = (index: number) => {
        setShowSwiper(false);
        setSelectedIndex(index);
    };

    const closeDetail = (data: any) => {
        state.setDataset("ds_qPointList", data);

        setShowSwiper(false);
        setSelectedIndex(undefined);
    };

    useEffect(() => {
        if(selectedIndex !== undefined) {
            setShowSwiper(true);
        }
    }, [selectedIndex]);

    useEffect(() => {
        // console.log("Q-Point 탭");
        // console.log("state.params ↓");
        // console.log(state.params);
        // console.log("Q-Point 탭");

        form_onload();
    }, []);

    /* form_onload */
    function form_onload() {
        /* Q-Point정보 세팅 */
        initDs_qPointList();
    }

    /* Q-Point정보 세팅 */
    function initDs_qPointList() {
        let copyDs = [...ds_qPointList];

        for (let i = 0; i < copyDs.length; i++) {
            copyDs[i]['SEC_INSP_ID'] = state.params.get('pv_data')[0]['SEC_INSP_ID'];
            copyDs[i]['INSERT_BY'] = sessionValues?.USER_ID;
            copyDs[i]['UPDATE_BY'] = sessionValues?.USER_ID;

            setDs_qPointList(copyDs);
        }
    }

    return (
        <>
            <div className={'table-wrap'} style={{display: 'block'}}>
                <table>
                    <colgroup>
                        <col style={{width: '3rem'}}/>
                        <col/>
                        <col style={{width: '4.8rem'}}/>
                        <col style={{width: '4.8rem'}}/>
                        <col style={{width: '4.8rem'}}/>
                        <col style={{width: '4.2rem'}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope={'col'}>{getDomain('DOMAIN4608')} {/*No*/}		</th>
                            <th scope={'col'}>{getDomain('DOMAIN4758')} {/*검사항목*/}	</th>
                            <th scope={'col'}>{getDomain('DOMAIN2056')} {/*샘플수*/}		</th>
                            <th scope={'col'}>{getDomain('DOMAIN4619')} {/*검사수*/}		</th>
                            <th scope={'col'}>{getDomain('DOMAIN2057')} {/*불량수*/}		</th>
                            <th scope={'col'}>{getDomain('DOMAIN4620')} {/*판정*/}		</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        ds_qPointList.map((item: any, index: number) => {
                            return <tr
                                key={'tr_'.concat(String(index))}
                                onClick={() => goDetail(index)}>
                                <td key={'td_0_'.concat(String(index))}>{index + 1}</td>
                                <td key={'td_1_'.concat(String(index))} className={'left'}><a>{item.INSP_ITEM_NM}</a></td>
                                <td key={'td_2_'.concat(String(index))} className={'right'}>1</td>
                                <td key={'td_3_'.concat(String(index))} className={'right'}>{item.ITEM_INSP_CNT}</td>
                                <td key={'td_4_'.concat(String(index))} className={'right'}>{item.ITEM_FAIL_CNT}</td>
                                <td key={'td_5_'.concat(String(index))}>
                                    <span key={'span_'.concat(String(index))} className={item.ITEM_MEAS_RESULT === 'OK' ? 'label-state ok' : item.ITEM_MEAS_RESULT === 'NG' ? 'label-state ng' : 'label-state wait'}>{item.ITEM_MEAS_RESULT == null ? 'Wait' : item.ITEM_MEAS_RESULT}</span>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
            {
                state.params.get('pv_flag') == 'INS'
                ?
                    <div className={'btn-area bottom'}>
                        {
                            ds_Part[0]['GROUP_YN'] == "Y"
                                ?
                                    ''
                                :
                                    <button type={'button'} className={'button-line'} onClick={() => state.fn_tempSaveFile()}>{getDomain('DOMAIN1888')}{/*임시저장*/}</button>
                        }
                        <button type={'button'} className={'button-primary'} onClick={() => state.fn_saveFile()}>{getDomain("DOMAIN2083")}</button>{/*저장*/}
                    </div>
                :
                    <div className={'btn-area bottom'}>
                        <button type={'button'} className={'button-primary'} onClick={() => pageMove(-1)}>{getDomain('DOMAIN4555')}{/*목록으로 이동*/}</button>
                    </div>
            }
            <CSSTransition in={showSwiper} classNames={showSwiper ? 'right' : 'left'} timeout={500} unmountOnExit>
                <QmsDetailInspectDetail list={ds_qPointList} onClose={closeDetail} selected={selectedIndex} ds_Part={state.params.get('pv_data')} pv_flag={state.params.get('pv_flag')}/>
            </CSSTransition>
        </>
    );
};