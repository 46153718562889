/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > 입고검사 성적서 (기본정보)
 * 02. 프로그램ID : QmsBasicInformation.tsx
 * 03. 프로그램명 : 입고검사 성적서 작성 (기본정보 탭)
 * 04. 화면설명 : 입고검사 성적서 작성
 * 05. 작성일 : 2023.08.24
 * 06. 작성자 : 채보람
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-08-24     채보람    최초 작성
 **************************************************************************************************
 */

import React, {useEffect, useState} from 'react';
import {NexacroJsonType, NexacroJsonUnitType} from "../../../../components/model/SqciTypes";
import useAxios, {AXIOS_HEADER} from "../../../../hooks/useAxios";
import useGlobalData from "../../../../hooks/useGlobalData";
import {STORAGE_NAME} from "../../../../components/CommonConstants";
import {useDataset} from "../../../../components/contexts/CommonContext";
import usePageMove from "../../../../hooks/usePageMove";
import * as gfn from "../../../../components/utils/CommonUtils";

export default function QmsBasicInformation({state, nextTab}: {state: any, nextTab: (name: string) => void}) {
    const {getGlobalSetting, setGlobalSetting} = useGlobalData();
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const {sessionValues} = useDataset();
    const {dataset} = useDataset();
    const {getDomain, getMessage} = useGlobalData();
    const {pageMove} = usePageMove();

    const [ds_Part, setDs_Part] = useState(state.params.get('pv_data'));
    const [ds_Spec, setDs_Spec] = useState(state.params.get('pv_spec'));
    const [division_nm, setDivision_nm] = useState(String);
    const [plant_nm, setPlant_nm] = useState(String);
    const [hq_nm, setHq_nm] = useState(String);
    const [maker_cd, setMaker_cd] = useState(String);
    const [insp_div_nm, setInsp_div_nm] = useState(String);
    const [part_nm, setPart_nm] = useState(String);
    const [categoryName, setCategoryName] = useState(String);
    const [dmrStage, setDmrStage] = useState(String);
    const [inv_no, setInv_no] = useState(String);
    const [prodDate, setProdDate] = useState(String);
    const [lotSize, setLotSize] = useState(String);
    const [grDttm, setGrDttm] = useState(String);
    const [inspStatus, setInspStatus] = useState(String);
    const [inspUser, setInspUser] = useState(String);
    const [inspDttm, setInspDttm] = useState(String);

    useEffect(() => {
        form_onload();

        // console.log("입고검사 성적서 작성 (기본정보 탭)");
        // console.log("ds_Part[0]");
        // console.log(ds_Part[0]);
        // console.log("입고검사 성적서 작성 (기본정보 탭)");
    });

    /* form_onload */
    function form_onload() {
        let inspUserNm = String(sessionValues?.USER_NAME);

        if (ds_Part[0]['INSP_USER_ONE'] != undefined && ds_Part[0]['INSP_USER_ONE'] != null && ds_Part[0]['INSP_USER_ONE'] != '') {
            inspUserNm = ds_Part[0]['INSP_USER_ONE'];
        }

        setDivision_nm(ds_Part[0]['DIVISION_NM']);
        setPlant_nm("[" + ds_Part[0]['PLANT_CD'] + "] " + ds_Part[0]['PLANT_NM']);
        setHq_nm("[" + ds_Part[0]['HQ_CD'] + "] " + ds_Part[0]['HQ_NM']);
        setMaker_cd(ds_Part[0]['MAKER_CD']);
        setInsp_div_nm(ds_Part[0]['INSP_DIV_NM']);
        setPart_nm("[" + ds_Part[0]['PART_CD'] + "] " + ds_Part[0]['PART_NM']);
        setCategoryName(ds_Part[0]['CATEGORY2_NM']);
        setDmrStage(ds_Part[0]['DMR_DETAIL']);
        setInv_no(ds_Part[0]['GROUP_YN'] == "Y" ? String(ds_Part[0]['INV_NOS']).replaceAll(',', '\r\n') : ds_Part[0]['INV_NO']);
        setProdDate(ds_Part[0]['PROD_DT']);
        setLotSize(String(ds_Part[0]['GR_QTY']));
        setGrDttm(ds_Part[0]['GR_DATE'] + " " + (ds_Part[0]['GR_TIME'] ? ds_Part[0]['GR_TIME'] : ''));
        setInspStatus(ds_Spec.length > 0 ? ds_Spec[0]['INSP_STD_STATUS_NM'] : '');
        setInspUser(inspUserNm);
        setInspDttm((ds_Part[0]['SEC_INSP_DT'] ? ds_Part[0]['SEC_INSP_DT'] : '') + " " + (ds_Part[0]['SEC_INSP_TIME'] ? ds_Part[0]['SEC_INSP_TIME'] : ''));
    }

    return (
        <>
            <div className={'contents-table-wrap width-type2'} style={{display: 'block'}}>
                <ul className={'contents-table-list'}>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN0222')}</div>  {/*사업부*/}
                        <div className={'item-wrap'}>{division_nm}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN2426')}</div>  {/*Plant*/}
                        <div className={'item-wrap'}>{plant_nm}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN0372')}</div>  {/*협력사*/}
                        <div className={'item-wrap'}>{hq_nm}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN2887')}</div>  {/*IPC Vendor*/}
                        <div className={'item-wrap'}>{maker_cd}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN4642')}</div>  {/*거래유형*/}
                        <div className={'item-wrap'}>{insp_div_nm}</div>
                    </li>
                    <li>
                        <div className={'title'} dangerouslySetInnerHTML={{__html:getDomain('DOMAIN4596')}}></div>  {/*부품명*/}
                        <div className={'item-wrap'}>{part_nm}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN4643')}</div>  {/*부품군*/}
                        <div className={'item-wrap'}>{categoryName}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN4644')}</div>  {/*DMR Stage*/}
                        <div className={'item-wrap'}>{dmrStage}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN2414')}</div>  {/*Invoice No*/}
                        <div className={'item-wrap'} style={{wordBreak:"normal"}}>{inv_no}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN2602')}</div>  {/*생산일자*/}
                        <div className={'item-wrap'}>{prodDate}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN2415')}</div>  {/*입고수량*/}
                        <div className={'item-wrap'}>{gfn.fn_comma(lotSize)}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN4645')}</div>  {/*입고일시*/}
                        <div className={'item-wrap'}>{grDttm}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN4646')}</div>  {/*검사기준서 상태*/}
                        <div className={'item-wrap'}>{inspStatus}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN2421')}</div>  {/*검사원*/}
                        <div className={'item-wrap'}>{inspUser}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN4647')}</div>  {/*검사일시*/}
                        <div className={'item-wrap'}>{inspDttm}</div>
                    </li>
                </ul>
            </div>
            {
                state.params.get('pv_flag') == 'INS'
                ?
                    <div className={'btn-area bottom'}>
                        {
                            ds_Part[0]['GROUP_YN'] == "Y"
                            ?
                                ''
                            :
                                <button type={'button'} className={'button-line'} onClick={() => state.fn_tempSaveFile()}>{getDomain('DOMAIN1888')}{/*임시저장*/}</button>
                        }
                        <button type={'button'} className={'button-primary'} onClick={() => nextTab('qmsBasicInformation')}>{getDomain('DOMAIN3849')}{/*다음*/}</button>
                    </div>
                :
                    <div className={'btn-area bottom'}>
                        <button type={'button'} className={'button-primary'} onClick={() => pageMove(-1)}>{getDomain('DOMAIN4555')}{/*목록으로 이동*/}</button>
                    </div>
            }

        </>
    );
};