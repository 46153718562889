/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > 입고검사 성적서 (판정결과)
 * 02. 프로그램ID : QmsJudgmentResult.tsx
 * 03. 프로그램명 : 입고검사 성적서 작성 (판정결과 탭)
 * 04. 화면설명 : 입고검사 성적서 작성
 * 05. 작성일 : 2023.08.24
 * 06. 작성자 : 채보람
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-08-24     채보람    최초 작성
 **************************************************************************************************
 */

import React, {RefObject, useEffect, useRef, useState} from 'react';
import * as gfn from "../../../../components/utils/CommonUtils";
import {useComponent} from "../../../../components/contexts/ComponentContext";
import {NexacroJsonUnitType} from "../../../../components/model/SqciTypes";
import {useDataset} from "../../../../components/contexts/CommonContext";
import {STORAGE_NAME} from "../../../../components/CommonConstants";
import useGlobalData from "../../../../hooks/useGlobalData";
import usePageMove from "../../../../hooks/usePageMove";

export default function QmsJudgmentResult({state, nextTab, reportRef}: {state: any, nextTab: (name: string) => void, reportRef: RefObject<HTMLInputElement>}) {
    const {setDialog,setLoader} = useComponent();
    const {dataset, sessionValues} = useDataset();
    const {getGlobalSetting, setGlobalSetting} = useGlobalData();
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const {getDomain, getMessage} = useGlobalData();
    const {pageMove} = usePageMove();

    const [ds_Part, setDs_Part] = useState(state.params.get('pv_data'));
    const [ds_upload, setDs_upload] = useState(state.params.get('pv_ds_upload'));
    const [ds_remove, setDs_remove] = useState(state.params.get('pv_ds_remove'));
    const [vds_remove, setVds_remove] = useState([]);

    const [resultCss, setResultCss] = useState(String);
    const [resultDesc, setResultDesc] = useState(String);
    const [insp_memo, setInsp_memo] = useState(state.params.get('pv_data')[0]['INSP_MEMO']);
    const [insp_memoReadonly, setInsp_memoReadonly] = useState(false);
    const [secMeasRslt, setSecMeasRslt] = useState(state.params.get('pv_secMeasRslt'));

    useEffect(() => {
        form_onload();
    }, []);

    useEffect(() => {
        setDs_upload(state.params.get('pv_ds_upload'));
    });

    /* form_onload */
    function form_onload() {
        // console.log("판정결과 탭");
        // console.log("ds_Part UD_CD ↓");
        // console.log(ds_Part);
        // // console.log("secMeasRslt ↓");
        // // console.log(secMeasRslt);
        // console.log("판정결과 탭");

        // 작성된것 보여주기
        if (String(state.params.get('pv_flag')) != "INS") {
            initNowIns();
        }

        // {biztype:"Combo", code:"SQCI000127", selecttype: "S", objid: this.Div02.form.Div00.form.Combo00} // 최종판정결과
    }

    /* 작성된것 보여주기 */
    function initNowIns() {
        // setResultDesc(ds_Part[0]['UD_CD_NM']);
        //
        // if (ds_Part[0]['UD_CD'] == "A") {
        //     setResultCss("pass");
        // } else {
        //     setResultCss("fail");
        // }

        setInsp_memoReadonly(true);
    }

    /* 최종 결과 체크 */
    function fn_checkMeasCheck() {
        state.fn_checkMeasCheck();
    }

    /**
     * 출하검사 성적서 업로드 click event
     */
    const reportCallback = () => {
        reportRef.current?.click();
    };

    /**
     * 파일 업로드, 멀티 파일 업로드 change event
     * @param e
     */
    const reportChangeCallback = async (e: React.ChangeEvent<HTMLInputElement>) => {
        await setLoader(true);

        let copyArray = [...ds_upload];

        copyArray = copyArray.filter(item => item.FILE_ID != '');

        if (e.target.files?.length != undefined) {
            for (let i = 0; i < e.target.files?.length; i++) {
                let file = e.target.files[i];

                copyArray.push({
                    FILE_ID:'',
                    FILE_NAME:file.name,
                    FILE_SIZE:file.size,
                    prog:"0",
                    fileType:file.type
                });
            }
        }

        let fileCnt = 10;

        if (copyArray.length > fileCnt) {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG01067").replace("{0}", String(fileCnt))
            });

            copyArray = copyArray.filter(item => item.FILE_ID != '');
        }

        state.setDataset('ds_upload', copyArray);

        await setLoader(false);
    };

    /* 첨부 파일 삭제 */
    const reportDeleteCallback = (fItem: NexacroJsonUnitType, fIndex:number) => {
        let copyArray = [...ds_upload];
        let temp_ds_upload = copyArray.filter((item,index) => index != fIndex);

        state.setDataset('ds_upload', temp_ds_upload);

        if (fItem.FILE_ID != "") {
            let temp_ds_remove: any = [...vds_remove];

            temp_ds_remove.push(copyArray.find((item,index) => index === fIndex)||{});

            setVds_remove(temp_ds_remove);

            state.setDataset('ds_remove', temp_ds_remove);
        }
    };

    /* 측정결과 입력 시 */
    const changeInspVal = (e: any) => {
        setInsp_memo(e.target.value);

        /* 변경값 저장 */
        setInspValData(e);
    };

    const Div02_Div00_Combo00_onitemchanged = (e: any) => {
        // // 합격 -> 세부 Spec 의 선택값들 일괄 OK 처리
        // if(this.Div02.form.Div00.form.Combo00.value == "1"){
        //     for(var i=0; i<this.ds_Spec.rowcount; i++){
        //         if(this.ds_Spec.getColumn(i,"NUM_YN") == "N"){
        //             for(var j=0; j<this.ds_Spec.getColumn(i,"SAM_CNT"); j++){
        //                 this.ds_Spec.setColumn(i,this.ds_grdColList.getColumn(j,"BIND_COL"),"1");
        //                 this.ds_Spec.setColumn(i,"MEAS_RSLT", "Y");
        //             }
        //         }
        //     }
        //
        //     //this.fn_setResultSpec();  // 판정결과 표시
        // }
        // 합격으로 바뀌면 콤보애들 한번에 OK 하는 기능

        let copyArray = [...ds_Part];
        copyArray[0]['SEC_MEAS_RESULT'] = e.target.value;
        copyArray[0]['MEAS_RESULT'] = e.target.value;
        setDs_Part(copyArray);
        state.setDataset("ds_Part", copyArray);

        e.target.blur();
    };

    /* 변경값 useState 적용 */
    function setInspValData(e: any) {
        let copyArray = [...ds_Part];
        copyArray[0][e.target.name] = e.target.value;
        setDs_Part(copyArray);
        state.setDataset("ds_Part", copyArray);
    }

    /* 파일 다운로드 */
    function downloadFile(fileId: string, fileSeq: string){
        if (fileId == '') {
            return;
        }

        const link = document.createElement('a');
        link.href = process.env.REACT_APP_HTTP_BASE_URL+"/common/nexacro/commonFileDownload.do?fileId="+fileId+"&fileSeq="+fileSeq;
        link.style.display = 'none';
        link.click();
        link.remove();
    }

    return (
        <>
            <div className={'contents-table-wrap'} style={{display: 'block'}}>
                {/*디자인에 입고에만 화살표*/}
                {/*버튼기능을 해야 할 경우  role={'button'} 추가 : 예시 <div class={'result-state arrow'} role={'button'}>*/}
                {/*<div className={'result-state arrow'}>*/}
                {/*    <span className={'result ' + resultCss}>{resultDesc}</span>*/}
                {/*</div>*/}
                {/*<div className={'result-state arrow'}>*/}
                {/*    <span className={'result fail'}>불합격(SEC)</span>*/}
                {/*</div>*/}
                {/*<div className={'result-state arrow'}>*/}
                {/*    <span className={'result fail'}>불합격(Vendor)</span>*/}
                {/*</div>*/}

                {
                    state.params.get('pv_flag') != "INS"
                    ?
                        <select
                            value={ds_Part[0]['UD_CD']}
                            className={'judgment-select-box'}
                            style={{backgroundColor: ds_Part[0]['UD_CD'] == 'A' ? '#3478F5' : '#FF005C'}}
                            disabled={true}>
                            <option key={ds_Part[0]['UD_CD']} value={ds_Part[0]['UD_CD']}>{ds_Part[0]['UD_CD_NM']}</option>
                        </select>
                    :
                        <select
                            name={'MEAS_RESULT'}
                            onChange={Div02_Div00_Combo00_onitemchanged}
                            value={ds_Part[0]['MEAS_RESULT']}
                            className={'judgment-select-box'}
                            style={{backgroundColor: ds_Part[0]['MEAS_RESULT'] == '1' ? '#3478F5' : '#FF005C'}}
                            disabled={false}>
                            <option key={''} value={''}>==</option>
                            {
                                secMeasRslt
                                    .map((i: any, index: number) => {
                                        return <option key={i.CD} value={i.CD as string}>{i.CDNM}</option>;
                                    })
                            }
                        </select>
                }

                <ul className={'contents-table-list form'}>
                    <li>
                        <div className={'title'}>
                            <span>{getDomain('DOMAIN4623')}</span>  {/*메모*/}
                        </div>
                        <div className={'item-wrap'}>
                            <textarea disabled={insp_memoReadonly} readOnly={insp_memoReadonly} onBlur={changeInspVal} name={'INSP_MEMO'} defaultValue={insp_memo}></textarea>
                        </div>
                    </li>
                </ul>
                <div className={'title-wrap'}>
                    <h2 className={'h2-title'}>{getDomain('DOMAIN4624')}{/*첨부파일*/}</h2>
                    <p>{getDomain('DOMAIN4625')}</p>
                </div>
                {
                    state.params.get('pv_flag') == 'INS'
                        ?
                            <ul className={'contents-table-list form'}>
                                <li>
                                    <div className={'title'}>{getDomain('DOMAIN4624')}</div>   {/*첨부파일*/}
                                    <div className={'item-wrap'}>
                                        <input type={'file'} multiple={true}
                                               ref={reportRef}
                                               accept={"*"} style={{display: "none"}} onChange={e => reportChangeCallback(e)}/>
                                        <button type={'button'} className={'button-upload'} onClick={() => reportCallback()}>{getDomain("DOMAIN4572")}</button>{/*업로드*/}
                                    </div>
                                </li>
                            </ul>
                        :
                            ''
                }
                <ul className={'attach-file-list'}>
                    {
                        ds_upload.map((fItem: any, fIndex: number) => {
                            return <React.Fragment key={'partFile_'.concat(String(fIndex))}>
                                <li>
                                    <div className={'file-item'}>
                                        <button type={'button'} className={'file-download'} onClick={() => downloadFile(
                                            gfn.gfn_tostring(fItem.FILE_ID),
                                            gfn.gfn_tostring(fItem.FILE_SEQ)
                                        )}><span
                                            className={'text'}><em>Image</em> {gfn.fn_getFileSizeDisplay(fItem.FILE_SIZE)+"KB"}</span></button>
                                        {
                                            state.params.get('pv_flag') == 'INS'
                                            ?
                                                <button type={'button'} className={'button-delete'} onClick={() => reportDeleteCallback(fItem,fIndex)}><i className={'hidden'}>{getDomain("DOMAIN0229")}</i></button>
                                            :
                                                ''
                                        }
                                    </div>
                                </li>
                            </React.Fragment>
                        })
                    }
                </ul>
            </div>
            {
                state.params.get('pv_flag') == 'INS'
                ?
                    <div className={'btn-area bottom'}>
                        {
                            ds_Part[0]['GROUP_YN'] == "Y"
                                ?
                                    ''
                                :
                                    <button type={'button'} className={'button-line'} onClick={() => state.fn_tempSaveFile()}>{getDomain('DOMAIN1888')}{/*임시저장*/}</button>
                        }
                        <button type={'button'} className={'button-primary'} onClick={() => state.fn_saveFile()}>{getDomain("DOMAIN2083")}</button>{/*저장*/}
                    </div>
                :
                    <div className={'btn-area bottom'}>
                        <button type={'button'} className={'button-primary'} onClick={() => pageMove(-1)}>{getDomain('DOMAIN4555')}{/*목록으로 이동*/}</button>
                    </div>
            }
        </>
    );
};