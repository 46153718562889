import React from 'react';
import {v4 as uuidv4} from 'uuid';
import {NexacroJsonUnitType} from "../../components/model/SqciTypes";
import * as gfn from "../../components/utils/CommonUtils";
import useGlobalData from "../../hooks/useGlobalData";

export default function PopChangePoint({title, list, onClose}: {title: string, list: NexacroJsonUnitType[], onClose: () => void}) {
    const {getDomain} = useGlobalData();

    return (
        <>
            <div className={'pop-layer'} style={{display: 'block'}}>
                <div className={'wrap'}>
                    <header>
                        <h1>{title}</h1>
                        <span className={'btn-wrap right'}>
                            <button type={'button'} className={'button-close'} onClick={onClose}>
                                <i className={'hidden'}>{getDomain('DOMAIN0173')} {/*닫기*/}</i>
                            </button>
                        </span>
                    </header>
                    <div className={'container'}>
                        <div className={'card-wrap'}>
                            <ul className={'card-list'}>
                                {
                                    list.map(item => {
                                        return <li key={uuidv4()} className={'card-item'}>
                                            <div className={'item'} role={'button'}>
                                                <p>
                                                    <span>
                                                        <em>[{item.EC_NO}]</em>
                                                        <i className={'label-type1'}>{item.ECN_CLASS_NM}</i>
                                                        <i className={'label-type2'}>{item.CODE_NM}</i>
                                                    </span>
                                                    <strong>{item.ECR_TITLE}</strong>
                                                </p>
                                                <ul className={'date-list'}>
                                                    <li>
                                                        <em>{getDomain('DOMAIN4629')} {/*적용일*/}</em>
                                                        <span>{gfn.gfn_n_today_yyyy_mm_dd(item.ECR_END_DATE||'')}</span>
                                                    </li>
                                                    <li>
                                                        <em>{getDomain('DOMAIN4630')} {/*입고예정일*/}</em>
                                                        <span>{gfn.gfn_n_today_yyyy_mm_dd(item.STOCK_PLAN_DATE||'')}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>;
                                    })
                                }
                            </ul>
                        </div>
                        <div className={'btn-area bottom'}>
                            <button type={'button'} className={'button-primary'} onClick={onClose}>{getDomain('DOMAIN0173')} {/*닫기*/}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};