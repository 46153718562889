/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > 입고검사 성적서 (불량내용)
 * 02. 프로그램ID : QmsDefectiveContent.tsx
 * 03. 프로그램명 : 입고검사 성적서 작성 (불량내용 탭)
 * 04. 화면설명 : 입고검사 성적서 작성
 * 05. 작성일 : 2023.08.24
 * 06. 작성자 : 채보람
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-08-24     채보람    최초 작성
 **************************************************************************************************
 */

import React, {useEffect, useState} from 'react';
import {v4 as uuidv4} from "uuid";
import useHtmlElement from "../../../../hooks/useHtmlElement";
import useGlobalData from "../../../../hooks/useGlobalData";
import usePageMove from "../../../../hooks/usePageMove";

export default function QmsDefectiveContent({state, nextTab}: {state: any, nextTab: (name: string) => void}) {
    const [ds_Defect, setDs_Defect] = useState(state.params.get('pv_defect'));
    const [ds_Part, setDs_Part] = useState(state.params.get('pv_data'));
    const ds_DefectGroup = state.params.get('pv_vds_DefectGroup');
    const ds_DefectSymptom2  = state.params.get('pv_vds_DefectSymptom2');
    const {useSelectBox} = useHtmlElement();
    const {getDomain, getMessage} = useGlobalData();
    const {pageMove} = usePageMove();
    const [disabled, setDisabled] = useState(state.params.get('pv_flag') != 'INS');

    useEffect(() => {
        // console.log("불량내용 탭");
        // console.log("state.params ↓");
        // console.log(state.params);
        // console.log("불량내용 탭");
    }, []);

    /* 측정결과 입력 시 */
    const changeInspVal = (e: any, index: number) => {
        /* 변경값 저장 */
        setInspValData(e, index);
    };

    /* 변경값 useState 적용 */
    function setInspValData(e: any, index: number) {
        let copyArray = [...ds_Defect];
        copyArray[index][e.target.name] = e.target.value;

        setDs_Defect(copyArray);
        state.setDataset("ds_Defect", copyArray);
    }

    return (
        <>
            <div className={'contents-table-wrap'} style={{display: 'block'}}>
                {
                    ds_Defect.map((item: any, index: number) => {
                        return <React.Fragment key={uuidv4()}>
                            <h2 className={'h2-title'}>{index + 1}. {item.PARA_NM}</h2>
                            <ul className={'contents-table-list form'}>
                                <li>
                                    <div className={'title'}>{getDomain('DOMAIN2057')}</div> {/*불량수*/}
                                    <div className={'item-wrap'}>{item.DEFECT_CNT}</div>
                                </li>
                                <li>
                                    <div className={'title'} dangerouslySetInnerHTML={{__html: "<span> <strong class='required'>*</strong>"+getDomain('DOMAIN2198')||''+"</span>"}}></div> {/*불량그룹*/}
                                    <div className={'item-wrap'}>
                                        <select
                                            defaultValue={item.DEFECT_GROUP_CD as string}
                                            onChange={(e) => changeInspVal(e, index)}
                                            name={'DEFECT_GROUP_CD'}
                                            disabled={disabled}
                                            >
                                            <option value={""}>==</option>
                                            {
                                                ds_DefectGroup.map((i: any) => {
                                                    return <option key={i.DEFECT_GROUP_CD} value={i.DEFECT_GROUP_CD as string}>{i.DEFECT_GROUP_NAME}</option>;
                                                })
                                            }
                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <div className={'title'} dangerouslySetInnerHTML={{__html: "<span> <strong class='required'>*</strong>"+getDomain('DOMAIN1158')||''+"</span>"}}></div> {/*불량증상*/}
                                    <div className={'item-wrap'}>
                                        <select value={item.SYMPTOM_CD as string}
                                            onChange={(e) => changeInspVal(e, index)}
                                            name={'SYMPTOM_CD'}
                                            disabled={disabled}
                                            >
                                            <option value={""}>==</option>
                                            {
                                                ds_DefectSymptom2
                                                .filter((i: any) => i.DEFECT_GROUP_CD === item.DEFECT_GROUP_CD)
                                                .map((i: any) => {
                                                    return <option key={i.SYMPTOM_CD} value={i.SYMPTOM_CD as string}>{i.SYMPTOM_NAME}</option>;
                                                })
                                            }
                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <div className={'title'} dangerouslySetInnerHTML={{__html: "<span> <strong class='required'>*</strong>"+getDomain('DOMAIN4622')||''+"</span>"}}></div> {/*상세불량*/}
                                    <div className={'item-wrap'}>
                                        <textarea name={'DEFECT_CONTENTS'} onBlur={(e) => changeInspVal(e, index)} defaultValue={item.DEFECT_CONTENTS} disabled={disabled}></textarea>
                                    </div>
                                </li>
                                <li>
                                    <div className={'title'}>
                                        <span>{getDomain('DOMAIN2290')}</span> {/*비고*/}
                                    </div>
                                    <div className={'item-wrap'}>
                                        <input type={'text'} name={'DEFECT_DESC'} onBlur={(e) => changeInspVal(e, index)} defaultValue={item.DEFECT_DESC} disabled={disabled}/>
                                    </div>
                                </li>
                            </ul>
                        </React.Fragment>
                    })
                }
            </div>
            {
                state.params.get('pv_flag') == 'INS'
                ?
                    <div className={'btn-area bottom'}>
                        {
                            ds_Part[0]['GROUP_YN'] == "Y"
                                ?
                                    ''
                                :
                                    <button type={'button'} className={'button-line'} onClick={() => state.fn_tempSaveFile()}>{getDomain('DOMAIN1888')}{/*임시저장*/}</button>
                        }
                        <button type={'button'} className={'button-primary'} onClick={() => nextTab('qmsDefectiveContent')}>{getDomain('DOMAIN3849')}{/*다음*/}</button>
                    </div>
                :
                    <div className={'btn-area bottom'}>
                        <button type={'button'} className={'button-primary'} onClick={() => pageMove(-1)}>{getDomain('DOMAIN4555')}{/*목록으로 이동*/}</button>
                    </div>
            }
        </>
    );
};