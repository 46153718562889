import React, {useEffect} from 'react';

export default function SamplePartInspectionInformation({state, nextTab}: {state: any, nextTab: (name: string) => void}) {
    useEffect(() => console.log(state), []);

    return (
        <>
            <div className={'contents-table-wrap width-type1'} style={{display: 'block'}}>
                <ul className={'contents-table-list'}>
                    <li>
                        <div className={'title'}>관련정보</div>
                        <div className={'item-wrap'}>
                        <span className={'item-btn-wrap'}>
                            <button type={'button'} className={'button-secondary'}>변경점 확인</button>
                            <button type={'button'} className={'button-secondary'}>실패사례</button>
                            <button type={'button'} className={'button-secondary'}>SPEC 첨부파일</button>
                        </span>
                    </div>
                </li>
                <li>
                    <div className={'title'}>
                        <span>
                            <strong className={'required'}>*</strong> 자재구분
                        </span>
                    </div>
                    <div className={'item-wrap'}>
                        <select>
                            <option value="">양산자재</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div className={'title'}>
                        <span>
                            <strong className={'required'}>*</strong> ECN No.
                        </span>
                    </div>
                    <div className={'item-wrap'}>
                        <span className={'search-item'}>
                            <input type={'text'} placeholder=""/>
                            <button type={'button'} className={'icon-btn-search'}><i className={'hidden'}>검색</i></button>
                        </span>
                    </div>
                </li>
                <li>
                    <div className={'title'}>Risk Lot<br/>사전 신고</div>
                    <div className={'item-wrap'}>
                        <label htmlFor={'check'} className={'check-wrap'}>
                            <input type={'checkbox'} id={'check'}/> ※ Risk Lot 사전신고 체크 시 유형 및 메모 필수 입력
                        </label>
                    </div>
                </li>
                <li>
                    <div className={'title'}>
                        <span>
                            <strong className={'required'}>*</strong> Risk Lot<br/>사전 신고 유형
                        </span>
                    </div>
                    <div className={'item-wrap'}>
                        <select>
                            <option value=""></option>
                        </select>
                    </div>
                </li>
                <li>
                    <div className={'title'}>
                        <span>
                            <strong className={'required'}>*</strong> Risk Lot<br/>메모
                        </span>
                    </div>
                    <div className={'item-wrap'}>
                        <textarea></textarea>
                    </div>
                </li>
            </ul>
        </div>
        <div className={'btn-area bottom'}>
            <button type={'button'} className={'button-line'}>임시저장</button>
            <button type={'button'} className={'button-primary'} onClick={() => nextTab('partInspectionInformation')}>다음</button>
        </div>
    </>
);
};