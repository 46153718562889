import React, {Fragment, RefObject, useEffect, useRef, useState} from 'react';
import {NexacroJsonUnitType} from "../../../../components/model/SqciTypes";
import * as gfn from "../../../../components/utils/CommonUtils";
import {useComponent} from "../../../../components/contexts/ComponentContext";
import useGlobalData from "../../../../hooks/useGlobalData";

export default function JudgmentResult(
        {state, ds_Part, ds_upload, ds_remove, reportRef, fn_changDs}:
        {
            state: string,
            ds_Part: NexacroJsonUnitType[],
            ds_upload: NexacroJsonUnitType[],
            ds_remove: NexacroJsonUnitType[],
            reportRef: RefObject<HTMLInputElement>,
            fn_changDs:(datasetName: string, dataset: Array<NexacroJsonUnitType>) => void
        }
    ) {
    const {setDialog,setLoader} = useComponent();
    const {getDomain, getMessage} = useGlobalData();

    const partHandleChange = (e: any, columnN :string) => {
        let index = 0;
        let copyArray = [...ds_Part];
        copyArray[index][columnN] = e.target.value;
        fn_changDs('ds_Part', copyArray);
    };

    /**
     * 출하검사 성적서 업로드 click event
     */
    const reportCallback = () => {
        reportRef.current?.click();
    };

    /**
     * 멀티 파일 업로드 change event
     * @param e
     */
    const reportChangeCallback = async (e: React.ChangeEvent<HTMLInputElement>) => {
        await setLoader(true);
        let copyArray = [...ds_upload];
        copyArray = copyArray.filter(item => item.FILE_ID != '');
        if (e.target.files?.length != undefined) {
            for (let i = 0; i < e.target.files?.length; i++) {
                let file = e.target.files[i];

                copyArray.push({
                    FILE_ID:'',
                    FILE_NAME:file.name,
                    FILE_SIZE:file.size,
                    prog:"0",
                    fileType:file.type
                });
            }
        }

        let fileCnt = 10;
        if(copyArray.length > fileCnt){
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG01067").replace("{0}", String(fileCnt))
            });
            copyArray = copyArray.filter(item => item.FILE_ID != '');
        }

        fn_changDs('ds_upload', copyArray);
        await setLoader(false);
    };

    /**
     * 특정 파일 삭제
     * @param fItem
     * @param fIndex
     */
    const reportDeleteCallback = (fItem: NexacroJsonUnitType, fIndex:number) => {
        let copyArray = [...ds_upload];
        let temp_ds_upload = copyArray.filter((item,index) => index != fIndex);
        fn_changDs('ds_upload', temp_ds_upload);

        if(fItem.FILE_ID === ""){
        }else{
            let temp_ds_remove = [...ds_remove];
            temp_ds_remove.push(copyArray.find((item,index) => index === fIndex)||{});
            fn_changDs('ds_remove', temp_ds_remove);
        }
    };

    function downloadFile(fileId: string, fileSeq: string){
        const link = document.createElement('a');
        link.href = process.env.REACT_APP_HTTP_BASE_URL+"/common/nexacro/commonFileDownload.do?fileId="+fileId+"&fileSeq="+fileSeq;
        link.style.display = 'none';
        link.click();
        link.remove();
    }

    return (
        <>
            {state === "INFO" ?
            <div className={'contents-table-wrap width-type2'} style={{display: 'block'}}>
                {
                    ds_Part.filter((item, index) => index === 0).map((item, index) => {
                        return (
                            <React.Fragment key={'react_'.concat(String(index))}>
                            <div className={'result-state'}>
                                {
                                    gfn.gfn_isNull(item["LOT_RESULT"]) ?
                                        <span key={index} className={'result fail'}>==</span>    /*==*/
                                        :
                                        item["LOT_RESULT"] == 'N' ?
                                            <span key={index} className={'result fail'}>{getDomain('DOMAIN2332')}</span>    /*불합격*/
                                            :
                                            <span key={index} className={'result pass'}>{getDomain('DOMAIN2542')}</span>    /*합격*/
                                }

                            </div>
                            <ul className={'contents-table-list form'}>
                                <li>
                                    <div className={'title'}>
                                        <span>{getDomain('DOMAIN4623')}</span>  {/*메모*/}
                                    </div>
                                    <div className={'item-wrap'}>
                                        <textarea defaultValue={item.LOT_MEMO||''} onBlur={e => partHandleChange(e, 'LOT_MEMO')}></textarea>
                                    </div>
                                </li>
                            </ul>
                            <div className={'title-wrap'}>
                                <h2 className={'h2-title'}>{getDomain('DOMAIN4624')}{/*첨부파일*/}</h2>
                                <p>{getDomain('DOMAIN4625')}</p>
                            </div>
                            <ul className={'contents-table-list form'}>
                                <li>
                                    <div className={'title'}>{getDomain('DOMAIN4624')}{/*첨부파일*/}</div>
                                    <div className={'item-wrap'}>
                                        <input type={'file'} multiple={true}
                                               ref={reportRef}
                                               accept={"*"} style={{display: "none"}} onChange={e => reportChangeCallback(e)}/>
                                        <button type={'button'} className={'button-upload'} onClick={() => reportCallback()}>{getDomain('DOMAIN4626')}  {/*업로드*/}</button>
                                    </div>
                                </li>
                            </ul>
                            <ul className={'attach-file-list'}>
                                {
                                    ds_upload.map((fItem,fIndex) => {
                                        return <React.Fragment key={'partFile_'.concat(String(fIndex))}>
                                            <li>
                                                <div className={'file-item'}>
                                                    <button type={'button'} className={'file-download'}><span
                                                        className={'text'}><em>Image</em> {gfn.fn_getFileSizeDisplay(fItem.FILE_SIZE)+"KB"}</span></button>
                                                    <button type={'button'} className={'button-delete'} onClick={() => reportDeleteCallback(fItem,fIndex)}>
                                                        <i className={'hidden'}>{getDomain('DOMAIN0229')}{/*삭제*/}</i>
                                                    </button>
                                                </div>
                                            </li>
                                        </React.Fragment>
                                    })
                                }
                            </ul>
                        </React.Fragment>
                        )
                    })
                }
            </div>
            :
            <div className={'contents-table-wrap width-type2'} style={{display: 'block'}}>
                {
                    ds_Part.filter((item, index) => index === 0).map((item, index) => {
                        return (
                            <React.Fragment key={'react_'.concat(String(index))}>
                                <div className={'result-state'}>
                                    {
                                        item["LOT_RESULT"] == 'N' ?
                                            <span key={index} className={'result fail'}>{getDomain('DOMAIN2332')}</span>    /*불합격*/
                                            :
                                            <span key={index} className={'result pass'}>{getDomain('DOMAIN2542')}</span>    /*합격*/
                                    }

                                </div>
                                <ul className={'contents-table-list form'}>
                                    <li>
                                        <div className={'title'}>
                                            <span>{getDomain('DOMAIN4623')}</span>  {/*메모*/}
                                        </div>
                                        <div className={'item-wrap'}>
                                            <textarea defaultValue={item.LOT_MEMO||''} readOnly={true}></textarea>
                                        </div>
                                    </li>
                                </ul>
                                {/*첨부파일이 존재할 경우*/}
                                {
                                    ds_upload.length > 0 ?
                                    <React.Fragment>
                                        <div className={'title-wrap'}>
                                            <h2 className={'h2-title'}>{getDomain('DOMAIN4624')}{/*첨부파일*/}</h2>
                                            <p>{getDomain('DOMAIN4625')}</p>
                                        </div>
                                        <ul className="contents-table-list">
                                            <li>
                                                <div className="file-title">{getDomain('DOMAIN4624')}</div>   {/*첨부파일*/}
                                                <div className="item-wrap">
                                                    <ul className="file-list">
                                                        {
                                                            ds_upload.map((fItem,fIndex) => {
                                                                return <React.Fragment key={'partFile_'.concat(String(fIndex))}>
                                                                    <li>
                                                                        <div className="file-item" onClick={() => downloadFile(fItem.FILE_ID as string, fItem.FILE_SEQ as string)}>
                                                                            <em>{fItem.FILE_NAME}</em>
                                                                            <span>{gfn.fn_getFileSizeDisplay(fItem.FILE_SIZE)+"KB"}</span>
                                                                        </div>
                                                                    </li>
                                                                </React.Fragment>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </React.Fragment>
                                    :
                                    <React.Fragment></React.Fragment>
                                }
                            </React.Fragment>
                        )
                    })
                }
            </div>
            }
        </>
    );
};