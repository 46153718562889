/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > Test 결과
 * 02. 프로그램ID : QmsMTestListResult.tsx
 * 03. 프로그램명 : 
 * 04. 화면설명 : 
 * 05. 작성일 : 
 * 06. 작성자 : 
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 * 
 **************************************************************************************************
 */

import React, {useEffect, useState} from 'react';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
import {
    CardViewType,
    ComponentHistoryType,
    NexacroJsonType,
    NexacroJsonUnitType
} from '../../components/model/SqciTypes';
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {useDataset} from "../../components/contexts/CommonContext";
import {toNumber} from "lodash";
import usePageMove from "../../hooks/usePageMove";
import useGlobalData from "../../hooks/useGlobalData";
import {STORAGE_NAME} from "../../components/CommonConstants";
import {gfn_isNull} from "../../components/utils/CommonUtils";
import {useComponent} from "../../components/contexts/ComponentContext";
import {CSSTransition} from "react-transition-group";
import QmsTranLineMeas from "./QmsTranLineMeas";
import QmsInspGroup from "./QmsInspGroup";
import InfiniteCardView from "../common/InfiniteCardView";
import * as gfn from "../../components/utils/CommonUtils";
import {useError} from "../../hooks/useException";
import {v4 as uuidv4} from "uuid";

export default function QmsMIncmInspListResult() {
    /* react, 공통 변수 선언 */
    const {sendAxios} = useAxios();
    const navigate = useNavigate();
    const {state} = useLocation();
    const {dataset, sessionValues} = useDataset();
    const {pageMove} = usePageMove();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const sLocale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const {setDialog} = useComponent();
    const [showSwiperLineMeas, setShowSwiperLineMeas] = useState<boolean>(false);
    const [showSwiperInspGroup, setShowSwiperInspGroup] = useState<boolean>(false);
    const {throwException} = useError();

    /* 로직 변수 선언 */
    let [ds_List, setDs_List] = useState(new Array<any>());
    const [cardData, setCardData] = useState(new Array<any>());
    const [selectedCardDataIdx, setSelectedCardDataIdx] = useState(-1);
    const [secAnlResult, setSecAnlResult] = useState(String);
    let fv_InspResultNo = "";
    let fv_Qty = 0; //  검사할 수량
    let fv_qmvFlag = ""; // 출하성적서 열기 플래그
    let fv_saveListFlag = "N"; // 목록 판정 플래그
    let fv_groupFlag = "N"; // 그룹 판정 플래그
    let fv_refreshFlag = "N"; // 화면 리프레시 할건지 플래그
    let fv_isChangeSpecFlag = false; // 임시저장된 데이터의 Spec 변경 여부 변수 선언

    const [ds_resList, setDsList] = useState(new Array<any>());
    const [ds_Aql, setDs_Aql] = useState(new Array<NexacroJsonUnitType>());
    const [ds_AllSample, setDs_AllSample] = useState(new Array<NexacroJsonUnitType>());
    const [ds_IssuePartList, setDs_IssuePartList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_SubmitInspList, setDs_SubmitInspList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_chngPoingHList, setDs_chngPoingHList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Defect, setDs_Defect] = useState(new Array<NexacroJsonUnitType>());
    const [ds_FailCaseList, setDs_FailCaseList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_InspDetailList, setDs_InspDetailList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_ListLineMeas, setDs_ListLineMeas] = useState(new Array<NexacroJsonUnitType>());
    const [ds_ListSelect, setDs_ListSelect] = useState(new Array<NexacroJsonUnitType>());
    const [ds_GroupList, setDs_GroupList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Group, setDs_Group] = useState(new Array<NexacroJsonUnitType>());
    const [lineSaveDisabled, setLineSaveDisabled] = useState(false);
    const [seed, setSeed] = useState(1);

    let vds_Search = new Array<NexacroJsonUnitType>(state.requestJson.ds_Search);
    let vds_Aql = new Array<NexacroJsonUnitType>();
    let vds_AllSample = new Array<NexacroJsonUnitType>();
    let vds_popChngPoingHList = new Array<NexacroJsonUnitType>();
    let vds_popChngPoingHListFiltered = new Array<NexacroJsonUnitType>();
    let vds_BaseLine = new Array<NexacroJsonUnitType>();
    let vds_FailCaseList = new Array<NexacroJsonUnitType>();
    let vds_Group: any = [];
    let vds_GroupList = new Array<NexacroJsonUnitType>();
    let vds_ListSelect = new Array<NexacroJsonUnitType>();
    let vds_Spec = new Array<NexacroJsonUnitType>();
    let vds_Defect = new Array<NexacroJsonUnitType>();
    let vds_Sample = new Array<NexacroJsonUnitType>();
    let vds_searchSpecApproveStatus = new Array<NexacroJsonUnitType>();
    let vds_SpecApproveStatus = new Array<NexacroJsonUnitType>();
    let vds_IssuePartList = new Array<NexacroJsonUnitType>();
    let vds_SubmitInspList = new Array<NexacroJsonUnitType>();
    let vds_SearchInspDetail = new Array<NexacroJsonUnitType>();
    let vds_SearchSpec = new Array<NexacroJsonUnitType>();
    let vds_InspDetail = new Array<NexacroJsonUnitType>();

    let data: CardViewType[] = [];
    const [infiniteScroll, setInfiniteScroll]= useState<CardViewType[]>([]);

    const location = useLocation();
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
    const [isComponentLoaded, setIsComponentLoaded] = useState<boolean>(false);
    useEffect(() => {
        if((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            setIsComponentLoaded(true);

            form_onload();
        }
    }, [enteredComponent, exitedComponent]);

    /* 화면 onload */
    function form_onload() {
 
    }

    

    /* 입고검사대상 및 결과입력 목록 조회 */
    function fn_tranSearch() {
        setSeed(Math.random());
    }

    const getData = async (start: number, end: number) => {
        let temp_ds_Search: NexacroJsonUnitType[] = state.requestJson.ds_search;
        temp_ds_Search[0]['RECORDS'] = String(end);
        temp_ds_Search[0]['RECORDSOFFSET'] = String(start);

        if(gfn.gfn_isNull(temp_ds_Search)){
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG01526"), /*"올바르지 않은 경로입니다."*/
                confirm: () => {
                    pageMove(-1);
                }
            });
            return[];
        }

    return await sendAxios('/common/nexacro/selectVenderListTest.do', 'POST', AXIOS_HEADER.NEXACRO, state.requestJson).then(response => {
        let temp_ds_List:NexacroJsonUnitType[] = response?.data.ds_listAll||[];
        if(temp_ds_List.length == 0 && start === 1){
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage('MSG00930'), //검색결과가 없습니다.
                confirm: () => {
                    pageMove(-1);
                }
            });
            return [];
        }

        let copyArray = [...ds_List];
        setDsList([
            ...copyArray || [],
            ...response?.data.ds_listAll||[]
        ]);

        let cardL:CardViewType[] = (temp_ds_List||[]).map((item:NexacroJsonUnitType, index:number) => {
            let hqCd = item.HQ_CD;
            let hqCdStyle = '';
            let hqNm = item.HQ_NM;
            let hqNmStyle = '';
            let hqCountry = item.HQ_COUNTRY;
            let hqCountryStyle = '';
            let dealType = item.DEAL_TYPE;
            let dealTypeStyle = '';
            let closeBtn = true;


            hqCdStyle = 'label-type4';
            hqNmStyle = 'label-type4';
            hqCountryStyle = 'label-type4';
            dealTypeStyle = 'label-type4';

            let tempLotSize = Number(item.ORG_LOT_SIZE).toLocaleString('ko-KR');

            let cardD:CardViewType = {
                id: uuidv4(),
                label: [{
                    name: String(item.HQ_CD),
                    style: hqCdStyle
                }],
                title: {
                    category:  '[' + item.HQ_CD + ']' + ' ['+ item.HQ_NM +']',
                    name: String(item.HQ_NM)
                },
                state: [                   
                    {
                        left: getDomain('DOMAIN2812')+' : ' + String(item.DEAL_TYPE || ''),
                        right: String('')
                    }
                ],
                useClose: closeBtn
            }
            return cardD;
        });
        setInfiniteScroll([
            ...infiniteScroll || [],
            ...cardL
        ]);
        return cardL;
    });
};

    return (
        <>
            <div className={'container'}>
                {
                    isComponentLoaded ? <InfiniteCardView
                        listCount={10}
                        getData={(start: number, end: number) => getData(start, end)}
                        multiSelection={false}
                    /> : null
                }
            </div>
        </>
    );
};