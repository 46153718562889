import React from 'react';

export default function SampleBasicInformation({state, nextTab}: {state: any, nextTab: (name: string) => void}) {
    return (
        <>
            <div className={'contents-table-wrap width-type2'} style={{display: 'block'}}>
                <ul className={'contents-table-list'}>
                    <li>
                        <div className={'title'}>사업부</div>
                        <div className={'item-wrap ellipsis-text'}>MK</div>
                    </li>
                    <li>
                        <div className={'title'}>Plant</div>
                        <div className={'item-wrap ellipsis-text'}>[P518] SEV</div>
                    </li>
                    <li>
                        <div className={'title'}>협력사</div>
                        <div className={'item-wrap ellipsis-text'}>[B36C] DAP</div>
                    </li>
                    <li>
                        <div className={'title'}>IPC Vendor</div>
                        <div className={'item-wrap ellipsis-text'}>-</div>
                    </li>
                    <li>
                        <div className={'title'}>거래유형</div>
                        <div className={'item-wrap ellipsis-text'}>내자거래</div>
                    </li>
                    <li>
                        <div className={'title'}>부품명</div>
                        <div className={'item-wrap ellipsis-text'}>ETC</div>
                    </li>
                    <li>
                        <div className={'title'}>DMR Stage</div>
                        <div className={'item-wrap ellipsis-text'}>[F31-50] 1Lot 관리검사</div>
                    </li>
                    <li>
                        <div className={'title'}>Invoice No</div>
                        <div className={'item-wrap ellipsis-text'}>C1234949-14/05</div>
                    </li>
                    <li>
                        <div className={'title'}>생산일자</div>
                        <div className={'item-wrap ellipsis-text'}>2023-04-09</div>
                    </li>
                    <li>
                        <div className={'title'}>입고수량</div>
                        <div className={'item-wrap ellipsis-text'}>5,000</div>
                    </li>
                    <li>
                        <div className={'title'}>입고일시</div>
                        <div className={'item-wrap ellipsis-text'}>2023-04-09 13:26:48</div>
                    </li>
                    <li>
                        <div className={'title'}>검사기준서 상태</div>
                        <div className={'item-wrap ellipsis-text'}>확정</div>
                    </li>
                    <li>
                        <div className={'title'}>검사원</div>
                        <div className={'item-wrap ellipsis-text'}>이성우</div>
                    </li>
                    <li>
                        <div className={'title'}>검사일시</div>
                        <div className={'item-wrap ellipsis-text'}>2023-04-09</div>
                    </li>
                </ul>
            </div>
            <div className={'btn-area bottom'}>
                <button type={'button'} className={'button-line'}>임시저장</button>
                <button type={'button'} className={'button-primary'} onClick={() => nextTab('basicInformation')}>다음</button>
            </div>
        </>
    );
};