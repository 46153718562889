import React, {useEffect, useState} from 'react';
import {useLocation, useOutletContext} from 'react-router-dom';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
import {useError} from '../../hooks/useException';
import {unescape} from 'lodash';
import {ComponentHistoryType} from '../../components/model/SqciTypes';

export default function Terms() {
    const location = useLocation();
    const {sendAxios} = useAxios();
    const {throwException} = useError();
    const [termsParams, setTermsParams] = useState(new Map);// 2024.11.08 zilong.ge 서비스 이용약관 및 글로벌향 p.p 추가 반영 요청-mobile

    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
    useEffect(() => {
        if((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            sendAxios('/common/anonymous/privacyPolicy/mobileViewTerms.do', 'POST', AXIOS_HEADER.JSON, {
                langType: location.state.userLang || 'KO',
                // 2024.11.08 zilong.ge 서비스 이용약관 및 글로벌향 p.p 추가 반영 요청-mobile
                partId: termsParams.get('partId') || (location.state.partId || 'AU4ASjv6AAFlNm02'),
                termsId: termsParams.get('termsId') || ''
            })
            .then(response => {
                // 2024.11.08 zilong.ge 서비스 이용약관 및 글로벌향 p.p 추가 반영 요청-mobile
                termsParams.set('terms', unescape(response?.data.terms || ''));
                termsParams.set('partId', response?.data.partId || '');
                termsParams.set('termsList', response?.data.termsList || new Array());
                setTermsParams(termsParams);
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {});
            });
        }
    }, [enteredComponent, exitedComponent, termsParams]);// 2024.11.08 zilong.ge 서비스 이용약관 및 글로벌향 p.p 추가 반영 요청-mobile

    // start 2024.11.08 zilong.ge 서비스 이용약관 및 글로벌향 p.p 추가 반영 요청-mobile
    const setCountrySelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let sTermsParams = new Map(termsParams);
        sTermsParams.set('partId', event.target.value);
        sTermsParams.set('termsId', '');
        setTermsParams(sTermsParams);
    };
    
    const setStartDateSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let sTermsParams = new Map(termsParams);
        sTermsParams.set('termsId', event.target.value);
        setTermsParams(sTermsParams);
    };
    // end 2024.11.08 zilong.ge 서비스 이용약관 및 글로벌향 p.p 추가 반영 요청-mobile
    
    return (
        <>
            <div className={'container'}>
                <div className={'privacy-wrap'}>
                    {/* start 2024.11.08 zilong.ge 서비스 이용약관 및 글로벌향 p.p 추가 반영 요청-mobile*/}
                    <div style={{background: '#999999'}}>
                        <ul className={'date-list'} style={{padding:'0.5rem 1rem'}}>
                            {
                                <li className={'card-item'} style={{margin: 'auto'}}>
                                    <span style={{width: '50%'}}><b>국가별 Privacy Policy</b></span>
                                    <em style={{width: '50%'}}>
                                        <select style={{width: '50%', height: '28px'}}
                                                defaultValue={'AU4AS0I6AAJlNm02'}
                                                onChange={setCountrySelected}>
                                            <option value="AU4AS0I6AAJlNm02">대한민국</option>
                                            <option value="GDPR_POLISH">EU</option>
                                            <option value="CHINA_POLISH">China</option>
                                            <option value="VIETNAM_POLISH">Vietnam</option>
                                            <option value="US_POLISH">US</option>
                                            <option value="LA_POLISH">Latin America</option>
                                            <option value="TURKEY_POLISH">Turkey</option>
                                            <option value="OTHERS_POLISH">Others</option>
                                        </select>
                                    </em>
                                </li>
                            }
                            {
                                <li className={'card-item'} style={{margin: '0.5rem 0 0 0'}}>
                                    <span style={{width: '50%'}}><b>버전 및 시행일자</b></span>
                                    <em style={{width: '50%'}}>
                                        <select style={{width: '50%', height: '28px'}} 
                                                onChange={setStartDateSelected}>
                                            {
                                                termsParams.get("termsList") && termsParams.get("termsList").map((i: any) => {
                                                    return <option key={i.termsId} value={i.termsId as string}>{i.version}/{i.startDate}</option>;
                                                })
                                            }
                                        </select>
                                    </em>
                                </li>
                            }
                        </ul>
                    </div>
                    {/* end 2024.11.08 zilong.ge 서비스 이용약관 및 글로벌향 p.p 추가 반영 요청-mobile*/}
                    <div className={'privacy-statement terms-content'} style={{overflow: 'auto', marginTop: 'auto'}} dangerouslySetInnerHTML={{__html: termsParams.get("terms") || ''}}></div>
                </div>
            </div>
        </>
    );
};