import React from 'react';
import {ExceptionAfterAction, ExceptionNoticeType} from '../model/SqciTypes';

interface ExceptionType {
    type: ExceptionNoticeType;
    action: ExceptionAfterAction;
    message?: string;
}

export const SQCI_EXCEPTION = {
    INVALID_SESSION: {
        type: 'PAGE',
        action: 'LOGOUT',
        message: 'Current user is not logged in or already logged out.'     // 현재 사용자가 로그인되어 있지 않거나 이미 로그아웃되었습니다.
    } as ExceptionType,
    DESTROYED_SESSION: {
        type: 'PAGE',
        action: 'REFRESH',
        message: '서버와의 연결이 끊어졌습니다.<br/>브라우저 캐시를 지우고 다시 시도하세요.<br/>The connection to the server has been lost.<br/>Clear your browser cache and try again.'     // 서버와의 연결이 끊어졌습니다. 브라우저 캐시를 지우고 다시 시도하세요.
    } as ExceptionType,
    SAME_ACCOUNT: {
        type: 'PAGE',
        action: 'LOGOUT',
        message: 'Signed out due to sign in to another device.'     // 동일한 계정으로 다른 기기에 로그인하여 자동으로 로그아웃되었습니다.
    } as ExceptionType,
    COMMON_ERROR: {
        type: 'ALERT',
        action: 'NONE',
        message: 'MSG01529'     // 오류가 발생했습니다. 나중에 다시 시도 해주십시오.
    } as ExceptionType,
    NEXACRO_PARSING_ERROR: {
        type: 'ALERT',
        action: 'NONE',
        message: 'MSG01529'     // Nexacro 데이터 파싱 실패(MSG01530) : 사용자에게 노출 할 필요 없음. 일반 에러 메세지로 교체
    } as ExceptionType,
    EXPIRE_PRIVATE_KEY: {
        type: 'ALERT',
        action: 'REFRESH',
        // message: 'The waiting time has been exceed. Please, login again.'
        message: 'The input waiting time has expired.\nPlease log in again.'
    } as ExceptionType,
    WRONG_PASSWORD: {
        type: 'ALERT',
        action: 'REFRESH',
        message: 'ID / Password is wrong.\nPlease check ID / Password.'
    } as ExceptionType,
    EXPIRED_PASSWORD: {
        type: 'ALERT',
        action: 'NONE',
        message: '비밀번호는 매 30일마다 변경 하셔야 합니다. SQCI 웹에서 비밀번호를 변경해 주세요.\n\nYou have to change your password every 30 days. Please change the password in SQCI web.'     // 비밀번호는 매 30일마다 변경 하셔야 합니다.SQCI 웹에서 비밀번호를 변경해 주세요.
    } as ExceptionType,
    EXPIRED_USER: {
        type: 'ALERT',
        action: 'EXPIRED',
        message: 'You are inactive because you have retired, transferred, or attempted passwords more than 5 times.\n\nForget your password?'     // 비밀번호 5회 이상 틀림.
    } as ExceptionType,
    PENDING_APPROVAL: {
        type: 'ALERT',
        action: 'NONE',
        message: '현재 승인 대기 중 입니다.\nCurrently pending approval.'
    } as ExceptionType
} as const

export class SqciException extends Error {
    error: ExceptionType;
    constructor(error: ExceptionType) {
        super();
        this.error = error;
    }
}