import React, {useCallback, useEffect, useState} from 'react';
import {NexacroJsonUnitType, SelectBoxType} from "../../../../components/model/SqciTypes";
import useHtmlElement from "../../../../hooks/useHtmlElement";
import useGlobalData from "../../../../hooks/useGlobalData";

export default function DefectiveContent(
        {state, ds_DefectGroup, ds_DefectSymptom2, ds_Defect, fn_changDs}:
        {
            state: string,
            ds_DefectGroup: NexacroJsonUnitType[],
            ds_DefectSymptom2: NexacroJsonUnitType[],
            ds_Defect: NexacroJsonUnitType[],
            fn_changDs:(datasetName: string, dataset: Array<NexacroJsonUnitType>) => void
        }
    ) {
    const {useSelectBox} = useHtmlElement();
    const {getDomain, getMessage} = useGlobalData();

    const defectGroups = useSelectBox('defectGroup');
    const defectSymptom2s = useSelectBox('defectSymptom2');

    useEffect(() => {
        defectGroups.initOptions(() => {
            let returnList: SelectBoxType[] = [];

            for (const f of ds_DefectGroup || []) {
                returnList.push({
                    value: String(f.DEFECT_GROUP_CD),
                    text: String(f.DEFECT_GROUP_NAME)
                })
            }
            return returnList;
        });

        defectSymptom2s.initOptions(() => {
            let returnList: SelectBoxType[] = [];

            for (const f of ds_DefectSymptom2 || []) {
                returnList.push({
                    value: String(f.SYMPTOM_CD),
                    text: String(f.SYMPTOM_NAME)
                })
            }
            return returnList;
        });

        // 23.10.04 순번을 위해 추가함
        changeIdxDefect(ds_Defect);
    }, []);

    const defectHandleChange = (e: any, index :number, columnN :string) => {
        let copyArray = [...ds_Defect];
        copyArray[index][columnN] = e.target.value;
        changeIdxDefect(copyArray);
        fn_changDs('ds_Defect', copyArray);

        e.target.blur();
    };

    const changeIdxDefect = (copyArray : NexacroJsonUnitType[]) => {
        let idx = 1;
        copyArray.forEach(item => {
            if(item.ROW_TYPE !== 'D'){
                item.IDX_MOBILE = idx++;
            }
        });
    };

    return (
        <>
            {state === "INFO" ?
            <div className={'contents-table-wrap'} style={{display: 'block'}}>
                {
                    ds_Defect
                        // .filter(item => item.ROW_TYPE !== 'D')
                        .map((item, index) => {
                        return <React.Fragment key={'defect_'.concat(String(item.PARA_CD)).concat('_').concat(String(index))}>
                            {item.ROW_TYPE !== 'D' ?
                                <React.Fragment>
                                <h2 className={'h2-title'}>{item.IDX_MOBILE}. {item.PARA_NM}</h2>
                                <ul className={'contents-table-list form'}>
                                    <li>
                                        <div className={'title'}>{getDomain('DOMAIN2057')}</div> {/*불량수*/}
                                        <div className={'item-wrap'}>{item.DEFECT_CNT}</div>
                                    </li>
                                    <li>
                                        <div className={'title'} dangerouslySetInnerHTML={{__html: "<span> <strong class='required'>*</strong> "+getDomain('DOMAIN2198')||''+"</span>"}}></div> {/*불량그룹*/}
                                        <div className={'item-wrap'}>
                                            <select
                                                value={item.DEFECT_GROUP_CD||''}
                                                onChange={(e) => defectHandleChange(e, index, 'DEFECT_GROUP_CD')}
                                                name={'DEFECT_GROUP_CD'}
                                            >
                                                <option value={""}>==</option>
                                                {
                                                    ds_DefectGroup.map((i, index) => {
                                                        return <option key={'defectGroupCd_'.concat(String(item.PARA_CD)).concat('_').concat(String(index))} value={i.DEFECT_GROUP_CD||''}>{i.DEFECT_GROUP_NAME}</option>;
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={'title'} dangerouslySetInnerHTML={{__html: "<span> <strong class='required'>*</strong> "+getDomain('DOMAIN1158')||''+"</span>"}}></div> {/*불량증상*/}
                                        <div className={'item-wrap'}>
                                            {/* "ds_Defect_onvaluechanged" 대체 */}
                                            <select
                                                value={item.SYMPTOM_CD||''}
                                                onChange={(e) => defectHandleChange(e, index, 'SYMPTOM_CD')}
                                                name={'SYMPTOM_CD'}
                                            >
                                                <option value={""}>==</option>
                                                {
                                                    ds_DefectSymptom2
                                                        .filter((i, index) => i.DEFECT_GROUP_CD === item.DEFECT_GROUP_CD)
                                                        .map((i, index) => {
                                                        return <option key={'symptomCd_'.concat(String(item.PARA_CD)).concat('_').concat(String(index))} value={i.SYMPTOM_CD as string}>{i.SYMPTOM_NAME}</option>;
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={'title'} dangerouslySetInnerHTML={{__html: "<span> <strong class='required'>*</strong> "+getDomain('DOMAIN4622')||''+"</span>"}}></div> {/*상세불량*/}
                                        <div className={'item-wrap'}>
                                            <textarea
                                                defaultValue={item.DEFECT_CONTENTS||''}
                                                onBlur={(e) => defectHandleChange(e, index, 'DEFECT_CONTENTS')}>
                                            </textarea>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={'title'}>
                                            <span>{getDomain('DOMAIN2290')}</span> {/*비고*/}
                                        </div>
                                        <div className={'item-wrap'}>
                                            <input
                                                type={'text'}
                                                defaultValue={item.DEFECT_DESC||''}
                                                onChange={(e) => defectHandleChange(e, index, 'DEFECT_DESC')}/>
                                        </div>
                                    </li>
                                </ul>
                            </React.Fragment>
                            :
                            <React.Fragment></React.Fragment>
                            }
                        </React.Fragment>
                    })
                }
            </div>
            :
                <div className={'contents-table-wrap'} style={{display: 'block'}}>
                    {
                        ds_Defect
                            .filter(item => item.ROW_TYPE !== 'D')
                            .map((item, index) => {
                                return <React.Fragment key={'defect_'.concat(String(item.PARA_CD)).concat('_').concat(String(index))}>
                                    <h2 className={'h2-title'}>{index + 1}. {item.PARA_NM}</h2>
                                    <ul className={'contents-table-list form'}>
                                        <li>
                                            <div className={'title'}>{getDomain('DOMAIN2057')}</div> {/*불량수*/}
                                            <div className={'item-wrap'}>{item.DEFECT_CNT}</div>
                                        </li>
                                        <li>
                                            <div className={'title'} dangerouslySetInnerHTML={{__html: "<span> <strong class='required'>*</strong> "+getDomain('DOMAIN2198')||''+"</span>"}}></div> {/*불량그룹*/}
                                            <div className={'item-wrap'}>
                                                {
                                                    ds_DefectGroup
                                                        .filter((i,index) => i.DEFECT_GROUP_CD === item.DEFECT_GROUP_CD )
                                                        .map((i, index) => {
                                                            return <React.Fragment key={'defectGroupCd_'.concat(String(item.PARA_CD)).concat('_').concat(String(index))}>{i.DEFECT_GROUP_NAME}</React.Fragment>;
                                                        })
                                                }
                                            </div>
                                        </li>
                                        <li>
                                            <div className={'title'} dangerouslySetInnerHTML={{__html: "<span> <strong class='required'>*</strong> "+getDomain('DOMAIN1158')||''+"</span>"}}></div> {/*불량증상*/}
                                            <div className={'item-wrap'}>
                                                {/* "ds_Defect_onvaluechanged" 대체 */}
                                                {
                                                    ds_DefectSymptom2
                                                        .filter((i,index) => i.SYMPTOM_CD === item.SYMPTOM_CD )
                                                        .map((i, index) => {
                                                            return <React.Fragment key={'symptomCd_'.concat(String(item.PARA_CD)).concat('_').concat(String(index))}>{i.SYMPTOM_NAME}</React.Fragment>;
                                                        })
                                                }
                                            </div>
                                        </li>
                                        <li>
                                            <div className={'title'} dangerouslySetInnerHTML={{__html: "<span> <strong class='required'>*</strong> "+getDomain('DOMAIN4622')||''+"</span>"}}></div> {/*상세불량*/}
                                            <div className={'item-wrap'}>
                                                <textarea
                                                    defaultValue={item.DEFECT_CONTENTS||''} readOnly={true}>
                                                </textarea>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={'title'}>
                                                <span>{getDomain('DOMAIN2290')}</span> {/*비고*/}
                                            </div>
                                            <div className={'item-wrap'}>
                                                <input
                                                    type={'text'}
                                                    defaultValue={item.DEFECT_DESC||''} readOnly={true}/>
                                            </div>
                                        </li>
                                    </ul>
                                </React.Fragment>
                            })
                    }
                </div>
            }
        </>
    );
};