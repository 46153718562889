import React, {useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import QmvDetailInspectDetail from './QmvDetailInspectDetail';
import {NexacroJsonUnitType} from "../../../../components/model/SqciTypes";
import useGlobalData from "../../../../hooks/useGlobalData";

export default function QmvDetailInspectList(
        {state, ds_Part, ds_Spec, ds_grdColList, fn_changDs, fn_setSpecGrid_N}:
        {
            state: string,
            ds_Part: NexacroJsonUnitType[],
            ds_Spec: NexacroJsonUnitType[],
            ds_grdColList: NexacroJsonUnitType[],
            fn_changDs:(datasetName: string, dataset: Array<NexacroJsonUnitType>) => void,
            fn_setSpecGrid_N:(sgIndex:number, sgBoolean:boolean, sgDs_Spec: Array<NexacroJsonUnitType>, sgDs_Part: Array<NexacroJsonUnitType>) => Array<NexacroJsonUnitType>
        }
    ) {
    const {getDomain} = useGlobalData();

    const [showSwiper, setShowSwiper] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>();

    const goDetail = (index: number) => {
        setShowSwiper(false);
        setSelectedIndex(index);
    };

    const closeDetail = () => {
        setShowSwiper(false);
        setSelectedIndex(undefined);
    };

    useEffect(() => {
        if(selectedIndex !== undefined) {
            setShowSwiper(true);
        }
    }, [selectedIndex]);

    return (
        <>
            <div className={'table-wrap'} style={{display: 'block'}}>
                <table>
                    <colgroup>
                        <col style={{width: '3rem'}}/>
                        <col/>
                        <col style={{width: '4.8rem'}}/>
                        <col style={{width: '4.8rem'}}/>
                        <col style={{width: '4.8rem'}}/>
                        <col style={{width: '4.2rem'}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope={'col'}>{getDomain('DOMAIN4608')} {/*No*/}		</th>
                            <th scope={'col'}>{getDomain('DOMAIN4758')} {/*검사항목*/}	</th>
                            <th scope={'col'}>{getDomain('DOMAIN2056')} {/*샘플수*/}		</th>
                            <th scope={'col'}>{getDomain('DOMAIN4619')} {/*검사수*/}		</th>
                            <th scope={'col'}>{getDomain('DOMAIN2057')} {/*불량수*/}		</th>
                            <th scope={'col'}>{getDomain('DOMAIN4620')} {/*결과*/}		</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        ds_Spec.map((item, index) => {
                            return <tr
                                key={'tr_'.concat(String(index))}
                                className={item.MEAS_RSLT === 'Wait' ? 'bg-color-y' : item.MEAS_RSLT === 'NG' ? 'bg-color-p' : ''}
                                onClick={() => goDetail(index)}>
                                <td key={'td_0_'.concat(String(index))}>{index + 1}</td>
                                <td key={'td_1_'.concat(String(index))} className={'left'}><a>{item.PARA_NM}</a></td>
                                <td key={'td_2_'.concat(String(index))} className={'right'}>{item.SAM_CNT}</td>
                                <td key={'td_3_'.concat(String(index))} className={'right'}>{item.NOT_NULL_CNT}</td>
                                <td key={'td_4_'.concat(String(index))} className={'right'}>{item.MEAS_DEFECT_CNT}</td>
                                <td key={'td_5_'.concat(String(index))}>
                                    <span key={'span_'.concat(String(index))} className={item.MEAS_RSLT === 'Wait' ? 'label-state wait' : item.MEAS_RSLT === 'NG' ? 'label-state ng' : 'label-state ok'}>{item.MEAS_RSLT}</span>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
            <CSSTransition in={showSwiper} classNames={showSwiper ? 'right' : 'left'} timeout={500} unmountOnExit>
                <QmvDetailInspectDetail
                    state={state}
                    ds_Part={ds_Part}
                    ds_Spec={ds_Spec}
                    ds_grdColList={ds_grdColList}
                    onClose={closeDetail}
                    selected={selectedIndex}
                    fn_changDs={fn_changDs}
                    fn_setSpecGrid_N={fn_setSpecGrid_N}
                />
            </CSSTransition>
        </>
    );
};