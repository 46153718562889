import React from 'react';
import usePageMove from '../../hooks/usePageMove';

export default function Footer({lang}: {lang?: string}) {
    const {pageMove} = usePageMove();

    return (
        <>
            <div className={'footer'}>
                <p>
                    본 시스템은 인가된 분만 사용 가능하며,<br/>
                    불법적으로 사용 시 법적 제재를 받을 수 있습니다.
                </p>
                <p>
                    This system is strictly restricted to authorized users only.<br/>
                    Any illegal access shall be punished with a related-law.<br/>
                    © Samsung
                    {/*2024.11.08 zilong.ge 서비스 이용약관 및 글로벌향 p.p 추가 반영 요청-mobile*/}
                    <a onClick={() => pageMove(process.env.REACT_APP_URL_TERMS || '', {userLang: 'KO', partId: 'AU4AS0I6AAJlNm02'})}>Privacy Policy</a>
                    {/*<a onClick={() => pageMove(process.env.REACT_APP_URL_TERMS || '', {userLang: 'KO', partId: 'AU4AS0I6AAJlNm02'})}>Privacy(Basic)</a>
                    <a onClick={() => pageMove(process.env.REACT_APP_URL_TERMS || '', {userLang: 'EN', partId: 'GDPR_POLISH'})}>Privacy(EU)</a>
                    <a onClick={() => pageMove(process.env.REACT_APP_URL_TERMS || '', {userLang: 'CH', partId: 'CHINA_POLISH'})}>Privacy(China)</a>
                    <a onClick={() => pageMove(process.env.REACT_APP_URL_TERMS || '', {userLang: 'VI', partId: 'VIETNAM_POLISH'})}>Privacy(Vietnam)</a>*/}
                </p>
            </div>
        </>
    );
};