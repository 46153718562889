import React, {useEffect, useState} from 'react';
import {Html5QrcodeError, Html5QrcodeResult} from 'html5-qrcode/src/core';
import {Html5Qrcode} from 'html5-qrcode';
import {useComponent} from '../../components/contexts/ComponentContext';
import useGlobalData from '../../hooks/useGlobalData';

export default function Scanner({onClose, scanResult}: {onClose: () => void, scanResult: (result?: string) => void}) {
    const {setDialog} = useComponent();
    const {getDomain, getMessage} = useGlobalData();

    const [scanner, setScanner] = useState<Html5Qrcode>();
    const [decodedText, setDecodedText] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        setScanner(new Html5Qrcode('qrRenderWrap'));
    }, []);

    useEffect(() => {
        if(scanner) {
            // Html5Qrcode.getCameras().then(device => setCamera(device[2].id));
            scanner.start({
                    facingMode: 'environment',
                }, {
                    fps: 60,
                    disableFlip: false,
                    qrbox: {
                        width: 250,
                        height: 250
                    }
                },
                (decodedText: string, result: Html5QrcodeResult) => {
                    setDecodedText(decodedText);
                },
                (errorMessage: string, error: Html5QrcodeError) => {
                    setErrorMessage(errorMessage);
                })
            .then();
        }
    }, [scanner]);

    useEffect(() => {
        if(decodedText) {
            handleClose();
            // setDialog({
            //     show: true,
            //     type: 'ALERT',
            //     text: getMessage('MSG01543'),
            //     confirm: handleClose
            // });
        }
    }, [decodedText]);

    const handleClose = () => {
        try {
            scanner?.stop()
            .then(() => scanResult(decodedText))
            .finally(() => {
                scanner.clear();
                onClose();
            });
        } catch(e) {
            onClose();
        }
    };

    return (
        <>
            <div className={'pop-layer'} style={{
                display: 'block',
                zIndex: 99998
            }}>
                <div className={'wrap'}>
                    <header>
                        <h1>QR Scanner</h1>
                        <span className={'btn-wrap right'}>
                        <button type={'button'} className={'button-close'} onClick={handleClose}>
                            <i className={'hidden'}>{getDomain('DOMAIN0173')}</i>
                        </button>
                        </span>
                    </header>
                    <div className={'container-noheader'}>
                        <div id={'qrRenderWrap'} style={{top: '-1.5rem'}}></div>
                        <div className={'btn-area bottom'}>
                            <button type={'button'} className={'button-primary'} onClick={handleClose}>{getDomain('DOMAIN0173')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};