import React, {useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectFade, Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import {v4 as uuidv4} from 'uuid';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
import {useError} from '../../hooks/useException';

export default function QPointPicture({fileId, onClose, shootingFilePath}: {fileId: string, onClose: () => void, shootingFilePath?: string}) {
    const {sendAxios} = useAxios();
    const {throwException} = useError();

    const [fileList, setFileList] = useState<{[K: string]: string}[]>();
    const [qPointFilePath, setQPointFilePath] = useState<string | undefined>(shootingFilePath);
    const qPointPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.files) {
            setQPointFilePath(URL.createObjectURL(event.target.files[0]));
        }
    };

    useEffect(() => {
        let file: {[K: string]: string}[] = [];
        for(let i = 0; i < 5; i++) {
            file.push({
                DB_SRCH: 'DB_SRCH',
                DESCRIPTION: 'DESCRIPTION',
                DOWNLOAD_TYPE: 'DOWNLOAD_TYPE',
                FILE_EXTENSION_NAME: 'FILE_EXTENSION_NAME',
                FILE_ID: 'FILE_ID',
                FILE_MIME_TYPE_NAME: 'FILE_MIME_TYPE_NAME',
                FILE_NAME: 'FILE_NAME',
                FILE_PATH_NAME: '/images/@temp2.png',
                FILE_SEQ: 'FILE_SEQ',
                FILE_SIZE: 'FILE_SIZE',
                FIRST_REGR_ID: 'FIRST_REGR_ID',
                FIRST_REG_DATETIME: 'FIRST_REG_DATETIME',
                FROM_DATE: 'FROM_DATE',
                MODULE_ID: 'MODULE_ID',
                ORDER_IDX: 'ORDER_IDX',
                THRU_DATE: 'THRU_DATE'
            });
        }
        setFileList(file);

        // sendAxios('/common/nexacro/commonSelectFileList.do', 'POST', AXIOS_HEADER.NEXACRO, {
        //     ds_Search: [{
        //         FILE_ID: fileId
        //     }]
        // })
        // .then(response => {
        //     let file: {[K: string]: string}[] = [];
        //     for(const path of response?.data.ds_List) {
        //         file.push({
        //             DB_SRCH: path.DB_SRCH,
        //             DESCRIPTION: path.DESCRIPTION,
        //             DOWNLOAD_TYPE: path.DOWNLOAD_TYPE,
        //             FILE_EXTENSION_NAME: path.FILE_EXTENSION_NAME,
        //             FILE_ID: path.FILE_ID,
        //             FILE_MIME_TYPE_NAME: path.FILE_MIME_TYPE_NAME,
        //             FILE_NAME: path.FILE_NAME,
        //             FILE_PATH_NAME: '/images/@temp1.png',
        //             FILE_SEQ: path.FILE_SEQ,
        //             FILE_SIZE: path.FILE_SIZE,
        //             FIRST_REGR_ID: path.FIRST_REGR_ID,
        //             FIRST_REG_DATETIME: path.FIRST_REG_DATETIME,
        //             FROM_DATE: path.FROM_DATE,
        //             MODULE_ID: path.MODULE_ID,
        //             ORDER_IDX: path.ORDER_IDX,
        //             THRU_DATE: path.THRU_DATE
        //         });
        //     }
        //
        //     if(file.length > 0) {
        //         setFileList(file);
        //     }
        // })
        // .catch(e => {
        //     throwException(e);
        //     return new Promise(() => {});
        // });
    }, [fileId]);

    return (
        <>
            <div className={'pop-layer'} style={{display: 'block'}}>
                <div className={'wrap'}>
                    <header>
                        <h1>사진</h1>
                        <span className={'btn-wrap right'}>
                        <button type={'button'} className={'button-close'} onClick={onClose}>
                            <i className={'hidden'}>닫기</i>
                        </button>
                        </span>
                    </header>
                    <div className={'container-noheader'}>
                        <div className={'comm-slide-wrap'}>
                            <div className={'swiper-pagination swiper-pagination-img'} style={{
                                width: '100% !important',
                                paddingBottom: '2rem'
                            }}></div>
                            <Swiper
                                modules={[Pagination, Navigation, EffectFade]}
                                pagination={{
                                    dynamicBullets: true,
                                    el: '.swiper-pagination-img',
                                    clickable: true
                                }}
                                spaceBetween={30}
                                navigation={true}
                                initialSlide={0}
                                className={'swiper mySwiper'}>
                                {
                                    fileList && fileList.length > 0 ? fileList.map(file => {
                                        return <SwiperSlide key={uuidv4()}>
                                            <div className={'image-wrap'} style={{
                                                marginTop: 'unset',
                                                overflowY: 'unset',
                                                padding: 'unset'
                                            }}>
                                                <img src={file.FILE_PATH_NAME} alt={file.FILE_NAME}
                                                    style={{width: '100%'}}/>
                                            </div>
                                        </SwiperSlide>;
                                    }) : null
                                }
                            </Swiper>
                            <div className={'result-img-box'}>
                                <h2 className={'h2-title'}>측정결과</h2>
                                <div className={'img-wrap'} style={{padding: 'unset', backgroundColor: 'unset'}}>
                                    <img src={qPointFilePath} alt={''} style={{width: '100%'}}/>
                                </div>
                            </div>
                        </div>
                        <div className={'btn-area bottom'}>
                            <div className={'file-search-wrap'}>
                                <input type={'file'} accept={'image/*'} capture={'environment'} onChange={qPointPicture}/>
                                <span className={'file-search'} style={{height: '3.6rem'}}>재촬영</span>
                            </div>
                            <button type={'button'} className={'button-primary'} onClick={onClose}>확인</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};