/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > 입고검사 성적서 (Q-Point)
 * 02. 프로그램ID : QmsDetailInspectDetail.tsx
 * 03. 프로그램명 : 입고검사 성적서 작성 (Q-Point 탭 > 상세)
 * 04. 화면설명 : 입고검사 성적서 작성
 * 05. 작성일 : 2023.08.24
 * 06. 작성자 : 채보람
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-08-24     채보람    최초 작성
 **************************************************************************************************
 */

import React, {useEffect, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import {CSSTransition} from 'react-transition-group';
import Picture from '../../../common/Picture';
import * as gfn from "../../../../components/utils/CommonUtils";
import {useDataset} from "../../../../components/contexts/CommonContext";
import {toNumber} from "lodash";
import {requestParser, responseParser} from "../../../../components/utils/NexacroParseUtil";
import useAxios, {AXIOS_HEADER} from "../../../../hooks/useAxios";
import {NexacroJsonUnitType} from "../../../../components/model/SqciTypes";
import {useError} from "../../../../hooks/useException";
import {useComponent} from "../../../../components/contexts/ComponentContext";
import useGlobalData from "../../../../hooks/useGlobalData";
import {gfn_isNull} from "../../../../components/utils/CommonUtils";
import imageCompression from "browser-image-compression";

export default function QmsQpointDetail({list, onClose, selected, ds_Part, pv_flag}: {list: any[], onClose: (ds_qPointList: any[]) => void, selected?: number, ds_Part?: any, pv_flag: string}) {
    const {sendAxios} = useAxios();
    const {throwException} = useError();
    const {setDialog} = useComponent();
    const {getMessage, getDomain} = useGlobalData();

    const [imagePath, setImagePath] = useState<string[]>();
    const [showPicture, setShowPicture] = useState<boolean>(false);

    const [selectedIdx, setSelectedIdx] = useState(Number(selected) + 1);
    const [ds_qPointList, setDs_qPointList] = useState(list);
    const imageRef = useRef<any>([]);
    const [ds_upload, setDs_upload] = useState(new Array<NexacroJsonUnitType>());
    const [disabled, setDisabled] = useState(pv_flag != 'INS')

    let vds_remove = new Array<NexacroJsonUnitType>();

    const goPictureView = (path: string[]) => {
        setImagePath(path);
        setShowPicture(false);
    };

    const closePicture = () => {
        setShowPicture(false);
        setImagePath(undefined);
    };

    useEffect(() => {
        if(imagePath) {
            setShowPicture(true);
        }
    }, [imagePath]);

    useEffect(() => {
        // console.log("Q-Point 탭 > 상세");
        // console.log("list ↓");
        // console.log(list);
        // console.log("Q-Point 탭 > 상세");

        form_onload();
    }, []);

    /* form_onload */
    function form_onload() {
        setSlideData(Number(selected));
    }

    /* 측정결과 입력 시 */
    const changeInspVal = (e: any) => {
        let copyArray = [...ds_qPointList];
        copyArray[selectedIdx - 1][e.target.name] = e.target.value;

        if (copyArray[selectedIdx - 1]['ITEM_MEAS_RESULT'] == undefined) {
            copyArray[selectedIdx - 1]['ITEM_MEAS_RESULT'] = "OK";
        }

        if (copyArray[selectedIdx - 1]['ITEM_MEAS_RESULT'] == "NG") {
            copyArray[selectedIdx - 1]['ITEM_FAIL_CNT'] = "1";
        } else {
            copyArray[selectedIdx - 1]['ITEM_FAIL_CNT'] = "0";
        }

        setDs_qPointList(copyArray);

        /* 변경값 저장 */
        setInspValData();
    };

    /* 변경값 useState 적용 */
    function setInspValData() {
        let copyArray = [...ds_qPointList];
        copyArray[selectedIdx - 1]['ITEM_INSP_CNT'] = "1";

        setDs_qPointList(copyArray);
    }

    /* 사진촬영 callback */
    const photoCallback = (seq: number) => {
        imageRef.current[seq]?.click();
    };

    /* 사진촬영 change event */
    let photoChangeCallback = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        if (e.target.files) {
            fn_file_save(e.target.files, idx);
        }
    };

    async function fn_file_save(file: FileList, idx: number){
        if (!gfn_isNull(ds_qPointList[idx]["ITEM_FILE_ID"])) {
            vds_remove.push(ds_upload[0]);
        }

        const formData = new FormData();
        let files = file;
        let maxSize = 30 * 1024 * 1024; //* 30MB 사이즈 제한
        let fileSize = 0;
        const options = {
            maxWidthOrHeight: 1920, //width
            initialQuality: 0.8 //quality
        }

        if (files?.length != undefined) {
            for (let i = 0; i < files?.length; i++) {
                let fileD = files[i];

                if (fileD.type.match('image')) {
                    const compressedFile = await imageCompression(files[i], options);
                    fileD = new File([compressedFile], files[i].name, {type: files[i].type});
                }

                // 23.10.16 파일 사이즈 조정
                fileSize = fileSize + fileD.size;
                if(fileSize >= maxSize){
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG01296").replace("0", "30MB"),
                    });
                    return;
                }

                formData.append('file' + i, fileD);
            }

            formData.append("workType", "QMS");

            let inputDatasets: any = {
                ds_input: ds_upload,
                ds_fileInfo: [{
                    FILE_ID: gfn_isNull(ds_qPointList[idx]["ITEM_FILE_ID"]) ? null : ds_qPointList[idx]["ITEM_FILE_ID"],
                    MODULE_ID: "QMS"
                }],
                ds_remove: vds_remove
            }

            if (gfn_isNull(ds_qPointList[idx]["ITEM_FILE_ID"])) {
                inputDatasets = {
                    ds_input: ds_upload,
                    ds_fileInfo: [{
                        MODULE_ID: "QMS"
                    }],
                    ds_remove: vds_remove
                }
            }

            formData.append("inputDatasets", String(requestParser(inputDatasets)));

            sendAxios('/common/nexacro/commonFileupload.do', 'POST', AXIOS_HEADER.FORM, formData)
            .then(response => {
                for (const ds_res of responseParser(response?.data)?.ds_output || []) {
                    if (ds_res.ERR_CODE === "SUCESS") {
                        let sRetFileId = ds_res.ERR_MSG as string;
                        let copyArray = [...ds_qPointList];

                        copyArray[idx]['ITEM_FILE_ID'] = sRetFileId;
                        setDs_qPointList(copyArray);

                        setSlideData(idx);

                        /* 변경값 저장 */
                        setInspValData();
                    }else{
                        setDialog({
                            type: 'ALERT',
                            show: true,
                            text: getMessage("MSG00220"),
                            confirm: () => {
                            }
                        });

                        return;
                    }
                }
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
        }
    }

    /* 슬라이드 교체시 selectedIdx 변경, file id 가져오기 */
    function setSlideData(activeIndex: number) {
        setSelectedIdx(activeIndex + 1);

        sendAxios('/common/nexacro/commonSelectFileList.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Search: [{
                FILE_ID: ds_qPointList[activeIndex]['ITEM_FILE_ID']
            }]
        })
        .then(response => {
            setDs_upload(response?.data.ds_List || []);
        })
        .catch(e => {
            throwException(e);

            return new Promise(() => {
            });
        });
    }

    return (
        <>
            <div className={'pop-layer'} style={{display: 'block'}}>
                <div className={'wrap'}>
                    <header>
                        <h1>{getDomain('DOMAIN4669')}</h1>  {/*입고검사 성적서 작성*/}
                            <span className={'btn-wrap right'}>
                            <button type={'button'} className={'button-close'} onClick={() => onClose(ds_qPointList)}>
                                <i className={'hidden'}>{getDomain('DOMAIN0173')}</i>   {/*닫기*/}
                            </button>
                        </span>
                    </header>
                    <div className={'container-noheader'}>
                        <div className={'comm-slide-wrap'} style={{
                            display: 'block',
                            // position: 'absolute',
                            backgroundColor: 'white',
                            width: '100%',
                            height: 'unset'
                        }}>
                            <div className={'swiper-pagination'} style={{
                                width: '100% !important',
                                paddingBottom: '2rem'
                            }}></div>
                            <div className="pagination-type2"><em>{selectedIdx}</em>/<span>{list.length}</span></div>

                            {/*23.11.06 네비게이션 기능 추가*/}
                            <div className={'swiper-next'}></div>
                            <div className={'swiper-prev'}></div>
                            <Swiper
                                modules={[Pagination, Navigation]}
                                pagination={{
                                    dynamicBullets: true,
                                    el: '.swiper-pagination',
                                    clickable: true
                                }}
                                // onSwiper={swiper => console.log(swiper)}
                                onSlideChange={(swiper) => setSlideData(swiper.activeIndex)}
                                initialSlide={selected || 0}
                                className={'swiper mySwiper'}
                                navigation={{
                                    nextEl: ".swiper-next",
                                    prevEl: ".swiper-prev",
                                }}
                            >
                                {
                                    list.map((item, index) => {
                                        return <SwiperSlide key={uuidv4()}>
                                            <div key={uuidv4()} className={'swiper-slide'}>
                                                <ul key={uuidv4()} className={'contents-table-list'}>
                                                    <li key={uuidv4()}>
                                                        <div key={uuidv4()} className={'col'}>
                                                            <span key={uuidv4()} className={'title'}>{getDomain('DOMAIN2048')}</span>    {/*검사항목*/}
                                                            <span key={uuidv4()} className={'item-wrap'}>{item.INSP_ITEM_NM}
                                                                <button type={'button'} className={'btn-gallary'} onClick={() => goPictureView([
                                                                    process.env.REACT_APP_HTTP_BASE_URL+'/common/nexacro/commonGetImageFileAndFileSeq.do?fileId='+list[(selectedIdx - 1)]['FILE_ID']+"&fileSeq="+list[(selectedIdx - 1)]['FILE_SEQ']
                                                                ])}>
                                                                    <i className={'hidden'}>{getDomain('DOMAIN4584')}</i>  {/*이미지*/}
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </li>
                                                </ul>

                                                <h2 key={uuidv4()} className={'h2-title'}>{getDomain('DOMAIN4633')} {/*측정결과*/}</h2>
                                                <div key={uuidv4()} className={'table-wrap'}>
                                                    <table key={uuidv4()}>
                                                        <colgroup key={uuidv4()}>
                                                            <col key={uuidv4()} style={{width:'60%'}} />
                                                            <col key={uuidv4()} />
                                                        </colgroup>
                                                        <thead key={uuidv4()}>
                                                            <tr key={uuidv4()}>
                                                                <th key={uuidv4()} scope="col">{getDomain('DOMAIN4633')} {/*측정결과*/}</th>
                                                                <th key={uuidv4()} scope="col">{getDomain('DOMAIN1914')} {/*판정결과*/}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody key={uuidv4()}>
                                                            <tr key={uuidv4()}>
                                                                <td key={uuidv4()} className={'pad-type'}>
                                                                    <input key={uuidv4()} disabled={disabled} type={'text'} defaultValue={item.ITEM_MEAS_VAL} className={'result-right'} name={'ITEM_MEAS_VAL'} onBlur={changeInspVal} />
                                                                </td>
                                                                <td key={uuidv4()}>
                                                                    <select key={uuidv4()} disabled={disabled} defaultValue={item.ITEM_MEAS_RESULT} name={'ITEM_MEAS_RESULT'} onChange={changeInspVal}>
                                                                        <option value="OK">OK</option>
                                                                        <option value="NG">NG</option>
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div>
                                                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "2rem", marginBottom: "1.2rem"}}>
                                                        <h2 className={'h2-title'}>{getDomain('DOMAIN4660')}</h2>  {/*사진*/}
                                                        <input type={'file'} ref={(el) => imageRef.current[toNumber(index)] = el}
                                                               id={'photoFile' + index} accept={"image/*"} capture={"environment"}
                                                               style={{display: "none"}} onChange={e => photoChangeCallback(e, index)}/>
                                                        {
                                                            disabled
                                                            ?
                                                                ''
                                                            :
                                                                <button type={'button'} className={'button-shoot'} onClick={() => photoCallback(index)}>
                                                                    <i className={'hidden'}>{getDomain('DOMAIN4569')}</i> {/*촬영*/}
                                                                </button>
                                                        }
                                                    </div>
                                                </div>
                                                <div className={'result-image-wrap'}>
                                                    {
                                                        ds_upload.length > 0
                                                        ?
                                                            <img src={process.env.REACT_APP_HTTP_BASE_URL+'/common/nexacro/commonGetImageFileAndFileSeq.do?fileId='+item.ITEM_FILE_ID+"&fileSeq="+ds_upload[0]['FILE_SEQ']} alt="" />
                                                        :
                                                            ''
                                                    }
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                        </div>
                        <div className={'btn-area bottom'}>
                            <button type={'button'} className={'button-primary'} style={{height: '4.9rem', fontSize: '1.8rem'}} onClick={() => onClose(ds_qPointList)}>{getDomain('DOMAIN0173')}</button>  {/*닫기*/}
                        </div>
                    </div>
                </div>
            </div>
            <CSSTransition in={showPicture} classNames={showPicture ? 'right' : 'left'} timeout={500} unmountOnExit>
                <Picture pathList={imagePath || []} onClose={closePicture}/>
            </CSSTransition>
        </>
    );
};