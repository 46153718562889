import React from 'react';
import useHtmlElement from '../../../../hooks/useHtmlElement';

export default function SampleJudgmentResult({state, nextTab}: {state: any, nextTab: (name: string) => void}) {
    const {useSelectBox} = useHtmlElement();

    const selectBox = useSelectBox('selectBox', () => {
        return [
            {value: '1', text: '합격'},
            {value: '2', text: '불합격(SEC)'},
            {value: '3', text: '불합격(vendor)'}
        ]
    });

    return (
        <>
            <div className={'contents-table-wrap'} style={{display: 'block'}}>
                {/*디자인에 입고에만 화살표*/}
                {/*버튼기능을 해야 할 경우  role={'button'} 추가 : 예시 <div class={'result-state arrow'} role={'button'}>*/}
                {/*<div className={'result-state arrow'}>*/}
                {/*    <span className={'result pass'}>합격</span>*/}
                {/*</div>*/}
                {/*<div className={'result-state arrow'}>*/}
                {/*    <span className={'result fail'}>불합격(SEC)</span>*/}
                {/*</div>*/}
                {/*<div className={'result-state arrow'}>*/}
                {/*    <span className={'result fail'}>불합격(Vendor)</span>*/}
                {/*</div>*/}

                <select
                    name={selectBox.name}
                    onChange={selectBox.onChange}
                    value={selectBox.value}
                    className={'judgment-select-box'}
                    style={{backgroundColor: selectBox.value === '1' ? '#FF005C' : '#3478F5'}}
                    disabled={true}>
                    {[...selectBox.options]}
                </select>

                <ul className={'contents-table-list form'}>
                    <li>
                        <div className={'title'}>
                            <span>메모</span>
                        </div>
                        <div className={'item-wrap'}>
                            <textarea></textarea>
                        </div>
                    </li>
                </ul>
                <div className={'title-wrap'}>
                    <h2 className={'h2-title'}>첨부파일</h2>
                    <p>※ 최대 10까지 업로드 가능합니다.</p>
                </div>
                <ul className={'contents-table-list form'}>
                    <li>
                        <div className={'title'}>첨부파일</div>
                        <div className={'item-wrap'}>
                            <button type={'button'} className={'button-upload'}>업로드</button>
                        </div>
                    </li>
                </ul>
                <ul className={'attach-file-list'}>
                    <li>
                        <div className={'file-item'}>
                            <button type={'button'} className={'file-download'}><span
                                className={'text'}><em>Image</em> 00MB</span></button>
                            <button type={'button'} className={'button-delete'}><i className={'hidden'}>삭제</i></button>
                        </div>
                    </li>
                    <li>
                        <div className={'file-item'}>
                            <button type={'button'} className={'file-download'}><span
                                className={'text'}><em>Image</em> 00MB</span></button>
                            <button type={'button'} className={'button-delete'}><i className={'hidden'}>삭제</i></button>
                        </div>
                    </li>
                    <li>
                        <div className={'file-item'}>
                            <button type={'button'} className={'file-download'}><span
                                className={'text'}><em>Image</em> 00MB</span></button>
                            <button type={'button'} className={'button-delete'}><i className={'hidden'}>삭제</i></button>
                        </div>
                    </li>
                    <li>
                        <div className={'file-item'}>
                            <button type={'button'} className={'file-download'}><span
                                className={'text'}><em>Image</em> 00MB</span></button>
                            <button type={'button'} className={'button-delete'}><i className={'hidden'}>삭제</i></button>
                        </div>
                    </li>
                    <li>
                        <div className={'file-item'}>
                            <button type={'button'} className={'file-download'}><span
                                className={'text'}><em>Image</em> 00MB</span></button>
                            <button type={'button'} className={'button-delete'}><i className={'hidden'}>삭제</i></button>
                        </div>
                    </li>
                    <li>
                        <div className={'file-item'}>
                            <button type={'button'} className={'file-download'}><span
                                className={'text'}><em>Image</em> 00MB</span></button>
                            <button type={'button'} className={'button-delete'}><i className={'hidden'}>삭제</i></button>
                        </div>
                    </li>
                    <li>
                        <div className={'file-item'}>
                            <button type={'button'} className={'file-download'}><span
                                className={'text'}><em>Image</em> 00MB</span></button>
                            <button type={'button'} className={'button-delete'}><i className={'hidden'}>삭제</i></button>
                        </div>
                    </li>
                </ul>
            </div>
            <div className={'btn-area bottom'}>
                <button type={'button'} className={'button-line'}>임시저장</button>
                <button type={'button'} className={'button-primary'} onClick={() => nextTab('judgmentResult')}>저장</button>
            </div>
        </>
    );
};