import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
import {ComponentHistoryType, NexacroJsonUnitType, TabNavigationType} from '../../components/model/SqciTypes';
import {useComponent} from '../../components/contexts/ComponentContext';
import BasicInformation from "./info/basic/BasicInformation";
import PartInspectionInformation from "./info/part/PartInspectionInformation";
import QmvDetailInspectList from "./info/detailed/QmvDetailInspectList";
import DefectiveContent from "./info/defect/DefectiveContent";
import JudgmentResult from "./info/judgment/JudgmentResult";
import {useDataset} from "../../components/contexts/CommonContext";
import * as gfn from "../../components/utils/CommonUtils";
import {STORAGE_NAME} from "../../components/CommonConstants";
import useGlobalData from "../../hooks/useGlobalData";
import {useError} from "../../hooks/useException";
import {requestParser, responseParser} from "../../components/utils/NexacroParseUtil";
import usePageMove from "../../hooks/usePageMove";
import {useLocation, useOutletContext} from "react-router-dom";
import imageCompression from "browser-image-compression";

export default function QmvInfo() {
    const {sendAxios} = useAxios();
    const {setDialog} = useComponent();
    const {dataset, confirmHistoryBack} = useDataset();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const {pageMove} = usePageMove();
    const location = useLocation();

    const {throwException} = useError();

    const [ds_qrCode, setDs_qrCode] = useState(new Array<NexacroJsonUnitType>());
    const [ds_LotId, setDs_LotId] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Search, setDs_Search] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Aql, setDs_Aql] = useState(new Array<NexacroJsonUnitType>());
    const [ds_AllSample, setDs_AllSample] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Part, setDs_Part] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Btn02, setDs_Btn02] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Spec, setDs_Spec] = useState(new Array<NexacroJsonUnitType>());
    const [ds_serialRev, setDs_serialRev] = useState(new Array<NexacroJsonUnitType>());
    const [ds_submitDetail, setDs_submitDetail] = useState(new Array<NexacroJsonUnitType>());
    const [ds_SpecOrg, setDs_SpecOrg] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Defect, setDs_Defect] = useState(new Array<NexacroJsonUnitType>());
    const [ds_SpecFile, setDs_SpecFile] = useState(new Array<NexacroJsonUnitType>());
    const [ds_DefectGroup, setDs_DefectGroup] = useState(new Array<NexacroJsonUnitType>());
    const [ds_DefectSymptom2, setDs_DefectSymptom2] = useState(new Array<NexacroJsonUnitType>());
    const [ds_grdColList, setDs_grdColList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_ecnList, setDs_ecnList] = useState(new Array<NexacroJsonUnitType>());

    const [ds_upload, setDs_upload] = useState(new Array<NexacroJsonUnitType>());
    const [ds_remove, setDs_remove] = useState(new Array<NexacroJsonUnitType>());

    const [css_btn_Attach, setCss_btn_Attach] = useState(false);
    const [css_btn_EcnNo, setCss_btn_EcnNo] = useState(false);
    const [cssRiskLotYN, setCssRiskLotYN] = useState(true);

    const [pv, setPv] = useState(new Map);
    const [fv, setFv] = useState(new Map);

    // 23.10.04 react수정) 팝업 종료 플래그 변경
    const [fv_close, setFv_close] = useState(false);

    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
    
    const reportRef = useRef<HTMLInputElement>(null);   // 5번째 탭 > 판정 > 업로드

    const [tabList, setTabList] = useState<TabNavigationType[]>([
        {name: 'basicInformation', selected: true, direction: 'right', element:
                <BasicInformation
                    state={''}
                    ds_Part={ds_Part}
                />},
        {name: 'partInspectionInformation', selected: false, direction: 'right', element:
                <PartInspectionInformation
                    state={''}
                    ds_Part={ds_Part}
                    ds_Btn02={ds_Btn02}
                    ds_SpecFile={ds_SpecFile}
                    ds_ecnList={ds_ecnList}
                    css_btn_Attach={css_btn_Attach}
                    fn_changDs={(datasetName: string, dataset: Array<NexacroJsonUnitType>) => setDataset(datasetName, dataset)
                }
                />},
        {name: 'detailedInspectionInformation', selected: false, direction: 'right', element:
                <QmvDetailInspectList
                    state={''}
                    ds_Part={ds_Part}
                    ds_Spec={ds_Spec}
                    ds_grdColList={ds_grdColList}
                    fn_changDs={(datasetName: string, dataset: Array<NexacroJsonUnitType>) => setDataset(datasetName, dataset)}
                    fn_setSpecGrid_N={(sgIndex:number, sgBoolean:boolean, sgDs_Spec: Array<NexacroJsonUnitType>, sgDs_Part: Array<NexacroJsonUnitType>) => fn_setSpecGrid_N(sgIndex, sgBoolean, sgDs_Spec, sgDs_Part)}
                />},
        {name: 'defectiveContent', selected: false, direction: 'right', element:
                <DefectiveContent
                    state={''}
                    ds_DefectGroup={ds_DefectGroup}
                    ds_DefectSymptom2={ds_DefectSymptom2}
                    ds_Defect={ds_Defect}
                    fn_changDs={(datasetName: string, dataset: Array<NexacroJsonUnitType>) => setDataset(datasetName, dataset)}
                />},
        {name: 'judgmentResult', selected: false, direction: 'right', element:
                <JudgmentResult
                    state={''}
                    ds_Part={ds_Part}
                    ds_upload={ds_upload}
                    ds_remove={ds_remove}
                    reportRef={reportRef}
                    fn_changDs={(datasetName: string, dataset: Array<NexacroJsonUnitType>) => setDataset(datasetName, dataset)}
                />}
    ]);
    const [focusTab, setFocusTab] = useState<TabNavigationType>(tabList[0]);
    const [showTab, setShowTab] = useState<boolean>(false);
    
    let gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    let fv_sBtnFlag		= "SAVE";	//저장 or 임시저장
    // let ngArr = new Array();

    //데이터 변경 플레그
    let fv_changeData;

    let gv_servertime = dataset?.ds_Output[0]["SERVERTIME"] as string;


    let temp_pv = new Map();
    let temp_fv = new Map<string, any>();
    let temp_ds_Btn02: NexacroJsonUnitType[] = [];
    let temp_ds_Part: NexacroJsonUnitType[] = [{
        LANG : "",
        LOT_ID : "",
        REINSP_NUM : "",
        DIVISION_CD : "",
        DIVISION_NM : "",
        PLANT_CD : "",
        PLANT_NM : "",
        VENDOR_CD : "",
        VENDOR_NM : "",
        PART_CD : "",
        PART_NM : "",
        PROD_DT : "",
        LOT_NO : "",
        MAX_LOT_SIZE : "",
        LOT_SIZE : "",
        NEW_PART_TYPE : "",
        LOT_RESULT : "",
        LOT_STATUS : "",
        MOD_PART_MEMO : "",
        LOT_MEMO : "",
        ATTACH_FILE_ID : null,
        INSP_GB : "",
        INSP_USER : "",
        INSP_USER_NM : "",
        UPDATE_DT : "",
        USER_ID : "",
        ORG_LOT_SIZE : "",
        SPEC_REV : "",
        AGENT_ASSOCIATION : "",
        REASON : "",
        L2_CODE : "",
        TSI_AGENT_YN : "",
        SUBMIT_TYPE : "",
        SUBMIT_DT : "",
        SUBMIT_QTY : "",
        MAPPING_YN : "",
        AGENT_SUBMIT_ID : "",
        QR_YN : "",
        RISK_LOT_YN : "",
        RISK_LOT_TYPE : "",
        RISK_LOT_MEMO : ""}];

    let temp_ds_Spec: NexacroJsonUnitType[] = [];
    let temp_ds_Defect: NexacroJsonUnitType[] = [];
    let temp_ds_qrCode: NexacroJsonUnitType[] = [];
    let temp_ds_Search: NexacroJsonUnitType[] = [{}];

    let temp_ds_searchSerial: NexacroJsonUnitType[] = [{}];

    let temp_ds_SpecFile: NexacroJsonUnitType[] = [{
        ATTACH_FILE_ID : "",
        SPEC_REV : "",
    }];

    let temp_ds_AllSample: NexacroJsonUnitType[] = [{
        MEAS_MTH : "",
        FROM_SIZE : "",
        TO_SIZE : "",
        SAM_CNT : ""
    }];

    let temp_ds_Aql: NexacroJsonUnitType[] = [{
        AQL_LEVEL : "",
        AQL_SAMPLE_CNT : "",
        AC_CNT : "",
        RE_CNT : "",
        ACCESS_PATH : ""
    }]

    let temp_ds_SpecOrg: NexacroJsonUnitType[] = [{
        LOT_ID : "",
        REINSP_NUM : "",
        SPEC_ID : "",
        SPEC_REV : "",
        PART_CD : "",
        EQUIP_CD : "",
        EQUIP_NM : "",
        SERNO : "",
        PARA_CD : "",
        PARA_NM : "",
        USL : "",
        CL : "",
        LSL : "",
        UNIT : "",
        POINT : "",
        INSP_MTH : "",
        LU_GB : "",
        NUM_YN : "",
        SAM_CNT : "",
        MEAS_VAL : "",
        MEAS_RESULT : "",
        AQL_LEVEL : "",
        CO_YN : "",
    }];

    // { COL_NAME : "", BIND_COL : "", SERIAL_NO : "" }
    let temp_ds_grdColList: NexacroJsonUnitType[] = [];
    
    // { SERNO : "", SERIAL_NO : "" }
    let temp_ds_serialRev: NexacroJsonUnitType[] = [];
    let temp_ds_ecnList: NexacroJsonUnitType[] = [];
    let temp_ds_submitDetail: NexacroJsonUnitType[] = [];

    useEffect(() => {
        if(ds_Btn02 != null && ds_Btn02.length != 0){
            temp_ds_Btn02 = [...ds_Btn02];
        }
        if(ds_Part != null && ds_Part.length != 0){
            temp_ds_Part = [...ds_Part];
        }
        if(ds_Spec != null && ds_Spec.length != 0){
            temp_ds_Spec = [...ds_Spec];
        }
        if(ds_Defect != null && ds_Defect.length != 0){
            temp_ds_Defect = [...ds_Defect];
        }
        /*if(ds_searchSerial != null && ds_searchSerial.length != 0){
            temp_ds_searchSerial = [...ds_searchSerial];
        }*/
        if(ds_SpecFile != null && ds_SpecFile.length != 0){
            temp_ds_SpecFile = [...ds_SpecFile];
        }
        if(ds_AllSample != null && ds_AllSample.length != 0){
            temp_ds_AllSample = [...ds_AllSample];
        }
        if(ds_Aql != null && ds_Aql.length != 0){
            temp_ds_Aql = [...ds_Aql];
        }
        if(ds_SpecOrg != null && ds_SpecOrg.length != 0){
            temp_ds_SpecOrg = [...ds_SpecOrg];
        }
        if(ds_grdColList != null && ds_grdColList.length != 0){
            temp_ds_grdColList = [...ds_grdColList];
        }
        if(ds_serialRev != null && ds_serialRev.length != 0){
            temp_ds_serialRev = [...ds_serialRev];
        }
        if(ds_ecnList != null && ds_ecnList.length != 0){
            temp_ds_ecnList = [...ds_ecnList];
        }
        if(ds_submitDetail != null && ds_submitDetail.length != 0){
            temp_ds_submitDetail = [...ds_submitDetail];
        }
        if(ds_Search != null && ds_Search.length != 0){
            temp_ds_Search = [...ds_Search];
        }
        if(ds_qrCode != null && ds_qrCode.length != 0){
            temp_ds_qrCode = [...ds_qrCode];
        }
        if(pv != null && pv.size != 0){
            temp_pv = pv;
        }
        if(fv != null && fv.size != 0){
            temp_fv = fv;
        }
    });

    useEffect(() => {
        if((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            temp_pv = new Map(location.state.PARAM);
            temp_pv.set('pv_flag',location.state.PARAM.get('pv_flag'));
            temp_pv.set('pv_lotId',location.state.PARAM.get('pv_lotId'));
            temp_pv.set('pv_reinspNum',location.state.PARAM.get('pv_reinspNum'));
            temp_pv.set('pv_smartqYN',location.state.PARAM.get('pv_smartqYN'));
            temp_pv.set('pv_newPartType',location.state.PARAM.get('pv_newPartType'));
            temp_pv.set('pv_ecnList',location.state.PARAM.get('pv_ecnList')||[]);
            temp_pv.set('pv_status_N',location.state.PARAM.get('pv_status_N'));
            setPv(new Map(temp_pv));

            temp_fv.set("fv_sFlag", location.state.PARAM.get('pv_flag'));
            temp_fv.set("fv_selectFinish", false);  //템프 조회 완료후 true
            temp_fv.set("fv_maxSamCnt", 0);         // 최대 샘플수
            temp_fv.set("fv_bIsNum", false);        //LayerPopup 숫자 여부
            temp_fv.set("fv_sBtnFlag", "SAVE");     //저장 or 임시저장
            setFv(temp_fv);

            // form_onload();
        }
    }, [enteredComponent, exitedComponent]);

    useEffect(()=>{
        if(pv != null && pv.size != 0 && fv != null && fv.size != 0){
            form_onload();
        }
    },[fv,pv]);
    
    // 1번째 탭 : 기본정보
    useEffect(() => {
        // console.log("1번째 탭 : 기본정보");
        if(ds_Part.length != 0) {
            tabList.map(tab => {
                if (tab.name === 'basicInformation') {
                    tab.element =
                        <BasicInformation
                            state={temp_pv.get('pv_status_N')}
                            ds_Part={ds_Part}
                        />;
                }
                return tab;
            });
        }
    }, [ds_Part]);

    // 2번째 탭 : 부품검사정보
    useEffect(() => {
        // console.log("2번째 탭 : 부품검사정보");
        if(ds_Btn02.length != 0 && ds_Part.length != 0) {
            tabList.map(tab => {
                if (tab.name === 'partInspectionInformation') {
                    tab.element =
                        <PartInspectionInformation
                            state={temp_pv.get('pv_status_N')}
                            ds_Part={ds_Part}
                            ds_Btn02={ds_Btn02}
                            ds_SpecFile={ds_SpecFile}
                            ds_ecnList={ds_ecnList}
                            css_btn_Attach={css_btn_Attach}
                            fn_changDs={(datasetName: string, dataset: Array<NexacroJsonUnitType>) => setDataset(datasetName, dataset)
                        }
                        />;
                }
                return tab;
            });
        }
    }, [ds_Part, ds_Btn02, css_btn_Attach, css_btn_EcnNo, cssRiskLotYN]);

    // 3번째 탭 : 세부검사정보
    useEffect(() => {
        // console.log("3번째 탭 : 세부검사정보");
        if(ds_Part.length != 0 && ds_Spec.length != 0 && ds_grdColList.length != 0) {
            tabList.map(tab => {
                if (tab.name === 'detailedInspectionInformation') {
                    tab.element =
                        <QmvDetailInspectList
                            state={temp_pv.get('pv_status_N')}
                            ds_Part={ds_Part}
                            ds_Spec={ds_Spec}
                            ds_grdColList={ds_grdColList}
                            fn_changDs={(datasetName: string, dataset: Array<NexacroJsonUnitType>) => setDataset(datasetName, dataset)}
                            fn_setSpecGrid_N={(sgIndex:number, sgBoolean:boolean, sgDs_Spec: Array<NexacroJsonUnitType>, sgDs_Part: Array<NexacroJsonUnitType>) => fn_setSpecGrid_N(sgIndex, sgBoolean, sgDs_Spec, sgDs_Part)}
                        />;
                }
                return tab;
            });
        }
    }, [ds_Part, ds_Spec, ds_grdColList]);

    // 4번째 탭 : 불량내용
    useEffect(() => {
        // console.log("4번째 탭 : 불량내용");
        if(ds_DefectGroup.length != 0 && ds_DefectSymptom2.length != 0) {
            tabList.map(tab => {
                if (tab.name === 'defectiveContent') {
                    tab.element =
                        <DefectiveContent
                            state={temp_pv.get('pv_status_N')}
                            ds_DefectGroup={ds_DefectGroup}
                            ds_DefectSymptom2={ds_DefectSymptom2}
                            ds_Defect={ds_Defect}
                            fn_changDs={(datasetName: string, dataset: Array<NexacroJsonUnitType>) => setDataset(datasetName, dataset)}
                        />;
                }
                return tab;
            });
        }
    }, [ds_Defect, ds_DefectGroup, ds_DefectSymptom2]);

    // 5번째 탭 : 판정결과
    useEffect(() => {
        // console.log("5번째 탭 : 판정결과");
        if(ds_Part.length != 0) {
            setTabList(tabList.map(tab => {
                if (tab.name === 'judgmentResult') {
                    tab.element =
                        <JudgmentResult
                            state={temp_pv.get('pv_status_N')}
                            ds_Part={ds_Part}
                            ds_upload={ds_upload}
                            ds_remove={ds_remove}
                            reportRef={reportRef}
                            fn_changDs={(datasetName: string, dataset: Array<NexacroJsonUnitType>) => setDataset(datasetName, dataset)}
                        />;
                }
                return tab;
            }));
        }
    }, [ds_Part, ds_upload, ds_remove]);

    useEffect(() => {
        if(ds_SpecFile.length != 0){
            temp_ds_Part[0]["SPEC_REV"] = ds_SpecFile[0]["SPEC_REV"] as string;

            //바로 임시저장 처리
            //alert(this.fv_sFlag + " : " + this.ds_Part.getColumn(0, "AGENT_ASSOCIATION"));
            if(temp_fv.get("fv_sFlag") == "INS"
                && temp_ds_Part[0]["AGENT_ASSOCIATION"] == "01"){ //처음 등록시 AGENT 연계일때 임시저장
                fn_tempSave();
            }
        }
    }, [ds_SpecFile]);

    async function form_onload(){
        temp_ds_Btn02 = [
            {
                BTN_ID : "btn_Ecn",
                BTN_NM : "변경점 확인",
                CALL_FUNC : "fn_ecnInfoSelect",
                CSSCLASS : "btn_WF_Gray",
                DOMAIN_ID : "DOMAIN2394",
                BTN_SIZE : 96,
                BTN_ENABLE : "false"
            },
            {
                BTN_ID : "btn_Attach",
                BTN_NM : "SPEC 첨부파일",
                CALL_FUNC : "fn_specAttach",
                CSSCLASS : "btn_WF_Gray",
                DOMAIN_ID : "DOMAIN2988",
                BTN_SIZE : 110,
                BTN_ENABLE : "false"
            }
        ];

        //파라미터 초기화
        temp_ds_ecnList = temp_pv.get("pv_ecnList")||[];
        /** VOC 14 Start : 2019.05.27 협력사 출하성적서 작성시 변경점 선택 및 연계 기능 개선 **/
        let ds_popChngPoingHList = temp_pv.get("pv_ecnList");
        /** VOC 14 End   : 2019.05.27 협력사 출하성적서 작성시 변경점 선택 및 연계 기능 개선 **/
        setDs_ecnList(temp_ds_ecnList);

        //변경점 확인 버튼 활성화/비활성화 처리
        if (temp_ds_ecnList.length > 0 ){
            // this.ds_Btn02.setColumn(0, "BTN_ENABLE", true);
            temp_ds_Btn02[0]["BTN_ENABLE"] = "true";
        }

        var newPartType = "";

        if(temp_fv.get("fv_sFlag") == "INS"){
            //입력
            temp_ds_Part = temp_pv.get("pv_part");
            temp_ds_Spec = temp_pv.get("pv_spec");
            let temp_ds_submit = temp_pv.get("pv_sumbit");
            temp_ds_submitDetail = temp_pv.get("pv_submitDetail");
            temp_ds_qrCode = temp_pv.get("pv_qrCode");

            //생산일자 Format
            var sProdDt = temp_ds_Part[0]["PROD_DT"] as string;
            if(sProdDt.indexOf("-") != -1){

            }else{
                sProdDt = sProdDt.substr(0,4)+"-"+sProdDt.substr(4,2)+"-"+sProdDt.substr(6,2);
                temp_ds_Part[0]["PROD_DT"] = sProdDt;
            }

            newPartType = temp_ds_Part[0]["NEW_PART_TYPE"] as string;

            if(temp_ds_Part[0]["AGENT_ASSOCIATION"] != "01"){//미연계나 비대상시 agent 관련내용 숨김 처리
                console.trace(temp_ds_Part[0]["AGENT_ASSOCIATION"]);
                // this.ds_Btn03.deleteRow(0);
                // this.div_Main.form.div_Detail.form.Static16.set_visible(false);
            }
        }else{
            //임시저장 수정
            // 23.08.22 react수정) 화면에 표시되는 부분 없음
            // this.div_Main.form.div_Part.form.cbo_Result.set_enable(false);
            temp_ds_Search[0]["LOT_ID"] = temp_pv.get("pv_lotId");
            temp_ds_Search[0]["REINSP_NUM"] = temp_pv.get("pv_reinspNum");
            temp_ds_Search[0]["SMARTQ_YN"] = temp_pv.get("pv_smartqYn");
            await fn_selectAqlCnt().then(response => {
                    temp_ds_Aql = response?.data.ds_Aql;
                    temp_ds_AllSample = response?.data.ds_AllSample;

                    setDs_Aql(response?.data.ds_Apl);
                    setDs_AllSample(response?.data.ds_AllSample);
                }
            ).catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
            newPartType = temp_pv.get("pv_newPartType");
        }

        //자재구분 콤포 필터구하기 ( 신규는 픽스/ 변초, 양산은 선택)
        // 23.08.22 react수정) 해당 function에 내용 없어서 삭제 처리
        // this.getNewPartTypeComboFilter(newPartType);
        let gds_user = dataset?.gds_user[0];
        let fv_sUserID = gds_user?.["USER_ID"] as string;
        let fv_sUserNm = gds_user?.["USER_NAME"] as string;

        //검사원
        temp_ds_Part[0]["INSP_USER"] = fv_sUserID;
        temp_ds_Part[0]["INSP_USER_NM"] = fv_sUserNm;
        temp_ds_Part[0]["USER_ID"] = fv_sUserID;

        var sLocale     = gv_locale ? gv_locale : "EN"; //gv_locale;
        temp_ds_Search[0]["LANG"] = sLocale;
        setDs_Search(temp_ds_Search);

        temp_ds_Part[0]["RISK_LOT_YN"] = "N";
        temp_ds_Part[0]["RISK_LOT_TYPE"] = "N";
        // 23.08.23 react수정) 각자 component에서 처리하도록 변경
        // this.gfn_getCommonCode(param);
        temp_ds_Part[0]["NEW_PART_TYPE"] = newPartType;

        /** VOC 28 Start : 2019.07.16 채보람 검사성적서 항목 SN 입력칸 추가 **/
        /** Serial No 조회 추가 **/
        await fn_searchSerial();
        /** VOC 28 End   : 2019.07.16 채보람 검사성적서 항목 SN 입력칸 추가 **/

        if(temp_fv.get("fv_sFlag") == "INS"){
            fn_creatDataset();
            setDs_Spec(temp_ds_Spec);
        }else{
            // this.div_BtnBot.form.fn_ShowBtn("btn_delete", true);
            // this.div_BtnTop.form.fn_ShowBtn("btn_delete", true);
            await fn_search();
        }

        await fn_selectSpecFile();

        if(temp_ds_SpecFile.length > 0){
            if(!gfn.gfn_isNull(temp_ds_SpecFile[0]["ATTACH_FILE_ID"])){
                temp_ds_Btn02[1]["BTN_ENABLE"] = "true"
                // 23.08.27 react수정) 파일 로직 따로
                // this.pdv_specAttach.form.div_fileDownload.form.fn_init(this, "DOWNLOAD", "QMV", "QMV_P_Out_InspectVndRcrdDetailReg");
                // this.pdv_specAttach.form.div_fileDownload.form.fn_getFileInfo([this.ds_SpecFile.getColumn(0,"ATTACH_FILE_ID")]);
            }
        }

        // 상단 버튼 blink 를 위한 timer 세팅
        var searchBlinkDate = gfn.gfn_minusMonth(gv_servertime, 3);
        if(temp_ds_ecnList.length > 0){
            /** VOC 14 Start : 2019.05.27 협력사 출하성적서 작성시 변경점 선택 및 연계 기능 개선 **/
            //this.ds_ecnList.updateSortGroup("S:-ECR_END_DATE"); //정렬하지않고 반복문으로 사용
            for (var i=0;i<temp_ds_ecnList.length;i++){
                if( Number(temp_ds_ecnList[i]["ECR_END_DATE"]) > Number(searchBlinkDate)){ // 3개월내 데이터 있으면
                    setCss_btn_Attach(true);
                    break;
                }
            }
            /** VOC 14 End   : 2019.05.27 협력사 출하성적서 작성시 변경점 선택 및 연계 기능 개선 **/
        }

        await fn_defectGroupSeclect();
        // 23.08.24 react수정) 화면에 표시되는 부분 따로 setting 할 필요 없음
        // this.div_Main.form.div_Part.form.stc_LotSize.set_text(this.gfn_setComma(this.ds_Part.getColumn(0,"ORG_LOT_SIZE")));

        //변경점 팝업 활성화 / 비활성화
        if(temp_ds_Part[0]["NEW_PART_TYPE"] == "C"){
            setCss_btn_EcnNo(false);
        }

        // 23.08.24 react수정) 화면에 표시되는 부분 따로 setting 할 필요 없음 + 개수 화면에서 제외됨
        // this.div_Main.form.div_Basic.form.cal_InsertDt.set_value(this.gfn_today());	//작성일자
        // this.div_Main.form.div_Detail.form.div_ListResult.form.fn_setResultCount(this.ds_Spec.rowcount);

        //그리드 평균, Min, Max 컬럼 다국어 적용
        /*this.ds_MeasSumm.setColumn(0, "COL_NAME", this.gfn_getDomainText("DOMAIN3001"));
        this.ds_MeasSumm.setColumn(1, "COL_NAME", this.gfn_getDomainText("DOMAIN2979"));
        this.ds_MeasSumm.setColumn(2, "COL_NAME", this.gfn_getDomainText("DOMAIN2980"));*/

        temp_ds_Part[0]["MAX_SAM_CNT"] = temp_fv.get("fv_maxSamCnt");


        //팝업 중간 - 오른쪽 버튼
        /*this.div_Main.form.div_Detail.form.div_BtnMidRight.form.fn_init(this, this.ds_Btn03, "right");

        // 엑셀용 dataset 샘플수에 맞게 다시 설정
        this.fn_createExcelDataset();*/

        await fn_checkAgentSubmit();

        await fn_attachFile_N(temp_ds_Part[0]["ATTACH_FILE_ID"] as string);

        setDs_Part(temp_ds_Part);
        setDs_Btn02(temp_ds_Btn02);
    }

    function fn_search()
    {
        if(temp_pv.get("pv_status_N") === "INFO") {
            return sendAxios('/common/nexacro/selectLotTempDetailInfo.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_Search: [{
                    REINSP_NUM: temp_pv.get("pv_reinspNum"),
                    DataSetRowType: '0',
                    LOT_ID: temp_pv.get("pv_lotId"),
                    LANG: gv_locale,
                    SMARTQ_YN: temp_pv.get("pv_smartqYn"),
                    LOT_NO: null
                }]
            }).then(response => {
                temp_ds_Part = response?.data.ds_Part;
                temp_ds_submitDetail = response?.data.ds_submitDetail;
                temp_ds_SpecOrg = response?.data.ds_SpecOrg;
                temp_ds_Defect = response?.data.ds_Defect;

                setDs_Part(temp_ds_Part);
                setDs_submitDetail(response?.data.ds_submitDetail);
                setDs_SpecOrg(response?.data.ds_SpecOrg);
                setDs_Defect(response?.data.ds_Defect);

                if (temp_ds_Part.length > 0) {
                    // 23.08.22 react수정) 후속 로직에서 무조건 현재 날짜로 세팅되기 때문에 따로 작업하지 않음
                    // this.div_Main.form.div_Basic.form.cal_InsertDt.set_value(this.ds_Part.getColumn(0, "UPDATE_DT"));	//작성일자

                    //생산일자 Format
                    // var sProdDt = this.ds_Part.getColumn(0,"PROD_DT");
                    // this.div_Main.form.div_Part.form.stc_ProdDt.set_text(sProdDt);

                    //ECN No
                    var sPartType = temp_ds_Part[0]["NEW_PART_TYPE"];
                    if (sPartType == "C") {
                        setCss_btn_EcnNo(false);
                    } else {
                        setCss_btn_EcnNo(true);
                    }

                    let gds_user = dataset?.gds_user[0];
                    let fv_sUserID = gds_user?.["USER_ID"] as string;
                    temp_ds_Part[0]["USER_ID"] = fv_sUserID;

                    //스펙 조회(smartq일때 샘플숫자가 넘어가면 자르기 위함)
                    fn_specInfoSelect();

                    /*
                    //그리드 총 Row 결과를 초기화한다.
                    this.div_Main.form.div_Defect.form.div_ListResult.form.fn_setResultCount(this.ds_Defect.rowcount);

                    //첨부파일 조회
                    var sAttachFileId = this.ds_Part.getColumn(0, "ATTACH_FILE_ID");
                    this.fn_attachFileSearch(sAttachFileId, "UPLOAD");
                    this.div_Main.form.div_Part.form.stc_LotSize.set_text(this.gfn_setComma(this.ds_Part.getColumn(0,"ORG_LOT_SIZE")));
                    */

                    temp_fv.set("fv_selectFinish", true);
                    setFv(temp_fv);
                    //검사정보 입력 팝업 닫기

                    // 23.08.27 react수정)
                    /*if (this.ds_Part.getColumn(0, "RISK_LOT_YN") == "Y") {
                        this.div_Main.form.div_Part.form.chk_riskLotYn.set_value(true);
                        this.div_Main.form.div_Part.form.cbo_riskLotType.set_enable(true);
                        this.div_Main.form.div_Part.form.edt_riskLotMemo.set_enable(true)
                    }*/

                    if (temp_ds_Part[0]["AGENT_ASSOCIATION"] != "01") {//미연계나 비대상시 agent 관련내용 숨김 처리
                        console.trace(temp_ds_Part[0]["AGENT_ASSOCIATION"]);  // trace 처리되어 있어서 그대로 진행
                        // 23.08.22 react수정) 화면에 표시되는 부분 없음
                        // this.ds_Btn03.deleteRow(0);
                        // this.div_Main.form.div_Detail.form.Static16.set_visible(false);
                    }
                }
            }).catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
        }else{
            // "selectLotDetailInfo"
            return sendAxios('/common/nexacro/selectLotDetailInfo.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_Search: [{
                    REINSP_NUM: temp_pv.get("pv_reinspNum"),
                    DataSetRowType: '0',
                    LOT_ID: temp_pv.get("pv_lotId"),
                    LANG: gv_locale,
                    SMARTQ_YN: temp_pv.get("pv_smartqYn"),
                    LOT_NO: null
                }]
            }).then(response => {
                temp_ds_Part = response?.data.ds_Part;
                temp_ds_SpecOrg = response?.data.ds_SpecOrg;
                temp_ds_Defect = response?.data.ds_Defect;

                setDs_Part(temp_ds_Part);
                setDs_SpecOrg(response?.data.ds_SpecOrg);
                setDs_Defect(response?.data.ds_Defect);

                if (temp_ds_Part.length > 0) {
                    //trace(this.ds_SpecOrg.saveXML());
                    var sPartType = temp_ds_Part[0]["NEW_PART_TYPE"];

                    // 스펙 조회
                    //this.fn_specInfoSelect();
                    // fn_specInfoSelect();
                    fn_setDsSpec();
                }
            }).catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
        }
    }

    function fn_defectGroupSeclect()
    {
        sendAxios('/common/nexacro/QMV_P_Out_InspectVndRcrdDetailRegDefectGroupSelect.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Part: temp_ds_Part
        })
        .then(response => {
            setDs_DefectGroup(response?.data.ds_DefectGroup);
            setDs_DefectSymptom2(response?.data.ds_DefectSymptom2);
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    };

    function fn_selectSpecFile()
    {
        return sendAxios('/common/nexacro/qmvInspectVndRcrdSpecFile.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Part: temp_ds_Part
        }).then(response => {
            temp_ds_SpecFile = response?.data.ds_SpecFile;
            setDs_SpecFile(temp_ds_SpecFile);
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    };

    /*
     *	Lot ID 채번
     */
    function fn_lotIdSelect()
    {
        // qmvInspectVndRcrdDetailRegLotIdSelect
        return sendAxios('/common/nexacro/qmvInspectVndRcrdDetailRegLotIdSelect.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Part : temp_ds_Part
        })
        .then(response => {
            let temp_ds_LotId = response?.data.ds_LotId;
            if(temp_ds_LotId.length > 0){
                temp_ds_Part[0]["LOT_ID"] = temp_ds_LotId[0]["LOT_ID"];
                temp_ds_Part[0]["REINSP_NUM"] = "0";
                setDs_Part(temp_ds_Part);
            }
        });
    };

    /*
    * 임시저장
    */
    function fn_tranTempSave()
    {
        let save_ds_Part = temp_ds_Part;
        var sProdDt = String(save_ds_Part[0]["PROD_DT"]);
        if(sProdDt.length > 8){
            let sProdDtArr = sProdDt.split("-");
            save_ds_Part[0]["PROD_DT"] = sProdDtArr[0]+sProdDtArr[1]+sProdDtArr[2];
        }

        save_ds_Part[0]["LOT_STATUS"] = "T";
        save_ds_Part[0]["MAPPING_YN"] = "N";

        // "qmvInspectVndRcrdDetailRegTempSave"
        return sendAxios('/common/nexacro/QMV_P_Out_InspectVndRcrdDetailRegTempSave.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Search: temp_ds_Search,
            ds_LotId: [{LOT_ID:""}],
            ds_Part: save_ds_Part,
            ds_PartInfo: [{LOT_STATUS : "",ECN_NO : "",LOT_MEMO : "",ATTACH_FILE_ID : "",USER_ID : "",USER_NM : ""}],
            ds_Spec: ds_Spec,
            ds_Defect: ds_Defect,
            ds_grdColList: ds_grdColList,
            ds_submitDetail: ds_submitDetail,
            ds_qrCode: ds_qrCode,
            Parameters:[{
                "sFlag": temp_fv.get("fv_sFlag")
            }]

        }).then(response => {
            if(!(temp_fv.get("fv_sFlag") == "INS" && temp_ds_Part[0]["AGENT_ASSOCIATION"] == "01")){
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage('MSG01053'),   // 임시저장되었습니다.
                });
            }

            // 23.10.18 현업팀 요청 사항 : 신규등록일 경우 신규등록 페이지로 리턴, 조회일 경우 조회 페이지로 리턴
            // 23.09.22 현업팀 요청사항 : 메인 페이지로 이동 처리
            // 23.09.07 react수정) INS > UPD로 바꾸는 로직이 존재하나 새로고침하면 다시 INS로 돌아감
            // INS 일 경우에는 홈으로 튕기게 변경
            /*if(temp_fv.get("fv_sFlag") == "INS"){
                pageMove(process.env.REACT_APP_URL_MAIN|| '');
            }*/
            // fn_attachFile_N(temp_ds_Part[0]["ATTACH_FILE_ID"] as string);
            if(temp_fv.get("fv_sFlag") == "INS") {
                pageMove(process.env.REACT_APP_URL_QMV_ADD || '');
            }else{
                pageMove(process.env.REACT_APP_URL_QMV_SEARCH || '');
            }

            // 23.09.07 react수정) 넥사에서도 사용하지 않는 소스
            // this.selectFinish = true;

            //데이터 변경이력 초기화
            /*this.ds_Spec.applyChange();
            this.ds_Defect.applyChange();
            this.fv_tempSaveCnt = this.fv_tempSaveCnt + 1;

            this.ds_Part.setColumn(0, "AGENT_SUBMIT_ID", this.ds_Res.getColumn(0, "AGENT_SUBMIT_ID"));

            this.div_BtnBot.form.fn_ShowBtn("btn_delete", true);
            this.div_BtnTop.form.fn_ShowBtn("btn_delete", true);

            if (this.fv_close==true) {
                this.close("S");
            }*/
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    };

    function fn_tranSave()
    {
        let save_ds_Part = temp_ds_Part;
        var sProdDt = String(save_ds_Part[0]["PROD_DT"]);
        if(sProdDt.length > 8){
            let sProdDtArr = sProdDt.split("-");
            save_ds_Part[0]["PROD_DT"] = sProdDtArr[0]+sProdDtArr[1]+sProdDtArr[2];
        }
        save_ds_Part[0]["MAPPING_YN"] = "Y";

        save_ds_Part[0]["MOBILE_YN"] = "Y";

        //RSR_USE_YN 임시
        save_ds_Part[0]["RSR_USE_YN"] = "";

        // "qmvInspectVndRcrdDetailRegSave"
        return sendAxios('/common/nexacro/QMV_P_Out_InspectVndRcrdDetailRegSave.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Search: temp_ds_Search,
            ds_LotId: [{LOT_ID:""}],
            ds_Part: save_ds_Part,
            ds_PartInfo: [{LOT_STATUS : "",ECN_NO : "",LOT_MEMO : "",ATTACH_FILE_ID : "",USER_ID : "",USER_NM : ""}],
            ds_Spec: temp_ds_Spec,
            ds_Defect: temp_ds_Defect,
            ds_grdColList: temp_ds_grdColList,
            ds_submitDetail: temp_ds_submitDetail,
            ds_qrCode: temp_ds_qrCode,
            Parameters:[{
                "sFlag": temp_fv.get("fv_sFlag")
            }]
        }).then(response => {
            let ds_Res = response?.data.ds_Res;
            if(ds_Res.length > 0){
                if(ds_Res[0]["CODE"] == "SUCESS"){
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG00058"), //저장되었습니다.
                    });

                    // 23.10.18 현업팀 요청 사항 : 신규등록일 경우 신규등록 페이지로 리턴, 조회일 경우 조회 페이지로 리턴
                    if(temp_fv.get("fv_sFlag") == "INS") {
                        pageMove(process.env.REACT_APP_URL_QMV_ADD || '');
                    }else{
                        pageMove(process.env.REACT_APP_URL_QMV_SEARCH || '');
                    }

                    //this.close("S");	//S : Save, N : cancel
                }  else {
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: ds_Res[0]["MSG"]  // 저장 실패
                    });
                }
            }
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /*
    *	Spec Info 조회
    */
    function fn_specInfoSelect()
    {
        return sendAxios('/common/nexacro/QMV_P_OUT_InspectRcrdInfoRegSpecSelect.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Part: temp_ds_Part
        }).then(response => {
            temp_ds_Spec = response?.data.ds_Spec;

            let lotSize = Number(temp_ds_Part[0]["LOT_SIZE"]);
            let samCnt = 0;

            if(temp_ds_Spec.length > 0){
                for(let i=0;i<temp_ds_Spec.length;i++){
                    let inspMth = temp_ds_Spec[i]["INSP_MTH"] as string;
                    let aql = Number(temp_ds_Spec[i]["AQL_LEVEL"]);

                    //sample cnt 계산 로직
                    if(inspMth != null && gfn.gfn_left(inspMth,1) == "A"){// 전수검사일때
                        samCnt = lotSize;
                    }else{ // 검사방식, AQL 계산
                        samCnt = fn_getSampleCntByMeasMth(inspMth, lotSize);
                        if(gfn.gfn_left(inspMth,1) == "S" || gfn.gfn_left(inspMth,1) == "G"){
                            if(aql != null){
                                samCnt = fn_getSampleCntByAql(aql, samCnt, lotSize);
                            }
                        }
                    }
                    //lot 보다 샘플수가 크면 샘플수를 랏사이즈로 변경
                    samCnt = lotSize < samCnt ? lotSize : samCnt;

                    temp_ds_Spec[i]["SAM_CNT"] = String(samCnt);
                }
            }

            //2018.12.07 KSK Modify 현업요청사항으로 수정가능한 항목이 없을 경우 '측정값 입력' 버튼을 숨길 수 있도록 한다.
            var bIsModify = false;
            let filterList = temp_ds_SpecOrg.filter(item => item.SERNO === '1');
            for( var i =0; i< temp_ds_Spec.length; i++){
                var specId = temp_ds_Spec[i]["SPEC_ID"];
                var findrow = temp_ds_SpecOrg.findIndex((item) =>item.SPEC_ID == specId);

                // 23.08.27 react수정) typescript로 인한 오류 발생 > 해결
                if(temp_ds_SpecOrg[findrow]) {
                    temp_ds_Spec[i]["LOT_ID"] = temp_ds_SpecOrg[findrow]["LOT_ID"];
                    temp_ds_Spec[i]["REINSP_NUM"] = temp_ds_SpecOrg[findrow]["REINSP_NUM"];
                    temp_ds_Spec[i]["CO_YN"] = temp_ds_SpecOrg[findrow]["CO_YN"];

                    if (temp_ds_Spec[i]["CO_YN"] != "Y" && Number(temp_ds_Spec[i]["SAM_CNT"]) > 0) {
                        bIsModify = true;
                    }
                }else{
                    temp_ds_Spec[i]["LOT_ID"] = "";
                    temp_ds_Spec[i]["REINSP_NUM"] = "";
                    temp_ds_Spec[i]["CO_YN"] = "";
                }
            }

            // react확인) 하단 제거
            /*
            //2018.12.07 KSK Modify 현업요청사항으로 수정가능한 항목이 없을 경우 '측정값 입력' 버튼을 숨길 수 있도록 한다.
            var bIsModify = false;
            this.ds_SpecOrg.filter("SERNO=='1'");
            for( var i =0; i< this.ds_Spec.rowcount; i++){
                var specId = this.ds_Spec.getColumn(i,"SPEC_ID");
                var findrow  =this.ds_SpecOrg.findRow("SPEC_ID", specId);
                this.ds_Spec.setColumn(i, "LOT_ID", this.ds_SpecOrg.getColumn(findrow, "LOT_ID"));
                this.ds_Spec.setColumn(i, "REINSP_NUM", this.ds_SpecOrg.getColumn(findrow, "REINSP_NUM"));
                this.ds_Spec.setColumn(i, "CO_YN", this.ds_SpecOrg.getColumn(findrow, "CO_YN"));

                if(this.ds_Spec.getColumn(i, "CO_YN") != "Y" && this.ds_Spec.getColumn(i, "SAM_CNT") > 0){
                    bIsModify = true;
                }
            }

            if(bIsModify == false){
                //측정값 입력
                this.div_Main.form.div_Detail.form.div_BtnMidRight.form.fn_ShowBtn("btn_inputList", false);
            }
            */

            fn_creatDataset();
            /*//그리드 총 Row 결과를 초기화한다.
            this.div_Main.form.div_Detail.form.div_ListResult.form.fn_setResultCount(this.ds_Spec.rowcount);
            */
            for( var i =0; i< temp_ds_Spec.length;i++){
                fn_setSpecGrid(i, true);
            }
            //데이터 상태 초기화
            /*this.ds_Spec.applyChange();
            this.ds_Defect.applyChange();*/
            setDs_Spec(temp_ds_Spec);
            setDs_Defect(temp_ds_Defect);
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    function fn_setDsSpec()
    {
        //TODO NUM_YN 없는 데이터 세팅
        for(var i = 0; i < temp_ds_SpecOrg.length; i++){
            if ( gfn.gfn_isNull(temp_ds_SpecOrg[i]["NUM_YN"])) {
                if ( gfn.gfn_isNull(temp_ds_SpecOrg[i]["USL"])
                    && gfn.gfn_isNull(temp_ds_SpecOrg[i]["CL"])
                    && gfn.gfn_isNull(temp_ds_SpecOrg[i]["LSL"]))
                {
                    temp_ds_SpecOrg[i]["NUM_YN"] = "N";
                }else {
                    temp_ds_SpecOrg[i]["NUM_YN"] = "Y";
                }
            }
        }

        for(var i = 0; i < temp_ds_SpecOrg.length; i++){
            var sSamCnt = temp_ds_SpecOrg[i]["SAM_CNT"];
            var sSerNo = temp_ds_SpecOrg[i]["SERNO"];
            if(sSerNo == "1"){
                temp_ds_Spec.push({
                    "LOT_ID" : temp_ds_SpecOrg[i]["LOT_ID"],
                    "REINSP_NUM" : temp_ds_SpecOrg[i]["REINSP_NUM"],
                    "SPEC_ID" : temp_ds_SpecOrg[i]["SPEC_ID"],
                    "SPEC_REV" : temp_ds_SpecOrg[i]["SPEC_REV"],
                    "PART_CD" : temp_ds_SpecOrg[i]["PART_CD"],
                    "ATTACH_FILE_ID" : temp_ds_SpecOrg[i]["ATTACH_FILE_ID"],
                    "MEQUIP_CD" : temp_ds_SpecOrg[i]["EQUIP_CD"],
                    "MEQUIP_NM" : temp_ds_SpecOrg[i]["EQUIP_NM"],
                    "SERNO" : temp_ds_SpecOrg[i]["SERNO"],
                    "PARA_CD" : temp_ds_SpecOrg[i]["PARA_CD"],
                    "PARA_NM" : temp_ds_SpecOrg[i]["PARA_NM"],
                    "AQL_LEVEL" : temp_ds_SpecOrg[i]["AQL_LEVEL"],
                    "USL" : temp_ds_SpecOrg[i]["USL"],
                    "CL" : temp_ds_SpecOrg[i]["CL"],
                    "LSL" : temp_ds_SpecOrg[i]["LSL"],
                    "UNIT" : temp_ds_SpecOrg[i]["UNIT"],
                    "POINT" : temp_ds_SpecOrg[i]["POINT"],
                    "INSP_MTH" : temp_ds_SpecOrg[i]["INSP_MTH"],
                    "LU_GB" : temp_ds_SpecOrg[i]["LU_GB"],
                    "NUM_YN" : temp_ds_SpecOrg[i]["NUM_YN"],
                    "SAM_CNT" : temp_ds_SpecOrg[i]["SAM_CNT"]
                });
            }
        }

        for( var i =0; i< temp_ds_Spec.length; i++){

            var specId = temp_ds_Spec[i]["SPEC_ID"];
            var findrow  = temp_ds_SpecOrg.findIndex((item) =>item.SPEC_ID == specId);
            temp_ds_Spec[i]["LOT_ID"] = temp_ds_SpecOrg[findrow]["LOT_ID"];
            temp_ds_Spec[i]["REINSP_NUM"] = temp_ds_SpecOrg[findrow]["REINSP_NUM"];

            //				trace("findrow  :"  + findrow);
            var samCnt = temp_ds_SpecOrg[findrow]["SAM_CNT"];
            //trace("findrow  :"  + findrow + "SAMCNT : " + samCnt);
            //샘플 있는경우
            if ( findrow != -1 ) {
                temp_ds_Spec[i]["SAM_CNT"] = samCnt;

                //샘플 갯수 없는경우 //기본정보반 저장
            } else {
                temp_ds_Spec[i]["SAM_CNT"] = 0;
            }
        }

        fn_creatDataset();
        for( var i =0; i<temp_ds_Spec.length;i++){
            fn_setSpecGrid(i, true);
        }

        setDs_Spec(temp_ds_Spec);
        setDs_Defect(temp_ds_Defect);
    }

    /*
     *	데이터셋에 샘플측정 결과 컬럼 생성
     */
    function fn_creatDataset()
    {
        // 23.08.27 react수정) react는 filter하면 다른 변수에 선언하기 때문에 초기화 할 필요 없음
        // this.ds_SpecOrg.filter("");

        var nMaxSamArr = new Array();
        for(var i =0; i<temp_ds_Spec.length ; i++){
            nMaxSamArr[i] = temp_ds_Spec[i]["SAM_CNT"];
        }
        var nMaxSam = Math.max.apply(null, nMaxSamArr); // 최대값 구하기
        var nSam = 0;

        temp_fv.set("fv_maxSamCnt",nMaxSam);
        setFv(temp_fv); //엑셀 다운로드시 강제펼침에 사용
        // 23.08.27 react수정) 웹에만 표기되는 내용 삭제 처리
        /*if (nMaxSam<=3){
            this.div_Main.form.div_Detail.form.grd_DetailList.setCellProperty("head",17,"cssclass","");
        }
        this.div_Main.form.div_Detail.form.grd_DetailList.set_enableevent(false);
        this.div_Main.form.div_Detail.form.grd_DetailList.set_enableredraw(false);*/

        for(var i=0; i<nMaxSam; i++){
            // 23.08.27 react수정) 웹에만 표기되는 내용 삭제 처리
            // this.ds_Spec.addColumn( "MEAS_VAL_"+(i+1), "string", 256);

            temp_ds_grdColList.push({
                COL_NAME: "#" + (i + 1),
                BIND_COL: "MEAS_VAL_" + (i + 1)
            });

            /** VOC 28 Start : 2019.07.16 채보람 검사성적서 항목 SN 입력칸 추가 **/
            /** Serial No 저장으로 인하여 ds_grdColList 추가 **/
            if (temp_ds_serialRev.length > 0)
            {
                var revSerialNo = temp_ds_serialRev[i]?.SERIAL_NO;
                var revSerNo = Number(temp_ds_serialRev[i]?.SERNO) -1;

                if (revSerialNo == undefined || revSerialNo == null || revSerialNo == "")
                {
                    revSerialNo = null;
                }
                temp_ds_grdColList[revSerNo]['SERIAL_NO'] = revSerialNo;
            }
            /** VOC 28 End   : 2019.07.16 채보람 검사성적서 항목 SN 입력칸 추가 **/
        }
        // 23.08.22 react수정) react 기능으로 추가
        setDs_grdColList(temp_ds_grdColList);

        // 측정 결과값 spec에 매핑
        if(temp_fv.get("fv_sFlag") == "UPD"){
            var nSpecRow = 0;
            var nCol = 1;
            for(var j=0; j<temp_ds_SpecOrg.length; j++){

                var sSerNo = temp_ds_SpecOrg[j]["SERNO"];
                var specId = temp_ds_SpecOrg[j]["SPEC_ID"];
                if ( sSerNo =="1" ){
                    var nSpecRow = temp_ds_Spec.findIndex((item)=>item["SPEC_ID"] === specId);
                }

                var specSampleCnt = Number(temp_ds_Spec[nSpecRow]["SAM_CNT"]);
                var specOrgMaxCnt = temp_ds_SpecOrg.filter(item => item.SPEC_ID === specId).length;

                if (specOrgMaxCnt < specSampleCnt ) {
                    specSampleCnt = specOrgMaxCnt;
                }

                if (nCol <= specSampleCnt ) {
                    let sNumYnS = temp_ds_SpecOrg[j][temp_ds_SpecOrg[j]["NUM_YN"] == "Y" ? "MEAS_VAL" : "MEAS_RESULT"];

                    temp_ds_Spec[nSpecRow]["MEAS_VAL_"+nCol] = sNumYnS;
                }

                if(nCol >= specSampleCnt){
                    nCol = 1;
                }else{
                    nCol++;
                }
            }
        }

        setDs_Spec(temp_ds_Spec);

        // this.div_Main.form.div_Detail.form.grd_DetailList.set_enableredraw(true);
        // this.div_Main.form.div_Detail.form.grd_DetailList.set_enableevent(true);

        // 23.08.27 react수정) 페이징 처리 제외됨
        // this.fn_PageInit();

        //스펙 스크롤 위치 변경
        // this.ds_Spec.set_rowposition(0);
        temp_fv.set("fv_changeData",false);
        setFv(temp_fv);

    };

    /*
     *	불량판정내용요약 추가
     */
    function fn_addDefect(specRow :number)
    {
        var sSpectId = temp_ds_Spec[specRow]["SPEC_ID"];		//불량 선택된 검사항목의 specId
        var sSpectRev = temp_ds_Spec[specRow]["SPEC_REV"];	//불량 선택된 검사항목의 specRev

        var addRowYn = true;											//로우 추가 여부
        var nDefectRow = temp_ds_Defect.length;						//현재 불량판정내용요약 총 row수
        var sDefectRowType = "I";										//불량판정내용요약 rowtype(S,I,U,D)
        var nRow = 0;													//추가해야할 row
        for(var i = 0; i < nDefectRow; i++){
            var sDSpectId = temp_ds_Defect[i]["SPEC_ID"];
            var sDSpectRev = temp_ds_Defect[i]["SPEC_REV"];

            // 23.09.09 react수정) 넥사에서는 deleteRow로 처리하지만 모바일에서는 그렇게 진행할 수 없음
            if(sSpectId == sDSpectId){
                if(temp_ds_Defect[i]["ROW_TYPE"]==='D'){
                    temp_ds_Defect = temp_ds_Defect.filter((item, index) => index != i);
                    break;
                }
                //trace("불량그룹 추가 안함");
                addRowYn = false;
                nRow = i;
            }
        }
        if(addRowYn){
            let temp_ds_Defect_N = {
                ROW_TYPE    : sDefectRowType,
                SPEC_ID     : sSpectId,
                SPEC_REV    : sSpectRev,
                EQUIP_CD    : temp_ds_Spec[specRow]["MEQUIP_CD"],
                EQUIP_NM    : temp_ds_Spec[specRow]["MEQUIP_NM"],
                DEFECT_CNT  : temp_ds_Spec[specRow]["MEAS_DEFECT_CNT"],
                PARA_CD     : temp_ds_Spec[specRow]["PARA_CD"],
                PARA_NM     : temp_ds_Spec[specRow]["PARA_NM"]
            }
            temp_ds_Defect.push(temp_ds_Defect_N);

            //this.fn_defectGroupSeclect();	//불량 그룹 조회(Combo)
            //그리드 총 Row 결과를 초기화한다.
            // 23.08.22 react수정) 화면에 표시되는 부분 없음
            // this.div_Main.form.div_Defect.form.div_ListResult.form.fn_setResultCount(this.ds_Defect.rowcount);
        }
    };

    function fn_removeDefect(paraCd:string)
    {
        var row= temp_ds_Defect.findIndex((item) =>item.PARA_CD == paraCd);

        if (row == -1) {
            return;
        }

        // this.ds_Defect.setColumn( row, "ROW_TYPE", "D");
        // this.ds_Defect.deleteRow(row);
        temp_ds_Defect[row]["ROW_TYPE"] = "D";

        // 23.08.22 react수정) 화면에 표시되는 부분 없음
        // this.div_Main.form.div_Defect.form.div_ListResult.form.fn_setResultCount(this.ds_Defect.rowcount);
    }

    // 23.08.22 react수정) 현재 사용하지 않음
    // function checkChangeDataSet() {}

    /*
    * 버튼 임시저장 클릭 시 이벤트 함수
    */
    function fn_tempSave()
    {
        setDialog({
            show: true,
            type: 'CONFIRM',
            text: getMessage('MSG01519'),   // 임시저장 하시겠습니까?
            confirm: () => {
                fv_sBtnFlag = "TSAVE";
                fn_fileUpload(checkChangeFile());
            }
        });
    };

    function fn_tempSave_N()
    {
        fv_sBtnFlag = "TSAVE";
        fn_fileUpload(checkChangeFile());
    }

    /*
    * 버튼 저장 클릭 시 이벤트 함수
    */
    function fn_save()
    {
        setDialog({
            show: true,
            type: 'CONFIRM',
            text: getMessage('MSG01520'),   // 저장 하시겠습니까?
            confirm: () => {
                fv_sBtnFlag = "SAVE";
                fn_fileUpload(checkChangeFile());
            }
        });
    };

    /*
     *	파일 변경사항이 있으면 true 리턴
     */
    function checkChangeFile()
    {
        var result = false;

        let temp_ds_upload = [...ds_upload];
        let isUpadte = temp_ds_upload.filter(item => item.FILE_ID === "").length > 0 ? true : false;

        // if(!this.gfn_dsIsUpdated(fileUpload.ds_upload) && fileUpload.ds_remove.rowcount == 0){
        if(!isUpadte && ds_remove.length == 0){
            result = false;
        }else{
            result = true;
        }
        return result;
    }

    /*
    * 첨부파일 저장
    * 첨부파일을 먼저 저장 하고 콜백함수에서 (fn_attachFileComplete) insert해야한다.
    param : none
    */
    function fn_fileUpload(isChanged:boolean)
    {
        if(!checkValid()) {
            return;
        }

        // "fn_setInspGb" 대체
        // this.fn_setInspGb(this.ds_Part.getColumn(0,"NEW_PART_TYPE"));

        if (isChanged) {
            //파일 변경되었으면 세이브 하고 저장(->fn_attachFileComplete)
            fn_file_save();
        } else {
            //아니면 바로저장
            fn_preSave();
        }
    };

    /*
    * 첨부파일 업로드 성공 후 호출되는 함수
    * Param : sFileId - 파일업로드 성공 시 생성된 FileId
    * return : none
    */
    function fn_attachFileComplete(sFileId:string)
    {
        temp_ds_Part[0]["ATTACH_FILE_ID"] = sFileId;
        fn_preSave();
    };

    // 저장전 분기 처리
    async function fn_preSave()
    {
        if(temp_fv.get("fv_sFlag") == "INS"){
            await fn_lotIdSelect(); // 신규 일때 채번
        }

        if (fv_sBtnFlag=="TSAVE"){
            await fn_tranTempSave();
        }else if (fv_sBtnFlag=="SAVE") {
            await fn_tranSave();
        }
    }

    function checkValid()
    {
        // 23.08.22 react수정) valid 부분 없음
        /*if (this.gfn_checkValidObject(this.div_Main.form.div_Part) == false){
            return false;
        }*/
        if(!(temp_ds_Part[0]["AGENT_ASSOCIATION"] == "01" && fv_sBtnFlag == "TSAVE")) {
            if(temp_ds_Part[0]["NEW_PART_TYPE"] == "C"){
                if(gfn.gfn_isNull(temp_ds_Part[0]["MOD_PART_MEMO"])) {
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage('MSG01071'),   //변경자재는 ECN 번호가 필수 입력값 입니다.
                    });
                    return false;
                }
            }
        }

        if(fv_sBtnFlag == "SAVE") {
            var nullCnt = 0;
            for(var i = 0; i < temp_ds_Spec.length; i++){
                var sNumYn = temp_ds_Spec[i]["NUM_YN"];
                var nSam = Number(temp_ds_Spec[i]["SAM_CNT"]);
                for(var j = 0; j < nSam; j++){
                    var nSamVal = temp_ds_Spec[i]["MEAS_VAL_"+(j+1)];	//샘플측정값
                    if(gfn.gfn_isNull(nSamVal)){
                        nullCnt++;

                        // this.fn_moveToPage(i, j+1);
                        setDialog({
                            type: 'ALERT',
                            show: true,
                            text: getMessage("MSG09001").replace("{0}",getDomain("DOMAIN2059")),//샘플측정결과
                        });
                        return false;
                    }
                }
            }
        }

        if (fv_sBtnFlag == "SAVE")  {
            let temp_ds_Defect = [...ds_Defect];
            temp_ds_Defect = temp_ds_Defect.filter(f=>f.ROW_TYPE !== "D");

            // if (this.gfn_checkValidObject(this.div_Main.form.div_Defect.form.grd_Defect) == false){
            for(let a=0;a<temp_ds_Defect.length;a++){
                if(gfn.gfn_isNull(temp_ds_Defect[a]["DEFECT_GROUP_CD"]) || gfn.gfn_isNull(temp_ds_Defect[a]["SYMPTOM_CD"]) || gfn.gfn_isNull(temp_ds_Defect[a]["DEFECT_CONTENTS"])){
                    let msg = "";
                    if(gfn.gfn_isNull(temp_ds_Defect[a]["DEFECT_GROUP_CD"])){   // 불량그룹
                        msg =getDomain('DOMAIN2198');
                    }else if(gfn.gfn_isNull(temp_ds_Defect[a]["SYMPTOM_CD"])){  // 불량증상
                        msg =getDomain('DOMAIN1158');
                    }else if(gfn.gfn_isNull(temp_ds_Defect[a]["DEFECT_CONTENTS"])){  // 상세불량
                        msg =getDomain('DOMAIN2390');
                    }

                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG09001").replace("{0}",msg)
                    });
                    return false;
                }
            }

            if(temp_ds_Part[0]["RISK_LOT_YN"]=="Y") {
                if(gfn.gfn_isNull(temp_ds_Part[0]["RISK_LOT_TYPE"])){
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG09001").replace("{0}",getDomain("DOMAIN3995")),//Risk 사전신고
                    });
                    return false;
                }

                if(gfn.gfn_isNull(temp_ds_Part[0]["RISK_LOT_MEMO"])){
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG09001").replace("{0}",getDomain("DOMAIN3997")),//Risk Lot 메모
                    });
                    return false;
                }
            }
        }

        if(gfn.gfn_toString(temp_ds_Part[0]["LOT_MEMO"]).length > 1000){
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG09001").replace("{0}",getDomain("DOMAIN2883")),//메모
            });
            return false;
        }

        return true;
    }

    // 23.08.22 react수정) 페이지 이동하는 로직이여서 사용 안해도 됨
    function fn_moveToPage(row:number, col:number) {}

    function fn_setSpecGrid_N(i :number, isTemp :boolean, vds_Spec :Array<NexacroJsonUnitType>, vds_Part :Array<NexacroJsonUnitType>){
        temp_ds_Part = vds_Part;
        temp_ds_Spec = vds_Spec;
        fn_setSpecGrid(i, isTemp);
        setDs_Defect(temp_ds_Defect);
        return temp_ds_Spec;
    }

    // "fn_attachFileSearch" 대체
    function fn_attachFile_N(file_id:string){
        if(!gfn.gfn_isNull(file_id)) {
            return sendAxios('/common/nexacro/commonSelectFileList.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_Search: [{
                    FILE_ID: file_id
                }]
            })
            .then(response => {
                setDs_upload(response?.data.ds_List || []);
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
        }else{
            return;
        }
    }
    
    function fn_setSpecGrid(i :number, isTemp :boolean)
    {
        var sNumYn = temp_ds_Spec[i]["NUM_YN"];
        var nSam = Number(temp_ds_Spec[i]["SAM_CNT"]);
        var nDefect = 0;		//불량수
        var nAvg:number|string = 0;	//평균
        var nMin:number|string = 0;	//Min
        var nMax:number|string = 0;	//Max
        var nullCnt =0;
        var notNullCnt = 0;

        //var samMeasCnt = 0;
        var specMeasArr = new Array();
        var point = Number(temp_ds_Spec[i]["POINT"]);

        for(var j = 0; j < nSam; j++){
            var nSamVal = temp_ds_Spec[i]["MEAS_VAL_"+(j+1)] as string;

            if( gfn.gfn_isNull(nSamVal)){
                nullCnt++;
            } else {
                notNullCnt++;
            }
            //측정값이 숫자일 경우
            if(sNumYn == "Y"){
                // 23.08.24 react수정) nSamValN 추가
                // react확인) 하단 변경
                let nSamValN = Number(gfn.gfn_return_string(nSamVal).replace(/,/gi, ""));

                /** VOC 26 Start : 2019.05.02 채보람 NG찾기 기능 개선 **/
                //if (!this.gfn_isNull(nSamVal)){
                /** VOC 26 End   : 2019.05.02 채보람 NG찾기 기능 개선 **/

                specMeasArr[j] = Number(nSamVal);

                /** VOC 31 Start : 2019.04.29 채보람 숫자입력값 복사 기능 개선 **/
                if (
                    temp_ds_Spec[i][temp_ds_grdColList[j]["BIND_COL"] as string] != undefined &&
                    temp_ds_Spec[i][temp_ds_grdColList[j]["BIND_COL"] as string] != ""
                ){
                    var originStr = nSamVal;

                    // react확인) 하단 변경
                    // temp_ds_Spec[i][temp_ds_grdColList[j+1]["BIND_COL"]] = fn_comma(originStr, i, j + 1, ""));
                    temp_ds_Spec[i][temp_ds_grdColList[j]["BIND_COL"] as string] = originStr;
                }
                /** VOC 31 End   : 2019.04.29 채보람 숫자입력값 복사 기능 개선 **/

                var nUsl = Number(temp_ds_Spec[i]["USL"]);	//최대값
                var nLsl = Number(temp_ds_Spec[i]["LSL"]);	//최소값

                /** VOC 26 Start : 2019.05.02 채보람 NG찾기 기능 개선 **/
                if (gfn.gfn_isNull(nSamVal))
                {
                    /*nDefect++;*/
                    // 23.08.24 react수정) NG찾기를 위한 데이터 세팅 > NG찾기 화면에 존재하지 않음
                    // ngArr.push(i + "::" +j);
                }
                else
                {
                    if(nSamValN < nLsl || nSamValN > nUsl){
                        nDefect++;
                        // 23.08.24 react수정) NG찾기를 위한 데이터 세팅 > NG찾기 화면에 존재하지 않음
                        // ngArr.push(i + "::" +j);
                    }

                    nAvg = Number(nAvg + nSamValN);	// 평균 구하기 위한 누적
                }
                //}
                /** VOC 26 End   : 2019.05.02 채보람 NG찾기 기능 개선 **/

                //측정값이 합/불일 경우
            }else{
                // 23.09.06 react수정) 값이 없을 경우 무조건 OK 처리 [
                if( gfn.gfn_isNull(nSamVal)) {
                    temp_ds_Spec[i]["MEAS_VAL_" + (j + 1)] = "Y";
                    nSamVal = temp_ds_Spec[i]["MEAS_VAL_" + (j + 1)] as string;
                    nullCnt--;
                    notNullCnt++;
                }
                // 23.09.06 react수정) 값이 없을 경우 무조건 OK 처리 ]
                if(nSamVal == "N"){
                    nDefect++;
                    // 23.08.24 react수정) NG찾기를 위한 데이터 세팅 > NG찾기 화면에 존재하지 않음
                    // ngArr.push(i + "::" +j);
                }
            }
        }
        temp_ds_Spec[i]["MEAS_DEFECT_CNT"] = nDefect;	//불량수

        if(sNumYn == "Y") {
            if (notNullCnt > 0) {
                nAvg = nAvg / notNullCnt;
                nMin = Math.min.apply(null, specMeasArr);
                nMax = Math.max.apply(null, specMeasArr);
                nAvg = nAvg.toFixed(point);
                nMin = nMin.toFixed(point);
                nMax = nMax.toFixed(point);
            }else {
                nAvg = "";
                nMin = "";
                nMax = "";
            }
        }else{
            nAvg = "-";
            nMin = "-";
            nMax = "-";
        }

        temp_ds_Spec[i]["MEAS_MAX"] = nMax; //Max
        temp_ds_Spec[i]["MEAS_AVG"] = nAvg; //평균
        temp_ds_Spec[i]["MEAS_MIN"] = nMin; //Min

        // 23.09.06 react수정) 검사수 세팅
        temp_ds_Spec[i]["NOT_NULL_CNT"] = notNullCnt;

        // SPEC 판정
        if(nDefect > 0 ){
            temp_ds_Spec[i]["MEAS_RSLT"] = "NG";		//판정
        }
        if (nullCnt ==0 && nDefect==0) {
            temp_ds_Spec[i]["MEAS_RSLT"] = "OK";		//판정
        }
        if (nullCnt > 0 && nDefect ==0) {
            temp_ds_Spec[i]["MEAS_RSLT"] = "-";		//판정
        }

        //SPEC 불량 사유 : 템프로 조회할때는 동작하지 않음
        // 23.08.22 react수정) 상세와 수정이 같은 페이지 참조
        if (!isTemp ) {
            //trace("신규 불량 사유");
            if(nDefect > 0 ){
                fn_addDefect(i);
            }else {
                fn_removeDefect(temp_ds_Spec[i]["PARA_CD"] as string);
            }
        }

        checkLotResultYn();
        
        // 23.08.22 react수정) 제거
        // this.div_Main.form.div_Part.form.cbo_Result.set_enable(false);
        //this.div_Main.form.div_Detail.form.grd_DetailList.set_enableredraw(true);

    };

    let fv_row = 0;
    let fv_col = 0;

    /** VOC 31 Start : 2019.04.29 채보람 숫자입력값 복사 기능 개선 **/
    /*function fn_comma(x:string, row:number, col:number, flag:string)
    {
        var numberX = x.replace(/,/gi, "");
        var rv = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (row != "NOT" && isNaN(Number(numberX)))
        {
            rv = "";

            fv_row = row;
            fv_col = col;

            if (flag != "POP")
            {
                setDialog({
                    type: 'CONFIRM',
                    show: true,
                    text: getMessage('MSG09004'),
                    confirm: () => {

                    }
                });
            }
        }

        return rv;
    };*/

    function fn_callBackComma()
    {
        fn_moveToPage(fv_row, fv_col);
    };

    /*
     * 최종 합격불합격 자동 세팅
       각 SPEC의 측정결과를 기준으로 확인
     */
    function checkLotResultYn()
    {
        var result = true;
        var measCnt = 0;
        for(var i =0; i < temp_ds_Spec.length ; i ++) {
            //하나라도 합격을 못한다면
            if ( temp_ds_Spec[i]["MEAS_RSLT"] == "NG") {
                result = result && false;
            }
            if (temp_ds_Spec[i]["MEAS_RSLT"] != "-" ){
                measCnt++;
            }
        }

        if(result) {
            temp_ds_Part[0]["LOT_RESULT"] = "Y";
        } else {
            temp_ds_Part[0]["LOT_RESULT"] = "N";
        }
        setDs_Part(temp_ds_Part);
    };

    /* Agent Submit 저장 여부 확인 */
    function fn_checkAgentSubmit()
    {
        if(temp_ds_Part[0]["AGENT_ASSOCIATION"] as string != "01"){
            return;
        }

        // callBack = "selectQmvLotAgentSumit"
        return sendAxios('/common/nexacro/selectQmvLotAgentSumit.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Part : temp_ds_Part
        }).then(response => {
            let temp_ds_Res = response?.data.ds_Res;

            if(temp_ds_Res.length > 0){
                if(temp_ds_Res[0]["CODE"] != "SUCESS"){
                    setDialog({
                        show: true,
                        type: 'ALERT',
                        text: getMessage("MSG01447")    // 알수없는 이유로 Agent Data 연계작업이 정상처리되지 않았습니다...
                    });
                }
            }
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /*
    * AQL 테이블 조회
    * return : none
    */
    function fn_selectAqlCnt()
    {
        return sendAxios('/common/nexacro/QMS_P_Pre_SelectAqlCnt.do', 'POST', AXIOS_HEADER.NEXACRO, {});
    }

    function fn_getSampleCntByAql(aql:number, samCnt:number, size:number)
    {
        var rtnSamCnt = 0;
        const filterAql = temp_ds_Aql.filter(item => Number(item.AQL_LEVEL) == aql);
        let idx = filterAql.findIndex((item) => Number(item.AQL_SAMPLE_CNT) == samCnt);

        var lotSize = size;

        if(idx > -1){
            var iterator = filterAql[idx]["ACCESS_PATH"];
            if(iterator == "U"){
                while(iterator != "OK"){
                    iterator = filterAql[idx]["ACCESS_PATH"];
                    rtnSamCnt = Number(filterAql[idx]["AQL_SAMPLE_CNT"]);
                    idx--;
                }
            }else if(iterator == "D"){
                while(iterator != "OK"){
                    iterator = filterAql[idx]["ACCESS_PATH"];
                    rtnSamCnt = Number(filterAql[idx]["AQL_SAMPLE_CNT"]);
                    idx++;
                }
            }else{
                rtnSamCnt = Number(filterAql[idx]["AQL_SAMPLE_CNT"]);
            }
            if(Number(lotSize) < rtnSamCnt){
                rtnSamCnt = lotSize;
            }
        }else{
            rtnSamCnt = samCnt;
        }
        return rtnSamCnt;
    };

    function fn_getSampleCntByMeasMth(measMth :string, size :number){
        var rtnSamCnt = 0;
        const filterList = temp_ds_AllSample.filter(item => item.MEAS_MTH === measMth);
        //var idx = this.ds_Aql.findRow("AQL_SAMPLE_CNT", samCnt);
        var lotSize = size;

        if(gfn.gfn_left(measMth,1)  == "A"){ //전수검사 case
            rtnSamCnt = lotSize;
        }else if(gfn.gfn_left(measMth,1)  == "C"){
            rtnSamCnt = Number(filterList[0]["SAM_CNT"]);
        }else{
            for(var i=0; i<filterList.length; i++){
                var fromSize = Number(filterList[i]["FROM_SIZE"]);
                var toSize = Number(filterList[i]["TO_SIZE"]);

                if(Number(lotSize) >= fromSize && Number(lotSize) <= toSize){
                    rtnSamCnt = Number(filterList[i]["SAM_CNT"]);
                    break;
                }
            }
        }

        return rtnSamCnt;
    };

    /** Serial No 조회 **/
    function fn_searchSerial()
    {
        if (temp_ds_Spec.length == 0)
        {
            return "";
        }

        temp_ds_searchSerial = [{
            "LOT_ID" : temp_pv.get("pv_lotId"),
            "MENU_STATUS" : "REG"
        }];

        return sendAxios('/common/nexacro/QMV_P_Out_InspectVndRcrdDetailRegSelectSerialNo.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_searchSerial: temp_ds_searchSerial
        }).then(response => {
            temp_ds_serialRev = response?.data.ds_serialRev;
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    };
    /** VOC 28 End   : 2019.07.16 채보람 검사성적서 항목 SN 입력칸 추가 **/

    async function fn_file_save(){
        const formData = new FormData();
        
        // 23.09.23 파일 리사이즈
        const options = {
            maxWidthOrHeight: 1920, //width
            initialQuality: 0.8 //quality
        }
        
        let files = reportRef.current?.files;
        let maxSize = 30 * 1024 * 1024; //* 30MB 사이즈 제한
        let fileSize = 0;
        if (files?.length != undefined) {
            for (let i = 0; i < files?.length; i++) {
                // formData.append('file' + i, files[i]);
                // 23.10.18 이미지 파일이 아닐 경우 resize 로직 제거
                let fileD = files[i];
                if (fileD.type.match('image')) {
                    const compressedFile = await imageCompression(fileD, options);
                    fileD = new File([compressedFile], fileD.name, {type: fileD.type});
                }

                // 23.10.16 파일 사이즈 조정
                fileSize = fileSize + fileD.size;
                if (fileSize >= maxSize) {
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG01296").replace("0", "30MB"),
                    });
                    return;
                }

                formData.append('file' + i, fileD);
            }
            formData.append("workType", "QMV");

            let temp_ds_fileInfo: NexacroJsonUnitType[] = [{}];
            temp_ds_fileInfo[0]['MODULE_ID'] = "QMV";
            if(!gfn.gfn_isNull(ds_Part[0]["ATTACH_FILE_ID"])){
                temp_ds_fileInfo[0]['FILE_ID'] = ds_Part[0]["ATTACH_FILE_ID"];
            }

            let inputDatasets: any = {
                ds_input: ds_upload,
                ds_fileInfo: temp_ds_fileInfo,
                ds_remove: ds_remove
            }
            formData.append("inputDatasets", String(requestParser(inputDatasets)));

            sendAxios('/common/nexacro/commonFileupload.do', 'POST', AXIOS_HEADER.FORM, formData)
                .then(response => {
                    for (const ds_res of responseParser(response?.data)?.ds_output || []) {
                        if (ds_res.ERR_CODE === "SUCESS") {
                            let sRetFileId = ds_res.ERR_MSG as string;
                            fn_attachFileComplete(sRetFileId);
                        }else{
                            setDialog({
                                type: 'ALERT',
                                show: true,
                                text: "파일업로드에 실패하였습니다."
                            });
                            return;
                        }
                    }
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }
    }

    const selectTab = (name: string) => {
        setShowTab(false);

        let preTab: string | undefined = undefined;
        setTabList(tabList.map(tab => {
            if(tab.selected) {
                preTab = tab.name;
            }

            if(tab.name === name) {
                if(preTab) {
                    tab.direction = 'right';
                } else {
                    tab.direction = 'left';
                }
                tab.selected = true;
            } else {
                tab.selected = false;
            }
            return tab;
        }));
    };

    useEffect(() => {
        setShowTab(true);
        setFocusTab(tabList.find(tab => tab.selected) || tabList[0]);
    }, [tabList]);

    const handleNextTab = (name: string) => {
        const selectedIndex = tabList.map((tab, index) => {
            if(tab.name === name) {
                return index;
            } else {
                return 0;
            }
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        if(selectedIndex < tabList.length - 1) {
            selectTab(tabList[selectedIndex + 1].name);
        } else {
            setDialog({
                show: true,
                type: 'ALERT',
                text: '마지막 탭 입니다.'
            });
        }
    };

    function setDataset(datasetName: string, dataset: Array<NexacroJsonUnitType>) {
        if ("ds_Defect" == datasetName) {
            setDs_Defect(dataset);
            setFv_close(true);
        }else if("ds_Spec" == datasetName) {
            setDs_Spec(dataset);
            setFv_close(true);
        }else if("ds_Part" == datasetName) {
            setDs_Part(dataset);
        }else if("ds_upload" == datasetName) {
            setDs_upload(dataset);
        }else if("ds_remove" == datasetName) {
            setDs_remove(dataset);
        }
    }

    useEffect(()=>{
        if(temp_pv.get("pv_status_N") === "INFO") {
            if(fv_close) {
                // 변경내용이 있습니다.\n저장 하시겠습니까?
                confirmHistoryBack(true, getMessage('MSG01521'),
                    () => {
                        fn_tempSave_N();
                    },
                    () => {
                        pageMove(process.env.REACT_APP_URL_MAIN || '');
                    }
                );
            }
        }
    },[fv_close]);

    // 포커싱된 tab을 따라, tab 스크롤 이동
    const scrollRef = useRef<HTMLUListElement>(null);
    useEffect(() => {
        if(scrollRef?.current) {
            const scrollMove = tabList.findIndex(tab => tab.selected) || 0;
            scrollRef.current.scrollTo({left: 0 + (scrollRef.current.children[0].clientWidth * scrollMove), top: 0, behavior: 'smooth'});
        }
    }, [focusTab]);


    return (
        <>
            <div className={'container'}>
                <div className={'tabs-wrap'}>
                    <ul className={'tab-list'} ref={scrollRef}>
                        <li className={focusTab.name === 'basicInformation' ? 'on' : ''}>
                            <button type={'button'} onClick={() => selectTab('basicInformation')}>{getDomain('DOMAIN2068')} {/*기본정보*/}</button>
                        </li>
                        <li className={focusTab.name === 'partInspectionInformation' ? 'on' : ''}>
                            <button type={'button'} onClick={() => selectTab('partInspectionInformation')}>{getDomain('DOMAIN4634')} {/*부품검사정보*/}</button>
                        </li>
                        <li className={focusTab.name === 'detailedInspectionInformation' ? 'on' : ''}>
                            <button type={'button'} onClick={() => selectTab('detailedInspectionInformation')}>{getDomain('DOMAIN2608')} {/*세부검사정보*/}</button>
                        </li>
                        <li className={focusTab.name === 'defectiveContent' ? 'on' : ''}>
                            <button type={'button'} onClick={() => selectTab('defectiveContent')}>{getDomain('DOMAIN4635')} {/*불량내용*/}</button>
                        </li>
                        <li className={focusTab.name === 'judgmentResult' ? 'on' : ''}>
                            <button type={'button'} onClick={() => selectTab('judgmentResult')}>{getDomain('DOMAIN1914')} {/*판정결과*/}</button>
                        </li>
                    </ul>
                </div>
                <CSSTransition in={showTab} classNames={focusTab.direction} timeout={500} unmountOnExit>{focusTab.element}</CSSTransition>
                <div className={'btn-area bottom'}>
                    {
                        (pv.get("pv_status_N") === "INFO") ?
                            (focusTab.name === 'judgmentResult') ?
                                <React.Fragment>
                                <button type={'button'} className={'button-line'} onClick={() => fn_tempSave()}>{getDomain('DOMAIN4636')}{/*임시저장*/}</button>
                                <button type={'button'} className={'button-primary'} onClick={() => fn_save()}>{getDomain('DOMAIN2083')}{/*저장*/}</button>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                <button type={'button'} className={'button-line'} onClick={() => fn_tempSave()}>{getDomain('DOMAIN4636')}{/*임시저장*/}</button>
                                <button type={'button'} className={'button-primary'} onClick={() => handleNextTab(focusTab.name)}>{getDomain('DOMAIN4637')}{/*다음*/}</button>
                                </React.Fragment>
                            :
                            <React.Fragment>
                            <button type={'button'} className={'button-primary'} onClick={() => pageMove(-1)}>{getDomain('DOMAIN4638')} {/*목록으로 이동*/}</button>
                            </React.Fragment>
                    }
                </div>
            </div>
        </>
    );
};