import React, {useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import {CSSTransition} from 'react-transition-group';
import Picture from '../../../common/Picture';

export default function SampleQmvDetailInspectDetail({list, onClose, selected}: {list: any[], onClose: () => void, selected?: number}) {
    const [imagePath, setImagePath] = useState<string[]>();
    const [showPicture, setShowPicture] = useState<boolean>(false);
    const goPictureView = (path: string[]) => {
        setImagePath(path);
        setShowPicture(false);
    };

    const closePicture = () => {
        setShowPicture(false);
        setImagePath(undefined);
    };

    useEffect(() => {
        if(imagePath) {
            setShowPicture(true);
        }
    }, [imagePath]);

    return (
        <>
            <div className={'pop-layer'} style={{display: 'block'}}>
                <div className={'wrap'}>
                    <header>
                        <h1>Sample 출하검사 성적서 작성</h1>
                            <span className={'btn-wrap right'}>
                            <button type={'button'} className={'button-close'} onClick={onClose}>
                                <i className={'hidden'}>닫기</i>
                            </button>
                        </span>
                    </header>
                    <div className={'container-noheader'}>
                        <div className={'comm-slide-wrap'} style={{
                            display: 'block',
                            backgroundColor: 'white',
                            width: '100%',
                            height: 'unset'
                        }}>
                            <div className={'swiper-pagination'} style={{
                                width: '100% !important',
                                paddingBottom: '2rem'
                            }}></div>
                            <Swiper
                                modules={[Pagination]}
                                pagination={{
                                    dynamicBullets: true,
                                    el: '.swiper-pagination',
                                    clickable: true
                                }}
                                // onSwiper={swiper => console.log(swiper)}
                                // onSlideChange={() => console.log('slide change')}
                                initialSlide={selected || 0}
                                className={'swiper mySwiper'}>
                                {
                                    list.map(item => {
                                        return <SwiperSlide key={uuidv4()}>
                                            <div key={uuidv4()} className={'swiper-slide'}>
                                                <ul key={uuidv4()} className={'contents-table-list'}>
                                                    <li key={uuidv4()}>
                                                        <div key={uuidv4()} className={'col'}>
                                                            <span key={uuidv4()} className={'title'}>검사항목</span>
                                                            <span key={uuidv4()} className={'item-wrap'}>{item.name}
                                                                <button key={uuidv4()} type={'button'} className={'btn-gallary'} onClick={() => goPictureView([
                                                                    '/images/@temp1.png',
                                                                    '/images/@temp1.png',
                                                                    '/images/@temp1.png',
                                                                    '/images/@temp1.png'
                                                                ])}>
                                                                    <i className={'hidden'}>이미지</i>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li key={uuidv4()}>
                                                        <div key={uuidv4()} className={'col'}>
                                                            <span key={uuidv4()} className={'title'}>검사방법</span>
                                                            <span key={uuidv4()} className={'item-wrap'}>육안 확대경</span>
                                                        </div>
                                                    </li>
                                                    <li key={uuidv4()}>
                                                        <div key={uuidv4()} className={'col'}>
                                                            <span key={uuidv4()} className={'title'}>LSL</span>
                                                            <span key={uuidv4()} className={'item-wrap right'}>138.24</span>
                                                        </div>
                                                        <div key={uuidv4()} className={'col'}>
                                                            <span key={uuidv4()} className={'title'}>USL</span>
                                                            <span key={uuidv4()} className={'item-wrap right'}>138.54</span>
                                                        </div>
                                                    </li>
                                                    <li key={uuidv4()}>
                                                        <div key={uuidv4()} className={'col'}>
                                                            <span key={uuidv4()} className={'title'}>CL</span>
                                                            <span key={uuidv4()} className={'item-wrap right'}>138.45</span>
                                                        </div>
                                                        <div key={uuidv4()} className={'col'}>
                                                            <span key={uuidv4()} className={'title'}>측정단위</span>
                                                            <span key={uuidv4()} className={'item-wrap right'}>mm</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div key={uuidv4()} className={'table-wrap'}>
                                                    <table key={uuidv4()}>
                                                        <colgroup key={uuidv4()}>
                                                            <col key={uuidv4()} style={{width: '20%'}}/>
                                                            <col key={uuidv4()}/>
                                                        </colgroup>
                                                        <thead key={uuidv4()}>
                                                        <tr key={uuidv4()}>
                                                            <th key={uuidv4()} scope={'col'}>No</th>
                                                            <th key={uuidv4()} scope={'col'}>
                                                                <strong className={'required'}>*</strong>측정결과
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody key={uuidv4()}>
                                                        <tr key={uuidv4()}>
                                                            <td key={uuidv4()}>1</td>
                                                            <td key={uuidv4()} className={'center'}>
                                                                <input key={uuidv4()} type={'text'} defaultValue={'138.46'} className={'result-right'}/>
                                                            </td>
                                                        </tr>
                                                        <tr key={uuidv4()}>
                                                            <td key={uuidv4()}>2</td>
                                                            <td key={uuidv4()} className={'center'}>
                                                                <input key={uuidv4()} type={'text'} defaultValue={'138.46'} className={'result-right'}/>
                                                            </td>
                                                        </tr>
                                                        <tr key={uuidv4()}>
                                                            <td key={uuidv4()}>3</td>
                                                            <td key={uuidv4()} className={'center'}>
                                                                <input key={uuidv4()} type={'text'} defaultValue={'138.46'} className={'result-right'}/>
                                                            </td>
                                                        </tr>
                                                        <tr key={uuidv4()}>
                                                            <td key={uuidv4()}>4</td>
                                                            <td key={uuidv4()} className={'center'}>
                                                                <input key={uuidv4()} type={'text'} defaultValue={'138.46'} className={'result-right'}/>
                                                            </td>
                                                        </tr>
                                                        <tr key={uuidv4()}>
                                                            <td key={uuidv4()}>5</td>
                                                            <td key={uuidv4()} className={'center'}>
                                                                <input key={uuidv4()} type={'text'} defaultValue={'138.46'} className={'result-right'}/>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                        </div>
                        <div className={'btn-area bottom'}>
                            <button type={'button'} className={'button-primary'} onClick={onClose}>닫기</button>
                        </div>
                    </div>
                </div>
            </div>
            <CSSTransition in={showPicture} classNames={showPicture ? 'right' : 'left'} timeout={500} unmountOnExit>
                <Picture pathList={imagePath || []} onClose={closePicture}/>
            </CSSTransition>
        </>
    );
};