/**************************************************************************************************
 * 01. 업무구분	    : P-SIS > 검사결과 입력
 * 02. 프로그램ID	: PsisInspResultReg.tsx
 * 03. 프로그램명	    : P-SIS > 검사결과 입력
 * 04. 화면설명	    : P-SIS > 검사결과 입력
 * 05. 작성일		: 2023.10.04
 * 06. 작성자		: YGY
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-10-04     YGY    최초 작성
 **************************************************************************************************
 */
/******************************************************************************************
 *                                        변경이력                                         *
 *----------   ----------   ------------   ------------------------------------------------*
 * 변경번호     변경일자       변경자                        변경내용                      *
 *----------   ----------   ------------   ------------------------------------------------*

 ******************************************************************************************/

import React, {useEffect, useState} from 'react';
import {useDataset} from '../../../components/contexts/CommonContext';
import useAxios, {AXIOS_HEADER} from '../../../hooks/useAxios';
import {useError} from '../../../hooks/useException';
import useHtmlElement from '../../../hooks/useHtmlElement';
import usePageMove from "../../../hooks/usePageMove";
import {useComponent} from "../../../components/contexts/ComponentContext";
import {useSearch} from "../../../hooks/useSearch";
import useStorage from "../../../hooks/useStorage";
import useGlobalData from "../../../hooks/useGlobalData";
import {ComponentHistoryType, NexacroJsonType, NexacroJsonUnitType} from "../../../components/model/SqciTypes";
import {STORAGE_NAME} from "../../../components/CommonConstants";
import {useLocation, useOutletContext} from "react-router-dom";
import * as gfn from "../../../components/utils/CommonUtils";
import {sqciRouter} from '../../../index';
import {HistoryType} from '../../../components/model/SqciTypes';

/**
 * 대상 부품 조회 메인 페이지(검사결과 입력 진입 페이지)
 * @constructor
 */
export default function PsisInspResultReg() {
    const {dataset, sessionValues} = useDataset();
    const {sendAxios} = useAxios();
    const {throwException} = useError();
    const location = useLocation();
    const {pageMove} = usePageMove();
    const {useSelectBox, useInput} = useHtmlElement();
    const {setDialog} = useComponent();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const divisions = useSelectBox('divisions');
    const plants = useSelectBox('plants');
    const hqCd = useSelectBox('hqCd');
    const parts = useSelectBox('parts');
    const hq2nd = useSelectBox('hq2nd');
    const lines = useSelectBox('lines');
    const [hqInspYn, setHqInspYn] = useState(String);
    const [inspectCountList, setInspectCountList] = useState(new Array<any>());
    const btnData = ['Y', 'N'];
    const {searchComponent, doSearchDivision, doSearchPlant, doSearchVendor, doSearchPart} = useSearch();
    const {getDocumentHistory} = useStorage();
    const {setStorage, getStorage, clearStorage} = useStorage();

    const regexPlant: RegExp = /^(\[\w+\])(.*)$/gi;

    const [divisionList, setDivisionList] = useState<NexacroJsonUnitType[]>([]);
    const [plantList, setPlantList] = useState<NexacroJsonUnitType[]>([]);
    const [vendorList, setVendorList] = useState<NexacroJsonUnitType[]>();
    const [secondVendorList, setSecondVendorList] = useState<NexacroJsonUnitType[]>([]);
    const [partList, setPartList] = useState<NexacroJsonUnitType[]>();
    const [dataList, setDataList] = useState<NexacroJsonUnitType[]>();
    const [division, setDivision] = useState<NexacroJsonUnitType>();
    const [plant, setPlant] = useState<NexacroJsonUnitType | undefined>();
    const [vendor, setVendor] = useState<NexacroJsonUnitType | undefined>();
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
    const sLocale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);

    const getSearchCondition:any = getStorage('LOCAL', location.pathname);

    useEffect(() => {

        if ((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            fn_onload();
        }
    }, [enteredComponent, exitedComponent]);

    async function fn_onload(){

        
        let plantList:any[] = [];
        if(sessionValues?.WORK_GROUP_CD == 2 || sessionValues?.WORK_GROUP_CD == 3 || sessionValues?.WORK_GROUP_CD == 4 || sessionValues?.WORK_GROUP_CD == 5) {
            await fn_selectPlantList().then(response => {
                plantList = response?.data.ds_Plant;
                plantList = plantList.filter((v: any) => v.LV == '4');
            }).catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
        }
        
        await fn_selectPsisVendorList(plantList);
    }

    async function fn_selectPsisVendorList(plantList:any[]){
        sendAxios('/common/nexacro/selectPsisVendorList.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Search: [{
                DIVISION_CD: '',
                HQ_CD: (sessionValues?.WORK_GROUP_CD || 0) === 1 ? sessionValues?.HQ_CD : vendor?.HQ_CD,
                USE_YN: ''
            }]
        }).then(response => {
            let sData = response?.data.ds_vendorList || [];
            let nData= [];

            if(sessionValues?.WORK_GROUP_CD == 2 || sessionValues?.WORK_GROUP_CD == 3 || sessionValues?.WORK_GROUP_CD == 4 || sessionValues?.WORK_GROUP_CD == 5) {
                for (let a = 0; a < plantList.length; a++) {
                    let detail = (sData.filter((v: any) => v.PLANT_CD == plantList[a].CODE));
                    for (let b = 0; b < detail.length; b++) {
                        nData.push(detail[b]);
                    }
                }
            }else{
                nData = sData;
            }

            setPlantList(nData.filter((v: any, i: number, callback: any) => i === callback.findIndex((loc: any) => loc.PLANT_CD === v.PLANT_CD)));
            setDataList(nData);
            // setPlant({PLANT_CD: 'all'});
            const getPlant = getSearchCondition?.plants;
            if(gfn.gfn_isNull(getPlant)){
                setPlant({PLANT_CD: 'all'});
            } else {
                setPlant(getPlant);
            }
        })
        .catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    async function fn_selectPlantList() {
        let requestJson: NexacroJsonType = {};

        if(sessionValues?.WORK_GROUP_CD == 2 || sessionValues?.WORK_GROUP_CD == 3){
            requestJson = {
                ds_Search: [{
                    DIVISION_CD : sessionValues?.DIVISION_CD,
                    LANG: sLocale,
                    SHOW_HQ: 'true'
                }]
            }
        }else if(sessionValues?.WORK_GROUP_CD == 4 || sessionValues?.WORK_GROUP_CD == 5){
            requestJson = {
                ds_Search: [{
                    PLANT_CD : sessionValues?.PLANT_CD,
                    AUTH_MANAGER : sessionValues?.WORK_GROUP_CD,
                    AUTH : 'PLACE',
                    LANG: sLocale,
                    SHOW_HQ: 'true'
                }]
            }
        }

        return sendAxios('/common/nexacro/selectPlantList.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson);
    }

    useEffect(() => {
        if (plant?.PLANT_CD && plant?.PLANT_CD !== 'all') {
            setVendorList(dataList?.filter((v: any) => v.PLANT_CD == plant?.PLANT_CD) || []);
        } else {
            setVendorList(dataList?.filter((v: any, i: number, callback: any) => i === callback.findIndex((loc: any) => loc.HQ_CD === v.HQ_CD)) || []);
        }
    }, [plant]);


    // useEffect(() => {
    //     if (plantList && plantList.length > 0) {
    //         setPlant(plantList.find(item => item.PLANT_CD === (getDocumentHistory('plant') || sessionValues?.PLANT_CD || plantList[0])));
    //     }
    // }, [plantList]);

    useEffect(() => {
        if (vendorList) {
            if (vendorList.length > 0) {
                const vendor = gfn.gfn_isNull(getSearchCondition?.hqCd) ? vendorList[0] : getSearchCondition.hqCd;
                setVendor(vendor);
                // setVendor(vendorList.find(item =>
                //     item.PLANT_CD === (plant?.PLANT_CD)
                //     && item.HQ_CD === (getDocumentHistory('hqCd') || sessionValues?.HQ_CD) || vendorList[0]));
            } else {
                setVendor(1 === (sessionValues?.WORK_GROUP_CD || 0) ? {
                    HQ_CD: sessionValues?.HQ_CD,
                    HQ_NM: sessionValues?.HQ_NM
                } : {});
            }
        } else {
            setVendor(1 === (sessionValues?.WORK_GROUP_CD || 0) ? {
                HQ_CD: sessionValues?.HQ_CD,
                HQ_NM: sessionValues?.HQ_NM
            } : {});
        }
    }, [vendorList]);

    useEffect(() => {
        if (plant?.PLANT_CD !== undefined && vendor?.HQ_CD !== undefined) {
            sendAxios('/common/nexacro/selectTargetPartGroupInspCount.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_search: [{
                    LANG: gv_locale,
                    PLANT_CD: plant?.PLANT_CD == 'all' ? '' : plant?.PLANT_CD,
                    HQ_CD: vendor?.HQ_CD
                }]
            })
                .then(response => {
                    setInspectCountList(response?.data.ds_inspectCounttList || []);
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }

    }, [plant, vendor]);

    const callbackInspYn = (e: React.MouseEvent<HTMLAnchorElement>, inspYn: string) => {
        e.preventDefault();
        setHqInspYn(inspYn);
    };

    const callbackSearchFn = () => {
        if (hqInspYn === '') {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage('MSG01506'),
                confirm: () => {
                }
            });
        } else {
            const newInspectCountList = inspectCountList.filter((inspectCount) => inspectCount.HQ_INSP_YN === hqInspYn);
            if (newInspectCountList.length === 0) {
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage('MSG01507'),
                    confirm: () => {
                    }
                });
                // setStorage('SESSION', STORAGE_NAME.HISTORY, {
                //     ...getStorage<HistoryType>('SESSION', STORAGE_NAME.HISTORY),
                //     [sqciRouter.state.location.pathname]: {
                //         plants: plant?.PLANT_CD === 'all'? '' : plant,
                //         hqCd: vendor,
                //         hqInspYn: hqInspYn
                //     }
                // });
                setStorage('LOCAL', location.pathname, {
                    plants: plant,
                    hqCd: vendor
                }, 7);
            } else {
                pageMove(process.env.REACT_APP_URL_PSIS_INPUT + '/PsisInspResultRegList' || '', {
                    plants: plant?.PLANT_CD === 'all'? '' : plant,
                    hqCd: vendor,
                    hqInspYn: hqInspYn
                });
                setStorage('LOCAL', location.pathname, {
                    plants: plant,
                    hqCd: vendor
                }, 7);
            }
        }
    };

    const selectedPlant = (value: any) => {
        // console.log('selected plant: ', value);
        setPlant(value);
    };

    const selectedVendor = (value: any) => {
        setVendor(value);
    };

    return (
        <>
            {searchComponent}
            <div className={'container'}>
                <div className={'contents-wrap reports-type'}>
                    <ul className={'reports-list'}>
                        {
                            <li className={'text-item'}>
                            <span className={'title'}>
                                 Plant
                            </span>
                                <div onClick={() => doSearchPlant('RADIO', selectedPlant, plantList, [], true)}>
                                    <p>{plant?.PLANT_CD === 'all' ? 'ALL' : plant?.PLANT_CD ? '['.concat(String(plant?.PLANT_CD), ']') : ''}<br/>{String(plant?.PLANT_PROD_NM || '').replace(regexPlant, '$2')}
                                    </p>
                                    <button type={'button'} className={'btn-link'}
                                            onClick={() => doSearchPlant('RADIO', selectedPlant, plantList, [], true)}>
                                        <i className={'hidden'}>{getDomain('DOMAIN4561')}</i>
                                    </button>
                                </div>
                            </li>
                        }
                        {
                                <li className={'text-item'}>
                                <span className={'title'}>
                                    {getDomain('DOMAIN0372')}
                                </span>
                                    <div className={'text-item'}
                                         onClick={() => 1 !== (sessionValues?.WORK_GROUP_CD || 0) ? doSearchVendor('RADIO', vendorList || [], selectedVendor) : null}>
                                        <p>{vendor?.HQ_CD ? '['.concat(String(vendor?.HQ_CD), ']') : ''}<br/>{vendor?.HQ_NM}
                                        </p>
                                        {
                                            1 !== (sessionValues?.WORK_GROUP_CD || 0) ?
                                                <button type={'button'} className={'btn-link'}
                                                        onClick={() => doSearchVendor('RADIO', vendorList || [], selectedVendor)}>
                                                    <i className={'hidden'}>{getDomain('DOMAIN4561')}</i>
                                                </button> : null
                                        }
                                    </div>
                                </li>
                        }

                    </ul>
                    <div className={'inspection-btn-wrap'}>
                        {btnData.map((item, idx) => {
                            if (item === 'Y') {
                                return <a className={'btn-ins'} style={{
                                    border: hqInspYn === item ? '5px solid #00215F' : '5px solid #FFFFFFFF',
                                    borderRadius: hqInspYn === item ? '1.5rem 1.5rem 1.5rem 1.5rem' : ''
                                }} key={'inspection_'.concat(String(idx))} onClick={(e) => callbackInspYn(e, item)}>
                                    <em className={'title'}>{getDomain('DOMAIN4594')}</em>
                                    <strong className={"text"}>
                                        {
                                            inspectCountList.filter(count => count.HQ_INSP_YN === 'Y').length > 0 && inspectCountList.filter(count => count.HQ_INSP_YN === 'Y').map((inspItem, inspIdx) => {
                                                return inspItem.HQ_INSP_COUNT.concat(' ', getDomain('DOMAIN4595'));

                                            })
                                        }
                                        {inspectCountList.filter(count => count.HQ_INSP_YN === 'Y').length == 0 && (
                                            '0'.concat(' ', getDomain('DOMAIN4595'))
                                        )
                                        }
                                    </strong>
                                </a>
                            } else {
                                return <a className={'btn-pro'} style={{
                                    border: hqInspYn === item ? '5px solid #5200FF' : '5px solid #FFFFFFFF',
                                    borderRadius: hqInspYn === item ? '1.5rem 1.5rem 1.5rem 1.5rem' : ''
                                }} key={'inspection_'.concat(String(idx))} onClick={(e) => callbackInspYn(e, item)}>
                                    <em className={'title'}>{getDomain('DOMAIN4472')}</em>
                                    <strong className={'text'}>
                                        {
                                            inspectCountList.filter(count => count.HQ_INSP_YN === 'N').length > 0 && inspectCountList.filter(count => count.HQ_INSP_YN === 'N').map((inspItem, inspIdx) => {
                                                return inspItem.HQ_INSP_COUNT.concat(' ', getDomain('DOMAIN4595'));

                                            })
                                        }
                                        {inspectCountList.filter(count => count.HQ_INSP_YN === 'N').length == 0 && (
                                            '0'.concat(' ', getDomain('DOMAIN4595'))
                                        )
                                        }
                                    </strong>
                                </a>
                            }
                        })}
                    </div>
                </div>
                <div className={'btn-area bottom'}>
                    <button type={'button'} className={'button-primary'}
                            disabled={hqInspYn !== '' ? false : true}
                            onClick={() => callbackSearchFn()}>{getDomain('DOMAIN0312')}
                    </button>
                </div>
            </div>
        </>
    );
};