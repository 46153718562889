/**************************************************************************************************
 * 01. 업무구분	    : P-SIS > 검사결과 입력 > 검사결과 뷰어
 * 02. 프로그램ID	: InspResultViewer.tsx
 * 03. 프로그램명	    : P-SIS > 검사결과 입력 > 검사결과 뷰어
 * 04. 화면설명	    : P-SIS > 검사결과 입력 > 검사결과 뷰어
 * 05. 작성일		: 2023.10.04
 * 06. 작성자		: YGY
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-10-04     YGY    최초 작성
 **************************************************************************************************
 */
/******************************************************************************************
 *                                        변경이력                                         *
 *----------   ----------   ------------   ------------------------------------------------*
 * 변경번호     변경일자       변경자                        변경내용                      *
 *----------   ----------   ------------   ------------------------------------------------*

 ******************************************************************************************/

import React, {useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectFade, Navigation, Pagination, Zoom} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import {v4 as uuidv4} from 'uuid';
import useAxios, {AXIOS_HEADER} from '../../../hooks/useAxios';
import {useError} from '../../../hooks/useException';
import useGlobalData from "../../../hooks/useGlobalData";
import {useLocation} from "react-router-dom";
import useStorage from "../../../hooks/useStorage";
import {STORAGE_NAME} from "../../../components/CommonConstants";
//image crop
import ReactCrop, { Crop } from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import { blob } from 'stream/consumers';
import * as gfn from "../../../components/utils/CommonUtils";

export default function InspResultViewer({inspMap, fileId, onConfirm, shootingFile}: {
    inspMap: any,
    fileId: string,
    onConfirm: (inspMap: any, resultFile: File) => void,
    shootingFile: any
}) {
    const {sendAxios} = useAxios();
    const {throwException} = useError();
    const location = useLocation();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const [fileList, setFileList] = useState<{ [K: string]: string }[]>();
    const [inspFilePath, setInspFilePath] = useState<string | undefined>();
    const [inspResultFile, setInspResultFile] = useState<File>(shootingFile);
    const {setStorage, getStorage, clearStorage} = useStorage();
    const [useOCR, setUseOCR] = useState<boolean>(Boolean(getStorage('LOCAL', STORAGE_NAME.OCR)) || false);
    const imageRef = useRef<any>([]);
    const insptPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setInspFilePath(URL.createObjectURL(event.target.files[0]));
            setInspResultFile(event.target.files[0]);
        }
    };
    const curImageRef = useRef<any>([]); //현재 이미지
    // const [scale, setScale] = useState<number>(Number(curImageRef.current.width)*10 || 2000); // zoom-in
    // const [rangeMax, setRangeMax] = useState<number>(Number(curImageRef.current.width)*10 || 2000); // max-reange
    const [scale, setScale] = useState<number>(100); // zoom-in
    // const [rangeMax, setRangeMax] = useState<number>(Number(curImageRef.current.width)*10 || 2000); // max-reange

    useEffect(() => {
        sendAxios('/common/nexacro/commonSelectFileList.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Search: [{
                FILE_ID: fileId
            }]
        })
            .then(response => {
                let file: { [K: string]: string }[] = [];
                for (const path of response?.data.ds_List) {
                    file.push({
                        DB_SRCH: path.DB_SRCH,
                        DESCRIPTION: path.DESCRIPTION,
                        DOWNLOAD_TYPE: path.DOWNLOAD_TYPE,
                        FILE_EXTENSION_NAME: path.FILE_EXTENSION_NAME,
                        FILE_ID: path.FILE_ID,
                        FILE_MIME_TYPE_NAME: path.FILE_MIME_TYPE_NAME,
                        FILE_NAME: path.FILE_NAME,
                        FILE_PATH_NAME: '/images/@temp1.png',
                        FILE_SEQ: path.FILE_SEQ,
                        FILE_SIZE: path.FILE_SIZE,
                        FIRST_REGR_ID: path.FIRST_REGR_ID,
                        FIRST_REG_DATETIME: path.FIRST_REG_DATETIME,
                        FROM_DATE: path.FROM_DATE,
                        MODULE_ID: path.MODULE_ID,
                        ORDER_IDX: path.ORDER_IDX,
                        THRU_DATE: path.THRU_DATE
                    });
                }

                if (file.length > 0) {
                    setFileList(file);
                }
                setInspFilePath(URL.createObjectURL(shootingFile));
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
    }, [fileId]);

    /**
     * 사진촬영  callback
     * @param seq
     */
    const photoCallback = () => {
        imageRef.current?.click();
    };

    const confirmCallBack = () => {
        onConfirm(inspMap, inspResultFile);
    };
    /**
     * imagecrop
     */
    
    const [crop, setCrop] = useState<Crop>({
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        unit: '%'
    });
    const onCropChange = (newCrop: Crop) => {
        const nCrop:Crop = {
            unit:'%',
            x:(newCrop.x / curImageRef.current.width) * 100,
            y:(newCrop.y / curImageRef.current.height) * 100,
            width:(newCrop.width / curImageRef.current.width) * 100,
            height:(newCrop.height / curImageRef.current.height) * 100,
        }
        setCrop(nCrop);
    };
    // 클릭시 이동
    const handleImageClick = (e: React.MouseEvent<HTMLDivElement>) => {
        // const rect = curImageRef.current.getBoundingClientRect();
        // const offsetX = e.clientX - rect.left;
        // const offsetY = e.clientY - rect.top;
    
        // const newCropX = Math.max(
        //   0,
        //   Math.min(offsetX - crop.width! / 2, curImageRef.current.width - crop.width!)
        // );
        // const newCropY = Math.max(
        //   0,
        //   Math.min(offsetY - crop.height! / 2, curImageRef.current.height - crop.height!)
        // );
    
        // setCrop((prevCrop) => ({
        //   ...prevCrop,
        //   x: newCropX,
        //   y: newCropY,
        // }));
        // //클릭시에도 cropped image setting
        // makeClientCrop(crop);
    };
    const makeClientCrop = async (crop:any) => {
        if (curImageRef.current && crop.width && crop.height) {
            let sCrop = crop;
            
            //'%'로 가져오는경우 'px'로 변환
            if(crop.unit === '%') {
                sCrop = {
                    unit:'px',
                    x:(crop.x * curImageRef.current.width) / 100,
                    y:(crop.y * curImageRef.current.height) / 100,
                    width:(crop.width * curImageRef.current.width) / 100,
                    height:(crop.height * curImageRef.current.height) / 100,
                }
            }
            const croppedBlob = await createCropImage(curImageRef.current, sCrop);
            if(croppedBlob){
                const croppedFile = blobToFile(croppedBlob, inspResultFile.name);
                setInspResultFile(croppedFile);
            }
        }
    };
    const blobToFile = (blob:Blob, fileNeme: string): File => {
        return new File([blob], fileNeme, {type:blob.type});
    }
    const createCropImage = async (image: HTMLImageElement, crop: Crop):Promise<Blob|null> => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
    
        ctx?.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        return new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (!blob) {
              reject(new Error('Canvas is empty'));
              return;
            }
            resolve(blob);
          }, 'image/jpeg');
        });
      };
    /**
     * imagecrop_end
     * slid zoom-in start
     */
    const handleScaleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{


        //경계선 확인
        if(!checkboundary()){
            let sValue = parseFloat(e.target.value);
            setScale(sValue);
    
            setCrop((prevCrop) => ({
                ...prevCrop,
                width: sValue,
                height: sValue
            }))
            
            //make croup image
            makeClientCrop(crop);
        }
    }
    const checkboundary = () => {
        const rWidth = crop.width * curImageRef.current.width / 100;
        const rCropX = crop.x * curImageRef.current.width / 100;
        const rHeight = crop.height * curImageRef.current.height / 100;
        const rCropY = crop.y * curImageRef.current.height / 100;
        const imageSize = {width:curImageRef.current.width, height:curImageRef.current.height};

        return Math.floor(rWidth + rCropX) > imageSize.width || Math.floor(rHeight + rCropY) > imageSize.height
    }
    const onImageChange = () =>{
        setCrop((prevCrop) => ({
            ...prevCrop,
            width: 100,
            height: 100,
        }))
    }

    return (
        <>
            <div className={'pop-layer'} style={{display: 'block', zIndex: 300}}>
                <div className={'wrap'}>
                    <div className={'container-noheader'} style={{overflowY: 'auto', paddingTop:'0rem'}}>
                        {/*<div className={'comm-slide-wrap'}>*/}
                        <div>
                            <div className={'swiper-pagination swiper-pagination-img'} style={{
                                width: '100% !important',
                                paddingBottom: '2rem'
                            }}></div>
                            <div className={'result-img-box'}>
                                <h2 className={'h2-title'}>{getDomain('DOMAIN4585')}</h2>
                            </div>
                            <Swiper
                                modules={[Zoom, Pagination, Navigation, EffectFade]}
                                pagination={{
                                    // dynamicBullets: true,
                                    //dynamicBullets: true,
                                    //el: '.swiper-pagination-img',
                                    clickable: true
                                }}
                                spaceBetween={30}
                                zoom={true}
                                navigation={true}
                                // initialSlide={0}
                                className={'mySwiper'}>
                                {
                                    fileList && fileList.length > 0 ? fileList.map((file, index) => {
                                        return <SwiperSlide key={uuidv4()} virtualIndex={index}>
                                            <div className={'image-wrap swiper-zoom-container'} style={{
                                                marginTop: 'unset',
                                                width: '100%',
                                                overflowY: 'unset',
                                                padding: 'unset'
                                            }}>
                                                <img
                                                    src={process.env.REACT_APP_HTTP_BASE_URL + '/common/nexacro/commonGetImageFileAndFileSeq.do?fileId=' + file.FILE_ID + '&fileSeq=' + file.FILE_SEQ}
                                                    alt={file.FILE_NAME}
                                                    style={{width: '100%'}}/>
                                            </div>
                                        </SwiperSlide>;
                                    }) : null
                                }
                            </Swiper>
                            <div className={'result-img-box'}>
                                <h2 className={'h2-title'}>{getDomain('DOMAIN2612')}</h2>
                                <div onClick={handleImageClick} className={'img-wrap'} style={{padding: 'unset', backgroundColor: 'unset'}}>
                                    {useOCR && inspMap[0]['NUM_YN'] === 'Y' ?
                                        ( 
                                        <div>
                                            <div>
                                                <p style={{marginBottom: '-19px', marginLeft:'15px'}}>이미지에서 문자인식 영역을 설정해주세요.</p>
                                                <input type="range" value={scale} min="0" max={100} step="0.1" onChange={handleScaleChange} style={{width: '99%', height: '50px'}}/>
                                            </div>
                                            <ReactCrop
                                                crop={crop}
                                                onChange={onCropChange}
                                                onComplete={makeClientCrop}
                                                locked
                                            >
                                                <img ref={curImageRef} src={inspFilePath} alt={''} onLoad={onImageChange}/>
                                            </ReactCrop>
                                        </div>
                                        )
                                         : (<img ref={curImageRef} src={inspFilePath} alt={''} onLoad={onImageChange}/>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'btn-area bottom'}>
                            <input type={'file'} ref={imageRef} accept={'image/*'} capture={'environment'}
                                   onChange={insptPicture} style={{display: "none"}}/>
                            <button type={'button'} className={'button-line'}
                                    onClick={photoCallback}>{getDomain('DOMAIN4580')}</button>
                            <button type={'button'} className={'button-primary'}
                                    onClick={confirmCallBack}>{getDomain('DOMAIN0385')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};