import React from 'react';
import ComponentProvider from './contexts/ComponentContext';
import Main from '../pages/Main';
import ErrorPage from '../pages/error/ErrorPage';
import ResetPassword from '../pages/user/ResetPassword';
import Terms from '../pages/user/Terms';
import QmsMIncmInspList from '../pages/qms/QmsMIncmInspList';
import {createBrowserRouter, RouteObject} from 'react-router-dom';
import QmvAdd from '../pages/qmv/QmvAdd';
import QmvList from '../pages/qmv/QmvList';
import {RouterType} from './model/SqciTypes';
import QmvInfo from '../pages/qmv/QmvInfo';
import MfaJwtTest from '../pages/sample/MfaJwtTest';
import PsisInspResultList from '../pages/psis/info/PsisInspResultList';
import PsisInspResultInfoList from '../pages/psis/info/PsisInspResultInfoList';
import PsisInspResultReg from '../pages/psis/input/PsisInspResultReg';
import PsisInspTargetReg from '../pages/psis/add/PsisInspTargetReg';
import QmvSearch from '../pages/qmv/QmvSearch';
import QmsMIncmInspListResult from '../pages/qms/QmsMIncmInspListResult';
import QmsPIncmInspReg from '../pages/qms/QmsPIncmInspReg';
import QmsMTestList from '../pages/qms/QmsMTestList';
import QmsMTestListResult from '../pages/qms/QmsMTestListResult';
import SampleQmvSearch from '../pages/sample/SampleQmvSearch';
import SampleQmvList from '../pages/sample/SampleQmvList';
import SampleQmvInfo from '../pages/sample/SampleQmvInfo';
import PsisInspResultRegIncoming from '../pages/psis/input/PsisInspResultRegIncoming';
import PsisInspResultRegList from '../pages/psis/input/PsisInspResultRegList';
import PsisInspResultRegProcess from '../pages/psis/input/PsisInspResultRegProcess';
import Test from '../pages/sample/Test';
import PsisInspResultInfoIncoming from '../pages/psis/info/PsisInspResultInfoIncoming';
import PsisInspResultInfoProcess from '../pages/psis/info/PsisInspResultInfoProcess';
import QmsSearch from '../pages/qms/QmsSearch';
import QmsList from '../pages/qms/QmsList';
import Login from '../pages/user/Login';


export const ROUTER_MENU_CODE = {
    MAIN: undefined,
    LOGIN: undefined,
    RESET_PASSWORD: '',
    TERMS: undefined,

    QMV: 'MOB_SQCI_QMV_',
    QMV_ADD: 'MOB_SQCI_QMV_QmvAdd',
    QMV_SEARCH: 'MOB_SQCI_QMV_QmvSearch',
    QMV_DETAIL: 'MOB_SQCI_QMV_QmvDetail',

    QMS: 'MOB_SQCI_QMS_',
    QMS_M_INCM_INSP_LIST: 'MOB_SQCI_QMS_QmsMIncmInspList',
    QMS_M_INCM_INSP_DETAIL: 'MOB_SQCI_QMS_QmsMIncmInspDetail',
    QMS_M_INSP_RCRD_LIST: 'MOB_SQCI_QMS_QMSMInspRcrdList',
    QMS_SEARCH: 'MOB_SQCI_QMS_QMSMInspRcrdList',
    QMS_LIST: 'MOB_SQCI_QMS_QMSMInspRcrdList',
    QMS_P_INCM_INSP_REG: 'MOB_SQCI_QMS_QmsPIncmInspReg',
    QMS_TEST_SEARCH: 'MOB_SQCI_QMS_QmsMTestList',

    PSIS: 'MOB_SQCI_PSIS_',
    PSIS_INSP_TARGET_REG: 'MOB_SQCI_PSIS_PsisInspTargetReg',                    
    PSIS_INSP_RESULT_REG: 'MOB_SQCI_PSIS_PsisInspResultReg',
    PSIS_INSP_RESULT_REG_DETAIL: 'MOB_SQCI_PSIS_PsisInspResultRegDetail',
    PSIS_INSP_RESULT_LIST: 'MOB_SQCI_PSIS_PsisInspResultList',

    ERROR: undefined
};

export const ROUTERS: RouterType[] = [
    {title: ROUTER_MENU_CODE.MAIN, path: process.env.REACT_APP_URL_MAIN || '', element: <Main/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: true},   // main
    {title: ROUTER_MENU_CODE.LOGIN, path: process.env.REACT_APP_URL_LOGIN || '', element: <Login/>, showHeader: false, showNavigate: false, showHistoryBack: false, appendHistory: false},  // login
    // {title: ROUTER_MENU_CODE.LOGIN, path: (process.env.REACT_APP_URL_LOGIN || '').concat('/:token'), element: <Login/>, showHeader: false, showNavigate: false, showHistoryBack: false, appendHistory: false},  // MFA 인증 결과 수신
    {title: ROUTER_MENU_CODE.RESET_PASSWORD, path: process.env.REACT_APP_URL_RESET_PASSWORD || '', element: <ResetPassword/>, showHeader: true, showHistoryBack: true, showNavigate: false, appendHistory: false},  // 비밀번호 재설정
    {title: ROUTER_MENU_CODE.TERMS, path: process.env.REACT_APP_URL_TERMS || '', element: <Terms/>, showHeader: true, showNavigate: false, showHistoryBack: true, appendHistory: false},   // 개인정보 처리방침

    {title: ROUTER_MENU_CODE.QMV_ADD, path: process.env.REACT_APP_URL_QMV_ADD || '', element: <QmvAdd/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: true},  // 출하검사 성적서 작성
    {title: ROUTER_MENU_CODE.QMV_SEARCH, path: process.env.REACT_APP_URL_QMV_SEARCH || '', element: <QmvSearch/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: true},  // 출하검사 성적서 조회
    {title: ROUTER_MENU_CODE.QMV_SEARCH, path: process.env.REACT_APP_URL_QMV_LIST || '', element: <QmvList/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: true},  // 출하검사 성적서 목록
    {title: ROUTER_MENU_CODE.QMV_ADD, path: process.env.REACT_APP_URL_QMV_INFO || '', element: <QmvInfo/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: true},  // 출하검사 성적서 작성
    {title: ROUTER_MENU_CODE.QMV_DETAIL, path: process.env.REACT_APP_URL_QMV_DETAIL || '', element: <QmvInfo/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: true},  // 출하검사 성적서 상세
    {title: ROUTER_MENU_CODE.QMS_M_INCM_INSP_LIST, path: process.env.REACT_APP_URL_QMS || '', element: <QmsMIncmInspList/>, showHeader: true, showHistoryBack: true, showNavigate: true, appendHistory: true},  // 입고검사 대상조회
    {title: ROUTER_MENU_CODE.QMS_M_INCM_INSP_LIST, path: process.env.REACT_APP_URL_QMS_RST || '', element: <QmsMIncmInspListResult/>, showHeader: true, showHistoryBack: true, showNavigate: true, appendHistory: true},  // 입고검사 대상조회 결과
    {title: ROUTER_MENU_CODE.QMS_P_INCM_INSP_REG, path: process.env.REACT_APP_URL_QMS_REG || '', element: <QmsPIncmInspReg/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: true},  // 입고검사 성적서 작성
    {title: ROUTER_MENU_CODE.QMS_LIST, path: process.env.REACT_APP_URL_QMS_DETAIL || '', element: <QmsPIncmInspReg/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: true},  // 입고검사 성적서 상세

    {title: ROUTER_MENU_CODE.QMS_SEARCH, path: process.env.REACT_APP_URL_QMS_SEARCH || '', element: <QmsSearch/>, showHeader: true, showHistoryBack: true, showNavigate: true, appendHistory: true},  // 입고검사 조회
    {title: ROUTER_MENU_CODE.QMS_LIST, path: process.env.REACT_APP_URL_QMS_LIST || '', element: <QmsList/>, showHeader: true, showHistoryBack: true, showNavigate: true, appendHistory: true},  // 입고검사 조회 결과 목록
    {title: ROUTER_MENU_CODE.QMS_TEST_SEARCH, path: process.env.REACT_APP_URL_QMS_TEST || '', element: <QmsMTestList/>, showHeader: true, showHistoryBack: true, showNavigate: true, appendHistory: true},  // 입고검사 TEST조회
    {title: ROUTER_MENU_CODE.QMS_TEST_SEARCH, path: process.env.REACT_APP_URL_QMS_TEST_SEARCH || '', element: <QmsMTestListResult/>, showHeader: true, showHistoryBack: true, showNavigate: true, appendHistory: true},  // 입고검사 대상조회
    
    {title: ROUTER_MENU_CODE.PSIS_INSP_TARGET_REG, path: process.env.REACT_APP_URL_PSIS_ADD || '', element: <PsisInspTargetReg/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: true},  // P-SIS 검사대상 등록
    {title: ROUTER_MENU_CODE.PSIS_INSP_RESULT_REG, path: process.env.REACT_APP_URL_PSIS_INPUT || '', element: <PsisInspResultReg/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: true},  // P-SIS 검사결과 입력
    {title: ROUTER_MENU_CODE.PSIS_INSP_RESULT_REG, path: process.env.REACT_APP_URL_PSIS_INPUT + '/PsisInspResultRegList' || '', element: <PsisInspResultRegList/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: true},  // P-SIS 검사결과 입력
    {title: ROUTER_MENU_CODE.PSIS_INSP_RESULT_REG_DETAIL, path: process.env.REACT_APP_URL_PSIS_INPUT + '/PsisInspResultRegIncoming' || '', element: <PsisInspResultRegIncoming/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: false},  // P-SIS 검사결과 입고 검사 입력
    {title: ROUTER_MENU_CODE.PSIS_INSP_RESULT_REG_DETAIL, path: process.env.REACT_APP_URL_PSIS_INPUT + '/PsisInspResultRegProcess' || '', element: <PsisInspResultRegProcess/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: false},  // P-SIS 검사결과 공정 검사 입력
    {title: ROUTER_MENU_CODE.PSIS_INSP_RESULT_LIST, path: process.env.REACT_APP_URL_PSIS_INFO || '', element: <PsisInspResultList/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: true},  // P-SIS 검사결과 조회
    {title: ROUTER_MENU_CODE.PSIS_INSP_RESULT_LIST, path: process.env.REACT_APP_URL_PSIS_INFO + '/PsisInspResultInfoList'|| '', element: <PsisInspResultInfoList/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: true},  // P-SIS 검사결과 조회
    {title: ROUTER_MENU_CODE.PSIS_INSP_RESULT_LIST, path: process.env.REACT_APP_URL_PSIS_INFO + '/PsisInspResultInfoIncoming'|| '', element: <PsisInspResultInfoIncoming/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: false},  // P-SIS 검사결과 입고 검사 조회
    {title: ROUTER_MENU_CODE.PSIS_INSP_RESULT_LIST, path: process.env.REACT_APP_URL_PSIS_INFO + '/PsisInspResultInfoProcess'|| '', element: <PsisInspResultInfoProcess/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: false},  // P-SIS 검사결과 공정 검사 조회
    {title: ROUTER_MENU_CODE.ERROR, path: process.env.REACT_APP_URL_EXCEPTION || '', element: <ErrorPage/>, showHeader: false, showNavigate: false, showHistoryBack: true, appendHistory: false},  // 에러 페이지

    // TODO 샘플 페이지 운영배포 전 삭제
    {title: 'Sample JWT', path: '/sample/jwt', element: <MfaJwtTest/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: false},
    {title: 'Sample 출하검사 성적서 조회', path: '/sample/qmv/search', element: <SampleQmvSearch/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: true},
    {title: 'Sample 출하검사 성적서 조회', path: '/sample/qmv/list', element: <SampleQmvList/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: false},
    {title: 'Sample 출하검사 성적서 작성', path: '/sample/qmv/info', element: <SampleQmvInfo/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: false},
    {title: 'Test', path: '/sample/test', element: <Test/>, showHeader: true, showNavigate: true, showHistoryBack: true, appendHistory: false},
];

export const createRouter = () => {
    let router: RouteObject[] = [{
        element: <ComponentProvider/>,
        children: []
    }];

    ROUTERS.forEach(item => {
        router[0].children?.push({
            path: item.path,
            element: item.element,
            errorElement: item.errorElement || <ErrorPage/>,
            loader: item.loader
        });
    });

    return createBrowserRouter(router);
};