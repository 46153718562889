/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > 입고검사 성적서
 * 02. 프로그램ID : QmsPIncmInspReg.tsx
 * 03. 프로그램명 : 입고검사 성적서 작성 (메인)
 * 04. 화면설명 : 입고검사 성적서 작성
 * 05. 작성일 : 2023.08.24
 * 06. 작성자 : 채보람
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-08-24     채보람    최초 작성
 **************************************************************************************************
 */

import React, {useEffect, useRef, useState} from 'react';
import QmsBasicInformation from './info/basic/QmsBasicInformation';
import QmsPartInspectionInformation from './info/part/QmsPartInspectionInformation';
import QmsDetailInspectList from './info/detailed/QmsDetailInspectList';
import QmsDefectiveContent from './info/defect/QmsDefectiveContent';
import QmsJudgmentResult from './info/judgment/QmsJudgmentResult';
import QmsQpoint from './info/qpoint/QmsQpoint';
import {CSSTransition} from 'react-transition-group';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
import {NexacroJsonType, NexacroJsonUnitType, SelectBoxType, TabNavigationType} from '../../components/model/SqciTypes';
import {useComponent} from '../../components/contexts/ComponentContext';
import {useLocation} from "react-router-dom";
import * as gfn from "../../components/utils/CommonUtils";
import {gfn_isNull} from "../../components/utils/CommonUtils";
import {useDataset} from "../../components/contexts/CommonContext";
import useGlobalData from '../../hooks/useGlobalData';
import {STORAGE_NAME} from "../../components/CommonConstants";
import useHtmlElement from "../../hooks/useHtmlElement";
import {useError} from "../../hooks/useException";
import {requestParser, responseParser} from "../../components/utils/NexacroParseUtil";
import usePageMove from "../../hooks/usePageMove";
import imageCompression from "browser-image-compression";

export default function QmsInfo() {
    const {sendAxios} = useAxios();
    const {setDialog} = useComponent();
    const {state} = useLocation();
    const {dataset, sessionValues} = useDataset();
    const {getSdpSysDate} = useGlobalData();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const sLocale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const {useSelectBox, useInput} = useHtmlElement();
    const {throwException} = useError();
    const {pageMove} = usePageMove();

    const [ds_Part, setDs_Part] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Ecn, setDs_Ecn] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Spec, setDs_Spec] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Aql, setDs_Aql] = useState(new Array<NexacroJsonUnitType>());
    const [ds_AllSample, setDs_AllSample] = useState(new Array<NexacroJsonUnitType>());
    const [ds_popChngPoingHList, setDs_popChngPoingHList] = useState(state.params.get('pv_ecn'));
    const [ds_GroupList, setDs_GroupList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_grdColList, setDs_grdColList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_DefectDelete, setDs_DefectDelete] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Defect, setDs_Defect] = useState(state.params.get('pv_defect'));
    const [ds_DefectGroup, setDs_DefectGroup] = useState(new Array<NexacroJsonUnitType>());
    const [ds_DefectSymptom2, setDs_DefectSymptom2] = useState(new Array<NexacroJsonUnitType>());
    const [ds_EcnReqInfo, setDs_EcnReqInfo] = useState(new Array<NexacroJsonUnitType>());
    const [ds_SpecFile, setDs_SpecFile] = useState(new Array<NexacroJsonUnitType>());
    const [ds_upload, setDs_upload] = useState(new Array<NexacroJsonUnitType>());
    const [ds_remove, setDs_remove] = useState(new Array<NexacroJsonUnitType>());
    const [ds_qPointList, setDs_qPointList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_IssuePartList, setDs_IssuePartList] = useState(state.params.get('pv_Issue'));
    const [fv_GroupYn, setFv_GroupYn] = useState(String);
    const [ds_PartCurrent, setDs_PartCurrent] = useState(new Array<NexacroJsonUnitType>());
    const [maxSamCnt, setMaxSamCnt] = useState(String);

    const reportRef = useRef<HTMLInputElement>(null);
    const [ds_serialRev, setDs_serialRev] = useState(new Array<NexacroJsonUnitType>());

    let params = new Map(state.params);
    let vds_Part = state.params.get('pv_data');
    let vds_Spec = state.params.get('pv_spec');
    let vds_Defect = state.params.get('pv_defect');
    let [ds_SubmitInspList, setDs_SubmitInspList] = useState(state.params.get('pv_ds_SubmitInspList'));
    let [pv_secAnlResult, setPv_secAnlResult] = useState(state.params.get('pv_secAnlResult'));
    let [ds_chngPoingHList, setDs_chngPoingHList] = useState(state.params.get('pv_ds_chngPoingHList'));
    let [fv_isChanged, setFv_isChanged] = useState(Boolean);
    let vds_DefectGroup: any = [];
    let vds_DefectSymptom2: any = [];
    let vds_EcnReqInfo: any = [];
    let vds_DefectDelete: any = [];
    let vds_grdColList: any = [];
    let vds_mesAlarmSearchLot: any = [];
    let vds_tsiSpecialInspList: any = [];
    let vds_ApproveList: any = [];
    let vds_SpecFile: any = [];
    let vds_PartCurrent: any = [];
    let fv_isDivisionQ = sessionValues?.DIVISION_CD == "Q" || sessionValues?.WORK_GROUP_CD == 7 ? true : false; // 무선사업부 여부 변수 선언
    let fv_doubleFlag = "";	//2배수 예, 아니오 실행 상태
    let fv_maxSamCnt = 0; // 샘플수 최대 값
    let fv_temp = "N";
    let vfv_isChanged: boolean;
    let vds_serialRev: any = [];
    let vds_searchSerial: any = [];

    const [tabList, setTabList] = useState<TabNavigationType[]>([
        {name: 'qmsBasicInformation', selected: true, direction: 'right', element: <QmsBasicInformation state={{params:state.params, setDataset}} nextTab={(name: string) => handleNextTab(name)}/>},
        {name: 'qmsPartInspectionInformation', selected: false, direction: 'right', element: <QmsPartInspectionInformation state={{params:state.params, setDataset, fn_tempSaveFile}} nextTab={(name: string) => handleNextTab(name)}/>},
        {name: 'qmsDetailInspectList', selected: false, direction: 'right', element: <QmsDetailInspectList state={{params:state.params, setDataset, setFv_maxSamCnt, fn_tempSaveFile, ds_DefectDelete}} nextTab={(name: string) => handleNextTab(name)}/>},
        {name: 'qmsDefectiveContent', selected: false, direction: 'right', element: <QmsDefectiveContent state={{params:state.params, setDataset, fn_tempSaveFile}} nextTab={(name: string) => handleNextTab(name)}/>},
        {name: 'qmsJudgmentResult', selected: false, direction: 'right', element: <QmsJudgmentResult state={{params:state.params, setDataset, fn_tempSaveFile, fn_checkMeasCheck, fn_saveFile}} nextTab={(name: string) => handleNextTab(name)} reportRef={reportRef}/>},
        {name: 'qmsQpoint', selected: false, direction: 'right', element: <QmsQpoint state={{params:state.params, setDataset, fn_tempSaveFile, fn_saveFile}} nextTab={(name: string) => handleNextTab(name)}/>}
    ]);
    const [focusTab, setFocusTab] = useState<TabNavigationType>(tabList[0]);
    const [showTab, setShowTab] = useState<boolean>(false);
    const [defaultSecMeasRslt, setDefaultSecMeasRslt] = useState((dataset?.ds_CommonCode || []).filter((i, index) => i.CATEGORY === "SQCI000127" && i.LANG === getGlobalSetting<string>(STORAGE_NAME.LANGUAGE)));
    let [secMeasRslt, setSecMeasRslt] = useState(new Array<NexacroJsonUnitType>());

    useEffect(() => {
        form_onload();

        // console.log("입고검사 성적서 작성 (메인)");
        // console.log("state ↓");
        // console.log(state);
        // console.log("입고검사 성적서 작성 (메인)");
    }, []);

    /* form_onload */
    function form_onload() {
        setDs_Part(state.params.get('pv_data'));
        setDs_Ecn(state.params.get('pv_ecnDetail'));
        setDs_Spec(state.params.get('pv_spec'));
        setDs_Aql(state.params.get('pv_aql'));
        setDs_AllSample(state.params.get('pv_allSample'));
        setDs_GroupList(state.params.get('pv_GroupList'));

        setFv_GroupYn((state.params.get('pv_data')[0].GROUP_YN) ? state.params.get('pv_data')[0].GROUP_YN : '');

        /* ds_part 정보 세팅 */
        initDs_Part();

        /* 세부검사정보 세팅 */
        initDs_Spec();

        /* 협력사 자체변경점 정보 load info */
        initDs_EcnReqInfo();

        /* Spec 첨부 파일 조회 */
        fn_selectSpecFile();

        /* 메모 쪽 첨부파일 조회 */
        fn_attachFile_N("ds_upload", String(vds_Part[0]['ATTACH_FILE_ID']));

        /* Q-Point 목록 조회 */
        fn_qpiontInfo();

        /* 이슈자재 alert 표기 */
        fn_SearchIssuePart();

        /* 데이터셋에 샘플측정 결과 컬럼 생성 */
        fn_creatDataset();

        if (state.params.get('pv_data')[0].GROUP_YN == "Y") {
            if (String(state.params.get('pv_flag')) != "INS") {
                //그룹 리스트 조회
                fn_selectGroupList();
            }
        }

        /* 필수 CTF 적용된 VD사업부의 자재이나 내용에 없을 경우 작성or수정시 알림팝업 */
        if (String(state.params.get('pv_flag')) == "INS") {
            fn_essCTFsearch();
        }
    }

    /* 세부검사정보 세팅 */
    async function initDs_Spec() {
        for (let i = 0; i < vds_Spec.length; i++) {
            vds_Spec[i]['SEC_INSP_MTH_F'] = String(vds_Spec[i]['SEC_INSP_MTH']).substr(0, 1);
        }

        // 새로 작성시
        if (String(state.params.get('pv_flag')) == "INS") {
            // 임시저장 불러올시
            if (String(state.params.get('pv_tmp')) == "Y") {
                if (vds_Defect.length == 0) { // 작성된 defect 이 없을 때  (모바일에서는 defect 없이 불량으로 들어오니까 defect 따로 추가해줘야함
                    for (let i = 0; i < vds_Spec.length; i++) {
                        let defCnt = 0;

                        if (vds_Spec[i]['RESULT_YN'] == "N") { // 검사항목중 NG 가 있는지 체크
                            for (let j = 0; j < Number(vds_Spec[i]['SAM_CNT']); j++) {
                                if (vds_Spec[i]['RESULT_YN_' + (j + 1)] == '0') {
                                    defCnt++;
                                }
                            }

                            vds_Spec[i]['MEAS_DEFECT_CNT'] = defCnt;

                            fn_addDefect(i, null);
                        }
                    }
                }
            }
        }

        await fn_defectGroupSeclect().then((response) => {
            vds_DefectGroup = response?.data.ds_DefectGroup;
            vds_DefectSymptom2 = response?.data.ds_DefectSymptom2;

            setDs_DefectGroup(response?.data.ds_DefectGroup);
            setDs_DefectSymptom2(response?.data.ds_DefectSymptom2);
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });

        fn_CalSummary();

        setDs_Spec(vds_Spec);
        setDs_Defect(vds_Defect);
    }

    /* 협력사 자체변경점 정보 load info */
    function initDs_EcnReqInfo() {
        const requestJson: NexacroJsonType = {
            ds_Part: vds_Part
        };

        sendAxios('/common/nexacro/selectVendorEcnNoInfo.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            setDs_EcnReqInfo(response?.data.ds_EcnReqInfo);
            vds_EcnReqInfo = response?.data.ds_EcnReqInfo;
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* Spec 첨부 파일 조회 */
    function fn_selectSpecFile() {
        vds_Part[0]['VENDOR_CD'] = vds_Part[0]['HQ_CD'];
        setDs_Part(vds_Part);

        const requestJson: NexacroJsonType = {
            ds_Part: vds_Part
        };

        sendAxios('/common/nexacro/qmvInspectVndRcrdSpecFile.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            setDs_SpecFile(response?.data.ds_SpecFile);
            vds_SpecFile = response?.data.ds_SpecFile;
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* ds_part 정보 세팅 */
    function initDs_Part() {
        vds_Part[0]['INSP_STD_STATUS_NM'] = vds_Spec.length > 0 ? vds_Spec[0]['INSP_STD_STATUS_NM'] : '';
        vds_Part[0]['INSP_USER_APPROVE'] = sessionValues?.USER_NAME;
        vds_Part[0]['LANG'] = sLocale;

        fn_checkMeasCheck();

        if (vds_Defect.length > 0) {    // 결함 존재시 콤보에서 합격 지움
            setSecMeasRslt(defaultSecMeasRslt.filter((item) => item.CD != '1')); // 콤보에서 합격 지움
        }

        setDs_Part(vds_Part);
    }

    /* 불량판정내용요약 추가 */
    function fn_addDefect(specRow: number, defectCnt: any) {
        let sSpectId = vds_Spec[specRow]['SPEC_ID'];      //불량 선택된 검사항목의 specId
        let sSpectRev = Number(vds_Spec[specRow]['SPEC_REV']);    //불량 선택된 검사항목의 specRev
        let addRowYn = true;                                            //로우 추가 여부
        let nDefectRow = vds_Defect.length;                       //현재 불량판정내용요약 총 row수
        let sDefectRowType = "I";                                       //불량판정내용요약 rowtype(S,I,U,D)
        let nRow = 0;                                                   //추가해야할 row

        for (let i = 0; i < nDefectRow; i++) {
            let sDSpectId = vds_Defect[i]['SPEC_ID'];
            let sDSpectRev = Number(vds_Defect[i]['SPEC_REV']);

            if (sSpectId == sDSpectId && sSpectRev == sDSpectRev) {
                addRowYn = false;
                nRow = i;
            }
        }

        if (addRowYn) {
            fn_defectGroupSeclect().then((response) => {
                vds_DefectGroup = response?.data.ds_DefectGroup;
                vds_DefectSymptom2 = response?.data.ds_DefectSymptom2;

                setDs_DefectGroup(response?.data.ds_DefectGroup);
                setDs_DefectSymptom2(response?.data.ds_DefectSymptom2);
            }).catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });

            vds_Defect.push({
                ROW_TYPE: sDefectRowType,
                SPEC_ID: sSpectId,
                SPEC_REV: sSpectRev,
                EQUIP_CD: vds_Spec[specRow]['MEQUIP_CD'],
                EQUIP_NM: vds_Spec[specRow]['MEQUIP_NM'],
                DEFECT_CNT: gfn_isNull(defectCnt) ? vds_Spec[specRow]['MEAS_DEFECT_CNT'] : defectCnt,
                PARA_CD: vds_Spec[specRow]['PARA_CD'],
                PARA_NM: vds_Spec[specRow]['PARA_NM']
            });
        } else {
            vds_Defect[nRow]['DEFECT_CNT'] = vds_Spec[specRow]['MEAS_DEFECT_CNT'];
        }

        setDataset("ds_DefectGroup", vds_DefectGroup);
        setDataset("ds_DefectSymptom2", vds_DefectSymptom2);
    }

    function fn_CalSummary() {
        //평균, min, max 값 구하기
        for (let i = 0; i < vds_Spec.length; i++) {
            if (vds_Spec[i]['NUM_YN'] == "Y" && Number(vds_Spec[i]['SAM_CNT']) > 0) {
                let nMax = 0;
                let nMin = 0;
                let nAvg = 0;
                let nTotal = 0;
                let nInputCnt = Number(vds_Spec[i]['SAM_CNT']);
                let nInputCntTmp = 0;
                let aMeansArray = [];

                for (let j = 0; j < nInputCnt; j++) {
                    if (vds_Spec[i]['MEAS_VAL_' + (j + 1)] == undefined || vds_Spec[i]['MEAS_VAL_' + (j + 1)] == null || vds_Spec[i]['MEAS_VAL_' + (j + 1)] == '') {
                        continue;
                    }

                    nInputCntTmp++;

                    let val = Number(vds_Spec[i]['MEAS_VAL_' + (j + 1)]);
                    nTotal += val;
                    aMeansArray.push(val);
                }

                if (nInputCntTmp > 0) {
                    nAvg = nTotal / nInputCntTmp;   //평균
                    nMax = Math.max.apply(null, aMeansArray);
                    nMin = Math.min.apply(null, aMeansArray);

                    let point = Number(vds_Spec[i]['POINT']);

                    nAvg = Number(nAvg.toFixed(point));
                    nMax = Number(nMax.toFixed(point));
                    nMin = Number(nMin.toFixed(point));
                    vds_Spec[i]['MEAS_AVG'] = nAvg;
                    vds_Spec[i]['MEAS_MAX'] = nMax;
                    vds_Spec[i]['MEAS_MIN'] = nMin;
                }
            }
        }
    }

    /* 불량그룹조회 */
    function fn_defectGroupSeclect() {
        const requestJson: NexacroJsonType = {
            ds_Part: vds_Part
        };

        return sendAxios('/common/nexacro/QMV_P_Out_InspectVndRcrdDetailRegDefectGroupSelect.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson);
    }

    /* 탭에서 전달 받은 dataset 세팅 */
    function setDataset(datasetName: string, dataset: Array<NexacroJsonUnitType>) {
        if ("ds_Part" == datasetName) {
            setDs_Part(dataset);
        } else if ("ds_Ecn" == datasetName) {
            setDs_Ecn(dataset);
        } else if ("ds_Spec" == datasetName) {
            setDs_Spec(dataset);
        } else if ("ds_Aql" == datasetName) {
            setDs_Aql(dataset);
        } else if ("ds_AllSample" == datasetName) {
            setDs_AllSample(dataset);
        } else if ("ds_popChngPoingHList" == datasetName) {
            setDs_popChngPoingHList(dataset);
        } else if ("ds_GroupList" == datasetName) {
            setDs_GroupList(dataset);
        } else if ("ds_grdColList" == datasetName) {
            setDs_grdColList(dataset);
        } else if ("ds_DefectDelete" == datasetName) {
            setDs_DefectDelete(dataset);
        } else if ("ds_DefectSymptom2" == datasetName) {
            setDs_DefectSymptom2(dataset);
        } else if ("ds_Defect" == datasetName) {
            setDs_Defect(dataset);
            vds_Defect = dataset;
        } else if ("ds_EcnReqInfo" == datasetName) {
            setDs_EcnReqInfo(dataset);
        } else if ("ds_upload" == datasetName) {
            setDs_upload(dataset);
        } else if ("ds_remove" == datasetName) {
            setDs_remove(dataset);
        } else if ("ds_qPointList" == datasetName) {
            setDs_qPointList(dataset);
        } else if ("secMeasRslt" == datasetName) {
            setSecMeasRslt(dataset);
        }
    }

    /* 탭에서 전달 받은 단일값 세팅 */
    function setFv_maxSamCnt(parameterValue: number) {
        fv_maxSamCnt = parameterValue;
        setMaxSamCnt(String(parameterValue));
    }

    function fn_tempSaveFile()
    {
        fv_temp = "Y";
        vds_Part[0]["IV_STATUS"] = "9";
        fn_fileUploadTemp(fn_checkChangeFile());
    }

    function fn_fileUploadTemp(isChanged:Boolean)
    {
        let msg = "";

        for (let i = 0; i < ds_Defect.length; i++) {
            if (ds_Defect[i]['DEFECT_GROUP_CD'] == undefined || ds_Defect[i]['DEFECT_GROUP_CD'] == "") {
                msg = getDomain("DOMAIN2198");
                break;
            }

            if (ds_Defect[i]['SYMPTOM_CD'] == undefined || ds_Defect[i]['SYMPTOM_CD'] == "") {
                msg = getDomain("DOMAIN1158");
                break;
            }

            if (ds_Defect[i]['DEFECT_CONTENTS'] == undefined || ds_Defect[i]['DEFECT_CONTENTS'] == "") {
                msg = getDomain("DOMAIN2390");
                break;
            }
        }

        if (msg != "") {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG09001").replaceAll('{0}', msg),
                confirm: () => {
                }
            });

            return;
        }

        if(isChanged){
            //파일 변경되었으면 세이브 하고 저장(->fn_attachFileComplete)
            fn_file_save();
        }else{
            fn_tranTempSave();
        }

    };

    /*
     *  파일 변경사항이 있으면 true 리턴
     */
    function fn_checkChangeFile(){
        let result = false;
        let temp_ds_upload = [...ds_upload];
        let isUpadte = temp_ds_upload.filter(item => item.FILE_ID === "").length > 0 ? true : false;

        // if(!this.gfn_dsIsUpdated(fileUpload.ds_upload) && fileUpload.ds_remove.rowcount == 0){
        if(!isUpadte && ds_remove.length == 0){
            result = false;
        }else{
            result = true;
        }
        return result;
    }

    /* 임시저장 */
    async function fn_tranTempSave() {
        let msg = "";

        for (let i = 0; i < vds_Spec.length; i++) {     //point 가 없을 경우
            if (vds_Spec[i]['POINT'] == undefined) {
                vds_Spec[i]['POINT'] = "0";
            }
        }

        // Agent Data 관련 로직으로 주석 처리
        /** VOC 41 Start : 2019.09.05 채보람 Smart Inspection Data 연계 입고 Lot 자동판정 **/
        // if (fv_isDivisionQ)
        // {
        //     /** Agent Data 사용시 Agent 저장 데이터 세팅 **/
        //     if (vds_Part[0]['SMART_INSP_YN'] == "Y" || vds_Part[0]['SMART_INSP_YN'] == "N")
        //     {
        //         // ds_Part[0]['SMART_INSP_STATUS'] = this.Div02.form.Div00.form.Combo00.value;  최종결과 콤보박스 데이터
        //         vds_Part[0]['SMART_INSP_STATUS'] = "";
        //
        //         if (vds_Part[0]['DOUBLE_EXECUT_YN'] != "Y")
        //         {
        //             if (fv_doubleFlag == "Y")
        //             {
        //                 vds_Part[0]['DOUBLE_EXECUT_YN'] = "Y";
        //             }
        //             else if (fv_doubleFlag == "N")
        //             {
        //                 vds_Part[0]['DOUBLE_EXECUT_YN'] = "N";
        //             }
        //         }
        //     }
        // }

        let today = "";

        await getSdpSysDate().then(response => {
            today = response?.data.ds_Output[0]['SERVERTIME'];
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });

        vds_Part[0]['INSP_USER_LIST'] = vds_Part[0]['INSP_USER'];  //검사담당자 저장
        vds_Part[0]['INSP_USER'] = sessionValues?.USER_ID;
        vds_Part[0]['INSP_USER_NM'] = sessionValues?.USER_NAME;

        vds_Part[0]['SEC_INSP_DT'] = today.substr(0, 8);
        vds_Part[0]['SEC_INSP_TIME'] = today.substr(8);

        //ECN 여러개 있을 때 String 형태로 다 던짐(구분자 콤마)
        vds_Part[0]['EC_NOS'] = vds_Part[0]['MOD_PART_MEMO'];

        //성적서 테이블에 SAMPLE_CNT, BAD_CNT 추가
        vds_Part[0]['MAX_SAM_CNT'] = maxSamCnt;

        vds_Part[0]['SAM_BAD_CNT'] = fn_getBadCnt();

        /** VOC Start : 2020.06.24 검사기준서상태값 추가 by LEJ **/
        vds_Part[0]['INSP_STD_STATUS'] = ds_Spec[0]['INSP_STD_STATUS'];

        if (vds_EcnReqInfo.length != 0) {
            vds_EcnReqInfo[0]['HQ_CD'] = vds_Part[0]['HQ_CD'];
            vds_EcnReqInfo[0]['PLANT_CD'] = vds_Part[0]['PLANT_CD'];
            vds_EcnReqInfo[0]['PART_CD'] = vds_Part[0]['PART_CD'];
        }

        const requestJson: NexacroJsonType = {
            ds_Part: vds_Part,
            ds_Spec: vds_Spec,
            ds_Defect: ds_Defect,
            ds_DefectDelete: ds_DefectDelete,
            ds_grdColList: ds_grdColList,
            ds_mesAlarmSearchLot: vds_mesAlarmSearchLot,
            ds_tsiSpecialInspList: vds_tsiSpecialInspList,
            ds_ApproveList: vds_ApproveList,
            ds_EcnReqInfo: vds_EcnReqInfo,
        };

        sendAxios('/common/nexacro/QMS_P_Incm_InspectRegTmpSave.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            let dsRes = response?.data.ds_Res;
            let msg = "MSG00058" //저장되었습니다.

            if (dsRes.length > 0) {
                if (dsRes[0]['CODE'] != "SUCESS") {
                    msg = "MSG09999";
                }

                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage(msg),
                    confirm: () => {
                    }
                });
            }

            /* Q-Point 검사 item insert */
            insertInspItem();
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* 첨부파일 저장 */
    function fn_saveFile() {
        // tsi 관련 로직
        // if (this.fn_isTsiUnderAnalysis()) {
        //     this.gfn_confirm("MSG01410", [""], "", "", "fn_confirmApproveInsp");
        // } else {
        //     this.fn_saveProcess();
        // }

        fn_saveProcess();
    }

    /* fn_saveProcess */
    async function fn_saveProcess() {
        vds_Part[0]['IV_STATUS'] = "2";

        await fn_selectIncmDetail().then((response) => {
            setDs_PartCurrent(response?.data.ds_InspDetail);
            vds_PartCurrent = response?.data.ds_InspDetail;
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });

        /** VOC 35 Start : 2019.05.30 채보람 임시저장 성적서 DMR Status 변경시 반영 **/
        /** 다시 조회한 데이터의 DMR Stage 체크 **/
        if (fn_isChangeLotInfoDMR())
        {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG01173"),
                confirm: () => {
                }
            });

            return;
        }
        /** VOC 35 End   : 2019.05.30 채보람 임시저장 성적서 DMR Status 변경시 반영 **/

        if (fn_isChangeLotInfo()) {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG01168"),
                confirm: () => {
                }
            });
        } else {
            // tsi 관련 로직
            // if (this.fn_isTsiUnderAnalysis()) {
            //     this.fv_temp = "A";
            // } else {
            //     this.fv_temp = "N";
            // }

            fv_temp = "N";

            fn_fileUpload(fn_checkChangeFile());
        }
        /** VOC 18 END : 2019.05.28 임시저장된 입고성적서와 변경된 출하성적서 자재구분 매칭 **/
    }

    /* 현재 입고 대상에 출하성적서 제출 매핑정보 조회 */
    async function fn_selectIncmDetail() {
        const requestJson: NexacroJsonType = {
            ds_SearchInspDetail: [{
                LANG: sLocale,
                HQ_CD: vds_Part[0]['HQ_CD'],
                PART_CD: vds_Part[0]['PART_CD'],
                PLANT_CD: vds_Part[0]['PLANT_CD'],
                INV_NO: vds_Part[0]['INV_NO']
            }]
        };

        return sendAxios('/common/nexacro/QMS_M_Incm_InspDetailData.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson);
    }

    /* 다시 조회한 데이터의 DMR Stage 체크 */
    /* 성적서 제출 내용 체크 */
    function fn_isChangeLotInfoDMR() {
        if (vds_Part[0]['DMR_GROUP'] != vds_PartCurrent[0]['DMR_GROUP'])
        {
            return true;
        }

        return false;
    }

    /* 성적서 제출 내용 체크 */
    function fn_isChangeLotInfo() {
        if (vds_Part[0]['LOT_ID'] != vds_PartCurrent[0]['LOT_ID']){
            return true;
        }

        if (vds_Part[0]['SUB_STATUS'] != vds_PartCurrent[0]['SUB_STATUS']){
            return true;
        }

        if (vds_Part[0]['GR_DATE'] != vds_PartCurrent[0]['GR_DATE']){
            return true;
        }

        if (vds_Part[0]['GR_TIME'] != vds_PartCurrent[0]['GR_TIME']){
            return true;
        }

        if (parseInt(vds_Part[0]['SUBMIT_CNT']) != parseInt(vds_PartCurrent[0]['SUBMIT_CNT'])){
            return true;
        }

        /** VOC 35 Start : 2019.05.30 채보람 임시저장 성적서 DMR Status 변경시 반영 **/
        /** 다시 조회한 데이터의 DMR Stage 체크 **/
        if (vds_Part[0]['DMR_GROUP'] != vds_PartCurrent[0]['DMR_GROUP'])
        {
            return true;
        }
        /** VOC 35 End   : 2019.05.30 채보람 임시저장 성적서 DMR Status 변경시 반영 **/

        return false;
    }

    /* fn_fileUpload */
    function fn_fileUpload(isChanged: boolean) {
        vds_Spec = ds_Spec;

        // 세부검사항목 값 전부 입력되어 있는지 validation 체크
        for (let i= 0 ; i < vds_Spec.length; i++) {
            for (let j = 0; j < Number(vds_Spec[i]['SAM_CNT']); j++) {
                if (gfn_isNull(vds_Spec[i]["RESULT_YN_"+ (j + 1)]) || gfn_isNull(vds_Spec[i]["MEAS_VAL_"+ (j + 1)])) {
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG09001").replace("{0}",getDomain("DOMAIN2059")),
                        confirm: () => {
                        }
                    });

                    return;
                }
            }
        }

        /* 불량판정입력값 체크 */
        let msg = "";

        for (let i = 0; i < ds_Defect.length; i++) {
            if (ds_Defect[i]['DEFECT_GROUP_CD'] == undefined || ds_Defect[i]['DEFECT_GROUP_CD'] == "") {
                msg = getDomain("DOMAIN2198");
                break;
            }

            if (ds_Defect[i]['SYMPTOM_CD'] == undefined || ds_Defect[i]['SYMPTOM_CD'] == "") {
                msg = getDomain("DOMAIN1158");
                break;
            }

            if (ds_Defect[i]['DEFECT_CONTENTS'] == undefined || ds_Defect[i]['DEFECT_CONTENTS'] == "") {
                msg = getDomain("DOMAIN2390");
                break;
            }
        }

        if (msg != "") {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG09001").replaceAll('{0}', msg),
                confirm: () => {
                }
            });

            return;
        }

        /* 메모 체크 */
        // if (!this.gfn_checkValidObject(this.Div02.form.Div02.form.memo)){ //validation check
        //     return;
        // } 메모 데이터가 필수가 아닌거 같아 주석 처리

        // if (this.fv_tsiAgentYn == "Y" &&  !this.gfn_checkValidObject(this.Div02.form.Div03.form.grd_tsiInsp)){ //validation check
        //     return;
        // } tsi 관련 로직 주석 처리

        if (vds_Part[0]['SUB_STATUS'] == "N" || vds_Part[0]['SUB_STATUS'] == "P") {
            //성적서 미제출일때 임시저장은 가능, 합격판정은 할 수 없음 불합격판정은 가능함.
            if (vds_Part[0]['SEC_MEAS_RESULT'] == "1") {
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage("MSG01046"),
                    confirm: () => {
                    }
                });

                return;
            }
        }

        /*VOC 34 재검사*/
        if (vds_Part[0]['INSP_STATUS'] == "R") {
            //재검사 입고검사 성적서 저장시
            if (gfn_isNull(vds_Part[0]['INSP_MEMO'])) {
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage("MSG01047"),
                    confirm: () => {
                    }
                });

                return;
            }
        }
        /*VOC 34 재검사*/

        /* 최종판정결과 체크 */
        if (vds_Part[0]['MEAS_RESULT'] == null || vds_Part[0]['MEAS_RESULT'] == "") {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG09001").replace("{0}",getDomain("DOMAIN2606")),
                confirm: () => {
                }
            });

            return;
        }

        for (let i = 0; i < vds_Spec.length; i++) {     //point 가 없을 경우
            if (vds_Spec[i]['POINT'] == undefined) {
                vds_Spec[i]['POINT'] = "0";
            }
        }

        // VOC C22020002185 Start 2022.02.24 xinbin.xu  판정시 Message에 합격/불합격에 따라 다른 메시지가 표기됨
        let result = vds_Part[0]['SEC_MEAS_RESULT'];
        let msgId = getMessage("MSG01332"); // Do you want to Reject?

        if (result == 1) {
            msgId = getMessage("MSG01331"); // Do you want to Pass?
        }
        // VOC C22020002185 Start 2020.02.24 xinbin.xu  판정시 Message에 합격/불합격에 따라 다른 메시지가 표기됨

        // if(nexacro.getApplication().gv_bIsPopup_Confirm == false){
        //     if(this.gfn_confirm(msgId)){ // VOC C22020002185 2020.02.24 xinbin.xu  판정시 Message에 합격/불합격에 따라 다른 메시지가 표기됨
        //         if(isChanged){
        //             this.Div02.form.Div02.form.div_fileUpload.form.fn_save();
        //         }else{
        //             this.fn_tranSave();
        //         }
        //     }
        // } else {
        //     let arg1 = msgId; // VOC C22020002185 2020.02.24 xinbin.xu  판정시 Message에 합격/불합격에 따라 다른 메시지가 표기됨
        //     let arg2 = [""];
        //     this.fv_isChanged = isChanged;
        //     let rtn = this.gfn_confirm(arg1, arg2, "", "", "fn_confirmCallbackSave");
        // } gv_bIsPopup_Confirm 가 true 고정으로 주석 처리

        setFv_isChanged(isChanged);
        vfv_isChanged = isChanged;

        setDialog({
            type: 'CONFIRM',
            show: true,
            text: msgId,
            confirm: () => {
                if (vfv_isChanged) {
                    fn_file_save();
                } else {
                    // if(this.fv_temp == "A") {
                    //     fn_openApproveInsp();
                    // } else {
                    //     fn_tranSave();
                    // } tsi 관련 로직 주석 처리

                    fn_tranSave();
                }
            }
        });
    }

    /* 저장 */
    async function fn_tranSave() {
        // Agent Data 관련 로직으로 주석 처리
        /** VOC 41 Start : 2019.09.05 채보람 Smart Inspection Data 연계 입고 Lot 자동판정 **/
        // if (this.fv_isDivisionQ)
        // {
        //     /** Agent Data 사용시 Agent 저장 데이터 세팅 **/
        //     if (this.ds_Part.getColumn(0, "SMART_INSP_YN") == "Y")
        //     {
        //         this.ds_Part.setColumn(0, "SMART_INSP_STATUS", this.Div02.form.Div00.form.Combo00.value);
        //
        //         if (this.ds_Part.getColumn(0, "DOUBLE_EXECUT_YN") != "Y")
        //         {
        //             if (this.fv_doubleFlag == "Y")
        //             {
        //                 this.ds_Part.setColumn(0, "DOUBLE_EXECUT_YN", "Y");
        //             }
        //             else if (this.fv_doubleFlag == "N")
        //             {
        //                 this.ds_Part.setColumn(0, "DOUBLE_EXECUT_YN", "N");
        //
        //                 if (this.ds_Part.getColumn(0, "INSP_MEMO") != this.pdv_doubleReason.form.txt_double.value)
        //                 {
        //                     this.ds_Part.setColumn(0, "INSP_MEMO", this.pdv_doubleReason.form.txt_double.value);
        //                 }
        //             }
        //         }
        //     }
        // }
        /** VOC 41 End   : 2019.09.05 채보람 Smart Inspection Data 연계 입고 Lot 자동판정 **/

        let today = "";

        await getSdpSysDate().then(response => {
            today = response?.data.ds_Output[0]['SERVERTIME'];
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });

        vds_Part[0]['INSP_USER_LIST'] = vds_Part[0]['INSP_USER'];  //검사담당자 저장
        vds_Part[0]['INSP_USER'] = sessionValues?.USER_ID;
        vds_Part[0]['INSP_USER_NM'] = sessionValues?.USER_NAME;

        vds_Part[0]['SEC_INSP_DT'] = today.substr(0, 8);
        vds_Part[0]['SEC_INSP_TIME'] = today.substr(8);

        //ECN 여러개 있을 때 String 형태로 다 던짐(구분자 콤마)
        vds_Part[0]['EC_NOS'] = vds_Part[0]['MOD_PART_MEMO'];

        //성적서 테이블에 SAMPLE_CNT, BAD_CNT 추가
        vds_Part[0]['MAX_SAM_CNT'] = maxSamCnt;

        vds_Part[0]['SAM_BAD_CNT'] = fn_getBadCnt();

        /** VOC Start : 2020.06.24 검사기준서상태값 추가 by LEJ **/
        vds_Part[0]['INSP_STD_STATUS'] = ds_Spec[0]['INSP_STD_STATUS'];

        vds_Part[0]['SEC_MEAS_RESULT'] = vds_Part[0]['MEAS_RESULT'];

        vds_Part[0]['MOBILE_YN'] = "Y";

        if (ds_EcnReqInfo.length != 0) {
            vds_EcnReqInfo = ds_EcnReqInfo;
            vds_EcnReqInfo[0]['HQ_CD'] = vds_Part[0]['HQ_CD'];
            vds_EcnReqInfo[0]['PLANT_CD'] = vds_Part[0]['PLANT_CD'];
            vds_EcnReqInfo[0]['PART_CD'] = vds_Part[0]['PART_CD'];
            // vds_EcnReqInfo[0]['GRADE'] = this.Div02.form.Div05.form.detailGrade.value; // 변경등급 가져오기
            // vds_EcnReqInfo[0]['VERIFICATION_CODE'] = this.Div02.form.Div05.form.verificationType.value; // 변경점검증유형 가져오기 보람보람보람
        }

        const requestJson: NexacroJsonType = {
            ds_Part: vds_Part,
            ds_Spec: vds_Spec,
            ds_Defect: ds_Defect,
            ds_DefectDelete: ds_DefectDelete,
            ds_grdColList: ds_grdColList,
            ds_GroupList: ds_GroupList,
            ds_mesAlarmSearchLot: vds_mesAlarmSearchLot,
            ds_tsiSpecialInspList: vds_tsiSpecialInspList,
            ds_EcnReqInfo: vds_EcnReqInfo
        };

        sendAxios('/common/nexacro/QMS_P_Incm_InspectRegSave.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            let dsRes = response?.data.ds_Res;

            if (dsRes.length > 0) {
                if (dsRes[0]['CODE'] == "SUCESS") {
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG01132"),
                        confirm: () => {
                            pageMove(process.env.REACT_APP_URL_QMS || '');
                        }
                    });
                } else {
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage(dsRes[0]['MSG']), //오류.
                        confirm: () => {
                        }
                    });
                }
            }

            /* Q-Point 검사 item insert */
            insertInspItem();
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* Q-Point 검사 item insert */
    function insertInspItem() {
        if (ds_qPointList.length == 0) {
            return;
        }

        let emptyCnt = 0;

        for (let i = 0; i < ds_qPointList.length; i++) {
            if (ds_qPointList[i]['ITEM_MEAS_RESULT'] == undefined || ds_qPointList[i]['ITEM_MEAS_RESULT'] == null || ds_qPointList[i]['ITEM_MEAS_RESULT'] == "") {
                emptyCnt++;
            }
        }

        if (emptyCnt == ds_qPointList.length) {
            return;
        }

        const requestJson: NexacroJsonType = {
            ds_qPointList: ds_qPointList
        };

        sendAxios('/common/nexacro/mergeQpointInspItem.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            insertInspResult();
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* Q-Point 검사 최종 결과 insert */
    function insertInspResult() {
        let finalResult = 'OK';

        for (let i = 0; i < ds_qPointList.length; i++) {
            if (ds_qPointList[i]['ITEM_MEAS_RESULT'] == 'NG') {
                finalResult = 'NG';

                break;
            }
        }

        const requestJson: NexacroJsonType = {
            ds_qPointResultList: [{
                SEC_INSP_ID: vds_Part[0]['SEC_INSP_ID'],
                INV_NO: vds_Part[0]['INV_NO'],
                PART_CD: vds_Part[0]['PART_CD'],
                HQ_CD: vds_Part[0]['HQ_CD'],
                PLANT_CD: vds_Part[0]['PLANT_CD'],
                DIVISION_CD: vds_Part[0]['DIVISION_CD'],
                LOT_NO: vds_Part[0]['INSP_LOT_NO'],
                GR_QTY: vds_Part[0]['GR_QTY'],
                FINAL_RESULT: finalResult,
                GR_DATE: vds_Part[0]['GR_DATE'].replaceAll('-', ''),
                INSERT_BY: sessionValues?.USER_ID,
                UPDATE_BY: sessionValues?.USER_ID,
            }]
        };

        sendAxios('/common/nexacro/mergeQpointInspResult.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* bad count 구하기 */
    function fn_getBadCnt() {
        return ds_Defect.reduce(function (prev: any, next: any) {
            return String(Number(prev) + Number(next.DEFECT_CNT));
        }, "0");
    }

    /* 판정 결과 체크 */
    function fn_checkMeasCheck() {
        let nPassSpecCnt = 0;

        let sampleCntOverzero = 0;

        // 전부 합격일때 전체판정결과 합격으로 표시
        for (let i = 0; i < vds_Spec.length; i++) {
            if (vds_Spec[i]['MEAS_RSLT'] == 'Y') {
                nPassSpecCnt++;
            }

            if (Number(vds_Spec[i]['SAM_CNT']) > 0) {
                sampleCntOverzero++;
            }
        }

        // tsi 관련 로직 주석
        // let tsiPassCnt = 0;
        // let tsiInspCnt = this.ds_tsiSpecialInspList.rowcount;
        //
        // for(var i=0; i<this.ds_tsiSpecialInspList.rowcount; i++) {
        //     if(this.ds_tsiSpecialInspList.getColumn(i, "MEAS_RESULT") == "Y") {
        //         tsiPassCnt++;
        //     }
        // }

        // 합격
        if (nPassSpecCnt == sampleCntOverzero) {  // 전체합격
            /** VOC 41 Start : 2019.09.05 채보람 Smart Inspection Data 연계 입고 Lot 자동판정 **/
            /** 전체 합격일 경우 Hold 도 선택 가능 **/
            if (fv_isDivisionQ)
            {
                setSecMeasRslt(defaultSecMeasRslt.filter((item) => item.CD == '1' || item.CD == 'H'));
                vds_Part[0]['MEAS_RESULT'] = "1";
                vds_Part[0]['SEC_MEAS_RESULT'] = "1";
            }
            else
            {
                setSecMeasRslt(defaultSecMeasRslt.filter((item) => item.CD == '1')); // 콤보에 합격만 표시
                vds_Part[0]['MEAS_RESULT'] = "1";
                vds_Part[0]['SEC_MEAS_RESULT'] = "1";
            }
            /** VOC 41 End   : 2019.09.05 채보람 Smart Inspection Data 연계 입고 Lot 자동판정 **/

        // 판전완료 되지 않음
        } else if (vds_Defect.length == 0) {
            setSecMeasRslt(defaultSecMeasRslt);
            vds_Part[0]['MEAS_RESULT'] = "";
            vds_Part[0]['SEC_MEAS_RESULT'] = "";
            // NG
        } else {
            setSecMeasRslt(defaultSecMeasRslt.filter((item) => item.CD != '1')); // 콤보에서 합격 지움
        }
    }

    /* 첨부 파일 조회 */
    function fn_attachFile_N(datasetName :string, file_id:string){
        if (!gfn.gfn_isNull(file_id)) {
            return sendAxios('/common/nexacro/commonSelectFileList.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_Search: [{
                    FILE_ID: file_id
                }]
            })
            .then(response => {
                if ("ds_upload" == datasetName) {
                    setDs_upload(response?.data.ds_List || []);
                }
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
        } else {
            return null;
        }
    }

    /* Q-Point 목록 조회 */
    function fn_qpiontInfo() {
        sendAxios('/common/nexacro/selectQpointInfoListPaging.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Search: [{
                HQ_CD: vds_Part[0]['HQ_CD'],
                PLANT_CD: vds_Part[0]['PLANT_CD'],
                PART_CD: vds_Part[0]['PART_CD'],
                DIVISION_CD: vds_Part[0]['DIVISION_CD'],
                USE_YN: "Y",
                RECORDS: "200",
                RECORDSOFFSET: "0",
                SEC_INSP_ID: vds_Part[0]['SEC_INSP_ID']
            }],
            ds_Plant: [{
                PLANT: vds_Part[0]['PLANT_CD']
            }],
            ds_division: [{
                DIVISION: vds_Part[0]['DIVISION_CD']
            }]
        })
        .then(response => {
            setDs_qPointList(response?.data.ds_QPointList);
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* 이슈자재 alert 표기 */
    function fn_SearchIssuePart() {
        if (String(state.params.get('pv_flag')) == "INS" && ds_IssuePartList.length > 0) {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG01179"),
                confirm: () => {
                }
            });
        } else {
            if (String(state.params.get('pv_flag')) == "INS") {
                if (ds_popChngPoingHList.length > 0) {
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG01495"),
                        confirm: () => {
                        }
                    });
                }
            }
        }
    }

    /* 그룹 리스트 조회 */
    function fn_selectGroupList() {
        const requestJson: NexacroJsonType = {
            ds_Part: vds_Part
        };

        sendAxios('/common/nexacro/selectGroupList.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            let vds_GroupList = response?.data.ds_GroupList;

            //그룹판정조회시 그룹판정시 기준 인보이스로으로 조회
            vds_Part[0]['INV_NO'] = vds_GroupList[0]['GROUP_INV_NO'];
            vds_Part[0]['GR_QTY'] = vds_GroupList[0]['GROUP_GR_QTY'];
            vds_Part[0]['GR_DATE'] = vds_GroupList[0]['GROUP_GR_DATE'];
            vds_Part[0]['GR_TIME'] = vds_GroupList[0]['GROUP_GR_TIME'];

            //INV_NO
            let invList = "";

            for (let i = 0; i < vds_GroupList.length; i++) {
                if (i == vds_GroupList.length - 1) {
                    invList = invList + vds_GroupList[i]["INV_NO"];
                } else {
                    invList = invList + vds_GroupList[i]["INV_NO"] + ",";
                }
            }

            vds_Part[0]["INV_NOS"] = invList;

            //입고수량 합계
            let grQty = 0;
            let realGrQty = 0;

            for (let i = 0; i < vds_GroupList.length; i++) {
                grQty += parseFloat(String(vds_GroupList[i]["GR_QTY"])) * 1000;

                if (vds_GroupList[i]["GR_DATE"] != null && vds_GroupList[i]["GR_DATE"] != '') {
                    realGrQty += parseFloat(String(vds_GroupList[i]["GR_QTY"])) * 1000;
                }
            }

            //GR_QTY
            vds_Part[0]["GR_QTY"] = grQty / 1000;

            setDs_GroupList(response?.data.ds_GroupList);
            setDs_Part(vds_Part);
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* 첨부 파일 저장 */
    async function fn_file_save(){
        const formData = new FormData();
        let files = reportRef.current?.files;
        let maxSize = 30 * 1024 * 1024; //* 30MB 사이즈 제한
        let fileSize = 0;
        const options = {
            maxWidthOrHeight: 1920, //width
            initialQuality: 0.8 //quality
        }

        if (files?.length != undefined) {
            for (let i = 0; i < files?.length; i++) {
                let fileD = files[i];

                if (fileD.type.match('image')) {
                    const compressedFile = await imageCompression(files[i], options);
                    fileD = new File([compressedFile], files[i].name, {type: files[i].type});
                }

                // 23.10.16 파일 사이즈 조정
                fileSize = fileSize + fileD.size;
                if(fileSize >= maxSize){
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG01296").replace("0", "30MB"),
                    });
                    return;
                }

                formData.append('file' + i, fileD);
            }

            formData.append("workType", "QMS");

            let inputDatasets: any = {
                ds_input: ds_upload,
                ds_fileInfo: [{
                    FILE_ID: gfn.gfn_isNull(ds_Part[0]["ATTACH_FILE_ID"]) ? null : ds_Part[0]["ATTACH_FILE_ID"],
                    MODULE_ID: "QMS"
                }],
                ds_remove: ds_remove
            }

            if (gfn.gfn_isNull(ds_Part[0]["ATTACH_FILE_ID"])) {
                inputDatasets = {
                    ds_input: ds_upload,
                    ds_fileInfo: [{
                        MODULE_ID: "QMS"
                    }],
                    ds_remove: ds_remove
                }
            }

            formData.append("inputDatasets", String(requestParser(inputDatasets)));

            sendAxios('/common/nexacro/commonFileupload.do', 'POST', AXIOS_HEADER.FORM, formData)
            .then(response => {
                for (const ds_res of responseParser(response?.data)?.ds_output || []) {
                    if (ds_res.ERR_CODE === "SUCESS") {
                        vds_Part[0]['ATTACH_FILE_ID'] = ds_res.ERR_MSG as string;
                        setDs_Part(vds_Part);

                        if (fv_temp == "Y") {
                            fn_tranTempSave();
                        } else if(fv_temp == "A") {
                            // fn_openApproveInsp();   // tsi 관련 로직으로 주석 처리
                        } else {
                            fn_tranSave();
                        }
                    } else {
                        setDialog({
                            type: 'ALERT',
                            show: true,
                            text: getMessage("MSG00220"),
                            confirm: () => {
                            }
                        });
                    }
                }
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
        }
    }

    /* 데이터셋에 샘플측정 결과 컬럼 생성 */
    async function fn_creatDataset() {
        let nMaxSam = 0;
        let nSam = 0;

        for (let i = 0; i < vds_Spec.length; i++) {
            nSam = Number(vds_Spec[i]['SAM_CNT']);

            if (nMaxSam < Number(nSam)) {
                nMaxSam = Number(nSam);
            }
        }

        setFv_maxSamCnt(nMaxSam);

        for (let i = 0; i < nMaxSam; i++) {
            vds_grdColList.push({
                COL_NAME: "#" + (i + 1),
                BIND_COL: "MEAS_VAL_" + (i + 1)
            });
        }

        setDs_grdColList(vds_grdColList);
    }

    /* 필수 CTF 적용된 VD사업부의 자재이나 내용에 없을 경우 작성or수정시 알림팝업 */
    function fn_essCTFsearch() {
        const requestJson: NexacroJsonType = {
            ds_Search: [{
                PLANT_CD: vds_Part[0]['PLANT_CD'],
                HQ_CD: vds_Part[0]['HQ_CD'],
                PART_CD: vds_Part[0]['PART_CD'],
                CATEGORY2: vds_Part[0]['CATEGORY2'],
                LANG: sLocale,
                DIVISION_CD: vds_Part[0]['DIVISION_CD']
            }],
            ds_SpecList: vds_Spec
        };

        sendAxios('/common/nexacro/selectEssCTFInfo2.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            if (response?.data.ds_CTFList.length > 0) {
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage("MSG01561"),
                    confirm: () => {
                    }
                });
            }
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    const selectTab = (name: string) => {
        setShowTab(false);

        let preTab: string | undefined = undefined;
        setTabList(tabList.map(tab => {
            if(tab.selected) {
                preTab = tab.name;
            }

            if(tab.name === name) {
                if(preTab) {
                    tab.direction = 'right';
                } else {
                    tab.direction = 'left';
                }
                tab.selected = true;
            } else {
                tab.selected = false;
            }
            return tab;
        }));
    };

    useEffect(() => {
        setShowTab(true);
        setFocusTab(tabList.find(tab => tab.selected) || tabList[0]);
    }, [tabList]);

    const handleNextTab = (name: string) => {
        const selectedIndex = tabList.map((tab, index) => {
            if(tab.name === name) {
                return index;
            } else {
                return 0;
            }
        })
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        if(selectedIndex < tabList.length - 1) {
            selectTab(tabList[selectedIndex + 1].name);
        } else {
            // setDialog({
            //     show: true,
            //     type: 'ALERT',
            //     text: '마지막 탭 입니다.'
            // });
        }
    };

    /* 탭정보 갱신 (기본정보) */
    useEffect(() => {
        // params.set('pv_data', ds_Part);

        tabList.map(tab => {
            if (tab.name === 'qmsBasicInformation') {
                tab.element = <QmsBasicInformation state={{params: params, setDataset, fn_tempSaveFile}} nextTab={(name: string) => handleNextTab(name)}/>;
            }

            return tab;
        });
    }, [ds_Part, ds_GroupList]);

    /* 탭정보 갱신 (부품검사정보) */
    useEffect(() => {
        if (ds_Part.length != 0) {
            vds_Part = ds_Part;
        }

        if (ds_EcnReqInfo.length != 0) {
            vds_EcnReqInfo = ds_EcnReqInfo;
        }

        if (ds_SpecFile.length != 0) {
            vds_SpecFile = ds_SpecFile;
        }

        params.set('pv_data', vds_Part);
        params.set('pv_ecn', ds_popChngPoingHList);
        params.set('pv_ecnDetail', ds_Ecn);
        params.set('pv_vds_EcnReqInfo', ds_EcnReqInfo);
        params.set('pv_vds_SpecFile', ds_SpecFile);
        params.set('pv_secAnlResult', pv_secAnlResult);
        params.set('pv_ds_SubmitInspList', ds_SubmitInspList);
        params.set('pv_ds_chngPoingHList', ds_chngPoingHList);
        // params.set('pv_ds_IssuePartList', pv_ds_IssuePartList);

        tabList.map(tab => {
            if (tab.name === 'qmsPartInspectionInformation') {
                tab.element = <QmsPartInspectionInformation state={{params: params, setDataset, fn_tempSaveFile}} nextTab={(name: string) => handleNextTab(name)}/>;
            }

            return tab;
        });
    }, [ds_Part, ds_popChngPoingHList, ds_Ecn, ds_EcnReqInfo, ds_SpecFile]);

    /* 탭정보 갱신 (세부검사정보) */
    useEffect(() => {
        if (ds_Spec.length != 0) {
            vds_Spec = ds_Spec;
        }

        if (ds_Part.length != 0) {
            vds_Part = [...ds_Part];
        }

        params.set('pv_spec', vds_Spec);
        params.set('pv_data', vds_Part);
        params.set('pv_defect', ds_Defect);

        tabList.map(tab => {
            if (tab.name === 'qmsDetailInspectList') {
                tab.element = <QmsDetailInspectList state={{params:params, setDataset, setFv_maxSamCnt, fn_tempSaveFile, ds_DefectDelete}} nextTab={(name: string) => handleNextTab(name)}/>;
            }

            return tab;
        });
    }, [ds_Spec, ds_Part, ds_Defect]);

    /* 탭정보 갱신 (불량내용) */
    useEffect(() => {
        if (ds_DefectGroup.length != 0) {
            vds_DefectGroup = ds_DefectGroup;
        }

        if (ds_DefectSymptom2.length != 0) {
            vds_DefectSymptom2 = ds_DefectSymptom2;
        }

        params.set('pv_defect', ds_Defect);
        params.set('pv_vds_DefectGroup', vds_DefectGroup);
        params.set('pv_vds_DefectSymptom2', vds_DefectSymptom2);

        tabList.map(tab => {
            if (tab.name === 'qmsDefectiveContent') {
                tab.element = <QmsDefectiveContent state={{params: params, setDataset, fn_tempSaveFile}} nextTab={(name: string) => handleNextTab(name)}/>;
            }

            return tab;
        });
    }, [ds_Part, ds_Defect, ds_DefectGroup, ds_DefectSymptom2]);

    /* 탭정보 갱신 (판정결과) */
    useEffect(() => {
        if (ds_Part.length != 0) {
            vds_Part = ds_Part;
        }

        params.set('pv_data', vds_Part);
        params.set('pv_ds_upload', ds_upload);
        params.set('pv_ds_remove', ds_remove);
        params.set('pv_secMeasRslt', secMeasRslt);

        setTabList(tabList.map(tab => {
            if (tab.name === 'qmsJudgmentResult') {
                tab.element = <QmsJudgmentResult state={{params: params, setDataset, fn_tempSaveFile, fn_checkMeasCheck, fn_saveFile}} nextTab={(name: string) => handleNextTab(name)} reportRef={reportRef}/>;
            }

            return tab;
        }));
    }, [ds_Part, ds_upload, ds_remove, secMeasRslt]);

    /* 탭정보 갱신 (Q-Point) */
    useEffect(() => {
        params.set('pv_ds_qPointList', ds_qPointList);

        tabList.map(tab => {
            if (tab.name === 'qmsQpoint') {
                tab.element = <QmsQpoint state={{params: params, setDataset, fn_tempSaveFile, fn_saveFile}} nextTab={(name: string) => handleNextTab(name)}/>;
            }

            return tab;
        });
    }, [ds_qPointList, ds_Defect]);

    // 포커싱된 tab을 따라, tab 스크롤 이동
    const scrollRef = useRef<HTMLUListElement>(null);
    useEffect(() => {
        if(scrollRef?.current) {
            const scrollMove = tabList.findIndex(tab => tab.selected) || 0;
            scrollRef.current.scrollTo({left: 0 + (scrollRef.current.children[0].clientWidth * scrollMove), top: 0, behavior: 'smooth'});
        }
    }, [focusTab]);

    return (
        <>
            <div className={'container'}>
                <div className={'tabs-wrap'}>
                    <ul className={'tab-list'} ref={scrollRef}>
                        <li className={focusTab.name === 'qmsBasicInformation' ? 'on' : ''}><button type={'button'} onClick={() => selectTab('qmsBasicInformation')}>{getDomain('DOMAIN2068')} {/*기본정보*/}</button></li>
                        <li className={focusTab.name === 'qmsPartInspectionInformation' ? 'on' : ''}><button type={'button'} onClick={() => selectTab('qmsPartInspectionInformation')}>{getDomain('DOMAIN2605')} {/*부품검사정보*/}</button></li>
                        <li className={focusTab.name === 'qmsDetailInspectList' ? 'on' : ''}><button type={'button'} onClick={() => selectTab('qmsDetailInspectList')}>{getDomain('DOMAIN2608')} {/*세부검사정보*/}</button></li>
                        <li className={focusTab.name === 'qmsDefectiveContent' ? 'on' : ''}><button type={'button'} onClick={() => selectTab('qmsDefectiveContent')}>{getDomain('DOMAIN3295')} {/*불량내용*/}</button></li>
                        <li className={focusTab.name === 'qmsJudgmentResult' ? 'on' : ''}><button type={'button'} onClick={() => selectTab('qmsJudgmentResult')}>{getDomain('DOMAIN1914')} {/*판정결과*/}</button></li>
                        <li className={focusTab.name === 'qmsQpoint' ? 'on' : ''}><button type={'button'} onClick={() => selectTab('qmsQpoint')}>Q-Point</button></li>
                    </ul>
                </div>
                <CSSTransition in={showTab} classNames={focusTab.direction} timeout={500} unmountOnExit>{focusTab.element}</CSSTransition>
            </div>
        </>
    );
};