import React, {useState} from 'react';
import usePageMove from '../../hooks/usePageMove';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
import {useDataset} from '../../components/contexts/CommonContext';
import {CSSTransition} from 'react-transition-group';
import Setting from '../user/Setting';
import useGlobalData from '../../hooks/useGlobalData';
import {ROUTER_MENU_CODE} from '../../components/Routers';
import {useComponent} from '../../components/contexts/ComponentContext';

export default function Navigation({onClose}: {onClose: () => void}) {
    const {pageMove} = usePageMove();
    const {sendAxios} = useAxios();
    const {setSessionValues, setAuthMfa, setAuthSession, sessionValues, dataset, language} = useDataset();
    const {getMenuName,getDomain, getMessage} = useGlobalData();
    const {setDialog} = useComponent();

    const [showSetting, setShowSetting] = useState<boolean>(false);

    const handleLink = (linkPath: string) => {
        onClose();
        pageMove(linkPath, undefined);
    };

    const doLogout = () => {
        setDialog({
            show: true,
            type: 'CONFIRM',
            text: getMessage('MSG01560'),
            confirm: () => {
                sendAxios('/common/identity/logout.do', 'GET', AXIOS_HEADER.GET)
                .catch(e => {
                    pageMove(process.env.REACT_APP_URL_MAIN || '/');
                })
                .finally(() => {
                    onClose();
                    setAuthSession(false);
                    setAuthMfa(false);
                    setSessionValues();
                });
            }
        });
    };

    const doSetting = () => {
        setShowSetting(true);
    };

    const closeSetting = () => {
        onClose();
        setShowSetting(false);
    };

    return (
        <>
            <div className={'all-menu-wrap'} style={{display: 'block'}}>
                <div className={'inner'}>
                    <div className={'all-menu-top'}>
                        <div className={'left'}>
                            <button type={'button'} className={'btn-logout'} onClick={doLogout}><i className={'hidden'}>{getDomain('DOMAIN4690')}</i></button>
                            <span className={'name'}>
                                <strong>{sessionValues?.USER_NAME}</strong>
                                <em>{sessionValues?.SINGLE_ID}</em>
                            </span>
                        </div>
                        <div className={'right'}>
                            <button type={'button'} className={'btn-home'} onClick={() => handleLink(process.env.REACT_APP_URL_MAIN || '/')}><i className={'hidden'}>{getDomain('DOMAIN4689')}</i></button>
                            <button type={'button'} className={'btn-setting'} onClick={doSetting}><i className={'hidden'}>{getDomain('DOMAIN4691')}</i></button>
                            <button type={'button'} className={'btn-close'} onClick={onClose}><i className={'hidden'}>메뉴닫기</i></button>
                        </div>
                    </div>
                    <section className={'menu-wrap'}>
                        <p className={'title'}><strong>{getDomain('DOMAIN4688')} {/*전체*/}</strong></p>
                        <article>
                            <h2>{getMenuName(ROUTER_MENU_CODE.QMV)}</h2>
                            <ul className={'menu-list'}>
                                <li>
                                    <button type={'button'} style={{color: '#000000'}} onClick={() => handleLink(process.env.REACT_APP_URL_QMV_ADD || '')}>
                                        <i><img src={'/images/main-a-icon1.png'}/></i>{getMenuName(ROUTER_MENU_CODE.QMV_ADD)}
                                    </button>
                                </li>
                                <li>
                                    <button type={'button'} style={{color: '#000000'}} onClick={() => handleLink(process.env.REACT_APP_URL_QMV_SEARCH || '')}>
                                        <i><img src={'/images/main-a-icon2.png'}/></i>{getMenuName(ROUTER_MENU_CODE.QMV_SEARCH)}
                                    </button>
                                </li>
                            </ul>
                        </article>
                        {
                            2 <= (sessionValues?.WORK_GROUP_CD || 0) && 7 >= (sessionValues?.WORK_GROUP_CD || 0) ? <article>
                                <h2>{getMenuName(ROUTER_MENU_CODE.QMS)}</h2>
                                <ul className={'menu-list'}>
                                    <li>
                                        <button type={'button'} style={{color: '#000000'}} onClick={() => handleLink(process.env.REACT_APP_URL_QMS || '')}>
                                            <i><img src={'/images/main-b-icon1.png'}/></i>{getMenuName(ROUTER_MENU_CODE.QMS_M_INCM_INSP_LIST)}
                                        </button>
                                    </li>
                                    <li>
                                        <button type={'button'} style={{color: '#000000'}} onClick={() => handleLink(process.env.REACT_APP_URL_QMS_SEARCH || '')}>
                                            <i><img src={'/images/main-b-icon2.png'}/></i>{getMenuName(ROUTER_MENU_CODE.QMS_SEARCH)}
                                        </button>
                                    </li>
                                    { 7 == (sessionValues?.WORK_GROUP_CD || 0) ?
                                    <li>
                                        <button type={'button'} style={{color: '#000000'}} onClick={() => handleLink(process.env.REACT_APP_URL_QMS_TEST || '')}>
                                            <i><img src={'/images/main-b-icon2.png'}/></i>{getMenuName(ROUTER_MENU_CODE.QMS_TEST_SEARCH)}
                                        </button>
                                    </li>: null
                                    }
                                </ul>
                            </article>: null
                        }
                        <article>
                            <h2>{getMenuName(ROUTER_MENU_CODE.PSIS)}</h2>
                            <ul className={'menu-list'}>
                                <li>
                                    <button type={'button'} style={{color: '#000000'}} onClick={() => handleLink(process.env.REACT_APP_URL_PSIS_ADD || '')}>
                                        <i><img src={'/images/main-c-icon1.png'}/></i>{getMenuName(ROUTER_MENU_CODE.PSIS_INSP_TARGET_REG)}
                                    </button>
                                </li>
                                <li>
                                    <button type={'button'} style={{color: '#000000'}} onClick={() => handleLink(process.env.REACT_APP_URL_PSIS_INPUT || '')}>
                                        <i><img src={'/images/main-c-icon2.png'}/></i>{getMenuName(ROUTER_MENU_CODE.PSIS_INSP_RESULT_REG)}
                                    </button>
                                </li>
                                <li>
                                    <button type={'button'} style={{color: '#000000'}} onClick={() => handleLink(process.env.REACT_APP_URL_PSIS_INFO || '')}>
                                        <i><img src={'/images/main-c-icon3.png'}/></i>{getMenuName(ROUTER_MENU_CODE.PSIS_INSP_RESULT_LIST)}
                                    </button>
                                </li>
                            </ul>
                        </article>
                        {
                            'DEVELOPMENT' === process.env.REACT_APP_PROFILE ? <article>
                                <h2>Sample</h2>
                                <ul className={'menu-list'}>
                                    <li><button type={'button'} onClick={() => handleLink('/sample/test')}><i><img src={'/images/main-c-icon1.png'}/></i>Sample 테스트</button></li>
                                    <li><button type={'button'} onClick={() => handleLink('/sample/qmv/search')}><i><img src={'/images/main-c-icon1.png'}/></i>Sample 출하검사 성적서 조회</button></li>
                                    <li><button type={'button'} onClick={() => handleLink('/sample/jwt')}><i><img src={'/images/main-c-icon1.png'}/></i>Sample JWT</button></li>
                                </ul>
                            </article> : null
                        }
                    </section>
                </div>
            </div>
            <CSSTransition in={showSetting} classNames={showSetting ? 'right' : 'left'} timeout={500} unmountOnExit>
                <Setting onClose={closeSetting}/>
            </CSSTransition>
        </>
    );
};