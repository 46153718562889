import React, {forwardRef, useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {
    DataPickerResultType,
    DatePickerDefault,
    DatePickerOptionType,
    DatePickerType
} from '../components/model/SqciTypes';

export default function useDatePicker(type: DatePickerType, options?: DatePickerOptionType, defaultDateTime?: DatePickerDefault) {
    const [singleSelected, setSingleSelected] = useState<Date>(defaultDateTime && defaultDateTime.single ? moment(defaultDateTime.single.selected, 'YYYYMMDDHHmmss').toDate() : new Date());
    const [rangeSelected, setRangeSelected] = useState<[Date | null, Date | null]>([
        defaultDateTime && defaultDateTime.range ? moment(defaultDateTime.range.start, 'YYYYMMDDHHmmss').toDate() : new Date(),
        defaultDateTime && defaultDateTime.range ? moment(defaultDateTime.range.end, 'YYYYMMDDHHmmss').toDate() : new Date()
    ]);
    const [startDate, endDate] = rangeSelected;

    const [selectedPicker, setSelectedPicker] = useState<DataPickerResultType>();

    const CustomPicker = forwardRef(({value, onClick}: {value: any, onClick: (date: Date | null) => void}, ref) => (
        <input type={'text'}
            inputMode={'none'}
            defaultValue={value}
            onClick={() => onClick ? onClick(singleSelected) : undefined}
            style={{
                backgroundImage: 'url(/images/icon-calendar.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '3.8rem',
                backgroundPosition: 'right center'
            }}/>
    ));

    useEffect(() => {
        setSelectedPicker(type === 'RANGE' ? rangePicker() : singlePicker());
    }, [singleSelected, rangeSelected, startDate]);

    const singlePicker = (): Omit<DataPickerResultType, 'startDate' | 'endDate'> => {
        return {
            selectedDate: moment(singleSelected).format(options?.dataFormat || (options?.time?.show ? 'yyyyMMDDHHmmss' : 'yyyyMMDD')),
            datePicker: <DatePicker
                showIcon={false}
                fixedHeight={true}
                placeholderText={options?.placeholder || 'Click to select a date'}
                dateFormat={options?.displayFormat || (options?.time?.show ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd')}
                timeCaption={'Time'}
                timeFormat={'HH:mm'}
                showTimeSelect={options?.time?.show === undefined ? false : options.time.show}
                timeIntervals={options?.time?.interval || 10}
                customInput={<CustomPicker value={''} onClick={(date) => setSingleSelected(date || new Date())}/>}

                selected={singleSelected}
                onChange={date => setSingleSelected(date || new Date())}

                // 20230920 임성준: year, month select box 추가. CSS 반영 후 기능 추가 예정
                // peekNextMonth
                // showMonthDropdown
                // showYearDropdown
                // dropdownMode={'select'}
            />
        }
    };

    const rangePicker = (): Omit<DataPickerResultType, 'selectedDate'> => {
        return {
            startDate: moment(startDate).format(options?.dataFormat || 'yyyyMMDD'),
            endDate: moment(endDate).format(options?.dataFormat || 'yyyyMMDD'),
            datePicker: <DatePicker
                showIcon={false}
                fixedHeight={true}
                placeholderText={options?.placeholder || 'Click to select a date'}
                dateFormat={options?.displayFormat || (options?.time?.show ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd')}
                customInput={<CustomPicker value={''} onClick={(date) => setRangeSelected([startDate, endDate])}/>}

                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={date => setRangeSelected(date)}
            />
        }
    };

    return selectedPicker;
}