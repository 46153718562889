import React, {useEffect, useState} from 'react';
import {
    CardViewType,
    ComponentHistoryType,
    NexacroJsonType,
    NexacroJsonUnitType
} from '../../components/model/SqciTypes';
import usePageMove from "../../hooks/usePageMove";
import {useDataset} from "../../components/contexts/CommonContext";
import {useError} from "../../hooks/useException";
import useAxios, {AXIOS_HEADER} from "../../hooks/useAxios";
import {useLocation, useOutletContext} from "react-router-dom";
import {useComponent} from "../../components/contexts/ComponentContext";
import {STORAGE_NAME} from "../../components/CommonConstants";
import useGlobalData from "../../hooks/useGlobalData";
import InfiniteCardView from "../common/InfiniteCardView";
import * as gfn from "../../components/utils/CommonUtils";
import {v4 as uuidv4} from "uuid";

export default function QmvList() {
    const {pageMove} = usePageMove();
    const {dataset, sessionValues} = useDataset();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const sLocale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);

    const [ds_List, setDsList] = useState(new Array<any>());
    const [ds_search, setDsSearch] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Res, setDsRes] = useState(new Array<NexacroJsonUnitType>());
    const [ds_chngPoingHListEcn, setDsChngPoingHListEcn] = useState(new Array<NexacroJsonUnitType>());
    const [ds_SpecApproveStatus, setDsSpecApproveStatus] = useState(new Array<NexacroJsonUnitType>());
    const [ds_division, setDsDivision] = useState(new Array<NexacroJsonUnitType>());
    const [infiniteScroll, setInfiniteScroll]= useState<CardViewType[]>([]);

    const [cardData, setCardData] = useState(new Array<any>());
    const [selectedCardDataIdx, setSelectedCardDataIdx] = useState(-1);
    const {sendAxios} = useAxios();
    const {throwException} = useError();
    const [link, setLink] = useState();
    const {dialog, setDialog} = useComponent();
    let fv_userId = sessionValues?.USER_ID;
    let temp_ds_SpecApproveStatus = new Array<NexacroJsonUnitType>();
    let data: CardViewType[] = [];
    let tempDsChngPoingHList: NexacroJsonUnitType[] = [];

    const location = useLocation();
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
    const [isComponentLoaded, setIsComponentLoaded] = useState<boolean>(false);

    useEffect(()=> {
        if((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            setIsComponentLoaded(true);
        }
    },[enteredComponent, exitedComponent]);

    function fn_gridRowDel(row:number) {
        let bDel = true;
        //삭제 대상 여부 확인
        let status = ds_List[row].STATUS;
        let sInvtYn = ds_List[row].INVT_YN;
        if(status=='Y' || sInvtYn=='Y' ||status=='P'){
            bDel = false;
        }
        if(!bDel){
            setDialog({
                type: 'ALERT',
                show: true,
                text: getDomain('DOMAIN2965'), //선행검사 및 Invoice와 연계된 성적서는 삭제할 수 없습니다.
                confirm:() => {
                },
            });
            return;
        }
    }

    async function fn_delete(row:number) {
        const requestJson: NexacroJsonType = {
            ds_List: [{
                PLANT_CD : ds_List[row]['PLANT_CD'],
                INSP_GB : ds_List[row]['INSP_GB'],
                INSP_USER : ds_List[row]['INSP_USER'],
                PART_NM : ds_List[row]['PART_NM'],
                SEC_ANL_ERR_MSG : ds_List[row]['SEC_ANL_ERR_MSG'],
                CHK : 1,
                DIVISION_NM : ds_List[row]['DIVISION_NM'],
                NEW_PART_TYPE : ds_List[row]['NEW_PART_TYPE'],
                HQ_CD : ds_List[row]['HQ_CD'],
                NEW_PART_TYPE_NM : ds_List[row]['NEW_PART_TYPE_NM'],
                LOT_SIZE : ds_List[row]['LOT_SIZE'],
                STATUS : ds_List[row]['STATUS'],
                INVT_QTY : ds_List[row]['INVT_QTY'],
                SEC_ANL_RESULT : ds_List[row]['SEC_ANL_RESULT'],
                REINSP_NUM : ds_List[row]['REINSP_NUM'],
                INVT_YN : ds_List[row]['INVT_YN'],
                MOD_PART_MEMO : ds_List[row]['MOD_PART_MEMO'],
                LOT_ID : ds_List[row]['LOT_ID'],
                CATEGORY2 : ds_List[row]['CATEGORY2'],
                SEC_ANL_RESULT_NM : ds_List[row]['SEC_ANL_RESULT_NM'],
                ORG_LOT_SIZE : ds_List[row]['ORG_LOT_SIZE'],
                LOT_STATUS : ds_List[row]['LOT_STATUS'],
                STATUS_NM : ds_List[row]['STATUS_NM'],
                PROD_DT : ds_List[row]['PROD_DT'],
                RISK_LOT_TYPE_NM : ds_List[row]['RISK_LOT_TYPE_NM'],
                PART_CD : ds_List[row]['PART_CD'],
                VENDOR_CD : ds_List[row]['VENDOR_CD'],
                PLANT_NM : ds_List[row]['PLANT_NM'],
                UPDATE_BY : fv_userId,
                HQ_NM : ds_List[row]['HQ_NM'],
                INSP_USER_NM : ds_List[row]['INSP_USER_NM'],
                DIVISION_CD : ds_List[row]['DIVISION_CD'],
                CATEGORY2_NM : ds_List[row]['CATEGORY2_NM'],
                SPEC_REV : ds_List[row]['SPEC_REV'],
                SAVE_GB : ds_List[row]['SAVE_GB'],
                LOT_RESULT : ds_List[row]['LOT_RESULT'],
                LOT_RESULT_NM : ds_List[row]['LOT_RESULT_NM'],
                RISK_LOT_TYPE : ds_List[row]['RISK_LOT_TYPE'],
                DataSetRowType : ds_List[row]['DataSetRowType'],
                INSERT_DT : ds_List[row]['INSERT_DT'],
                ROWNUM1 : ds_List[row]['ROWNUM1'],
                LAST_SPEC_REV : ds_List[row]['LAST_SPEC_REV'],
                LOT_NO : ds_List[row]['LOT_NO'],
                SMARTQ_YN : ds_List[row]['SMARTQ_YN']
            }]
        }
        sendAxios('/common/nexacro/QMV_M_OUT_InspectListDataDelete.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
            .then(response => {
                if(response?.data.ds_Res[0].CODE == 'SUCESS'){
                    // fn_search();
                    return;
                }
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
    }

    async function fn_openPopupPage(row: number = -1){
        let linkPath = process.env.REACT_APP_URL_QMV_INFO;
        if(row == -1){
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage('MSG00934'), // 선택된 출하성적서가 없습니다.
            });
            return;
        }

        //현재 자재 스펙 승인상태 체크
        if (!fn_checkSpecApproveStatus(row)) {
            return;
        }
        //현재 자재 버전 체크
        //임시저장인데 스펙이 다르면 삭제처리
        if (ds_List[row].SAVE_GB == "T"){
            let result = parseInt(ds_List[row].SPEC_REV) == parseInt(ds_List[row].LAST_SPEC_REV)? true:false;
            if(!result) {
                setDialog({
                    type: 'CONFIRM',
                    show: true,
                    text: getMessage('MSG01123'), //최신 SPEC 버전이 아닙니다. 새로 출하성적서를 작성하시기 바랍니다. 확인시 임시저장 되어있는 성적서는 삭제됩니다. 삭제하시겠습니까?
                    confirm: () => {
                        fn_delete(row);
                    },
                    cancel: () => {
                        return;
                    }
                });
            }else{
                //출하검사 성적서 팝업 창 열기
                if(ds_List[row]["LOT_STATUS"] == "T"){
                    //임시저장 상태인 경우 => QmvList로 이동 (출하검사 성적서 수정)
                    // linkPath = process.env.REACT_APP_URL_QMV_INFO;
                    await fn_searchEcn(row).then((response) => {
                        setDsChngPoingHListEcn(response?.data.ds_chngPoingHList);
                        tempDsChngPoingHList = response?.data.ds_chngPoingHList;
                    })
                }
                let param = new Map();
                param.set('pv_flag','UPD');
                param.set('pv_menuId01', 'QMV_M_Out_InspectList');
                param.set('pv_lotId', ds_List[row]['LOT_ID']);
                param.set('pv_reinspNum', ds_List[row]['REINSP_NUM']);
                param.set('pv_newPartType', ds_List[row]['NEW_PART_TYPE']);
                param.set('pv_smartqYn', ds_List[row]['SMARTQ_YN']);
                param.set('pv_ecnList', tempDsChngPoingHList);
                param.set('pv_status_N','INFO');

                pageMove(linkPath || '',{
                    PARAM: param
                });
            }
        }else {
            //임시저장 상태가 아닌 경우 DETAIL로 이동
            linkPath = process.env.REACT_APP_URL_QMV_DETAIL;
            let param = new Map();
            param.set('pv_flag','UPD');
            param.set('pv_menuId01', 'QMV_M_Out_InspectList');
            param.set('pv_lotId', ds_List[row]['LOT_ID']);
            param.set('pv_reinspNum', ds_List[row]['REINSP_NUM']);
            param.set('pv_newPartType', ds_List[row]['NEW_PART_TYPE']);
            param.set('pv_smartqYn', ds_List[row]['SMARTQ_YN']);
            param.set('pv_ecnList', ds_chngPoingHListEcn);
            param.set('pv_status_N','DETAIL');

            pageMove(linkPath || '',{
                PARAM: param
            });
        }
    };

    function fn_searchSpecApproveStatus(){
        const requestJson: NexacroJsonType = {
            ds_Part: [{
                VENDOR_CD: ds_List[0]["HQ_CD"],
                PART_CD: ds_List[0]["PART_CD"]
            }]
        }
        return sendAxios('/common/nexacro/selectSpecApproveStatus.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson);
    };

    //임시저장인데 스펙 승인 처리건
    async function fn_checkSpecApproveStatus(row:number){
        if (ds_List[0].SAVE_GB == "T" ){
            //SPEC 승인중인지 체크
            await fn_searchSpecApproveStatus().then((response) => {
                setDsSpecApproveStatus(response?.data.ds_SpecApproveStatus);
                temp_ds_SpecApproveStatus = response?.data.ds_SpecApproveStatus;
            });

            //승인중이라면 삭제 처리
            let temp = temp_ds_SpecApproveStatus.filter(result => (result['LAST_REV_YN'] == 'Y' &&
                (result['APPROVE_STATUS'] == 1 || result['APPROVE_STATUS'] == 4 || result['APPROVE_STATUS'] == 5))).length;
            if(temp != 1) {
                setDialog({
                    type: 'CONFIRM',
                    show: true,
                    text: getMessage('MSG01131'), //해당 자재는 SPEC 승인 요청중입니다.
                    confirm: () => {
                        fn_delete(row);
                        return false;
                    }
                });
            }
        }
        return true;
    };

    // 임시저장인데 스펙이 다르면 삭제처리
    // function fn_checkTempLastSpec(row:number){
    //     if (ds_List[0].SAVE_GB == "T"){
    //         if(parseInt(ds_List[0].SPEC_REV) != parseInt(ds_List[0].LAST_SPEC_REV)) {
    //             setDialog({
    //                 type: 'CONFIRM',
    //                 show: true,
    //                 text: getMessage('MSG01123'), //최신 SPEC 버전이 아닙니다. 새로 출하성적서를 작성하시기 바랍니다. 확인시 임시저장 되어있는 성적서는 삭제됩니다. 삭제하시겠습니까?
    //                 confirm: () => {
    //                     fn_delete(row);
    //                 }
    //             });
    //         }
    //     }
    //     return true;
    // };

    /* 날짜 계산 */
    function getToday(d: number = 0, m: number = 0, y: number = 0){
        let date = new Date();

        date.setFullYear(date.getFullYear() + y);
        date.setMonth(date.getMonth() + m);
        date.setDate(date.getDate() + d);

        let year = date.getFullYear();
        let month = ("0" + (1 + date.getMonth())).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);

        return year + month + day;
    };

    /**
     * @description ECN 정보 조회
     */
    function fn_searchEcn(row:number) {
        const requestJson: NexacroJsonType = {
            ds_division: [{
                DIVISION_CD: ds_List[row]["DIVISION_CD"]
            }],
            ds_plant: [{
                PLANT_CD: ds_List[row]["PLANT_CD"]
            }],
            ds_search: [{
                TYPE: '01',
                FROM_DATE : getToday(0,-24,0), //12.06 JKA ECN 적용일 24개월 이전부터 조회
                TO_DATE: getToday(0,1,0), //07.18 JKA ECN 적용일 1개월 이후까지 조회
                VENDOR_CD: ds_List[row]['HQ_CD'],
                MATERIAL_CD: ds_List[row]['PART_CD'],
                LANG: sLocale,
                RECORDS: 100,
                RECORDSOFFSET: '0',
                SEARCH_TYPE: '1'
            }]
        };

        return sendAxios('/common/nexacro/selectTotalStatusList.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson);
    };

    const getData = async (start: number, end: number) => {
        let temp_ds_Search: NexacroJsonUnitType[] = location.state?.requestJson.ds_Search;
        temp_ds_Search[0]['RECORDS'] = String(end);
        temp_ds_Search[0]['RECORDSOFFSET'] = String(start-1);

        if(gfn.gfn_isNull(temp_ds_Search)){
            setDialog({
                type: 'ALERT',
                show: true,
                text: "올바르지 않은 경로입니다.",
                confirm: () => {
                    pageMove(-1);
                }
            });
            // pageMove(-1);
        }

        // return infiniteScroll.slice(start, end);
        return await sendAxios('/common/nexacro/QMV_M_OUT_InspectListDataSelect.do', 'POST', AXIOS_HEADER.NEXACRO, location.state.requestJson).then(response => {
            let temp_ds_List:NexacroJsonUnitType[] = response?.data.ds_List||[];
            if(temp_ds_List.length == 0 && start === 1){
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage('MSG00930'), //검색결과가 없습니다.
                    confirm: () => {
                        pageMove(-1);
                    }
                });
                // pageMove(process.env.REACT_APP_URL_QMV_SEARCH || '');
                return [];
            }

            let copyArray = [...ds_List];
            setDsList([
                ...copyArray || [],
                ...response?.data.ds_List||[]
            ]);

            let cardL:CardViewType[] = (temp_ds_List||[]).map((item:NexacroJsonUnitType, index:number) => {
                let status = item.STATUS;
                let statusStyle = '';
                let newPartType = item.NEW_PART_TYPE;
                let newPartTypeStyle = '';
                let lotResult = item.LOT_RESULT;
                let lotResultStyle = '';
                let closeBtn = status=='T'? true:false;
                let lotResultNm = item.LOT_RESULT_NM? String(item.LOT_RESULT_NM): '';

                if(status=='T'){ //임시저장 상태
                    //status = '임시저장';
                    statusStyle = 'label-type1';
                    if(gfn.gfn_isNull(lotResult)){
                        lotResultStyle = '';
                    }
                }else{ //제출완료,미제출,미완료,제출불가
                    //status = '완료';
                    statusStyle = 'label-type4';
                    if(lotResult == 'Y'){ //합격
                        lotResultStyle = 'label-type2';
                    }else if(lotResult == 'N'){ //불합격
                        lotResultStyle = 'label-type7';
                    }
                }

                if(newPartType == 'O'){ //양산자재
                    newPartTypeStyle = 'label-type3';
                }else if(newPartType == 'C'){ //변경초물
                    newPartTypeStyle = 'label-type5';
                }else if(newPartType == 'N'){ //신규초물
                    newPartTypeStyle = 'label-type6';
                }
                let tempLotSize = Number(item.ORG_LOT_SIZE).toLocaleString('ko-KR');

                let cardD:CardViewType = {
                    id: uuidv4(),
                    label: [{
                        name: String(item.STATUS_NM), //STATUS
                        style: statusStyle
                    }, {
                        name: String(item.NEW_PART_TYPE_NM), //PART TYPE
                        style: newPartTypeStyle
                    }, {
                        name: lotResultNm, // 검사결과
                        style: lotResultStyle
                    }],
                    title: {
                        category:  '[' + item.PLANT_CD + ']' + ' ['+ item.VENDOR_CD +']'+' [' + item.PART_CD +']',
                        name: String(item.PART_NM)
                    },
                    state: [
                        {
                            left: getDomain('DOMAIN2027')+' : ' + item.LOT_NO,
                            right: String(item.PROD_DT)
                        },
                        {
                            left: getDomain('DOMAIN2028')+' : ' + tempLotSize,
                            right: String(item.INSP_USER_NM || '')
                        }
                    ],
                    // useSelect: true
                    useClose: closeBtn
                }
                return cardD;
            });
            setInfiniteScroll([
                ...infiniteScroll || [],
                ...cardL
            ]);
            return cardL;
        });
    };

    const selectedCard = (card?: CardViewType[]) => {
        let id = card && card.length > 0 ? card[0].id : '';
        // setSelectedCardDataIdx(Number(card.id));
        if(id !== '') {
            setSelectedCardDataIdx(Number(infiniteScroll.findIndex((item, index) => item.id === id)));
        }
    };

    // 임시저장 상태에서만 출하검사 성적서 삭제 가능
    const closedCard = async (card?: CardViewType): Promise<CardViewType | undefined> => {
        let id = card ? card.id : '';
        fn_gridRowDel(Number(infiniteScroll.findIndex((item, index) => item.id === id)));
        return await fn_delete(Number(infiniteScroll.findIndex((item, index) => item.id === id))).then(() => card);
    };

    const btnEvent = () => {
        if(selectedCardDataIdx == -1){
            return <button type={'button'} className={'button-primary'} onClick={() => fn_openPopupPage(selectedCardDataIdx)} disabled>{getDomain('DOMAIN4600')}</button>
        }else{
            return <button type={'button'} className={'button-primary'} onClick={() => fn_openPopupPage(selectedCardDataIdx)}>{getDomain('DOMAIN4600')}</button>
        }
    }

    return (
        <>
            <div className={'container'}>
                {
                    isComponentLoaded ? <InfiniteCardView
                        listCount={10}
                        getData={(start: number, end: number) => getData(start, end)}
                        multiSelection={false}
                        onSelect={selectedCard}
                        onClose={closedCard}
                    /> : null
                }
                <div className={'btn-area bottom'}>
                    {btnEvent()}
                </div>
            </div>
        </>
    );
};