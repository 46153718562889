import React from 'react';

export default function SampleDefectiveContent({state, nextTab}: {state: any, nextTab: (name: string) => void}) {
    return (
        <>
            <div className={'contents-table-wrap'} style={{display: 'block'}}>
                <h2 className={'h2-title'}>1. PCS 가로치수</h2>
                <ul className={'contents-table-list form'}>
                    <li>
                        <div className={'title'}>불량수</div>
                        <div className={'item-wrap'}>1</div>
                    </li>
                    <li>
                        <div className={'title'}>
                            <span>
                                <strong className={'required'}>*</strong> 불량그룹
                            </span>
                        </div>
                        <div className={'item-wrap'}>
                            <select>
                                <option value="">외관</option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <div className={'title'}>
                            <span>
                                <strong className={'required'}>*</strong> 불량증상
                            </span>
                        </div>
                        <div className={'item-wrap'}>
                            <select>
                                <option value="">Burn Higher</option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <div className={'title'}>
                            <span>
                                <strong className={'required'}>*</strong> 상세불량
                            </span>
                        </div>
                        <div className={'item-wrap'}>
                            <textarea></textarea>
                        </div>
                    </li>
                    <li>
                        <div className={'title'}>
                            <span>비고</span>
                        </div>
                        <div className={'item-wrap'}>
                            <input type={'text'}/>
                        </div>
                    </li>
                </ul>
                <h2 className={'h2-title'}>2. 복합 PCT</h2>
                <ul className={'contents-table-list form'}>
                    <li>
                        <div className={'title'}>불량수</div>
                        <div className={'item-wrap'}>5</div>
                    </li>
                    <li>
                        <div className={'title'}>
                            <span>
                                <strong className={'required'}>*</strong> 불량그룹
                            </span>
                        </div>
                        <div className={'item-wrap'}>
                            <select>
                                <option value="">외관</option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <div className={'title'}>
                            <span>
                                <strong className={'required'}>*</strong> 불량증상
                            </span>
                        </div>
                        <div className={'item-wrap'}>
                            <select>
                                <option value="">Burn Higher</option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <div className={'title'}>
                            <span>
                                <strong className={'required'}>*</strong> 상세불량
                            </span>
                        </div>
                        <div className={'item-wrap'}>
                            <textarea></textarea>
                        </div>
                    </li>
                    <li>
                        <div className={'title'}>
                            <span>비고</span>
                        </div>
                        <div className={'item-wrap'}>
                            <input type={'text'}/>
                        </div>
                    </li>
                </ul>
            </div>
            <div className={'btn-area bottom'}>
                <button type={'button'} className={'button-line'}>임시저장</button>
                <button type={'button'} className={'button-primary'} onClick={() => nextTab('defectiveContent')}>다음</button>
            </div>
        </>
    );
};