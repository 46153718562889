import React from 'react';
import './assets/css/ui.css';
import './assets/css/react-slider.css';
import './assets/css/react-datepicker.css';
import ReactDOM from 'react-dom/client';
import {RouterProvider} from 'react-router-dom';
import {createRouter} from './components/Routers';
import {reportWebVitals} from './reportWebVitals';

export const sqciRouter = createRouter();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
.render(
    // <React.StrictMode>
        <RouterProvider
            router={sqciRouter}
            fallbackElement={<div>...Loading</div>}
        />
    // </React.StrictMode>
);

reportWebVitals();