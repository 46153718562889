import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {ScaleLoader} from 'react-spinners';
import CommonProvider from './CommonContext';
import {DialogDataType, NexacroJsonType} from '../model/SqciTypes';
import useGlobalData from '../../hooks/useGlobalData';
import useStorage from '../../hooks/useStorage';
import {STORAGE_NAME} from '../CommonConstants';

interface ComponentContextType {
    loader: boolean;
    setLoader: (loader: boolean) => void;
    dialog?: DialogDataType;
    setDialog: (dialog: DialogDataType) => void;
}

const ComponentContext = createContext<ComponentContextType>({
    loader: false,
    setLoader: (loader: boolean) => {},
    dialog: undefined,
    setDialog: (dialog: DialogDataType) => {}
});

const ComponentProvider = ({children}: any) => {
    const {getStorage} = useStorage();
    const {getGlobalSetting} = useGlobalData();

    const [loader, changeLoader] = useState(false);
    const [dialog, changeDialog] = useState<DialogDataType>();

    const setLoader = useCallback((item?: boolean) => changeLoader(item || false), [loader]);
    const setDialog = useCallback((item: DialogDataType) => changeDialog(item), [dialog]);

    const [buttonMessage, setButtonMessage] = useState<{
        confirmButton: string,
        cancelButton: string
    }>({
        confirmButton: 'Confirm',
        cancelButton: 'Cancel'
    });

    useEffect(() => {
        if(dialog) {
            const tempDomain = getStorage<NexacroJsonType>('SESSION', STORAGE_NAME.DATASET);
            if(tempDomain) {
                if(tempDomain['gds_domain'] && Object.entries(tempDomain['gds_domain']).length > 0) {
                    const langCode = 'VN' === getGlobalSetting<string>(STORAGE_NAME.LANGUAGE) ? 'VI' : getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
                    setButtonMessage({
                        confirmButton: String(dialog.confirmText || tempDomain['gds_domain']
                        .filter(domain => 'DOMAIN0385' === domain.DOMAIN_ID)
                        .map(domain => domain['DOMAIN_NM_'.concat(langCode || 'EN')]) || 'Confirm'),
                        cancelButton: String(dialog.cancelText || tempDomain['gds_domain']
                        .filter(domain => 'DOMAIN2081' === domain.DOMAIN_ID)
                        .map(domain => domain['DOMAIN_NM_'.concat(langCode || 'EN')]) || 'Cancel')
                    });
                }
            }
        }
    }, [dialog]);

    return (
        <ComponentContext.Provider value={{loader, setLoader, dialog, setDialog}}>
            <div className={'pop-layer'} style={{display: loader || dialog?.show ? 'block' : 'none', zIndex: 99999}}>
                <ScaleLoader
                    loading={loader}
                    height={50}
                    width={10}
                    color={'#3478F5'}
                    cssOverride={{
                        display: 'block',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}/>
                <div className={'popup-wrap dialog'} style={{display: dialog?.show ? 'block' : 'none'}}>
                    <div className={'pop-conts'}>
                        <p style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{__html: dialog?.text || ''}}></p>
                    </div>
                    <div className={'btn-area popup'}>
                        {
                            dialog?.type === 'CONFIRM' ? <button type={'button'} className={'button-line'} style={{height: '3.6rem', fontSize: '1.6rem'}} onClick={() => {
                                changeDialog({show: false});
                                if(dialog?.cancel) {
                                    dialog?.cancel();
                                }
                            }}>{buttonMessage?.cancelButton}</button> : null
                        }
                        <button type={'button'} className={'button-primary'} style={{height: '3.6rem', fontSize: '1.6rem'}} onClick={() => {
                            changeDialog({show: false});
                            if(dialog?.confirm) {
                                dialog?.confirm();
                            }
                        }}>{buttonMessage?.confirmButton}</button>
                    </div>
                </div>
            </div>
            <CommonProvider/>
        </ComponentContext.Provider>
    );
};

export const useComponent = () => useContext(ComponentContext);

export default ComponentProvider;