import React, {useEffect} from 'react';
import {NexacroJsonUnitType} from "../../../../components/model/SqciTypes";
import * as gfn from "../../../../components/utils/CommonUtils";
import {useDataset} from "../../../../components/contexts/CommonContext";
import useGlobalData from "../../../../hooks/useGlobalData";

export default function BasicInformation(
    {state, ds_Part} :
    {
        state: any,
        ds_Part: NexacroJsonUnitType[]
    }
) {
    const {dataset} = useDataset();
    const {getDomain, getMessage} = useGlobalData();

    const servertime = dataset?.ds_Output[0]["SERVERTIME"]||'';

    return (
        <>
            <div className={'contents-table-wrap width-type2'} style={{display: 'block'}}>
                {
                    ds_Part.filter((item, index) => index === 0).map((item, index) => {
                        return (
                            <ul className={'contents-table-list'} key={index}>
                                <li>
                                    <div className={'title'}>{getDomain('DOMAIN0222')}</div>  {/*사업부*/}
                                    <div className={'item-wrap ellipsis1'}>{item["DIVISION_NM"]}</div>
                                </li>
                                <li>
                                    <div className={'title'}>{getDomain('DOMAIN2426')}</div>  {/*Plant*/}
                                    <div className={'item-wrap ellipsis1'}>{item["PLANT_NM"]}</div>
                                </li>
                                <li>
                                    <div className={'title'}>{getDomain('DOMAIN0372')}</div>  {/*협력사*/}
                                    <div className={'item-wrap ellipsis1'}>{item["VENDOR_NM"]}</div>
                                </li>
                                <li>
                                    <div className={'title'} dangerouslySetInnerHTML={{__html:getDomain('DOMAIN4596')}}></div>  {/*부품명*/}
                                    <div className={'item-wrap ellipsis2'}>{item["PART_NM"]}</div>
                                </li>
                                <li>
                                    <div className={'title'}>{getDomain('DOMAIN4604')}</div>  {/*Lot No.*/}
                                    <div className={'item-wrap ellipsis1'}>{item["LOT_NO"]}</div>
                                </li>
                                <li>
                                    <div className={'title'}>{getDomain('DOMAIN4609')}</div>  {/*Lot Size*/}
                                    <div className={'item-wrap ellipsis1'}>{gfn.fn_comma(item["ORG_LOT_SIZE"])}</div>
                                </li>
                                <li>
                                    <div className={'title'}>{getDomain('DOMAIN4610')}</div>  {/*생산일*/}
                                    <div className={'item-wrap ellipsis1'}>{item["PROD_DT"]}</div>
                                </li>
                                <li>
                                    <div className={'title'}>{getDomain('DOMAIN2421')}</div>  {/*검사원*/}
                                    <div className={'item-wrap ellipsis1'}>{item["INSP_USER_NM"]}</div>
                                </li>
                                <li>
                                    <div className={'title'}>{getDomain('DOMAIN4612')}</div>  {/*작성일*/}
                                    <div className={'item-wrap ellipsis1'}>
                                        {
                                            gfn.gfn_isNull(item["INSERT_DT"])
                                                ?
                                                gfn.gfn_n_today_yyyy_mm_dd(servertime)
                                                :
                                                item["INSERT_DT"]
                                        }
                                    </div>
                                </li>
                            </ul>
                        )
                    })
                }
            </div>
        </>
    );
};