import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    ComponentHistoryType,
    FavoritesType,
    NexacroJsonType,
    NexacroJsonUnitType,
    SearchInfoType,
    SearchUiType
} from '../components/model/SqciTypes';
import {useDataset} from '../components/contexts/CommonContext';
import {useComponent} from '../components/contexts/ComponentContext';
import {CSSTransition} from 'react-transition-group';
import {v4 as uuidv4} from 'uuid';
import useAxios, {AXIOS_HEADER} from './useAxios';
import {useError} from './useException';
import {STORAGE_NAME} from '../components/CommonConstants';
import useGlobalData from './useGlobalData';
import {useLocation, useOutletContext} from 'react-router-dom';
import Scanner from '../pages/common/Scanner';

export const SEARCH_TYPE = {
    DIVISION: {
        title: 'DOMAIN0222',    // 사업부
        placeholder: 'MSG01534',
        props: {
            target: 'ds_divList',
            display: {
                name: 'DIVISION_NM',
                value: 'DIVISION_CD',
                strong: ''
            }
        },
        favorites: {
            type: 'S0001',
            column: 'DIVISION_CD'
        }
    } as Omit<SearchInfoType, 'show' | 'type' | 'searchText' | 'afterSearch' | 'onSelect' | 'onManualSearch'>,
    PLANT: {
        title: 'DOMAIN2426',        // Plant
        placeholder: 'MSG01535',
        props: {
            target: 'ds_plantList',
            display: {
                name: 'PLANT_PROD_NM',
                value: 'PLANT_CD',
                strong: 'PLANT_CD'
            }
        },
        favorites: {
            type: 'S0002',
            column: 'PLANT_CD'
        }
    } as Omit<SearchInfoType, 'show' | 'type' | 'searchText' | 'afterSearch' | 'onSelect' | 'onManualSearch'>,
    VENDOR: {
        title: 'DOMAIN0372',        // 협력사
        placeholder: 'MSG01536',
        props: {
            target: '',
            display: {
                name: 'HQ_NM',
                value: 'HQ_CD',
                strong: 'HQ_CD'
            }
        },
        favorites: {
            type: 'S0003',
            column: 'HQ_CD'
        }
    } as Omit<SearchInfoType, 'show' | 'type' | 'searchText' | 'afterSearch' | 'onSelect' | 'onManualSearch'>,
    PART: {
        title: 'DOMAIN4441',        // 부품코드
        placeholder: 'MSG01537',
        permitEmpty: true,
        props: {
            target: '',
            display: {
                name: 'PART_NM',
                value: 'PART_CD',
                strong: 'PART_CD'
            }
        },
        favorites: {
            type: 'S0004',
            column: 'PART_CD'
        }
    } as Omit<SearchInfoType, 'show' | 'type' | 'searchText' | 'afterSearch' | 'onSelect' | 'onManualSearch'>,
    SECOND_VENDOR: {
        title: 'DOMAIN4449',        // 2차협력사
        props: {
            target: '',
            display: {
                name: 'HQ_CD_2ND_EXPR',
                value: 'HQ_CD_2ND',
                strong: 'HQ_CD_2ND'
            }
        },
        favorites: {
            type: 'S0005',
            column: 'HQ_CD_2ND'
        }
    } as Omit<SearchInfoType, 'show' | 'type' | 'searchText' | 'afterSearch' | 'onSelect' | 'onManualSearch'>,
    LINE: {
        title: 'DOMAIN3926',        // Line
        placeholder: 'MSG01557',
        props: {
            target: '',
            display: {
                name: 'LINE_NM',
                value: 'LINE_CD',
                strong: 'LINE_CD'
            }
        },
        favorites: {
            type: 'S0006',
            column: 'HQ_CD_2ND'
        }
    } as Omit<SearchInfoType, 'show' | 'type' | 'searchText' | 'afterSearch' | 'onSelect' | 'onManualSearch'>,
    PSIS_PART: {
        title: 'DOMAIN4441',        // 부품코드
        placeholder: 'MSG01558',
        props: {
            target: '',
            display: {
                name: 'PART_NM',
                value: 'PART_CD',
                strong: 'PART_CD'
            }
        },
        favorites: {
            type: 'S0007',
            column: 'PART_CD'
        }
    } as Omit<SearchInfoType, 'show' | 'type' | 'searchText' | 'afterSearch' | 'onSelect' | 'onManualSearch'>
};

export const useSearch = () => {
    const {dataset, sessionValues} = useDataset();
    const {setDialog} = useComponent();
    const {sendAxios} = useAxios();
    const {throwException} = useError();
    const {getGlobalSetting, getSdpPartList, getDomain, getMessage} = useGlobalData();

    const [search, setSearch] = useState<SearchInfoType>();

    const [show, setShow] = useState<boolean>(false);
    const [originList, setOriginList] = useState<NexacroJsonUnitType[]>();
    const [searchList, setSearchList] = useState<NexacroJsonUnitType[]>();
    const [searchText, setSearchText] = useState<string>();
    const searchTextRef = useRef<HTMLInputElement>(null);

    const [afterShow, setAfterShow] = useState<boolean>(false);
    const [afterData, setAfterData] = useState<NexacroJsonUnitType[]>();

    const [favorites, setFavorites] = useState<FavoritesType[]>();

    const [partSearchType, setPartSearchType] = useState<string>('C');

    const [qrScannerPop, setQrScannerPop] = useState(false);

    const location = useLocation();
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
    useEffect(() => {
        if((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            doSelectFavorites();
        }
    }, [enteredComponent, exitedComponent]);

    useEffect(() => {
        if(favorites) {
            setSearchList(getFilterList());
        }
    }, [favorites]);

    const doSelectFavorites = () => {
        sendAxios('/common/nexacro/mobile/selectSearchFavorites.do', 'POST', AXIOS_HEADER.JSON, {
            USER_ID: sessionValues?.USER_ID
        })
        .then(response => {
            setFavorites(response?.data);
        })
        .catch(e => {
            throwException(e);
            return new Promise(() => {});
        });
    };

    const doSaveFavorites = (favorites: Omit<FavoritesType, 'FAV_CD' | 'USER_ID' | 'INSERT_BY' | 'UPDATE_BY' | 'CONT'>, item: NexacroJsonUnitType, vendorCd?: string) => {
        sendAxios('/common/nexacro/mobile/insertSearchFavorites.do', 'POST', AXIOS_HEADER.JSON, {
            ...favorites,
            FAV_CD: uuidv4(),
            USER_ID: sessionValues?.USER_ID,
            INSERT_BY: sessionValues?.USER_ID,
            UPDATE_BY: sessionValues?.USER_ID,
            // CONT: JSON.stringify(item || {})
            CONT: JSON.stringify({
                ...item,
                HQ_CD: vendorCd || ''
            } || {})
        })
        .then(response => {
            doSelectFavorites();
        })
        .catch(e => {
            throwException(e);
            return new Promise(() => {});
        });
    };

    const doDeleteFavorites = (favorites: Omit<FavoritesType, 'USER_ID' | 'INSERT_BY' | 'UPDATE_BY' | 'CONT'>) => {
        sendAxios('/common/nexacro/mobile/deleteSearchFavorites.do', 'POST', AXIOS_HEADER.JSON, {
            ...favorites,
            USER_ID: sessionValues?.USER_ID,
            INSERT_BY: sessionValues?.USER_ID,
            UPDATE_BY: sessionValues?.USER_ID
        })
        .then(response => {
            doSelectFavorites();
        })
        .catch(e => {
            throwException(e);
            return new Promise(() => {});
        });
    };

    const doSearchDivision = (type: SearchUiType, onSelect: (item?: {[K: string]: any}) => void, target?: NexacroJsonUnitType[], filter?: {[K: string]: any[]}[]) => {
        SEARCH_TYPE.DIVISION.props.target = target || 'ds_divList';
        SEARCH_TYPE.DIVISION.filter = filter;

        doSearch({
            show: true,
            ...SEARCH_TYPE.DIVISION,
            type,
            onSelect
        });
    };

    const doSearchPlant = (type: SearchUiType, onSelect: (item?: {[K: string]: any}) => void, target?: NexacroJsonUnitType[], filter?: {[K: string]: any[]}[], useAll?: boolean) => {
        SEARCH_TYPE.PLANT.useAll = true === useAll ? true : false;
        SEARCH_TYPE.PLANT.props.target = target || 'ds_plantList';
        SEARCH_TYPE.PLANT.filter = filter;

        doSearch({
            show: true,
            ...SEARCH_TYPE.PLANT,
            type,
            onSelect
        });
    };

    const doSearchVendor = (type: SearchUiType, target: NexacroJsonUnitType[], onSelect: (item?: {[K: string]: any}) => void, filter?: {[K: string]: any[]}[]) => {
        SEARCH_TYPE.VENDOR.props.target = target;

        if(filter && filter.length > 0) {
            SEARCH_TYPE.VENDOR.filter = filter;
        }

        doSearch({
            show: true,
            ...SEARCH_TYPE.VENDOR,
            type,
            onSelect
        });
    };

    const doSearchSecondVendor = (type: SearchUiType, target: NexacroJsonUnitType[], onSelect: (item?: {[K: string]: any}) => void, filter?: {[K: string]: any[]}[]) => {
        SEARCH_TYPE.SECOND_VENDOR.props.target = target;

        if(filter && filter.length > 0) {
            SEARCH_TYPE.SECOND_VENDOR.filter = filter;
        }

        doSearch({
            show: true,
            ...SEARCH_TYPE.SECOND_VENDOR,
            type,
            onSelect
        });
    };

    const doSearchLine = (type: SearchUiType, target: NexacroJsonUnitType[], onSelect: (item?: {[K: string]: any}) => void, filter?: {[K: string]: any[]}[]) => {
        SEARCH_TYPE.LINE.props.target = target;

        if(filter && filter.length > 0) {
            SEARCH_TYPE.LINE.filter = filter;
        }

        doSearch({
            show: true,
            ...SEARCH_TYPE.LINE,
            type,
            onSelect
        });
    };

    const doSearchPart = (type: SearchUiType, target: NexacroJsonUnitType[], onSelect: (item?: {[K: string]: any}) => void, afterSearch?: {
        onSelect: (item?: { [K: string]: any }) => void;
        params?: NexacroJsonType;
    }, filter?: {[K: string]: any[]}[], divisionCd?: string, plantCd?: string, vendorCd?: string) => {
        const partFavoritesList: NexacroJsonUnitType[] | undefined = favorites?.filter(item => item.TYPE === SEARCH_TYPE.PART.favorites?.type)
        .map(item => JSON.parse(item.CONT))
        .filter(item => divisionCd ? divisionCd == item.DIVISION_CD : item)
        .filter(item => plantCd ? plantCd == item.PLANT_CD : item)
        .filter(item => vendorCd ? vendorCd == item.HQ_CD : item);
        SEARCH_TYPE.PART.props.target = target && target.length > 0 ? target : partFavoritesList ? partFavoritesList : [];

        if(filter && filter.length > 0) {
            SEARCH_TYPE.PART.filter = filter;
        }

        doSearch({
            show: true,
            ...SEARCH_TYPE.PART,
            type,
            onSelect,
            afterSearch: afterSearch,
            divisionCd: divisionCd,
            plantCd: plantCd,
            vendorCd: vendorCd
        });
    };

    const doSearchPsisPart = (type: SearchUiType, target: NexacroJsonUnitType[], onSelect: (item?: {[K: string]: any}) => void, afterSearch?: {
        onSelect: (item?: { [K: string]: any }) => void
    }, filter?: {[K: string]: any[]}[]) => {
        SEARCH_TYPE.PSIS_PART.props.target = target;

        if(filter && filter.length > 0) {
            SEARCH_TYPE.PSIS_PART.filter = filter;
        }

        doSearch({
            show: true,
            ...SEARCH_TYPE.PSIS_PART,
            type,
            onSelect,
            afterSearch: afterSearch
        });
    };

    const doSearch = (searchInfo: SearchInfoType) => {
        setSearch(searchInfo);

        const recursiveFilter = (list: NexacroJsonUnitType[] | void, filter: {
            [K: string]: string[]
        }): NexacroJsonUnitType[] | void => {
            if(list) {
                const result = list.filter(data => {
                    for(const key of Object.keys(filter)) {
                        if(filter[key].includes(String(data[key]))) {
                            return data;
                        }
                    }
                });
                return result;
            }
        };

        let filterResult: NexacroJsonUnitType[] | void = [];
        if(typeof searchInfo.props.target === 'string') {
            filterResult = dataset ? dataset[searchInfo.props.target] : [];
        } else {
            filterResult = searchInfo.props.target || [];
        }

        if(searchInfo?.filter && searchInfo.filter.length > 0) {
            for(const filter of searchInfo.filter) {
                filterResult = recursiveFilter(filterResult, filter);
            }
        }

        setOriginList(filterResult || []);
    };

    useEffect(() => {
        if(true !== search?.permitEmpty) {
            setSearchText('');
        }

        return () => {
            if(true !== search?.permitEmpty) {
                setSearchText('');
            }
        };
    }, [search?.props.target]);

    useEffect(() => {
        if(search?.show) {
            if(originList && originList.length > 0 || (search.permitEmpty !== undefined && search.permitEmpty)) {
                setSearchList(getFilterList());
                setShow(true);
            } else {
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage('MSG01544')
                });
            }
        }
    }, [search]);

    const handleSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const regexp = /(?!)[\~\!\@\#\$\%\^\&\*\(\)\=\+\_\'\;\<\>\/\.\`\:\"\,\[\]?|{}]/gi;
        if(regexp.test(event.target.value)) {
            setDialog({
                show: true,
                type: 'ALERT',
                text: getMessage('MSG01545'),
                confirm: () => setSearchText('')
            });
        } else {
            setSearchText(event.target.value);
        }
    };

    const getFilterList = useCallback(() => {
        let searchFilterResult: NexacroJsonUnitType[] = originList || [];
        if(searchText && searchText !== '' && originList && originList.length > 0 && search && (search.permitEmpty === undefined || search.permitEmpty === false)) {
            searchFilterResult = originList.filter(item => {
                return String(item[search.props.display.name] || '').match(new RegExp(searchText, 'gi'))
                    || String(item[search.props.display.value] || '').match(new RegExp(searchText, 'gi'));
            });
        }

        return searchFilterResult.map(item => {
            const favoritesResult: FavoritesType | undefined = favorites && favorites.length > 0 ? favorites?.find(unit => unit.TYPE === search?.favorites?.type && unit.CODE === (item ? item[search?.favorites?.column || ''] : undefined)) : undefined;

            const key = search ? 'DOMAIN0222' === search?.title ? String(item[search?.props.display.name || '']) : String(item[search?.props.display.value || '']) : '';
            if(favorites && favoritesResult) {
                return {
                    ...item,
                    favorites: favoritesResult?.FAV_CD,
                    favoritesSort: '0'.concat(key)
                };
            } else {
                return {
                    ...item,
                    favorites: undefined,
                    favoritesSort: '1'.concat(key)
                };
            }
        })
        .sort((a, b) => {
            if(a.favoritesSort > b.favoritesSort) {
                return 1;
            } else if(a.favoritesSort < b.favoritesSort) {
                return -1;
            } else {
                return 0;
            }
        });
    }, [searchText, search, favorites]);

    useEffect(() => {
        if(search?.permitEmpty === undefined || search.permitEmpty === false) {
            setSearchList(getFilterList());
        }
    }, [searchText]);

    const closeSearch = () => {
        setShow(false);
    };

    const handleSelectedItem = (selectedItem: NexacroJsonUnitType) => {
        search?.onSelect(selectedItem);
        search?.afterSearch?.onSelect();

        if(search?.afterSearch) {
            setAfterShow(false);

            sendAxios('/common/nexacro/selectPSISPartMgtByInspRslt.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_search: [{
                    DIVISION_CD: selectedItem.DIVISION_CD,
                    PLANT_CD: selectedItem.PLANT_CD,
                    HQ_CD: selectedItem.HQ_CD,
                    PART_CD: selectedItem.PART_CD,
                    HQ_INSP_YN: selectedItem.HQ_INSP_YN
                }]
            })
            .then(response => {
                const parrVendors = (response?.data.ds_psisPartMgt || []).filter((item: NexacroJsonUnitType) => item.HQ_CD_2ND);
                if(parrVendors && parrVendors.length > 0) {
                    if(parrVendors.length === 1) {
                        handleSelectedSecondVendor(parrVendors[0]);
                    } else {
                        setAfterShow(true);
                        setAfterData(parrVendors);
                    }
                } else {
                    handleSelectedSecondVendor({});
                }
            })
            .catch(e => {
                console.error(e);
            });
        } else {
            closeSearch();
        }
    };

    const handleSelectedSecondVendor = (selectedItem: NexacroJsonUnitType) => {
        closeSearch();
        setAfterShow(false);
        search?.afterSearch?.onSelect(selectedItem);
    };

    const handlePartSearchType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPartSearchType(event.target.value);
    };

    const getManualPartList = (divisionCd?: string, plantCd?: string, vendorCd?: string) => {
        if(!searchText || searchText === '' || searchText.length < 4) {
            setDialog({
                show: true,
                type: 'ALERT',
                text: getMessage('MSG01541')
            });
        } else {
            if(divisionCd && plantCd && vendorCd) {
                getSdpPartList({
                    ds_search: [{
                        SRCH_TYPE: partSearchType,
                        SEARCH: (searchText || '').toUpperCase(),
                        RECORDSOFFSET: '0',
                        RECORDS: '500',
                        EXTINCTION: '0',
                        CATEGORY2: '',
                        CATEGORY1: '',
                        HQ_CD: vendorCd,
                        POP_TYPE: 'D',
                        LANG: getGlobalSetting<string>(STORAGE_NAME.LANGUAGE)
                    }],
                    ds_plantList: [{
                        PLANT_CD: plantCd
                    }],
                    ds_divList: [{
                        DIVISION_CD: divisionCd
                    }]
                })
                .then(response => {
                    if(search) {
                        doSearchPart('FAVORITES', response?.data.ds_list, search.onSelect, search.afterSearch, undefined, search.divisionCd, search.plantCd, search.vendorCd);
                    }
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {});
                });
            } else {
                let messageList: string[] = [];
                if(!divisionCd) {
                    messageList.push(getDomain('DOMAIN0222'));
                }
                if(!plantCd) {
                    messageList.push(getDomain('DOMAIN2426'));
                }
                if(!vendorCd) {
                    messageList.push(getDomain('DOMAIN0372'));
                }

                setDialog({
                    show: true,
                    type: 'ALERT',
                    text: messageList.join('/').concat(getMessage('MSG01542')),
                    confirm: closeSearch
                });
            }
        }
    };

    const regexPlant: RegExp = /^(\[\w+\])(.*)$/gi;

    const searchComponent = <>
        <CSSTransition in={qrScannerPop} classNames={qrScannerPop ? 'right' : 'left'} timeout={500} unmountOnExit>
            <Scanner onClose={() => setQrScannerPop(false)} scanResult={result => {
                let resultStr = result || "";
                let twoData = resultStr.substr(2, 2);
                let noneDashData = "";
                let rv = resultStr;

                // twoData 숫자만 있으면 result 에서 0부터 10자리 자르기
                if (isNaN(Number(twoData))) {
                    noneDashData = resultStr.substr(2, 10);
                } else {
                    noneDashData = resultStr.substr(0, 10);
                }

                // twoPartData 문자열만 있으면 0부터 3까지 + 작대기
                // 나머지는 0부터 4까지 + 작대기
                if (isNaN(Number(noneDashData.substr(3, 3)))) {
                    rv = noneDashData.substr(0, 3) + "-" + noneDashData.substr(3);
                } else {
                    rv = noneDashData.substr(0, 4) + "-" + noneDashData.substr(4);
                }

                setSearchText(rv);
                if(searchTextRef.current) {
                    searchTextRef.current.value = rv || '';
                }
            }}/>
        </CSSTransition>
        <CSSTransition in={show} classNames={show ? 'right' : 'left'} timeout={500} unmountOnExit>
            <div className={'pop-layer'} style={{display: 'block'}}>
                <div className={'wrap'}>
                    <header>
                        <h1>{getDomain(search?.title || '').concat(' ', getDomain('DOMAIN2181'))}</h1>
                        <span className={'btn-wrap right'}>
                        <button type={'button'} className={'button-close'} onClick={closeSearch}>
                            <i className={'hidden'}>{getDomain('DOMAIN0173')}</i>
                        </button>
                    </span>
                    </header>
                    <div className={'container-noheader'}>
                        <div className={'search-list-wrap'}>
                            <div className={'search-wrap'} style={{display: 'flex'}}>
                                {
                                    search?.permitEmpty !== undefined && search.permitEmpty === true ? <span style={{
                                        width: '9rem',
                                        marginRight: '0.5rem'
                                    }}>
                                    <select key={uuidv4()} className={'search-select'} defaultValue={partSearchType} onChange={handlePartSearchType}>
                                        <option defaultValue={'C'} value={'C'}>Code</option>
                                        <option defaultValue={'N'} value={'N'}>Name</option>
                                    </select>
                                </span>: null
                                }
                                <span className={'search-item'}>
                                    <input type={'text'}
                                        placeholder={search?.placeholder ? getMessage(search?.placeholder) : ''}
                                        defaultValue={searchText}
                                        onChange={handleSearchText}
                                        ref={searchTextRef}
                                        style={{
                                            outline: 'none',
                                            width: search?.permitEmpty && location.pathname.startsWith('/qms') && 'DA' === search.divisionCd ? 'calc(100% - 7rem)' : 'calc(100% - 5rem)'
                                    }}/>
                                    {
                                        !search?.permitEmpty ? <button type={'button'} className={'button-cancel'} style={{
                                            position: 'absolute',
                                            margin: '0.3rem -3rem'
                                        }} onClick={() => {
                                            setSearchText('');
                                            if(searchTextRef.current) {
                                                searchTextRef.current.value = '';
                                            }
                                        }}><i className={'hidden'}>{getDomain('DOMAIN0229')}</i></button> : null
                                    }
                                    <button type={'button'} className={'icon-btn-search'} onClick={() => {
                                        if(search?.permitEmpty !== undefined && search.permitEmpty === true) {
                                            getManualPartList(search?.divisionCd, search?.plantCd, search?.vendorCd);
                                        }
                                    }}>
                                        <i className={'hidden'}>{getDomain('DOMAIN2181')}</i>
                                    </button>
                                    {
                                        search?.permitEmpty && location.pathname.startsWith('/qms') && 'DA' === search?.divisionCd ? <button type={'button'} className={'qms-btn-barcode'} onClick={() => setQrScannerPop(true)}>
                                            <i className={'hidden'}>{getDomain('DOMAIN0235')}</i>
                                        </button> : null
                                    }
                                </span>
                            </div>
                            <ul className={'FAVORITES' === search?.type ? 'code-search-list' : 'plant-search-list'}>
                                {
                                    search && search.useAll && 'RADIO' === search.type ?
                                        <li>
                                            <label>
                                                <span role={'button'} className={'ellipsis-text-nowidth'} style={{width: '90%'}} onClick={() => handleSelectedItem({
                                                    PLANT_CD: 'all'
                                                })}>
                                                    <strong>ALL</strong>
                                                </span>
                                                <input type={'radio'} name={'radio'} onClick={() => handleSelectedItem({
                                                    PLANT_CD: 'all'
                                                })}/>
                                            </label>
                                        </li> : null
                                }
                                {
                                    search ?
                                        searchList?.map((item, index) => {
                                            if('FAVORITES' === search.type) {
                                                return <li className={'radio-item'} key={uuidv4()}>
                                                    {
                                                        'DOMAIN4441' === search.title && !search.permitEmpty ? <span style={{
                                                            background: item.HQ_INSP_YN && 'Y' === item.HQ_INSP_YN ? '#00215F' : '#5200FF',
                                                            display: 'inline-flex',
                                                            height: '1.7rem',
                                                            minWidth: '8rem',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            whiteSpace: 'nowrap',
                                                            borderRadius: '1rem',
                                                            color: '#fff',
                                                            fontSize: '1.2rem'
                                                        }}>{item.HQ_INSP_YN && 'Y' === item.HQ_INSP_YN ? getDomain('DOMAIN4594') : getDomain('DOMAIN4472')}</span> : null
                                                    }
                                                    <span role={'button'} className={'ellipsis-text-nowidth'} style={{
                                                        width: '90%',
                                                        color: 'DOMAIN4441' === search.title && 'N' === item['SET_UP_CD'] ? '#FF0000' : '#747474'
                                                    }} onClick={() => {
                                                        if('DOMAIN4441' === search.title && 'N' === item['SET_UP_CD']) {
                                                            setDialog({
                                                                show: true,
                                                                type: 'ALERT',
                                                                text: getMessage('MSG01562')
                                                            })
                                                        } else {
                                                            handleSelectedItem(item);
                                                        }
                                                    }}>
                                                        <strong style={{
                                                            color: 'DOMAIN4441' === search.title && 'N' === item['SET_UP_CD'] ? '#FF0000' : '#000000'
                                                        }}>{search?.props.display.strong ? '['.concat(String(item[search?.props.display.strong]), ']') : ''}</strong>
                                                        {(String(item[search?.props.display.name] || '')).replace(regexPlant, '$2')}
                                                    </span>
                                                    <button type={'button'} className={item.favorites ? 'btn-favorite on' : 'btn-favorite'} onClick={() => {
                                                        if(item.favorites) {
                                                            doDeleteFavorites({
                                                                FAV_CD: String(item?.favorites),
                                                                CODE: String(item[search?.props.display.value]),
                                                                TYPE: String(search?.favorites?.type)
                                                            });
                                                        } else {
                                                            doSaveFavorites({
                                                                CODE: String(item[search?.props.display.value]),
                                                                TYPE: String(search?.favorites?.type)
                                                            }, item, search?.vendorCd);
                                                        }
                                                    }}>
                                                        <i className={'hidden'}>favorite</i>
                                                    </button>
                                                </li>;
                                            } else {
                                                return <li key={uuidv4()}>
                                                    <label>
                                                        <span role={'button'} className={'ellipsis-text-nowidth'} style={{width: '90%'}} onChange={() => handleSelectedItem(item)}>
                                                            <strong>{search?.props.display.strong ? '['.concat(String(item[search?.props.display.strong]), ']') : ''}</strong>
                                                            {(String(item[search?.props.display.name] || '')).replace(regexPlant, '$2')}
                                                        </span>
                                                        <input type={'radio'} name={'radio'} onChange={() => handleSelectedItem(item)}/>
                                                    </label>
                                                </li>;
                                            }
                                        }) : null
                                }
                            </ul>
                        </div>
                        <div className={'btn-area bottom'}>
                            <button type={'button'} className={'button-primary'} onClick={closeSearch}>{getDomain('DOMAIN0173')}</button>
                        </div>
                    </div>
                    <div className={'pop-layer'} style={{display: afterShow ? 'block' : 'none'}}></div>
                    {/*after search*/}
                    <CSSTransition in={afterShow} classNames={afterShow ? 'bottom' : 'top'} timeout={500} unmountOnExit>
                        <div className={'pop-layer'} style={{display: 'block', backgroundColor: 'unset'}}>
                            <div className={'popup-wrap bottom-type'}>
                                <div className={'pop-tit'}>
                                    <em>{getDomain('DOMAIN4449')}</em>
                                    <button type={'button'} className={'btn-close'} onClick={() => setAfterShow(false)}>
                                        <i>{getDomain('DOMAIN0173')}</i>
                                    </button>
                                </div>
                                <div className={'pop-conts'}>
                                    <ul className={'partner-list'}>
                                        {
                                            afterData?.map(item => {
                                                return <li key={uuidv4()}>
                                                    <label>
                                                        <strong style={{
                                                            color:'N' === item['SET_UP_CD'] ? '#FF0000' : '#000000'
                                                        }}>{item['HQ_CD_2ND'] ? '['.concat(String(item['HQ_CD_2ND']), ']') : ''}</strong>
                                                        <span style={{color: 'N' === item['SET_UP_CD'] ? '#FF0000' : '#000000'}}>{item['HQ_CD_2ND_NM']}</span>
                                                        <input type={'radio'} name={'radio'} onChange={() =>{
                                                            if('N' === item['SET_UP_CD']) {
                                                                setDialog({
                                                                    show: true,
                                                                    type: 'ALERT',
                                                                    text: getMessage('MSG01562')
                                                                })
                                                            } else {
                                                                handleSelectedSecondVendor(item);
                                                            }
                                                        }}/>
                                                    </label>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className={'btn-area'}>
                                    <button type={'button'} className={'button-primary'} onClick={() => setAfterShow(false)}>{getDomain('DOMAIN0173')}</button>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </div>
        </CSSTransition>
    </>;

    return {
        doSearch,
        doSearchDivision,
        doSearchPlant,
        doSearchVendor,
        doSearchSecondVendor,
        doSearchPart,
        doSearchLine,
        doSearchPsisPart,
        regexPlant,
        searchComponent
    };
};