import React, {useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {NexacroJsonUnitType} from "../../../../components/model/SqciTypes";
import * as gfn from "../../../../components/utils/CommonUtils";
import {useComponent} from "../../../../components/contexts/ComponentContext";
import useGlobalData from '../../../../hooks/useGlobalData';

export default function PopEcnList({title, list, onClose}: {title: string, list: NexacroJsonUnitType[], onClose: (selected_id:string) => void}) {
    const {getDomain, getMessage} = useGlobalData();
    const {setDialog} = useComponent();

    const [selected_id, setSelected_id] = useState("");

    const fn_confirm = () => {
        // 23.09.18 선택된 사항이 없더라도 진행 가능하게 변경 처리
        /*if(selected_id == ""){
            let msg = getMessage("MSG00903").replace("{0}",getDomain("DOMAIN0816"));
            setDialog({
                type: 'ALERT',
                show: true,
                text: msg,
                confirm: () => {
                }
            });
            return;
        }*/

        onClose(selected_id);
    }

    // 23.09.22 카드가 선택되어도 종료되게 변경 처리
    const fn_selected = (ec_no:string) => {
        setSelected_id(ec_no);
        onClose(ec_no);
    }

    return (
        <>
            <div className={'pop-layer'} style={{display: 'block'}}>
                <div className={'wrap'}>
                    <header>
                        <h1>{title}</h1>
                        <span className={'btn-wrap right'}>
                            <button type={'button'} className={'button-close'} onClick={() => onClose(selected_id)}>
                                <i className={'hidden'}>{getDomain('DOMAIN0173')}</i>   {/*닫기*/}
                            </button>
                        </span>
                    </header>
                    <div className={'container'}>
                        <div className={'card-wrap'}>
                            <ul className={'card-list'}>
                                {
                                    list.map((item, index) => {
                                        return <li
                                            key={'ecnDetail_'.concat(String(item.EC_NO)).concat(String(index))}
                                            className={'card-item'}
                                            style={{border: item.EC_NO === selected_id ? '0.1rem solid #3478F5' : '0.1rem solid #D7D7D7'}}
                                            onClick={() => fn_selected(gfn.gfn_tostring(item.EC_NO))}
                                        >
                                            <div className={'item'} role={'button'}>
                                                <p>
                                                    <span>
                                                        <em>[{item.EC_NO}]</em>
                                                        <i className={'label-type1'}>{item.ECN_CLASS_NM}</i>
                                                        <i className={'label-type2'}>{item.CODE_NM}</i>
                                                    </span>
                                                    <strong>{item.ECR_TITLE}</strong>
                                                </p>
                                                <ul className={'date-list'}>
                                                    <li>
                                                        <em>{getDomain('DOMAIN4629')} {/*적용일*/}</em>
                                                        <span>{gfn.gfn_n_today_yyyy_mm_dd(item.ECR_END_DATE||'')}</span>
                                                    </li>
                                                    <li>
                                                        <em>{getDomain('DOMAIN4630')} {/*입고예정일*/}</em>
                                                        <span>{gfn.gfn_n_today_yyyy_mm_dd(item.STOCK_PLAN_DATE||'')}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>;
                                    })
                                }
                            </ul>
                        </div>
                        <div className={'btn-area bottom'}>
                            <button type={'button'} className={'button-primary'} onClick={() => fn_confirm()}>{getDomain('DOMAIN0173')}</button>   {/*닫기*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};