/**************************************************************************************************
 * 01. 업무구분	    : P-SIS > 이미지 뷰어
 * 02. 프로그램ID	: ImageViewer.tsx
 * 03. 프로그램명	    : P-SIS > 이미지 뷰어
 * 04. 화면설명	    : P-SIS > 이미지 뷰어
 * 05. 작성일		: 2023.10.04
 * 06. 작성자		: YGY
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-10-04     YGY    최초 작성
 **************************************************************************************************
 */
/******************************************************************************************
 *                                        변경이력                                         *
 *----------   ----------   ------------   ------------------------------------------------*
 * 변경번호     변경일자       변경자                        변경내용                      *
 *----------   ----------   ------------   ------------------------------------------------*

 ******************************************************************************************/

import React, {useEffect, useState} from 'react';
import useAxios, {AXIOS_HEADER} from '../../../hooks/useAxios';
import {useError} from '../../../hooks/useException';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination, Zoom} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import useGlobalData from "../../../hooks/useGlobalData";
import {v4 as uuidv4} from "uuid";
import {useLocation, useOutletContext} from "react-router-dom";
import {ComponentHistoryType} from "../../../components/model/SqciTypes";

export default function ImageViewer({title, imageFileId, onClose}: {
    title: string,
    imageFileId: string,
    onClose: () => void
}) {
    const {sendAxios} = useAxios();
    const location = useLocation();
    const {throwException} = useError();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const [fileList, setFileList] = useState<{ [K: string]: string }[]>();
    const [titleNm, setTitleNm] = useState(String);
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();

    useEffect(() => {
        if ((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            setTitleNm(title);
            sendAxios('/common/nexacro/commonSelectFileList.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_Search: [{
                    FILE_ID: imageFileId
                }]
            })
                .then(response => {
                    //setFileList(response?.data.ds_List || []);
                    let file: { [K: string]: string }[] = [];
                    for (const path of response?.data.ds_List) {
                        file.push({
                            DB_SRCH: path.DB_SRCH,
                            DESCRIPTION: path.DESCRIPTION,
                            DOWNLOAD_TYPE: path.DOWNLOAD_TYPE,
                            FILE_EXTENSION_NAME: path.FILE_EXTENSION_NAME,
                            FILE_ID: path.FILE_ID,
                            FILE_MIME_TYPE_NAME: path.FILE_MIME_TYPE_NAME,
                            FILE_NAME: path.FILE_NAME,
                            FILE_PATH_NAME: '/images/@temp1.png',
                            FILE_SEQ: path.FILE_SEQ,
                            FILE_SIZE: path.FILE_SIZE,
                            FIRST_REGR_ID: path.FIRST_REGR_ID,
                            FIRST_REG_DATETIME: path.FIRST_REG_DATETIME,
                            FROM_DATE: path.FROM_DATE,
                            MODULE_ID: path.MODULE_ID,
                            ORDER_IDX: path.ORDER_IDX,
                            THRU_DATE: path.THRU_DATE
                        });
                        if (file.length > 0) {
                            setFileList(file);
                        }
                    }
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }
    }, [enteredComponent, exitedComponent]);

    return (
        <>
            <div className={'pop-layer'} style={{display: 'block', zIndex: 300, overflowY: 'auto'}}>
                <div className={'wrap'}>
                    <header>
                        <h1>{titleNm}</h1>
                        <span className={'btn-wrap right'}>
                        <button type={'button'} className={'button-close'} onClick={onClose}><i
                            className={'hidden'}>닫기</i></button>
                     </span>
                    </header>
                    <div style={{display:'block'}}>
                        <Swiper modules={[Zoom, Navigation, Pagination]} spaceBetween={30} zoom={true} navigation={true}
                                pagination={{clickable: true}} className={'mySwiper'}>
                            {
                                fileList && fileList.length > 0 ? fileList.map((item, index) => {
                                    return <SwiperSlide key={uuidv4()} virtualIndex={index}>
                                        <div className={'image-wrap swiper-zoom-container'} style={{
                                            marginTop: 'unset',
                                            width: '100%',
                                            overflowY: 'unset',
                                            padding: 'unset'
                                        }}>
                                            <p>{item.FILE_NAME}</p>
                                            <img
                                                src={process.env.REACT_APP_HTTP_BASE_URL + '/common/nexacro/commonGetImageFileAndFileSeq.do?fileId=' + item.FILE_ID + '&fileSeq=' + item.FILE_SEQ}
                                                alt={item.FILE_NAME}
                                                style={{width: '100%'}}/>
                                        </div>
                                    </SwiperSlide>;
                                }) : null
                            }
                        </Swiper>
                        <div className={'btn-area bottom'}>
                            <button type={'button'} className={'button-primary'}
                                    onClick={onClose}>{getDomain('DOMAIN0173')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};