// 입력값을 String으로 변경한다.
export function gfn_toString (val :any)
{
    if (gfn_isNull(val)){
        return new String();
    }
    return new String(val);
}

export function gfn_tostring (val :any)
{
    if (gfn_isNull(val)){
        return "";
    }
    return val;
}

// 입력값이 null에 해당하는 경우 모두를 한번에 체크한다.
export function gfn_isNull (val :any)
{
    if (new String(val).valueOf() == "undefined"){
        return true;
    }
    if (val == null){
        return true;
    }
    if (("x" + val == "xNaN") && (new String(val.length).valueOf() == "undefined")){
        return true;
    }
    if (val.length == 0){
        return true;
    }
    //string 일때 공백은 널처리
    if (typeof val === 'string') {
        var str = val.replace(/\s/gi, "");
        if (str.length ==0 ) {
            return true;
        }
    }
    return false;
}

export function gfn_n_stringToBoolean (val :any)
{
    if(typeof val === "string") {
        return JSON.parse(val);
    }else{
        return false;
    }
}

/**
 * @class 해당 PC의 오늘 날짜를 가져온다.
 * @param None
 * @return string
 */
export function gfn_c_today (val :string)
{
    var strToday = "";
    strToday = val.substr(0,8);

    return strToday;
}

export function gfn_n_today_yyyy_mm_dd (val :any)
{
    if(typeof val == 'string') {
        var strToday = "";
        strToday = val.substr(0, 4) + "-" + val.substr(4, 2) + "-" + val.substr(6, 2);

        return strToday;
    }else{
        return val;
    }
}

export function gfn_minusMonth (date:string, nOffSet:number)
{
    var nYear 	= parseInt(date.substr(0, 4));
    var nMonth 	= parseInt(date.substr(4, 2)) - nOffSet;
    var nDate 	= parseInt(date.substr(6, 2));

    return gfn_datetime(nYear, nMonth, nDate);
}

export function gfn_datetime (nYear:number, nMonth:number, nDate:number)
{
    let objDate = new Date();
    if (nYear.toString().trim().length >= 5){
        var sDate = new String(nYear);
        var nYear = parseInt(sDate.substr(0, 4));
        var nMonth = parseInt(sDate.substr(4, 2));
        var nDate = parseInt((sDate.substr(6, 2) == "") ? "1" : sDate.substr(6, 2));
        var nHours = parseInt((sDate.substr(8, 2) == "") ? "0" : sDate.substr(8, 2));
        var nMinutes = parseInt((sDate.substr(10, 2) == "") ? "0" : sDate.substr(10, 2));
        var nSeconds = parseInt((sDate.substr(12, 2) == "") ? "0" : sDate.substr(12, 2));

        objDate = new Date(nYear, nMonth - 1, nDate, nHours, nMinutes, nSeconds);
    } else {
        objDate = new Date(nYear, nMonth - 1, ((nDate == null) ? 1 : nDate));
    }

    var strYear = objDate.getFullYear().toString();
    var strMonth = (objDate.getMonth() + 1).toString();
    var strDate = objDate.getDate().toString();

    if (strMonth.length == 1){
        strMonth = "0" + strMonth;
    }
    if (strDate.length == 1){
        strDate = "0" + strDate;
    }

    return strYear + strMonth + strDate;
}

export function gfn_n_formate(val: string)
{
    var strRt = "";
    strRt = val.substr(0,4)+"-"+val.substr(4,2)+"-"+val.substr(6,2);

    return strRt;
}

/**
 * @class 문자열의 왼쪽부분을 지정한 길이만큼 Return 한다.
 * @param val(왼쪽부분을 얻어올 원본 문자열)
 * @param nSize - 얻어올 크기. [Default Value = 0]
 * @return String 왼쪽 부분이 얻어진 문자열.
 */
export function gfn_left(val: string, nSize: number)
{
    return gfn_toString(val).substr(0, nSize);
}

export function gfn_return_string(val:any)
{
    if(typeof val == 'string'){
        return val;
    }else{
        return "";
    }
}

export function fn_getFileSizeDisplay(nFileSize:any) {
    let rt:number = 0;
    if(typeof nFileSize == "number"){
        rt = nFileSize;
    }

    nFileSize = Number((nFileSize * 0.001).toFixed(2));

    if (nFileSize == 0) {
        return "0.00"; // 소수점 두자리까지 남겨 0.00으로 표시됩니다.
    }

    return nFileSize;
}

export function fn_comma(str:any)
{
    if(typeof str == 'string'){
        str = str.replace(/[^-\.0-9]/g, '');
        var parts = str.toString().split('.');
        if(str.substring(str.length - 1, str.length)=='.'){
            str = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",") +".";
        } else {
            str = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",") + (parts[1] ? "." + parts[1] : "");
        }
        return str;
    }else {
        return "";
    }
};

export function fn_uncomma(x:any)
{
    if(typeof x == 'string'){
        return x.toString().replace(/[^-\.0-9]/g, '');
    }else {
        return "";
    }
};