/**************************************************************************************************
 * 01. 업무구분	    : P-SIS > 대책서 정보
 * 02. 프로그램ID	: CounterViewerInfo.tsx
 * 03. 프로그램명	    : P-SIS > 대책서 정보
 * 04. 화면설명	    : P-SIS > 대책서 정보
 * 05. 작성일		: 2023.10.04
 * 06. 작성자		: YGY
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-10-04     YGY    최초 작성
 **************************************************************************************************
 */
/******************************************************************************************
 *                                        변경이력                                         *
 *----------   ----------   ------------   ------------------------------------------------*
 * 변경번호     변경일자       변경자                        변경내용                      *
 *----------   ----------   ------------   ------------------------------------------------*

 ******************************************************************************************/

import React, {useEffect, useRef, useState} from 'react';
import useAxios, {AXIOS_HEADER} from '../../../hooks/useAxios';
import {useError} from '../../../hooks/useException';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {floor, toNumber} from "lodash";
import {useComponent} from "../../../components/contexts/ComponentContext";
import {useDataset} from "../../../components/contexts/CommonContext";
import useGlobalData from "../../../hooks/useGlobalData";
import {useLocation, useOutletContext} from "react-router-dom";
import {ComponentHistoryType} from "../../../components/model/SqciTypes";

export default function CounterViewerInfo({title, counterMap, onClose}: {
    title: string,
    counterMap: any,
    onClose: () => void
}) {
    const {sendAxios} = useAxios();
    const location = useLocation();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const {dataset, sessionValues} = useDataset();
    const {setDialog} = useComponent();
    const {throwException} = useError();
    const [counterDetailMap, setCounterDetailMap] = useState<any>();
    const [titleNm, setTitleNm] = useState(String);
    const [counterFileList, setCounterFileList] = useState(new Array<any>());
    const [counterFileMap, setCounterFileMap] = useState<any>();
    const attachRef = useRef<HTMLInputElement>(null);
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();

    useEffect(() => {
        if((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            setTitleNm(title);
            sendAxios('/common/nexacro/selectPsisCounterPlanList.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_search: [{
                    INSP_TARGET_CD: counterMap.inspTargetCd,
                    INSP_TYPE: counterMap.inspType,
                    INSP_CD: counterMap.inspCd
                }]
            })
                .then(response => {
                    let ds_map: any = {
                        INSP_TARGET_CD: counterMap.inspTargetCd,
                        INSP_TYPE: counterMap.inspType,
                        INSP_CD: counterMap.inspCd,
                        PHENOMENON: '',
                        CAUSE: '',
                        COUNTERPLAN: '',
                        FILE_ID: '',
                        INSERT_BY: sessionValues?.USER_ID,
                    }
                    for (const ds_res of response?.data.ds_counterPlan || []) {
                        ds_map = {
                            INSP_TARGET_CD: ds_res["INSP_TARGET_CD"],
                            INSP_TYPE: ds_res["INSP_TYPE"],
                            INSP_CD: ds_res["INSP_CD"],
                            PHENOMENON: ds_res["PHENOMENON"],
                            CAUSE: ds_res["CAUSE"],
                            COUNTERPLAN: ds_res["COUNTERPLAN"],
                            FILE_ID: ds_res["FILE_ID"],
                            INSERT_BY: sessionValues?.USER_ID
                        }
                    }
                    setCounterDetailMap(ds_map);

                    if (ds_map.FILE_ID != '') {
                        setCounterFileMap({
                            fileId: ds_map.FILE_ID,
                            prevFileId: '',
                            fileState: 'S'
                        })
                    }
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }
    }, [enteredComponent, exitedComponent]);


    /**
     * fileState : S (신규 등록), U(수정)
     */
    useEffect(() => {
        if (counterFileMap !== undefined) {
            sendAxios('/common/nexacro/commonSelectFileList.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_Search: [{
                    FILE_ID: counterFileMap?.fileState === 'S' ? counterFileMap?.fileId : counterFileMap?.prevFileId
                }]
            })
                .then(response => {
                    setCounterFileList(response?.data.ds_List || []);
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }
    }, [counterFileMap]);


    const fileDownCallback = async (fileId:string, fileSeq:number) => {
        let sFileUrl = process.env.REACT_APP_HTTP_BASE_URL + "/common/nexacro/commonFileDownload.do?" + "fileId=" + fileId + "&fileSeq=" + fileSeq;
        window.open(sFileUrl);
    }

    return (
        <>
            <div className={'pop-layer'} style={{display: 'block', zIndex: 300, overflowY:'auto'}}>
                <div className={'wrap'}>
                    <header>
                        <h1>{titleNm}</h1>
                        <span className={'btn-wrap right'}>
                        <button type={'button'} className={'button-close'} onClick={onClose}><i
                            className={'hidden'}>{getDomain('DOMAIN0173')}</i></button>
                     </span>
                    </header>
                    <div className={'container'}>
                        <div className={'contents-table-wrap width-type2'}>
                            {
                                counterDetailMap != undefined && (
                                    <ul className={'contents-table-list'} style={{wordBreak:'break-all'}}>
                                        <li>
                                            <div className={'title'} style={{minWidth: '20%', maxWidth: '15%', wordBreak:'break-word'}}>{getDomain('DOMAIN4489')}</div>
                                            <div className={'item-wrap'}>{counterDetailMap.PHENOMENON}</div>
                                        </li>
                                        <li>
                                            <div className={'title'} style={{minWidth: '20%', maxWidth: '15%', wordBreak:'break-word'}}>{getDomain('DOMAIN2579')}</div>
                                            <div className={'item-wrap'}>{counterDetailMap.CAUSE}</div>
                                        </li>
                                        <li>
                                            <div className={'title'} style={{minWidth: '20%', maxWidth: '15%', wordBreak:'break-word'}}>{getDomain('DOMAIN4761')}</div>
                                            <div className={'item-wrap'}>{counterDetailMap.COUNTERPLAN}</div>
                                        </li>
                                        <li>
                                            <div className={'title'} style={{minWidth: '20%', maxWidth: '15%', wordBreak:'break-word'}}>{getDomain('DOMAIN4750')}</div>
                                            {
                                                counterFileList.length > 0 && (
                                                    <div className="item-wrap">
                                                        <ul className="file-list">
                                                            {
                                                                counterFileList.map((item, index) => {
                                                                    return <li key={'file'.concat(String(index))}>
                                                                        <div className="file-item" onClick={() => fileDownCallback(item.FILE_ID, item.FILE_SEQ)}>
                                                                            <em style={{wordBreak:'break-all'}}>{item.FILE_NAME}</em>
                                                                            <span>{floor(toNumber(item.FILE_SIZE) / (1024 * 1024), 2)}MB</span>
                                                                        </div>
                                                                    </li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                )
                                            }
                                        </li>
                                    </ul>
                                )
                            }
                        </div>
                    </div>
                    <div className={'btn-area bottom'}>
                        <button type={'button'} className={'button-primary'} onClick={() => onClose()}>{getDomain('DOMAIN0173')}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
};