/**************************************************************************************************
 * 01. 업무구분	    : P-SIS > 검사대상등록
 * 02. 프로그램ID	: PsisInspTargetReg.tsx
 * 03. 프로그램명	    : P-SIS > 검사대상등록
 * 04. 화면설명	    : P-SIS > 검사대상등록
 * 05. 작성일		: 2023.10.04
 * 06. 작성자		: YGY
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-10-04     YGY    최초 작성
 **************************************************************************************************
 */
/******************************************************************************************
 *                                        변경이력                                         *
 *----------   ----------   ------------   ------------------------------------------------*
 * 변경번호     변경일자       변경자                        변경내용                      *
 *----------   ----------   ------------   ------------------------------------------------*

 ******************************************************************************************/

import React, {useEffect, useState} from 'react';
import {useDataset} from '../../../components/contexts/CommonContext';
import useAxios, {AXIOS_HEADER} from '../../../hooks/useAxios';
import {useError} from '../../../hooks/useException';
import useHtmlElement from '../../../hooks/useHtmlElement';
import {ComponentHistoryType, NexacroJsonType, NexacroJsonUnitType} from '../../../components/model/SqciTypes';
import usePageMove from "../../../hooks/usePageMove";
import {toNumber} from "lodash";
import {useComponent} from "../../../components/contexts/ComponentContext";
import useStorage from '../../../hooks/useStorage';
import useGlobalData from '../../../hooks/useGlobalData';
import {STORAGE_NAME} from "../../../components/CommonConstants";
import {useSearch} from '../../../hooks/useSearch';
import {useLocation, useOutletContext} from "react-router-dom";
import * as gfn from "../../../components/utils/CommonUtils";

export default function PsisInspTargetReg() {
    const {dataset, sessionValues} = useDataset();
    const {sendAxios} = useAxios();
    const location = useLocation();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const {throwException} = useError();
    const {pageMove} = usePageMove();
    const {useSelectBox, useInput} = useHtmlElement();
    const {setDialog} = useComponent();
    const [inspectResult, setInspectResult] = useState(new Array<any>());
    const [indexCnt, setIndexCnt] = useState(Number);
    const [targetCnt, setTargetCnt] = useState(String);
    const {getDocumentHistory} = useStorage();
    const {setStorage, getStorage, clearStorage} = useStorage();
    const {
        searchComponent,
        doSearchDivision,
        doSearchPlant,
        doSearchVendor,
        doSearchPsisPart,
        doSearchLine
    } = useSearch();

    const regexPlant: RegExp = /^(\[\w+\])(.*)$/gi;
    const [plantList, setPlantList] = useState<NexacroJsonUnitType[]>([]);
    const [vendorList, setVendorList] = useState<NexacroJsonUnitType[]>();
    const [secondVendorList, setSecondVendorList] = useState<NexacroJsonUnitType[]>([]);
    const [dataList, setDataList] = useState<NexacroJsonUnitType[]>();
    const [partList, setPartList] = useState<NexacroJsonUnitType[]>();
    const [plant, setPlant] = useState<NexacroJsonUnitType | undefined>();
    const [vendor, setVendor] = useState<NexacroJsonUnitType | undefined>();
    const [lineList, setLineList] = useState<NexacroJsonUnitType[]>();
    const [secondVendor, setSecondVendor] = useState<NexacroJsonUnitType | undefined>(getDocumentHistory('secondVendor'));
    const [part, setPart] = useState<NexacroJsonUnitType | undefined>(getDocumentHistory('part'));
    const [line, setLine] = useState<NexacroJsonUnitType | undefined>(getDocumentHistory('partVendor'));
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
    const sLocale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    //검색조건 저장 KJS
    const getSearchCondition:any = getStorage('LOCAL', location.pathname);

    useEffect(() => {
        // clearStorage('LOCAL', location.pathname);
        if ((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            fn_onload();
        }
    }, [enteredComponent, exitedComponent]);

    async function fn_onload(){
        if(!gfn.gfn_isNull(getSearchCondition)) {
            setPlant(getSearchCondition['plant']);
            setVendor(getSearchCondition['hqCd']);
        }

        let plantList:any[] = [];
        if(sessionValues?.WORK_GROUP_CD == 2 || sessionValues?.WORK_GROUP_CD == 3 || sessionValues?.WORK_GROUP_CD == 4 || sessionValues?.WORK_GROUP_CD == 5) {
            await fn_selectPlantList().then(response => {
                plantList = response?.data.ds_Plant;
                plantList = plantList.filter((v: any) => v.LV == '4');
            }).catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
        }

        await fn_selectPsisVendorList(plantList);
    }

    async function fn_selectPsisVendorList(plantList:any[]){
        sendAxios('/common/nexacro/selectPsisVendorList.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Search: [{
                DIVISION_CD: '',
                HQ_CD: (sessionValues?.WORK_GROUP_CD || 0) === 1 ? sessionValues?.HQ_CD : vendor?.HQ_CD,
                USE_YN: ''
            }]
        }).then(response => {
            let sData = response?.data.ds_vendorList || [];
            let nData= [];

            if(sessionValues?.WORK_GROUP_CD == 2 || sessionValues?.WORK_GROUP_CD == 3 || sessionValues?.WORK_GROUP_CD == 4 || sessionValues?.WORK_GROUP_CD == 5) {
                for (let a = 0; a < plantList.length; a++) {
                    let detail = (sData.filter((v: any) => v.PLANT_CD == plantList[a].CODE));
                    for (let b = 0; b < detail.length; b++) {
                        nData.push(detail[b]);
                    }
                }
            }else{
                nData = sData;
            }

            setPlantList(nData.filter((v: any, i: number, callback: any) => i === callback.findIndex((loc: any) => loc.PLANT_CD === v.PLANT_CD)));
            setDataList(nData);
        })
        .catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    async function fn_selectPlantList() {
        let requestJson: NexacroJsonType = {};

        if(sessionValues?.WORK_GROUP_CD == 2 || sessionValues?.WORK_GROUP_CD == 3){
            requestJson = {
                ds_Search: [{
                    DIVISION_CD : sessionValues?.DIVISION_CD,
                    LANG: sLocale,
                    SHOW_HQ: 'true'
                }]
            }
        }else if(sessionValues?.WORK_GROUP_CD == 4 || sessionValues?.WORK_GROUP_CD == 5){
            requestJson = {
                ds_Search: [{
                    PLANT_CD : sessionValues?.PLANT_CD,
                    AUTH_MANAGER : sessionValues?.WORK_GROUP_CD,
                    AUTH : 'PLACE',
                    LANG: sLocale,
                    SHOW_HQ: 'true'
                }]
            }
        }

        return sendAxios('/common/nexacro/selectPlantList.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson);
    }

    useEffect(() => {
        if (plant?.PLANT_CD) {
            setVendorList(dataList?.filter((v: any) => v.PLANT_CD == plant?.PLANT_CD) || []);
        }
    }, [plant]);

    useEffect(() => {
        if (plant?.PLANT_CD && vendor?.HQ_CD) {
            sendAxios('/common/nexacro/selectNonSetUpPsisPartCombList.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_search: [{
                    APPROVE_STATUS: '',
                    TYPE: 'PSIS',
                    PLANT_CD: plant?.PLANT_CD,
                    HQ_CD: vendor?.HQ_CD,
                    RECORDSOFFSET: '0',
                    RECORDS: '1000',
                    SRCH_TYPE: '',
                    CATEGORY1: '',
                    CATEGORY2: '',
                    SEARCH: ''
                }]
            })
                .then(response => {
                    setPartList(response?.data.ds_list || []);
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }
    }, [plant, vendor]);

    
    useEffect(() => {
        if (vendor?.HQ_CD) {
            sendAxios('/common/nexacro/selectPSISLineMgtByInspRslt.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_search: [{
                    HQ_CD: vendor?.HQ_CD
                }]
            })
                .then(response => {
                    setLineList(response?.data.ds_lineList || []);
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }
    }, [vendor]);

    useEffect(() => {
        if (plantList && plantList.length > 0) {
            const plant = gfn.gfn_isNull(getSearchCondition?.hqCd) ? plantList[0] : getSearchCondition?.plant;
            // const plant = gfn.gfn_isNull(getSearchCondition['hqCd']) ? plantList[0] : getSearchCondition['plant'];
            setPlant(plant);
        }
    }, [plantList]);

    useEffect(() => {
        if (vendorList) {
            if (vendorList.length > 0) {
                const vendor = gfn.gfn_isNull(getSearchCondition?.hqCd) ? vendorList[0] : getSearchCondition?.hqCd;
                // const vendor = gfn.gfn_isNull(getSearchCondition['hqCd']) ? vendorList[0] : getSearchCondition['hqCd'];
                setVendor(vendor);
                // setVendor(vendorList.find(item =>
                //     item.PLANT_CD === (sessionValues?.PLANT_CD || plant?.PLANT_CD)
                //     && item.HQ_CD === sessionValues?.HQ_CD || vendorList[0].HQ_CD));
            } else {
                setVendor(1 === (sessionValues?.WORK_GROUP_CD || 0) ? {
                    HQ_CD: sessionValues?.HQ_CD,
                    HQ_NM: sessionValues?.HQ_NM
                } : {});
            }
        } else {
            setVendor(1 === (sessionValues?.WORK_GROUP_CD || 0) ? {
                HQ_CD: sessionValues?.HQ_CD,
                HQ_NM: sessionValues?.HQ_NM
            } : {});
        }
    }, [vendorList]);

    useEffect(() => {
        setPart(undefined);
        setSecondVendor(undefined);
        //setLine(undefined);
    }, [partList]);

    useEffect(() => {
        if (!secondVendorList || secondVendorList.length === 0) {
            setSecondVendor(undefined);
        }
    }, [secondVendorList]);

    useEffect(() => {
        setLine(undefined);
    }, [lineList]);

    const selectedPlant = (value: any) => {
        setPlant(value);
    };

    const selectedVendor = (value: any) => {
        setVendor(value);
    };

    const selectedPart = (value: any) => {
        setPart(value);
    };

    const selectedSecondVendor = (value: any) => {
        setSecondVendor(value);
    };

    const selectedLine = (value: any) => {
        setLine(value);
    };


    /**
     * 검사대상 추가 버튼 클릭시 이벤트
     */
    const targetAdd = () => {
        let isValid: boolean = true;
        let message: string = "";

        //Plant
        if (plant?.PLANT_CD === undefined) {
            isValid = false;
            message = getMessage('MSG01497');
        }
        //협력사
        else if (vendor?.HQ_CD === undefined) {
            isValid = false;
            message = getMessage('MSG01498');
        } else if (part?.PART_CD === undefined) {
            isValid = false;
            message = getMessage('MSG01499');
        }
        //라인명
        else if (line?.LINE_CD === undefined) {
            isValid = false;
            message = getMessage('MSG01500');
        }
        //대상 수량
        else if (toNumber(targetCnt) <= 0 || targetCnt == '') {
            isValid = false;
            message = getMessage('MSG01501');
        }

        if (!isValid) {
            setDialog({
                type: 'ALERT',
                show: true,
                text: message
            });
        } else {
            sendAxios('/common/nexacro/selectPSISTarget.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_search: [{
                    LANG: gv_locale,
                    PLANT_CD: plant?.PLANT_CD,
                    HQ_CD: vendor?.HQ_CD,
                    PART_CD: part?.PART_CD,
                    HQ_CD_2ND: secondVendor?.HQ_CD_2ND,
                    LINE_CD: line?.LINE_CD,
                    TARGET_CNT: targetCnt?.replaceAll(",", "")
                }]
            })
                .then(response => {
                    if (response?.data.ds_inspectResult.length === 0) {
                        setDialog({
                            type: 'ALERT',
                            show: true,
                            text: getMessage('MSG01502')
                        });
                    } else {
                        for (const ds_res of response?.data.ds_inspectResult || []) {
                            //현재 날짜를 계산하기 위함
                            let today = new Date();
                            let year = today.getFullYear();
                            let month = ('0' + (today.getMonth() + 1)).slice(-2);
                            let day = ('0' + today.getDate()).slice(-2);
                            let dateString = year + '-' + month + '-' + day;
                            setIndexCnt(indexCnt + 1);
                            ds_res["indexCnt"] = indexCnt;
                            ds_res["dateString"] = dateString;
                            //대상 수량이 1000이 넘을때만 , 처리함.
                            if (toNumber(ds_res.TARGET_CNT) > 999) {
                                ds_res["targetCntExpr"] = ds_res.TARGET_CNT.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                ds_res["targetCntExpr"] = ds_res.TARGET_CNT;
                            }
                            // for 문에서 얻은 걷들을 list에 add함
                            setInspectResult([...inspectResult, ds_res]);

                        }
                    }

                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }
    }

    useEffect(() => {
        setTargetCnt("");
        setLine(undefined);
        setPart(undefined);
        setSecondVendor(undefined);
    }, [inspectResult]);

    // useEffect(() => {
    //     if (targetCnt && targetCnt !== '' && !new RegExp(/\,/gi).test(targetCnt)) {
    //         setTargetCnt(targetCnt.replace(/[^0-9]/g, ""));
    //     } else {
    //         setTargetCnt(targetCnt);
    //     }
    //     // const cntVal = e.currentTarget.value.replace(/[^0-9]/g, "");
    //     // //targetCnt = cntVal;
    //     // setTargetCnt(cntVal);
    //     // e.currentTarget.value = cntVal.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    // }, [targetCnt]);

    /**
     * 대상 수량 값이 변할때, 숫자만 입력및 천단위 마다 , 처리하기 위함
     * @param e
     */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (Number(e.target.value) > e.target.maxLength) {
            setTargetCnt(e.target.value.replace(/[^0-9]/g, "").slice(0, e.target.maxLength));
        } else {
            setTargetCnt(e.target.value.replace(/[^0-9]/g, ""));

        }

        //const cntVal = e.target.value.replace(/[^0-9]/g, "");

    };


    /**
     * 카드뷰 삭제 처리
     * @param index
     */
    const callbacDelFn = (index: number) => {
        setDialog({
            type: 'CONFIRM',
            show: true,
            text: getMessage('MSG01503'),
            confirm: () => {
                setInspectResult(inspectResult.filter(inspectResult => inspectResult.indexCnt !== index));
            }
        });
    };
    const getYYYYMMDD24HIMMSS =() => {
        const today = new Date();
        const yyyy = today.getFullYear().toString();
        const mm = (today.getMonth() + 1) > 9 ? (today.getMonth() + 1).toString() : '0' + (today.getMonth() + 1).toString();
        const dd = today.getDate() > 9 ? today.getDate().toString() : '0' + today.getDate().toString();
        const hh = today.getHours() > 9 ? today.getHours().toString() : '0' + today.getHours().toString();;
        const mmm = today.getMinutes() > 9 ? today.getMinutes().toString() : '0' + today.getMinutes().toString();;
        const ss = today.getSeconds() > 9 ? today.getSeconds().toString() : '0' + today.getSeconds().toString();;

        return yyyy + mm + dd + hh + mmm + ss;
    }
    const callbackAddFn = () => {
        if (inspectResult.length === 0) {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage('MSG01504'),
                confirm: () => {
                }
            });
        } else {
            let inspCnt = inspectResult.length;
            //현지 시간으로수정
            let insertLclDt = getYYYYMMDD24HIMMSS();

            sendAxios('/common/nexacro/insertPSISInspTarget.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_search: [{
                    INSERT_BY: sessionValues?.USER_ID,
                    INSERT_LCL_DT: insertLclDt,
                }],
                ds_inspectResultList: inspectResult
            })
                .then(response => {
                    //set storage
                    // setStorage('SESSION', STORAGE_NAME.HISTORY, {
                    //     ...getStorage<HistoryType>('SESSION', STORAGE_NAME.HISTORY),
                    //     [sqciRouter.state.location.pathname]: {
                    //         plant: plant,
                    //         hqCd: vendor
                    //     }
                    // });

                    setStorage('LOCAL', location.pathname, {
                            plant: plant,
                            hqCd: vendor
                    }, 7);
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG01505").replace("{0}", String(inspCnt)),
                        confirm: () => {
                            setInspectResult(new Array<any>());
                        }
                    });

                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }
    };

    return (
        <>
            {searchComponent}
            <div className={'container'}>
                <div className={'contents-wrap'} style={{overflowY :'auto'}}>
                    <ul className={'reports-list'}>
                        {
                            <li className={'text-item'}>
                            <span className={'title'}>
                                Plant
                            </span>
                            <div onClick={() => doSearchPlant('RADIO', selectedPlant, plantList)}>
                                <p>{plant?.PLANT_CD ? '['.concat(String(plant?.PLANT_CD), ']') : ''}<br/>{String(plant?.PLANT_PROD_NM || '').replace(regexPlant, '$2')}
                                </p>
                                <button type={'button'} className={'btn-link'}
                                        onClick={() => doSearchPlant('RADIO', selectedPlant, plantList)}>
                                    <i className={'hidden'}>{getDomain('DOMAIN4561')}</i>
                                </button>
                            </div>
                            </li>
                        }
                        <li className={'text-item'}>
                            <span className={'title'}>
                                {getDomain('DOMAIN0372')}
                            </span>
                            <div className={'text-item'}
                                 onClick={() => 1 !== (sessionValues?.WORK_GROUP_CD || 0) ? doSearchVendor('RADIO', vendorList || [], selectedVendor) : null}>
                                <p>{vendor?.HQ_CD ? '['.concat(String(vendor?.HQ_CD), ']') : ''}<br/><strong>{vendor?.HQ_NM}</strong>
                                </p>
                                {
                                    1 !== (sessionValues?.WORK_GROUP_CD || 0) ?
                                        <button type={'button'} className={'btn-link'}>
                                            <i className={'hidden'}>{getDomain('DOMAIN4561')}</i>
                                        </button> : null
                                }
                            </div>
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                {getDomain('DOMAIN4731')}
                            </span>
                            <div className={'item-wrap ellipsis1'} role={'button'} onClick={() => doSearchPsisPart('FAVORITES', partList || [], selectedPart, {
                                onSelect: selectedSecondVendor
                            })}>
                                <p className={'item-wrap ellipsis2'}>
                                    {
                                        part ? <span  style={{
                                            background: part?.HQ_INSP_YN && 'Y' === part?.HQ_INSP_YN ? '#00215F' : '#5200FF',
                                            display: 'inline-flex',
                                            height: '1.7rem',
                                            minWidth: '8rem',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            whiteSpace: 'nowrap',
                                            borderRadius: '1rem',
                                            color: '#fff',
                                            fontSize: '1.2rem'
                                        }}>{part?.HQ_INSP_YN && 'Y' === part?.HQ_INSP_YN ? getDomain('DOMAIN4594') : getDomain('DOMAIN4472')}</span> : null
                                    }
                                    <br/>{part?.PART_CD ? '['.concat(String(part?.PART_CD), ']') : ''}
                                    {secondVendor?.HQ_CD_2ND ? '['.concat(String(secondVendor?.HQ_CD_2ND), ']') : ''}
                                    <br/><strong className={'item-wrap ellipsis2'}>{part?.PART_NM}</strong></p>
                                <button type={'button'} className={'btn-link'}>
                                    <i className={'hidden'}>{getDomain('DOMAIN4561')}</i>
                                </button>
                            </div>
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                {getDomain('DOMAIN4732')}
                            </span>
                            <div className={'text-item'}
                                 onClick={() => doSearchLine('RADIO', lineList || [], selectedLine)}>
                                <p>{line?.LINE_CD ? '['.concat(String(line?.LINE_CD), ']') : ''}<br/><strong>{String(line?.LINE_NM || '').replace(regexPlant, '$2')}</strong>
                                </p>
                                <button type={'button'} className={'btn-link'}>
                                    <i className={'hidden'}>{getDomain('DOMAIN4561')}</i>
                                </button>
                            </div>
                        </li>
                        <li className={'form-item'}>
                        <span className={'title'}>
                            {getDomain('DOMAIN4730')}
                        </span>
                            <div>
                                <input type={'number'} style={{textAlign: "right"}} maxLength={11}
                                       onInput={handleChange}
                                       inputMode={"numeric"}
                                       onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                        if(event.key === 'Enter') {
                                            targetAdd();
                                        }
                                    }}
                                       value={targetCnt}/>
                            </div>
                        </li>
                    </ul>
                    <div className={'add-btn-wrap'}>
                        <button type={'button'} className={'button-line square'} style={{marginBottom: '2rem'}}
                                onClick={() => targetAdd()}>{getDomain('DOMAIN4563')}
                        </button>
                    </div>
                    <div className={'card-wrap'}
                         style={{overflowY: 'unset', background: '#fff', marginTop: '0rem', padding: '0 0'}}>
                        <ul className={'card-list'}>
                            {
                                inspectResult.map((item, index) => {
                                    return <li className={'card-item'} key={'cardView_'.concat(String(index))}>
                                        <CardAddView data={item} callback={() => callbacDelFn(item.indexCnt)}/>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className={'btn-area bottom'}>
                    <button type={'button'} className={'button-primary'}
                            disabled={inspectResult.length > 0 ? false : true}
                            onClick={() => callbackAddFn()}>{getDomain('DOMAIN4443')}
                    </button>
                </div>
            </div>
        </>
    );

    /**
     * 카드뷰 동적 생성 처리
     * @param data
     * @param callback
     * @constructor
     */
    function CardAddView({data, callback}: { data: any, callback?: () => void }) {
        return (
            <>
                <div className={'item'} role={'button'}>
                    <section>
                        <div className={'label-wrap'}>
                            {data.HQ_INSP_YN === 'Y' && (
                                <i className={'label-type17'}>{data.HQ_INSP_YN_NM_MOB === undefined ? data.HQ_INSP_YN_NM : data.HQ_INSP_YN_NM_MOB}</i>
                            )}
                            {data.HQ_INSP_YN === 'N' && (
                                <i className={'label-type16'}>{data.HQ_INSP_YN_NM_MOB === undefined ? data.HQ_INSP_YN_NM : data.HQ_INSP_YN_NM_MOB}</i>
                            )}
                        </div>
                        <div className={'title-wrap'}>
                            <span>[{data.PLANT_CD}] [{data.PART_CD}] {data.HQ_INSP_YN === 'Y' ? '['.concat(data.HQ_CD_2ND, ']') : ''}
                            </span>
                            <strong>{data.PART_NM.split(';', 1)}</strong>
                        </div>
                    </section>
                    <ul className={'date-list'}>
                        {
                            <li>
                                <em style={{width: '50%'}}>[{data.LINE_CD}] {data.LINE_NM}</em>
                                <span style={{width: '50%'}}>{data.dateString}</span>
                            </li>
                        }
                        {
                            <li>
                                <em style={{width: '50%'}}>{data.SPEC_CNT_EXPR}</em>
                                <span style={{width: '50%'}}>{getDomain('DOMAIN4730')} {data.targetCntExpr}</span>
                            </li>
                        }
                    </ul>
                    <button type={'button'} className={'button-delete'} onClick={callback}>
                        <i className={'hidden'}>{getDomain('DOMAIN0229')}</i>
                    </button>
                </div>
            </>
        );
    };
};