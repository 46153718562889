import React, {useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import SampleQmvDetailInspectDetail from './SampleQmvDetailInspectDetail';

export default function SampleQmvDetailInspectList({state, nextTab}: {state: any, nextTab: (name: string) => void}) {
    const list = [
        {name: 'PCS 가로치수', sample: 5, inspect: 5, defect: 5, result: 'OK'},
        {name: 'PCS 세로치수', sample: 5, inspect: 5, defect: 15, result: 'OK'},
        {name: '두께', sample: 5, inspect: 5, defect: 5, result: 'Wait'},
        {name: '[PCB] 휨량', sample: 5, inspect: 5, defect: 5, result: 'OK'},
        {name: 'AU-도금두께(PC)', sample: 5, inspect: 5, defect: 5, result: 'Wait'},
        {name: 'NI-도금두께(PC)', sample: 5, inspect: 5, defect: 5, result: 'OK'},
        {name: 'XRF 테스트', sample: 5, inspect: 5, defect: 5, result: 'OK'},
        {name: '[자동화] 복합 PC', sample: 5, inspect: 5, defect: 5, result: 'NG'},
        {name: '복합 PCT', sample: 5, inspect: 5, defect: 5, result: 'OK'},
        {name: '외형치수가로(PC)', sample: 5, inspect: 5, defect: 5, result: 'OK'},
        {name: '외형치수세로(PC)', sample: 5, inspect: 5, defect: 5, result: 'OK'}
    ];

    const [showSwiper, setShowSwiper] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>();

    const goDetail = (index: number) => {
        setShowSwiper(false);
        setSelectedIndex(index);
    };

    const closeDetail = () => {
        setShowSwiper(false);
        setSelectedIndex(undefined);
    };

    useEffect(() => {
        if(selectedIndex !== undefined) {
            setShowSwiper(true);
        }
    }, [selectedIndex]);

    return (
        <>
            <div className={'table-wrap'} style={{display: 'block'}}>
                <table>
                    <colgroup>
                        <col style={{width: '3rem'}}/>
                        <col/>
                        <col style={{width: '4.8rem'}}/>
                        <col style={{width: '4.8rem'}}/>
                        <col style={{width: '4.8rem'}}/>
                        <col style={{width: '4.2rem'}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope={'col'}>No</th>
                            <th scope={'col'}>검사항목</th>
                            <th scope={'col'}>샘플수</th>
                            <th scope={'col'}>검사수</th>
                            <th scope={'col'}>불량수</th>
                            <th scope={'col'}>결과</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        list.map((item, index) => {
                            return <tr
                                key={'tr_'.concat(String(index))}
                                className={item.result === 'Wait' ? 'bg-color-y' : item.result === 'NG' ? 'bg-color-p' : ''}
                                onClick={() => goDetail(index)}>
                                <td key={'td_0_'.concat(String(index))}>{index + 1}</td>
                                <td key={'td_1_'.concat(String(index))} className={'left'}><a>{item.name}</a></td>
                                <td key={'td_2_'.concat(String(index))} className={'right'}>{item.sample}</td>
                                <td key={'td_3_'.concat(String(index))} className={'right'}>{item.inspect}</td>
                                <td key={'td_4_'.concat(String(index))} className={'right'}>{item.defect}</td>
                                <td key={'td_5_'.concat(String(index))}>
                                    <span key={'span_'.concat(String(index))} className={item.result === 'Wait' ? 'label-state wait' : item.result === 'NG' ? 'label-state ng' : 'label-state ok'}>{item.result}</span>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
            <div className={'btn-area bottom'}>
                <button type={'button'} className={'button-line'}>임시저장</button>
                <button type={'button'} className={'button-primary'} onClick={() => nextTab('detailedInspectionInformation')}>다음</button>
            </div>
            <CSSTransition in={showSwiper} classNames={showSwiper ? 'right' : 'left'} timeout={500} unmountOnExit>
                <SampleQmvDetailInspectDetail list={list} onClose={closeDetail} selected={selectedIndex}/>
            </CSSTransition>
        </>
    );
};