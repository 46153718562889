import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {STORAGE_NAME} from '../CommonConstants';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import useStorage from '../../hooks/useStorage';
import {NexacroJsonType, SessionValuesType} from '../model/SqciTypes';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {createBrowserHistory} from 'history';
import usePageMove from '../../hooks/usePageMove';
import Header from '../../pages/common/Header';
import useGlobalData from '../../hooks/useGlobalData';

interface CommonContextType {
    sessionValues?: SessionValuesType;
    dataset?: NexacroJsonType;
    language: string;
    authSession: boolean;
    authMfa: boolean;
    setSessionValues: (session?: SessionValuesType) => void;
    setDataset: (dataset?: NexacroJsonType) => void;
    setLanguage: (lang: string) => void;
    confirmHistoryBack: (usage: boolean, message?: string, okCallback?: () => void, nokCallback?: () => void) => void;
    setAuthSession: (isSuccess: boolean) => void;
    setAuthMfa: (isSuccess: boolean) => void;
}

const CommonData: CommonContextType = {
    sessionValues: JSON.parse(sessionStorage.getItem(STORAGE_NAME.SESSION_VALUES) || '{}') as SessionValuesType,
    dataset: JSON.parse(sessionStorage.getItem(STORAGE_NAME.DATASET) || '{}') as NexacroJsonType,
    language: JSON.parse(localStorage.getItem(STORAGE_NAME.LANGUAGE) || '{}').storageData || 'KO',
    authSession: false,
    authMfa: false,
    setSessionValues: (session?: SessionValuesType) => {},
    setDataset: (dataset?: NexacroJsonType) => {},
    setLanguage: (lang: string) => {},
    confirmHistoryBack: (usage: boolean, message?: string, okCallback?: () => void, nokCallback?: () => void) => {},
    setAuthSession: (isSuccess: boolean) => {},
    setAuthMfa: (isSuccess: boolean) => {}
}

const CommonContext = createContext<CommonContextType>(CommonData);

const history = createBrowserHistory();

const CommonProvider = ({children}: any) => {
    const location = useLocation();
    const {setStorage, getStorage, clearStorage} = useStorage();
    const {pageMove} = usePageMove();
    const {setGlobalSetting} = useGlobalData();

    const [sessionValues, changeSessionValues] = useState(CommonData.sessionValues);
    const [dataset, changeDataset] = useState(CommonData.dataset);
    const setDataset = useCallback((item?: NexacroJsonType) => changeDataset(item ? {...dataset, ...item} : {}), [dataset]);
    const setSessionValues = useCallback((item?: SessionValuesType) => changeSessionValues(item ? item : {}), [sessionValues]);

    /*
    로그인 성공 시, 글로벌 데이터셋이 설정된 후 로그인 세션 정보를 저장
     */
    useEffect(() => {
        setStorage('SESSION', STORAGE_NAME.DATASET, dataset);
        if(dataset && Object.keys(dataset).length > 0
            && dataset?.gds_user[0] && Object.keys(dataset?.gds_user[0]).length > 0
            && dataset?.gds_role[0] && Object.keys(dataset?.gds_role[0]).length > 0) {
            // setSessionValues(dataset?.gds_user[0]);
            setSessionValues({
                ...dataset?.gds_role[0],
                ...dataset?.gds_user[0]
            })
        }
    }, [setDataset]);

    /*
    로그인 세션 상태가 변경 된 후속조치
     */
    useEffect(() => {
        setStorage('SESSION', STORAGE_NAME.SESSION_VALUES, sessionValues);
        if(sessionValues && Object.keys(sessionValues).length > 0) {
            if(location.pathname === process.env.REACT_APP_URL_LOGIN
                || location.pathname === process.env.REACT_APP_URL_RESET_PASSWORD
                || location.pathname === process.env.REACT_APP_URL_TERMS) {
                console.log('%cLOGIN SUCCESS. MOVE TO MAIN PAGE', 'font-weight: bold;color: blue;');

                pageMove(process.env.REACT_APP_URL_MAIN || '', {
                    slideDirectionFrom: 'left'
                });
            }
        } else {
            console.log('%cEXPIRE SESSION. MOVE TO LOGIN PAGE', 'font-weight: bold;color: red;');
            clearStorage('SESSION', STORAGE_NAME.DATASET);
            clearStorage('SESSION', STORAGE_NAME.HISTORY);

            pageMove(process.env.REACT_APP_URL_LOGIN || '/login', {
                slideDirectionFrom: 'left'
            });
        }
    }, [setSessionValues]);

    /*
    뒤로가기 시, component 이탈 전 작업 설정
     */
    const [beforeCheckHistoryBack, setBeforeCheckHistoryBack] = useState<{
        usage: boolean;
        message?: string;
        okCallback?: () => void;
        nokCallback?: () => void;
    }>({
        usage: false,
        message : undefined,
        okCallback: undefined,
        nokCallback: undefined
    });
    const confirmHistoryBack = (usage: boolean, message?: string, okCallback?: () => void, nokCallback?: () => void) => {
        setBeforeCheckHistoryBack({
            usage: usage,
            message: message,
            okCallback: okCallback,
            nokCallback: nokCallback
        });
    };

    /*
    뒤로가기 시, component direction을 변경하고 component 이탈 전 작업을 초기화
     */
    const [slideDirectionFrom, setSlideDirectionFrom] = useState('right');
    useEffect(() => {
        setSlideDirectionFrom(location.state && location.state.slideDirectionFrom ? location.state.slideDirectionFrom : 'right');
        const historyEvent = history.listen(({action}) => {
            confirmHistoryBack(false);
            if(action === 'POP') {
                if(location.pathname === process.env.REACT_APP_URL_TERMS || location.pathname === process.env.REACT_APP_URL_RESET_PASSWORD) {
                    window.location.href = process.env.REACT_APP_URL_MAIN || '/';
                } else {
                    setSlideDirectionFrom('left');
                }
            }
        });
        return historyEvent;
    }, [location.pathname]);

    const [language, changeLanguage] = useState<string>(CommonData.language);
    const setLanguage = useCallback((lang: string) => {
        changeLanguage(lang);
    }, [language]);
    useEffect(() => {
        setGlobalSetting(STORAGE_NAME.LANGUAGE, language);
    }, [setLanguage]);

    const [authSession, changeAuthSession] = useState<boolean>(CommonData.authSession);
    const setAuthSession = useCallback((isSuccess: boolean) => {
        changeAuthSession(isSuccess);
    }, [authSession]);
    const [authMfa, changeAuthMfa] = useState<boolean>(CommonData.authMfa);
    const setAuthMfa = useCallback((isSuccess: boolean) => {
        changeAuthMfa(isSuccess);
    }, [authMfa]);

    const [enteredComponent, setEnteredComponent] = useState<string>();
    const [exitedComponent, setExitedComponent] = useState<string>();
    return (
        <CommonContext.Provider value={{
            sessionValues,
            dataset,
            language,
            authSession,
            authMfa,
            setSessionValues,
            setDataset,
            setLanguage,
            confirmHistoryBack,
            setAuthSession,
            setAuthMfa
        }}>
            <Header usage={beforeCheckHistoryBack.usage} message={beforeCheckHistoryBack.message} okCallback={beforeCheckHistoryBack.okCallback} nokCallback={beforeCheckHistoryBack.nokCallback}/>
            <TransitionGroup
                className={
                    location.pathname === process.env.REACT_APP_URL_LOGIN
                        || location.pathname === process.env.REACT_APP_URL_EXCEPTION ? 'transitions-wrapper-noheader' : 'transitions-wrapper'
                }
                childFactory={child => {
                    return React.cloneElement(child, {
                        classNames: slideDirectionFrom
                    });
                }}>
                <CSSTransition
                    key={location.pathname}
                    classNames={slideDirectionFrom}
                    timeout={500}
                    unmountOnExit
                    onEntered={() => setEnteredComponent(location.pathname)}
                    onExited={() => setExitedComponent(location.pathname)}
                >
                    <Outlet context={{
                        enteredComponent: enteredComponent,
                        exitedComponent: exitedComponent
                    }}/>
                </CSSTransition>
            </TransitionGroup>
        </CommonContext.Provider>
    );
};

export const useDataset = () => useContext(CommonContext);

export default CommonProvider;