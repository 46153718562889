import React, {useCallback} from 'react';
import axios, {AxiosRequestConfig, AxiosResponse, Method} from 'axios';
import {SQCI_EXCEPTION, SqciException} from '../components/exception/CommonException';
import {requestParser, responseParser} from '../components/utils/NexacroParseUtil';
import {useComponent} from '../components/contexts/ComponentContext';
import {NexacroJsonType} from '../components/model/SqciTypes';

interface AxiosHeaderType {
    headers: {
        'Content-Type'?: string;
        Accept?: string;
        Authorization?: string
    }
}

export const AXIOS_HEADER = {
    NEXACRO: {
        headers: {
            'Content-Type': process.env.REACT_APP_HTTP_CONTENT_TYPE_XML || 'text/xml',
            'Accept': process.env.REACT_APP_HTTP_ACCEPT_XML || 'application/xml'
        }
    } as AxiosHeaderType,
    JSON: {
        headers: {
            'Content-Type': process.env.REACT_APP_HTTP_CONTENT_TYPE_JSON || 'application/json',
            'Accept': process.env.REACT_APP_HTTP_ACCEPT_JSON || 'application/json'
        }
    } as AxiosHeaderType,
    FORM: {
        headers: {
            'Content-Type': process.env.REACT_APP_HTTP_CONTENT_TYPE_FORM || 'application/x-www-form-urlencoded'
        }
    } as AxiosHeaderType,
    GET: {
        headers: {
            'Accept': process.env.REACT_APP_HTTP_CONTENT_TYPE_XML || 'text/xml'
        }
    } as AxiosHeaderType
} as const;

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_HTTP_BASE_URL,
    timeout: Number(process.env.REACT_APP_HTTP_TIMEOUT) || 6000,
    withCredentials: true,
    maxRedirects: 0
});

axiosInstance.interceptors.response.use((response: AxiosResponse) => {
    // const regex: RegExp = /^((https?):\/\/([^:\/\s]+)(:([^\/]*))?)(?<uri>((\/[^\s/\/]+)*)?\/([^#\s\?]*))\??(?<params>([^#\s]*))?#?(?<anchor>(\w*))?$/gi;
    // const requestUri: string = response?.config.url || '';
    // const redirectUri: string = response?.request.responseURL.replace(regex, '$<uri>');
    // const pathParams: string = response?.request.responseURL.replace(regex, '$<params>');
    // const anchor: string = response?.request.responseURL.replace(regex, '$<anchor>');

    const regex: RegExp = /^((https?):\/\/([^:\/\s]+)(:([^\/]*))?)(((\/[^\s/\/]+)*)?\/([^#\s\?]*))\??(([^#\s]*))?#?((\w*))?$/gi;
    const requestUri: string = response?.config.url || '';
    const redirectUri: string = response?.request.responseURL.replace(regex, '$6');
    const pathParams: string = response?.request.responseURL.replace(regex, '$10');
    const anchor: string = response?.request.responseURL.replace(regex, '$12');

    if(requestUri !== redirectUri) {
        // console.log('requestUri: ', requestUri, '\nredirectUri: ', redirectUri, '\npathParams: ', pathParams, '\nanchor: ', anchor, '\nresponse: ', response);
        switch(redirectUri) {
            case '/common/identity/anonymous/SQCI3/loginPage.do':
                throw new SqciException(SQCI_EXCEPTION.INVALID_SESSION);
                break;
            case '/common/':
                throw new SqciException(SQCI_EXCEPTION.EXPIRE_PRIVATE_KEY);
                break;
            case '/common/identity/anonymous/SQCI3/duplicationLogout.html':
                throw new SqciException(SQCI_EXCEPTION.SAME_ACCOUNT);
                break;
            case '/common/identity/anonymous/error.jsp':
                throw new SqciException(SQCI_EXCEPTION.COMMON_ERROR);
                break;
            case '/common/identity/SQCI3/anonymous/logout.html':
                throw new SqciException(SQCI_EXCEPTION.INVALID_SESSION);
                break;
            case '/common/identity/anonymous/SQCI3/logInPrivateKeyError.do':
                throw new SqciException(SQCI_EXCEPTION.EXPIRE_PRIVATE_KEY);
                break;
            case '/common/identity/anonymous/SQCI3/wrongPasswordException.do':
                throw new SqciException(SQCI_EXCEPTION.WRONG_PASSWORD);
                break;
            case '/common/identity/anonymous/SQCI3/userNotExistsError.do':
                throw new SqciException(SQCI_EXCEPTION.WRONG_PASSWORD);
                break;
            case '/common/identity/anonymous/SQCI3/passwdChange.do':
                throw new SqciException(SQCI_EXCEPTION.EXPIRED_PASSWORD);
                break;
            case '/common/identity/anonymous/SQCI3/ExpireUser.do':
                throw new SqciException(SQCI_EXCEPTION.EXPIRED_USER);
                break;
            case '/common/identity/anonymous/SQCI3/userWatingError.do':
                throw new SqciException(SQCI_EXCEPTION.PENDING_APPROVAL);
                break;
            default:
                break;
        }
    }

    return response;
}, (e) => {
    if(e instanceof SqciException) {
        return Promise.reject(e);
    } else {
        return Promise.reject(new SqciException(SQCI_EXCEPTION.COMMON_ERROR));
    }
});

const useAxios = () => {
    const {setLoader} = useComponent();
    const sendAxios = useCallback(async (url: string, method: Method, requestHeader: AxiosHeaderType, data?: any, isBackground?: boolean): Promise<AxiosResponse | NexacroJsonType | undefined> => {
        if(url && method && requestHeader) {
            try {
                const config: AxiosRequestConfig = {
                    ...axiosInstance.defaults.headers,
                    transformRequest: (requestData, header) => {
                        if((isBackground || false) === false) {
                            setLoader(true);
                        }

                        if(requestHeader === AXIOS_HEADER.NEXACRO) {
                            header.setContentType(process.env.REACT_APP_HTTP_CONTENT_TYPE_XML || 'text/xml');
                            header.setAccept('*/*');
                        } else if(requestHeader === AXIOS_HEADER.JSON) {
                            header.setContentType(process.env.REACT_APP_HTTP_CONTENT_TYPE_JSON || 'application/json');
                            header.setAccept('*/*');
                        } else if(requestHeader === AXIOS_HEADER.FORM) {
                            header.setContentType(process.env.REACT_APP_HTTP_CONTENT_TYPE_FORM || 'application/x-www-form-urlencoded');
                        } else if(requestHeader === AXIOS_HEADER.GET) {
                            header.setAccept('*/*');
                        } else {
                            header.setContentType(process.env.REACT_APP_HTTP_CONTENT_TYPE_JSON || 'application/json');
                            header.setAccept('*/*');
                        }

                        if(requestData && requestHeader === AXIOS_HEADER.NEXACRO && data) {
                            return requestParser(requestData);
                        } else if(requestData && requestHeader === AXIOS_HEADER.JSON && data) {
                            return JSON.stringify(requestData);
                        } else {
                            return requestData;
                        }
                    },
                    transformResponse: (responseData, header, status) => {
                        let responseResult = undefined;
                        const responseContentType = header.getContentType(new RegExp(/application\/json/gi));
                        if(responseContentType && responseContentType.index >= 0) {
                            responseResult = JSON.parse(responseData || '{}');
                        } else {
                            responseResult = responseData;
                        }

                        let result = undefined;
                        if(responseResult && requestHeader === AXIOS_HEADER.NEXACRO) {
                            const isXmlResponse = header.getContentType(new RegExp(/text\/xml/gi));
                            if(isXmlResponse && isXmlResponse.index >= 0) {
                                result = responseParser(responseResult);
                            } else {
                                result = responseResult;
                            }
                        } else {
                            result = responseResult;
                        }

                        if((isBackground || false) === false) {
                            setLoader(false);
                        }

                        return result;
                    }
                };

                if('POST' === method.toUpperCase()) {
                    return await axiosInstance.post(url, data, config);
                } else if('GET' === method.toUpperCase()) {
                    return await axiosInstance.get(url, {
                        ...config,
                        params: data || {}
                    });
                }
            } catch(e) {
                throw e;
            }
        }
    }, []);

    return {sendAxios};
};

export default useAxios;