import React from 'react';
import {v4 as uuidv4} from 'uuid';
import {NexacroJsonUnitType} from "../../../../components/model/SqciTypes";
import useGlobalData from "../../../../hooks/useGlobalData";

export default function QmsPopIssuePartList({title, list, onClose}: {title: string, list: NexacroJsonUnitType[], onClose: () => void}) {
    const {getDomain} = useGlobalData();

    return (
        <>
            <div className={'pop-layer'} style={{display: 'block'}}>
                <div className={'wrap'}>
                    <header>
                        <h1>{title}</h1>
                        <span className={'btn-wrap right'}>
                            <button type={'button'} className={'button-close'} onClick={onClose}>
                                <i className={'hidden'}>{getDomain('DOMAIN0173')}</i>  {/*닫기*/}
                            </button>
                        </span>
                    </header>
                    <div className={'container'}>
                        <div className={'card-wrap'}>
                            <ul className={'card-list'}>
                                {
                                    list.map(item => {
                                        return <li key={uuidv4()} className={'card-item'}>
                                            <div className={'item'} role={'button'}>
                                                <p>
                                                    <span>
                                                        <em>[{item.MGT_NO}]</em>
                                                        <i className={'label-type1'}>{item.ISSUE_TYPE_NM}</i>
                                                        <i className={'label-type2'}>{item.FIX_DT}</i>
                                                    </span>
                                                    {/*<strong>{item.ECR_TITLE}</strong>*/}
                                                </p>
                                                <ul className={'date-list'}>
                                                    <li>
                                                        <em>{getDomain('DOMAIN4661')}{/*발생 Plant*/} : [{item.PLANT_CD}]</em>
                                                        <span>{getDomain('DOMAIN4662')}{/*발생장소*/} : {item.ISSUE_PLACE_NM}</span>
                                                    </li>
                                                    <li>
                                                        {getDomain('DOMAIN4663')}{/*불량그룹/증상*/} : {item.DEFECT_GROUP_NM + '/' + item.SYMPTOM_NM}
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>;
                                    })
                                }
                            </ul>
                        </div>
                        <div className={'btn-area bottom'}>
                            <button type={'button'} className={'button-primary'} onClick={onClose}>{getDomain('DOMAIN0173')}</button>  {/*닫기*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};