import React from 'react';
import xml2js from 'xml2js';
import {NexacroAttributeType, NexacroJsonType, NexacroRowType, NexacroXmlType} from '../model/SqciTypes';
import {SQCI_EXCEPTION, SqciException} from '../exception/CommonException';

export const requestParser = (json: NexacroJsonType): string | null => {
    // Nexacro XML 데이터 프리셋 설정
    let datasetMap: NexacroXmlType = {
        Root: {
            $: {
                xmlns: 'http://www.nexacroplatform.com/platform/dataset'
            },
            Parameters: [],
            Dataset: []
        }
    };

    try {
        // Dataset 별 데이터 추출
        for(const [dataset, rows] of Object.entries(json)) {
            let nexacroRows: NexacroRowType[] = [];
            let nexacroColumnInfos: NexacroAttributeType[] = [];
            let columnNameList: string[] = [];

            if('Parameters' === dataset) {
                for(const parameter of rows) {
                    let nexacroParameters: NexacroAttributeType[] = [];
                    for(const [key, value] of Object.entries(parameter)) {
                        nexacroParameters.push({
                            $: {
                                id: key
                            },
                            _: value || ''
                        });
                    }
                    datasetMap.Root.Parameters!.push({
                        Parameter: nexacroParameters
                    });
                }
            } else {
                // Rows > Row > Col / ColumnInfo > Column 데이터 목록 추출
                for(const columns of rows) {
                    // Rows > Row > Col / ColumnInfo > Column 데이터 추출
                    let nexacroColumns: NexacroAttributeType[] = [];
                    for(const [key, value] of Object.entries(columns)) {
                        // Rows > Row > Col 데이터 세팅
                        nexacroColumns.push({
                            $: {
                                id: key
                            },
                            _: value || ''
                        });

                        // ColumnInfo > Column 데이터 목록 추출
                        if(!columnNameList.includes(key)) {
                            columnNameList.push(key);
                        }
                    }

                    // Rows > Row 데이터 세팅
                    nexacroRows.push({
                        Col: nexacroColumns
                    });
                }

                // ColumnInfo > Column 데이터 세팅
                for(const column of columnNameList) {
                    nexacroColumnInfos.push({
                        $: {
                            id: column
                        }
                    });
                }

                // Dataset 데이터 세팅
                datasetMap.Root.Dataset!.push({
                    $: {
                        id: dataset
                    },
                    ColumnInfo: {
                        Column: nexacroColumnInfos
                    },
                    Rows: {
                        Row: nexacroRows
                    }
                });
            }
        }
    } catch(e) {
        throw new SqciException(SQCI_EXCEPTION.NEXACRO_PARSING_ERROR);
    }

    const builder = new xml2js.Builder();
    return builder.buildObject(datasetMap);
};

export const responseParser = (xmlResponse: string): NexacroJsonType | null => {
    let response: NexacroJsonType = {};

    try {
        const xml2jsParser = new xml2js.Parser();
        xml2jsParser.parseString(xmlResponse!, (e, xml: NexacroXmlType) => {
            for(const parameters of xml.Root.Parameters || []) {
                for(const parameter of parameters.Parameter) {
                    if('ErrorCode' === parameter.$.id && ('-80' === parameter._ || '-90' === parameter._)) {
                        // invalid session
                        throw new SqciException(SQCI_EXCEPTION.INVALID_SESSION);
                    }
                }
            }

            for(const dataset of xml.Root.Dataset || []) {
                // Dataset
                response = {
                    ...response,
                    [dataset.$.id]: []
                };
                for(const [, rows] of Object.entries(dataset.Rows)) {
                    // Dataset > Rows
                    for(const [, columns] of Object.entries(rows)) {
                        // Dataset > Rows > Row
                        for(const [, column] of Object.entries(columns)) {
                            // Dataset > Rows > Row > Col
                            let colGroup: Record<string, string | number> = {};
                            let colItems: NexacroAttributeType = column.Col;
                            if(colItems) {
                                for(const [, item] of Object.entries(colItems)) {
                                    // Dataset > Rows > Row > Col items
                                    colGroup = {
                                        ...colGroup,
                                        [item.$.id]: item._
                                    };
                                }
                                response[dataset.$.id].push(colGroup);
                            }
                        }
                    }
                }
            }
        });
    } catch(e) {
        if(e instanceof SqciException) {
            throw e;
        } else {
            throw new SqciException(SQCI_EXCEPTION.NEXACRO_PARSING_ERROR);
        }
    }

    return response;
}