import React from 'react';
import Footer from '../common/Footer';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
import useHtmlElement from '../../hooks/useHtmlElement';
import {useComponent} from '../../components/contexts/ComponentContext';
import usePageMove from '../../hooks/usePageMove';
import useGlobalData from '../../hooks/useGlobalData';

export default function ResetPassword() {
    const {sendAxios} = useAxios();
    const {useInput} = useHtmlElement();
    const {setDialog, setLoader} = useComponent();
    const {pageMove} = usePageMove();
    const {getMessage} = useGlobalData();

    const loginId = useInput('loginId', '', 'ID');
    const email = useInput('email', '', 'Email');

    const doResetPassword = () => {
        if(loginId.value && email.value) {
            setLoader(true);
            sendAxios('/common/identity/anonymous/SQCI3/mobileIdEmailByPassWordSearch.do', 'POST', AXIOS_HEADER.JSON, {
                loginId: loginId.value,
                email: email.value
            }, true)
            .then(response => {
                setLoader(false);

                if(response?.data._key === 'passwordSearch') {
                    setDialog({
                        show: true,
                        type: 'ALERT',
                        text: JSON.parse(response?.data.searchStatus) ? 'The basic password has been sent by email.' : 'There is no information that matches the ID and email entered.',
                        confirm: () => {
                            if(JSON.parse(response?.data.searchStatus)) {
                                pageMove(process.env.REACT_APP_URL_LOGIN || '');
                            } else {
                                loginId.onChange();
                                email.onChange();
                            }
                        },
                    });
                }
            })
            .catch(e => {
                setDialog({
                    show: true,
                    type: 'ALERT',
                    text: 'Failed to change password.',
                    confirm: () => setLoader(false)
                });
                return new Promise(() => {});
            });
        } else {
            setDialog({
                show: true,
                type: 'ALERT',
                text: 'Please enter ID/Email.',
                confirm: () => {
                    loginId.onChange();
                    email.onChange();
                },
            });
        }
    };

    return (
        <>
            <div className={'container login'}>
                <div className={'login-conts'}>
                    <div className={'heading-group type2'}>
                        <h1>SQCI</h1>
                        <p>Supplier Quality<br/>Control Innovation</p>
                    </div>
                    <p className={'password-text'}>
                        SQCI 시스템에서 사용하는 아이디와 이메일 주소를 입력해 주세요.<br/>
                        해당 주소로 비밀번호 재설정 메일을 보내 드립니다.<br/>
                        Enter your ID and Email. We’ll email instructions on
                        how to reset your password.
                    </p>
                    <fieldset>
                        <input type={'text'} {...loginId}/>
                        <input type={'text'} {...email}/>
                        <input type={'button'} value={'Confirm'} onClick={doResetPassword}/>
                    </fieldset>
                </div>
                <Footer/>
            </div>
        </>
    );
};