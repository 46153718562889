/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > 입고검사 조회 > 목록 판정
 * 02. 프로그램ID : QmsPIncmInspReg.tsx
 * 03. 프로그램명 : 검사대상 및 결과입력 리스트
 * 04. 화면설명 : 입고검사 검사대상 목록 판정
 * 05. 작성일 : 2023.08.24
 * 06. 작성자 : 채보람
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-08-24     채보람    최초 작성
 **************************************************************************************************
 */

import React, {useEffect, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import {CSSTransition} from 'react-transition-group';
import Picture from '../common/Picture';
import * as gfn from "../../components/utils/CommonUtils";
import {useDataset} from "../../components/contexts/CommonContext";
import {toNumber} from "lodash";
import {requestParser, responseParser} from "../../components/utils/NexacroParseUtil";
import useAxios, {AXIOS_HEADER} from "../../hooks/useAxios";
import {NexacroJsonType, NexacroJsonUnitType} from "../../components/model/SqciTypes";
import {useError} from "../../hooks/useException";
import {useComponent} from "../../components/contexts/ComponentContext";
import useGlobalData from "../../hooks/useGlobalData";
import {gfn_isNull} from "../../components/utils/CommonUtils";
import {STORAGE_NAME} from "../../components/CommonConstants";

export default function QmsTranLineMeas({ds_ListLineMeas, onClose, selected}: {ds_ListLineMeas: any, onClose: () => void, selected?: number}) {
    const {sendAxios} = useAxios();
    const {setDialog} = useComponent();
    const {getMessage} = useGlobalData();
    const {getSdpSysDate} = useGlobalData();
    const {dataset, sessionValues} = useDataset();
    const {getGlobalSetting} = useGlobalData();
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const {getDomain} = useGlobalData();

    const [memo, setMemo] = useState(String);
    const [measResult, setMeasResult] = useState(String);
    const {throwException} = useError();

    let vds_ListLineMeas: any = [];

    useEffect(() => {
        form_onload();
    }, []);

    /* form_onload */
    function form_onload() {
        // console.log("입고검사 > 입고검사 조회 > 목록 판정");
        // console.log("vds_ListLineMeas");
        // console.log(vds_ListLineMeas);
        // console.log("입고검사 > 입고검사 조회 > 목록 판정");
    }

    /* 목록 판정 confirm */
    function confirmMeas() {
        if (measResult == "") {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG09001").replace("{0}",getDomain("DOMAIN1914")),
                confirm: () => {
                }
            });

            return;
        }

        if (ds_ListLineMeas['SUB_STATUS'] == "N" || ds_ListLineMeas['SUB_STATUS'] == "P") {
            //성적서 미제출일때 임시저장은 가능, 합격판정은 할 수 없음 불합격판정은 가능함.
            if (measResult == "1") {
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage("MSG01046"),
                    confirm: () => {
                    }
                });

                return;
            }

        }

        setDialog({
            type: 'CONFIRM',
            show: true,
            text: getMessage("MSG00015"),
            confirm: () => {
                fn_tranLineMeas();
            }
        });
    }

    /* 목록 판정 */
    async function fn_tranLineMeas() {
        vds_ListLineMeas.push(ds_ListLineMeas);

        let today = "";

        await getSdpSysDate().then(response => {
            today = response?.data.ds_Output[0]['SERVERTIME'];
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });

        for (let i = 0; i < vds_ListLineMeas.length; i++) {
            vds_ListLineMeas[i]['INSP_USER_LIST'] = vds_ListLineMeas[i]['INSP_USER'];  //검사담당자 저장
            vds_ListLineMeas[i]['INSP_USER'] = sessionValues?.USER_ID;
            vds_ListLineMeas[i]['INSP_USER_NM'] = sessionValues?.USER_NAME;
            vds_ListLineMeas[i]['SEC_INSP_DT'] = today.substr(0, 8);
            vds_ListLineMeas[i]['SEC_INSP_TIME'] = today.substr(8);

            //ECN 여러개 있을 때 String 형태로 다 던짐(구분자 콤마)
            vds_ListLineMeas[i]['EC_NOS'] = vds_ListLineMeas[i]['MOD_PART_MEMO'];

            //메모
            vds_ListLineMeas[i]['RESULT_MEMO'] = memo;

            //판정 결과
            vds_ListLineMeas[i]['SEC_MEAS_RESULT'] = measResult;
            vds_ListLineMeas[i]['RESULT'] = measResult;

            //임시저장일때 임시저장 번호
            vds_ListLineMeas[i]['INSP_RESULT_NO'] = vds_ListLineMeas[i]['INSP_RESULT_NO_TMP'];

            vds_ListLineMeas[i]['MOBILE_YN'] = "Y";
        }

        const requestJson: NexacroJsonType = {
            ds_ListLineMeas: vds_ListLineMeas
        };

        sendAxios('/common/nexacro/QMS_P_Incm_InspectLineSave.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            let dsRes = response?.data.ds_Res;

            if (dsRes.length > 0) {
                if (dsRes[0]['CODE'] == "SUCESS") {
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG01132"),
                        confirm: () => {
                            onClose();
                        }
                    });
                } else {
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG09999"),
                        confirm: () => {
                        }
                    });
                }
            }
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    function inputMeasResult(e: any) {
        setMeasResult(e.target.value);
    }

    /* set memo */
    function inputMemo(e: any) {
        setMemo(e.target.value);
    }

    return (
        <>
            <div className={'pop-layer'} style={{display: 'block'}}>
                <div className={"wrap"}>
                    <header>
                        <h1>{getDomain('DOMAIN4668')}</h1> {/*판정결과 입력*/}
                        <span className={"btn-wrap right"}>
                            <button type="button" className={"button-close"} onClick={() => onClose()}><i className={"hidden"}>{getDomain('DOMAIN0173')}</i></button>  {/*닫기*/}
                         </span>
                    </header>
                    <div className={"container"}>
                        <div className={"contents-table-wrap"}>
                            <ul className={"contents-table-list form"}>
                                <li>
                                    <div className={"title"}>{getDomain('DOMAIN2959')}</div>  {/*I/V No.*/}
                                    <div className={"item-wrap"}>{ds_ListLineMeas['INV_NO']}</div>
                                </li>
                                <li>
                                    <div className={"title"}>{getDomain('DOMAIN4441')}</div>  {/*부품코드*/}
                                    <div className={"item-wrap"}>{ds_ListLineMeas['PART_CD']}</div>
                                </li>
                                <li>
                                    <div className={"title"}>
                                        <span>{getDomain('DOMAIN1914')}</span>  {/*판정결과*/}
                                    </div>
                                    <div className={"item-wrap"}>
                                        <select onChange={inputMeasResult}>
                                            <option key={''} value={''}>==</option>
                                            {
                                                (dataset?.ds_CommonCode || [])
                                                .filter((i, index) => i.CATEGORY === "SQCI000127" && i.LANG === gv_locale)
                                                .map((i, index) => {
                                                    return <option key={i.CD} value={i.CD as string}>{i.CDNM}</option>;
                                                })
                                            }
                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <div className={"title"}>
                                        <span>{getDomain('DOMAIN4623')}</span>  {/*메모*/}
                                    </div>
                                    <div className={"item-wrap"}>
                                        <textarea onBlur={inputMemo} defaultValue={memo}></textarea>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className={"btn-area bottom"}>
                            <button type={"button"} className={"button-line"} onClick={() => onClose()}>{getDomain('DOMAIN2081')}</button>  {/*취소*/}
                            <button type={"button"} className={"button-primary"} onClick={() => confirmMeas()}>{getDomain('DOMAIN2083')}</button>  {/*저장*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};