import React, {useEffect, useState} from 'react';
import usePageMove from '../../hooks/usePageMove';
import {ROUTERS} from '../../components/Routers';
import {useLocation} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';
import Navigation from './Navigation';
import {useDataset} from '../../components/contexts/CommonContext';
import {STORAGE_NAME} from '../../components/CommonConstants';
import useGlobalData from '../../hooks/useGlobalData';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
import {useComponent} from '../../components/contexts/ComponentContext';

export default function Header({usage, message, okCallback, nokCallback}: {usage?: boolean, message?: string, okCallback?: () => void, nokCallback?: () => void}) {
    const {pageMove} = usePageMove();
    const location = useLocation();
    const {dataset, setSessionValues, sessionValues, setAuthMfa, setAuthSession} = useDataset();
    const {getGlobalSetting, getMessage, getDomain} = useGlobalData();
    const {sendAxios} = useAxios();
    const {setDialog} = useComponent();

    const [showHeader, setShowHeader] = useState<boolean>(false);
    const [pageTitleCode, setPageTitleCode] = useState<string>();
    const [showNavigation, setShowNavigation] = useState<boolean>(false);
    const [showNavigate, setShowNavigate] = useState<boolean>(false);
    const [showHistoryBack, setShowHistoryBack] = useState<boolean>(false);

    const [pageTitle, setPageTitle] = useState<string>();

    useEffect(() => {
        for(const router of ROUTERS) {
            if(location.pathname === router.path) {
                setPageTitleCode(router.title);
                setShowHeader(router.showHeader);
                setShowNavigate(router.showNavigate);
                setShowHistoryBack(router.showHistoryBack);
            }
        }
    }, [location.pathname]);

    useEffect(() => {
        if(pageTitleCode && dataset && dataset['gds_mobileMenu']) {
            const title = dataset['gds_mobileMenu']
            .filter(menu => menu.MENU_KEY === pageTitleCode)
            .map(menu => menu['MENU_NM_'.concat(String('VN' === getGlobalSetting<string>(STORAGE_NAME.LANGUAGE) ? 'VI' : getGlobalSetting<string>(STORAGE_NAME.LANGUAGE)))] || menu['MENU_NM_KO']);
            setPageTitle(String(title || process.env.REACT_APP_PROJECT_NAME));
        } else {
            setPageTitle(process.env.REACT_APP_PROJECT_NAME);
        }
    }, [pageTitleCode]);

    const doHistoryBack = () => {
        if(location.pathname === process.env.REACT_APP_URL_MAIN) {
            setDialog({
                show: true,
                type: 'CONFIRM',
                text: getMessage('MSG01560'),
                confirm: () => {
                    sendAxios('/common/identity/logout.do', 'GET', AXIOS_HEADER.GET)
                    .catch(e => {
                        pageMove(process.env.REACT_APP_URL_MAIN || '/');
                    })
                    .finally(() => {
                        setAuthSession(false);
                        setAuthMfa(false);
                        setSessionValues();
                    });
                }
            });
        } else if(location.pathname === process.env.REACT_APP_URL_TERMS){
            // if(sessionValues && Object.entries(sessionValues || {}).length > 0) {
            //     pageMove(process.env.REACT_APP_URL_MAIN || '/');
            // } else {
            //     pageMove(process.env.REACT_APP_URL_LOGIN || '/login');
            // }
            window.location.href = process.env.REACT_APP_URL_MAIN || '/';
        } else {
            if(showNavigate) {
                if(usage) {
                    setDialog({
                        show: true,
                        type: 'CONFIRM',
                        text: message,
                        confirm: () => {
                            if(okCallback) {
                                okCallback();
                            } else {
                                pageMove(-1);
                            }
                        },
                        cancel: () => {
                            if(nokCallback) {
                                nokCallback();
                            } else {
                                pageMove(-1);
                            }
                        },
                    });
                } else {
                    pageMove(-1);
                }
            } else {
                pageMove(process.env.REACT_APP_URL_LOGIN || '/login');
            }
        }
    };

    return (
        <>
            {
                showHeader ?
                <header>
                    <span className={'btn-wrap left'}>
                        {
                            showHistoryBack ?
                            <button type={'button'} className={'button-back'} onClick={doHistoryBack}>
                                <i className={'hidden'}>{getMessage('MSG01554')}</i>
                            </button> : null
                        }
                    </span>
                    <h1>{pageTitle}</h1>
                    {
                        showNavigate ?
                        <span className={'btn-wrap right'}>
                            <button type={'button'} className={'button-menu'} onClick={() => setShowNavigation(true)}>
                                <i className={'hidden'}>{getDomain('DOMAIN4736')}</i>
                            </button>
                        </span> : null
                    }

                    <CSSTransition in={showNavigation} classNames={showNavigation ? 'right' : 'left'} timeout={500} unmountOnExit>
                        <Navigation onClose={() => setShowNavigation(false)}/>
                    </CSSTransition>

                </header> : null
            }
        </>
    );
};