/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > 입고검사 조회
 * 02. 프로그램ID : QmsPIncmInspReg.tsx
 * 03. 프로그램명 : 검사대상 및 결과입력 리스트
 * 04. 화면설명 : 입고검사 검사대상
 * 05. 작성일 : 2023.08.24
 * 06. 작성자 : 채보람
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-08-24     채보람    최초 작성
 **************************************************************************************************
 */

import React, {useEffect, useState} from 'react';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
import {NexacroJsonType, NexacroJsonUnitType, RsaModel, SearchInfoType} from '../../components/model/SqciTypes';
import useHtmlElement from '../../hooks/useHtmlElement';
import {Link, useNavigate} from "react-router-dom";
import {useDataset} from "../../components/contexts/CommonContext";
import {useError} from "../../hooks/useException";
import usePageMove from '../../hooks/usePageMove';
import {SelectBoxType} from '../../components/model/SqciTypes';
import useDatePicker from '../../hooks/useDatePicker';
import {useSearch} from '../../hooks/useSearch';
import useGlobalData from '../../hooks/useGlobalData';
import * as gfn from "../../components/utils/CommonUtils";
import useStorage from "../../hooks/useStorage";
import {useComponent} from "../../components/contexts/ComponentContext";
import {STORAGE_NAME} from "../../components/CommonConstants";
import Scanner from "../common/Scanner";
import {CSSTransition} from "react-transition-group";
import {useLocation, useOutletContext} from "react-router-dom";

const regexPlant: RegExp = /^(\[\w+\])(.*)$/gi;

export default function QmsMIncmInspList() {
    const navigate = useNavigate();
    const {dataset, sessionValues} = useDataset();
    const {sendAxios} = useAxios();
    const {throwException} = useError();
    const {pageMove} = usePageMove();
    const {useInput, useSelectBox} = useHtmlElement();
    const {getDocumentHistory} = useStorage();

    // const datePicker = useDatePicker('RANGE', undefined, {range: {start : fn_dateToString_N("D", -7) + "000000", end: fn_dateToString_Z(new Date) + "000000"}});
    // const datePickerFrom = useDatePicker('SINGLE', undefined, {single: {selected : fn_dateToString_N("D", -7) + "000000"}});
    // const datePickerTo = useDatePicker('SINGLE', undefined, {single: {selected : fn_dateToString_Z(new Date) + "000000"}});
    const [datePickerFromWithDash, setDatePickerFromWithDash] = useState(getDocumentHistory('datePickerFromWithDash') ? getDocumentHistory('datePickerFromWithDash') : fn_dateToString_N("D", -7, true));
    const [datePickerToWithDash, setDatePickerToWithDash] = useState(getDocumentHistory('datePickerToWithDash') ? getDocumentHistory('datePickerToWithDash') : fn_dateToString_Z(new Date, true));

    const {getSdpVendorList, getSdpPartList, getGlobalSetting, getMessage, getDomain} = useGlobalData();
    const {doSearch, searchComponent, doSearchDivision, doSearchPlant, doSearchVendor, doSearchPart} = useSearch();
    const {setDialog} = useComponent();

    // (1:협력사, 2:사업, 3:사업, 4:법인검사자, 5:법, 6:전사, 7:관리자, 8:IT담당자)
    let fv_isDivisionQ = dataset?.gds_user[0]["DIVISION_CD"] == "Q" || dataset?.gds_role[0]["WORK_GROUP_CD"] == 7 ? true : false;//보람보람

    let temp_ds_Search: NexacroJsonUnitType[] = [{}];
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);

    const [vendorList, setVendorList] = useState<NexacroJsonUnitType[]>();
    const [partList, setPartList] = useState<NexacroJsonUnitType[]>();
    //검색조건 저장 KJS
    const {setStorage, getStorage, clearStorage} = useStorage();
    const location = useLocation();
    const getSearchCondition:any = getStorage('LOCAL', location.pathname);
    //change local storage _ KJS
    const [division, setDivision] = useState<NexacroJsonUnitType>();
    const [plant, setPlant] = useState<NexacroJsonUnitType>();
    const [vendor, setVendor] = useState<NexacroJsonUnitType>();

    const [part, setPart] = useState<NexacroJsonUnitType>(getDocumentHistory('part'));
    const [ivStatus, setIvStatus] = useState<NexacroJsonUnitType>(getDocumentHistory('ivStatus'));

    const [ds_search, setDs_search] = useState<NexacroJsonUnitType[]>();
    const [ds_invStatus, setDs_invStatus] = useState<NexacroJsonUnitType[]>();
    const [inv_no, setInv_no] = useState(getDocumentHistory('inv_no'));   // Invoice No.

    const [valid, setValid] = useState(false);

    const [qrScannerPop, setQrScannerPop] = useState(false);

    let temp_ds_invStatus: NexacroJsonUnitType[] = [];

    // 일주일 전으로 날짜 세팅
    function fn_dateToString_Z(date:Date, dashFlag: boolean){
        let year = date.getFullYear();
        let month = ("0" + (1 + date.getMonth())).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);

        let rv = year + month + day;

        if (dashFlag) {
            rv = year + "-" + month + "-" + day;
        }

        return rv;
    }

    // 일주일 전으로 날짜 세팅
    function fn_dateToString_N(gb: string, diff: number, dashFlag: boolean){
        let nowDt = new Date;

        let diffY = 0;
        let diffM = 0;
        let diffD = 0;

        if (gb == "Y") {
            diffY = diff;
        } else if (gb == "M") {
            diffM = diff;
        } else if (gb == "D") {
            diffD = diff;
        }

        let nDate = new Date(nowDt.getFullYear() + diffY, nowDt.getMonth() + diffM, nowDt.getDate() + diffD);

        return fn_dateToString_Z(nDate, dashFlag);
    }

    const selectedDivision = (value: any) => {
        setPlant({});
        setVendor({});
        setPart({});
        setInv_no('');
        setDivision(value);
    };

    const selectedPlant = (value: any) => {
        setVendor({});
        setPart({});
        setInv_no('');
        setPlant(value);
    };

    const selectedVendor = (value: any) => {
        setPart({});
        setInv_no('');
        setVendor(value);
    };

    const selectedPart = (value: any) => {
        setInv_no('');
        setPart(value);
    };

    /* Status 선택 callback */
    const selectedValueStatus = (value:any)=> {
        setIvStatus(value);
    };

    useEffect(() => {
        if(
            (!gfn.gfn_isNull(division?.DIVISION_CD)
            && !gfn.gfn_isNull(plant?.PLANT_CD)
            // && !gfn.gfn_isNull(vendor?.HQ_CD)
            // && !gfn.gfn_isNull(part?.PART_CD)
            )
            || !gfn.gfn_isNull(inv_no)
        ){
            setValid(true);
        } else {
            setValid(false);
        }
    }, [division, plant, vendor, part, inv_no]);

    // 사진 스캔
    function fn_scnnerOpen()
    {
        // if(gfn.gfn_isNull(vendor?.HQ_CD)){
        //     setDialog({
        //         type: 'ALERT',
        //         show: true,
        //         text: getMessage("MSG09001").replace("{0}",getDomain("DOMAIN0372")),//협력사
        //         confirm: () => {
        //
        //         }
        //     });
        //     return;
        // }
        setQrScannerPop(true);
    }

    // 사진 스캔 이후 작업
    async function fn_scanner(result:string){
        if (result.length > 24) { /* 물품표 */
            let tempInvNo = result.substr(23);

            if (tempInvNo.substr(0, 1) == '-') {
                tempInvNo = tempInvNo.substr(1);
            }

            setInv_no(tempInvNo);
        } else { /* 인보이스 */
            await selectInvoiceHqList(result.substr(0, 4)).then((response) => {
                let res = response?.data.ds_invoiceHqList;
                let invoiceNo = "";

                for (let i = 0; i < res.length; i++) {
                    if (invoiceNo.length == 0 || invoiceNo.length > result.replace(res[i]['HQ_CD'], '').length) {
                        invoiceNo = result.replace(res[i]['HQ_CD'], '');
                    }
                }

                if (invoiceNo.substr(0, 1) == '-') {
                    invoiceNo = invoiceNo.substr(1);
                }

                setInv_no(invoiceNo);
            }).catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
        }
        initSearchParam();
        // result = result.replace(gfn.gfn_tostring(vendor?.HQ_CD),"");
        // setInv_no(result);
    }
    function initSearchParam(){
        //Invoice 입력된 후 사업부, Plant 초기화
        setVendor({HQ_CD: ''});
        setPart({PART_CD: ''});
        setIvStatus({CD: '',
            CDNM: 'All'})
        //날짜도?? 날짜는 Invoice 1년전으로 하네
        // setDatePickerFromWithDash(fn_dateToString_N("Y", -1, true))
        // setDatePickerToWithDash(fn_dateToString_Z(new Date, true))
    }
    /* 협력사 목록 조회 */
    function selectInvoiceHqList(hqCd: string) {
        const requestJson: NexacroJsonType = {
            ds_search: [{
                HQ_CD: hqCd
            }]
        };

        return sendAxios('/common/nexacro/selectInvoiceHqList.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson);
    }

    useEffect(() => {
        //get storage _KJS
        if(!gfn.gfn_isNull(getSearchCondition)) {
            setDivision(getSearchCondition['division']);
            setPlant(getSearchCondition['plant']);
            setVendor(getSearchCondition['hqCd']);
        }
        form_onload();

        const filter = (dataset?.ds_CommonCode || []).filter(f => f.CATEGORY === "SQCI000081" && f.LANG === gv_locale && (f.CD == '1' || f.CD == '2' || f.CD == '9' || f.CD == '4' || f.CD == '5'));

        temp_ds_invStatus.push({
            CD: '',
            CDNM: 'All'
        });

        for (const f of filter || []) {
            temp_ds_invStatus.push({
                CD: String(f.CD),
                CDNM: String(f.CDNM)
            })
        }
        setDs_invStatus(temp_ds_invStatus);

        /** Status Hold 추가 **/
        fn_isDivisionQ("SETHOLD");
    },[]);

    useEffect(() => {
        if(division?.DIVISION_CD && plant?.PLANT_CD) {
            getSdpVendorList({
                ds_Search: [{
                    DIVISION_CD: division?.DIVISION_CD,
                    PLANT_CD: plant?.PLANT_CD
                }]
            })
                .then(response => {
                    if(dataset?.gds_role[0]["WORK_GROUP_CD"] == 1) {
                        setVendorList(response?.data.ds_vendorList.filter((vendor: NexacroJsonUnitType) => vendor.HQ_CD === sessionValues?.HQ_CD));
                    }else {
                        setVendorList(response?.data.ds_vendorList
                            .filter((vendor: NexacroJsonUnitType) => vendor.PLANT_CD === plant?.PLANT_CD));
                    }
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {});
                });
        }
    }, [division, plant]);

    function form_onload(){
        //타 화면에서 해당 화면을 호출 시 파라미터가 있을 경우 아래와 같이 처리함
        //FromDate ~ ToDate 를 이용하여 조회 처리함
        // 23.09.08 react수정) 아직 모바일에서는 다른 페이지에서 현재 페이지 호출하는 경우 존재하지 않음
        // if(!this.gfn_isNull(this.getOwnerFrame().pArgs)){
        if(false){}
        else{
            //기간 기본 일주일 설정
            // 23.09.13 react수정) datepicker 따로 설정
        }
        fn_isDivisionQ("SEARCH");
    }

    /** VOC 41 Start : 2019.09.05 채보람 Smart Inspection Data 연계 입고 Lot 자동판정 **/
    /** 무선사업부일 경우 로직 함수 **/
    /**
     SEARCH -> Smart Inspection 검색 조건 추가
     SEARCHPARAM -> 검색 조건 추가
     **/
    function fn_isDivisionQ(flag:string)
    {
        if (!fv_isDivisionQ)
        {
            return;
        }

        if (flag == "SEARCH")
        {
            /*this.ds_InitSearch.insertRow(10);
            this.ds_InitSearch.setColumn(10, "SEARCH_DIV_ID", "SEARCH02");
            this.ds_InitSearch.setColumn(10, "ID", "div_smtInsp");
            this.ds_InitSearch.setColumn(10, "GUBUN", "DS");
            this.ds_InitSearch.setColumn(10, "TYPE", "DET");
            this.ds_InitSearch.setColumn(10, "TITLE", "Smart Inspection");
            this.ds_InitSearch.setColumn(10, "ENABLE", "true");
            this.ds_InitSearch.setColumn(10, "BIND_SEL_TYPE", "A");
            this.ds_InitSearch.setColumn(10, "BIND_DATESET", "ds_smartInspectionY");
            this.ds_InitSearch.setColumn(10, "BIND_DATA_COL", "VALUE");
            this.ds_InitSearch.setColumn(10, "BIND_CODE_COL", "CODE");

            this.grd_List.set_formatid("Q");
            this.gfn_setDomain(this.grd_List);*/
        }
        else if (flag == "SETHOLD")
        {
            temp_ds_invStatus.push({
                "CD" : "8",
                "CDNM" : "Hold"
            });
        }
        else if (flag == "SEARCHPARAM")
        {
            temp_ds_Search[0]["IS_DIV_Q"] = "Y";
            // 23.09.08 react수정) 화면에 "Smart Inspection" / "2배수 대상 여부" 없음
            // this.ds_Search.setColumn(0, "SMART_INSP_YN", this.fv_arrSearch["div_smtInsp"].form.fn_getValue());
        }
    };

    // 입고검사 대상목록 조회
    function fn_tranSearch(){
        let startDt = new Date(datePickerFromWithDash); //to_date
        let endtDt = new Date(datePickerToWithDash);
        let diff = Math.abs(startDt.getTime() - endtDt.getTime());
        diff = Math.ceil(diff / (1000 * 60 * 60 * 24));

        if (diff > 30) {
            setDialog({
                show: true,
                type: 'ALERT',
                text: getMessage("MSG00737") // 조회기간은 최대 30일 입니다.
            });

            return;
        }

        let sPartCd = part?.PART_CD == undefined ? "" : String(part?.PART_CD);
        let sSearchType = "EQ";

        if (sPartCd.length >= 4 && sPartCd.length <= 10) {
            //라이크 검색
            sSearchType = "LIKE";
        }

        //외부에서 강제 조회 시 처리를 위해 아래와 같이 구분한다.
        // 23.09.08 react수정) 현재 타화면에서 넘어오는 로직 존재하지 않아 하단 진행하지 않음
        // if(!this.gfn_isNull(pArgs)){
        if(false){
        }else {
            temp_ds_Search[0]["TYPE"] = "01";   // 입고일 고정

            if (!inv_no) {
                temp_ds_Search[0]["INSP_STATUS"] = "Y";   // 검사대상 (Random 검사대상) 고정
                temp_ds_Search[0]["DIVISION_CD"] = division?.DIVISION_CD;
                temp_ds_Search[0]["HQ_CD"] = vendor?.HQ_CD;
                temp_ds_Search[0]["PART_CD"] = part?.PART_CD;
                temp_ds_Search[0]["IV_STATUS"] = ivStatus?ivStatus.CD:"";
                temp_ds_Search[0]["FROM_DATE"] = datePickerFromWithDash.replaceAll('-', '').replaceAll('.', '');
                temp_ds_Search[0]["TO_DATE"] = datePickerToWithDash.replaceAll('-', '').replaceAll('.', '');
                temp_ds_Search[0]["SEARCH_TYPE"] = sSearchType;
            } else {
                temp_ds_Search[0]["INSP_STATUS"] = "";
                temp_ds_Search[0]["DIVISION_CD"] = "";
                temp_ds_Search[0]["HQ_CD"] = "";
                temp_ds_Search[0]["PART_CD"] = "";
                temp_ds_Search[0]["IV_STATUS"] = "";
                temp_ds_Search[0]["FROM_DATE"] = fn_dateToString_N("Y", -1, false);
                temp_ds_Search[0]["TO_DATE"] = fn_dateToString_Z(new Date, false);
                temp_ds_Search[0]["INV_NO"] = inv_no;
                temp_ds_Search[0]["SEARCH_TYPE"] = "EQ";
            }

            fn_isDivisionQ("SEARCHPARAM");
        }

        temp_ds_Search[0]["LANG"] = gv_locale ? gv_locale : "EN"; //gv_locale;
        setDs_search(temp_ds_Search);

        let requestJson:NexacroJsonType = {
            ds_Search : temp_ds_Search,
            ds_Plant : [{PLANT: plant?.PLANT_CD}]
        }

        if (inv_no) {
            requestJson = {
                ds_Search : temp_ds_Search,
                ds_Plant : ''||[]
            }
        }

        pageMove(process.env.REACT_APP_URL_QMS_RST || '', {
            division: division,
            plant: plant,
            vendor: vendor,
            part: part,
            inv_no: inv_no,
            ivStatus: ivStatus,
            datePickerFromWithDash: datePickerFromWithDash,
            datePickerToWithDash: datePickerToWithDash,
            requestJson:requestJson
        });
        //set storage
        setStorage('LOCAL', location.pathname, {
            division: division,
            plant: plant,
            hqCd: vendor
        }, 7);
    }

    /* Invoice No. 입력 시 */
    function changeInvNo(e: any) {
        setInv_no(e.target.value.toUpperCase());
        initSearchParam();
    }

    /* 달력 교체 시 */
    function changeDatePicker(e: any, dateFromTo: string) {
        if (dateFromTo == 'F') {
            setDatePickerFromWithDash(e.target.value);
        } else {
            setDatePickerToWithDash(e.target.value);
        }
        setInv_no('');
    }

    /* 부품명 클릭 시 */
    function searchPartClick() {
        let messageList: string[] = [];

        if(!division || !division.DIVISION_CD) {
            messageList.push(getDomain('DOMAIN0222'));
        }
        if(!plant || !plant.PLANT_CD) {
            messageList.push(getDomain('DOMAIN2426'));
        }
        if(!vendor || !vendor.HQ_CD) {
            messageList.push(getDomain('DOMAIN0372'));
        }

        if (messageList.length != 0) {
            setDialog({
                show: true,
                type: 'ALERT',
                text: messageList.join('/').concat(getMessage('MSG01542')),
            });

            return;
        }

        doSearchPart('FAVORITES', partList || [], selectedPart, undefined,
            undefined,
            division ? division.DIVISION_CD ? String(division.DIVISION_CD) : undefined : undefined,
            plant ? plant.PLANT_CD ? String(plant.PLANT_CD) : undefined : undefined,
            vendor ? vendor.HQ_CD ? String(vendor.HQ_CD) : undefined : undefined,
        );
    }

    return (
        <>
            {searchComponent}
            <div className={'container'}>
                <div className={'contents-wrap'}>
                    <ul className={'reports-list'}>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN0222')} {/*사업부*/}
                            </span>
                            {
                                ((
                                        2 === (sessionValues?.WORK_GROUP_CD || 0)  ||
                                        3 === (sessionValues?.WORK_GROUP_CD || 0) ||
                                        4 === (sessionValues?.WORK_GROUP_CD || 0) ||
                                        5 === (sessionValues?.WORK_GROUP_CD || 0)
                                    ) ? //시업부관리자 또는 법인검사자
                                        <div role={'button'} onClick={() => doSearchDivision('RADIO', selectedDivision,undefined, [{
                                            DIVISION_CD: gfn.gfn_tostring(sessionValues?.DIVISION_CD)
                                        }])}>
                                            <p>{division?.DIVISION_NM}</p>
                                            <button type={'button'} className={'btn-link'}>
                                                <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                            </button>
                                        </div>
                                        :
                                        <div role={'button'} onClick={() => doSearchDivision('RADIO', selectedDivision,undefined)}>
                                            <p>{division?.DIVISION_NM}</p>
                                            <button type={'button'} className={'btn-link'}>
                                                <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                            </button>
                                        </div>
                                )
                            }
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN2426')} {/*Plant*/}
                            </span>
                            <div role={'button'} onClick={() => doSearchPlant('RADIO', selectedPlant, undefined,[{
                                DIVISION_CD: gfn.gfn_tostring(division?.DIVISION_CD)
                            }])}>
                                <p>{plant?.PLANT_CD ? '['.concat(String(plant?.PLANT_CD), ']') : ''}<br/>{String(plant?.PLANT_PROD_NM || '').replace(regexPlant, '$2')}</p>
                                <button type={'button'} className={'btn-link'}>
                                    <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                </button>
                            </div>
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                {/*<strong className={'required'}>*</strong>*/} {getDomain('DOMAIN0372')} {/*협력사*/}
                            </span>
                            <div className={'text-item'} role={'button'} onClick={() => doSearchVendor('FAVORITES', vendorList || [], selectedVendor)}>
                                <p>{vendor?.HQ_CD ? '['.concat(String(vendor?.HQ_CD), ']') : ''}<br/>{vendor?.HQ_NM}</p>
                                <button type={'button'} className={'btn-link'}>
                                    <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                </button>
                            </div>
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                {/*<strong className={'required'}>*</strong>*/} {getDomain('DOMAIN2025')} {/*부품명*/}
                            </span>
                            <div className={'item-wrap ellipsis1'} role={'button'} onClick={() => searchPartClick()}>
                                <p className={'item-wrap ellipsis1'}>{part?.PART_CD ? '['.concat(String(part?.PART_CD), ']') : ''}<br/><strong className={'item-wrap ellipsis1'}>{part?.PART_NM}</strong></p>
                                <button type={'button'} className={'btn-link'}>
                                    <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                </button>
                            </div>
                        </li>
                        <li className={'form-item type-date'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN4756')} {/*입고일자*/}
                            </span>
                            <div className={'type-date-in'}>
                                <div className={'datepicker-wrap'}>
                                    {/*{datePickerFrom?.datePicker}*/}
                                    <span className={'date-item'}>
                                        <input type={"date"} style={{WebkitAppearance:"none"}} pattern={'\d{4}-\d{2}-\d{2}'} defaultValue={datePickerFromWithDash||''} onChange={(e) => changeDatePicker(e, 'F')} />
                                    </span>
                                </div>
                                <div className={'datepicker-wrap'}>
                                    {/*{datePickerTo?.datePicker}*/}
                                    <span className={'date-item'}>
                                        <input type={"date"} style={{WebkitAppearance:"none"}} pattern={'\d{4}-\d{2}-\d{2}'} defaultValue={datePickerToWithDash||''} onChange={(e) => changeDatePicker(e, 'T')} />
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li className={'form-item barcode'}>
                            <span className={'title'}>
                                {getDomain('DOMAIN2414')} {/*Invoice No.*/}
                            </span>
                            <div className={'barcode-item'}>
                                <input type={'text'}
                                       placeholder={''}
                                       value={inv_no}
                                       onChange={changeInvNo}
                                       // readOnly={true}
                                    // defaultValue={inv_no}
                                    // onBlur={(e) => blurEvent(e, 'inv_no')}
                                />
                                <button type={"button"} className={"btn-barcode"} onClick={() => fn_scnnerOpen()}>
                                    <i className={"hidden"}>{getDomain('DOMAIN0235')}</i>  {/*선택*/}
                                </button>
                            </div>
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                {getDomain('DOMAIN4598')}
                            </span>
                            <div role={'button'} onClick={() => doSearch({
                                show: true,
                                title: 'Status',
                                type: 'RADIO',
                                props: {
                                    target: ds_invStatus || [],
                                    display: {
                                        name: 'CDNM',
                                        value: 'CD',
                                        strong: ''
                                    }
                                },
                                onSelect: selectedValueStatus
                            })}>
                                <p>{ivStatus?.CDNM ? ivStatus?.CDNM : 'All'}</p>
                                <button type={'button'} className={'btn-link'}>
                                    <i className={'hidden'}>{getDomain('DOMAIN0235')}{/*선택*/}</i>
                                </button>
                            </div>
                        </li>
                    </ul>

                    <div className="info-text-wrap">
                        <p style={{paddingLeft:"1.5rem", textIndent:"-1.5rem"}}>{getDomain('DOMAIN4762')}{/*※ 모바일 버전에서는 검사대상 Lot만 조회됩니다.*/}</p>
                        <p style={{paddingLeft:"1.5rem", textIndent:"-1.5rem"}}>{getDomain('DOMAIN4763')}{/*※ Target 검사 또는 입고검사 Skip 자재는 SQCI Web 버전에서 확인하여 주십시오.*/}</p>
                    </div>
                </div>
                <div className={'btn-area bottom'}>
                    <button type={'button'} className={'button-primary'}
                            disabled={!valid} onClick={() => fn_tranSearch()}>
                        {getDomain('DOMAIN4605')} {/*입고검사 대상 조회*/}
                    </button>
                </div>
            </div>
            <CSSTransition in={qrScannerPop} classNames={qrScannerPop ? 'right' : 'left'} timeout={500} unmountOnExit>
                <Scanner onClose={() => setQrScannerPop(false)} scanResult={(result) => fn_scanner(gfn.gfn_tostring(result))}/>
            </CSSTransition>
        </>
    );
};