import React, {useEffect, useState} from 'react';
import usePageMove from '../../hooks/usePageMove';
import useDatePicker from '../../hooks/useDatePicker';
import {useSearch} from '../../hooks/useSearch';
import useGlobalData from '../../hooks/useGlobalData';
import {NexacroJsonUnitType} from '../../components/model/SqciTypes';
import useStorage from '../../hooks/useStorage';
import {useDataset} from '../../components/contexts/CommonContext';
import {useError} from '../../hooks/useException';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';

export default function SampleQmvSearch() {
    const {pageMove} = usePageMove();
    const datePicker = useDatePicker('SINGLE', {time: {show: true}});
    const {searchComponent, doSearchDivision, doSearchPlant, doSearchVendor, doSearchPart, doSearchLine, doSearchPsisPart, regexPlant} = useSearch();
    const {getSdpVendorList, getSdpPartList, getGlobalSetting} = useGlobalData();
    const {getDocumentHistory} = useStorage();
    const {sessionValues, confirmHistoryBack} = useDataset();
    const {throwException} = useError();
    const {sendAxios} = useAxios();
    const {getDomain} = useGlobalData();

    const [vendorList, setVendorList] = useState<NexacroJsonUnitType[]>();
    const [lineList, setLineList] = useState<NexacroJsonUnitType[]>();
    const [psisPartList, setPsisPartList] = useState<NexacroJsonUnitType[]>();

    const [division, setDivision] = useState<NexacroJsonUnitType>(getDocumentHistory('division'));
    const [plant, setPlant] = useState<NexacroJsonUnitType>(getDocumentHistory('plant'));
    const [vendor, setVendor] = useState<NexacroJsonUnitType>(getDocumentHistory('vendor'));
    const [part, setPart] = useState<NexacroJsonUnitType>(getDocumentHistory('part'));
    const [psisPart, setPsisPart] = useState<NexacroJsonUnitType>(getDocumentHistory('psisPart'));
    const [secondVendor, setSecondVendor] = useState<NexacroJsonUnitType>(getDocumentHistory('secondVendor'));
    const [line, setLine] = useState<NexacroJsonUnitType>(getDocumentHistory('partVendor'));

    const selectedDivision = (value: any) => {
        console.log('selected division: ', value);
        setDivision(value);
    };

    const selectedPlant = (value: any) => {
        console.log('selected plant: ', value);
        setPlant(value);
    };

    const selectedVendor = (value: any) => {
        console.log('selected vendor: ', value);
        setVendor(value);
    };

    const selectedPart = (value: any) => {
        console.log('selected part: ', value);
        setPart(value);
    };

    const selectedPsisPart = (value: any) => {
        console.log('selected psis part: ', value);
        setPart(value);
    };

    const selectedLine = (value: any) => {
        console.log('selected line: ', value);
        setLine(value);
    };

    const selectSecondVendor = (value: any) => {
        if(value) {
            console.log('selected second vendor: ', value);
            setSecondVendor(value);
        }
    };

    useEffect(() => {
        if(division?.DIVISION_CD && plant?.PLANT_CD) {
            getSdpVendorList({
                ds_Search: [{
                    DIVISION_CD: division?.DIVISION_CD,
                    PLANT_CD: plant?.PLANT_CD
                }]
            })
            .then(response => {
                setVendorList(response?.data.ds_vendorList);
                // setVendorList(response?.data.ds_vendorList.filter((vendor: NexacroJsonUnitType) => vendor.HQ_CD === sessionValues?.HQ_CD));
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {});
            });
        }
    }, [division, plant]);

    useEffect(() => {
        if(vendor?.HQ_CD) {
            sendAxios('/common/nexacro/selectPSISLineMgtByInspRslt.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_search: [{
                    HQ_CD: vendor?.HQ_CD
                }]
            })
            .then(response => {
                setLineList(response?.data.ds_lineList || []);
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {});
            });
        }

        if(plant && vendor) {
            sendAxios('/common/nexacro/selectNonSetUpPsisPartList.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_search: [{
                    APPROVE_STATUS: '',
                    TYPE: 'PSIS',
                    PLANT_CD: plant?.PLANT_CD,
                    HQ_CD: vendor?.HQ_CD,
                    RECORDSOFFSET: '0',
                    RECORDS: '1000',
                    SRCH_TYPE: '',
                    CATEGORY1: '',
                    CATEGORY2: '',
                    SEARCH: ''
                }]
            })
            .then(response => {
                setPsisPartList(response?.data.ds_list || []);
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {});
            });
        }
    }, [division, plant, vendor]);

    useEffect(() => {
        confirmHistoryBack(true, '임시저장하시겠습니까?', () => {
            console.log('ok click');
            pageMove('/sample/test');
        });
    }, []);

    return (
        <>
            {searchComponent}
            <div className={'container'}>
                <div className={'contents-wrap'}>
                    <ul className={'reports-list'}>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> 사업부
                            </span>
                            <div role={'button'} onClick={() => doSearchDivision('FAVORITES', selectedDivision)}>
                                <p>{division?.DIVISION_NM}</p>
                                <button type={'button'} className={'btn-link'}>
                                    <i className={'hidden'}>이동</i>
                                </button>
                            </div>
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> Plant
                            </span>
                            <div>
                                <p>{plant?.PLANT_CD ? '['.concat(String(plant?.PLANT_CD), ']') : ''}<br/>{String(plant?.PLANT_PROD_NM || '').replace(regexPlant, '$2')}
                                </p>
                                <button type={'button'} className={'btn-link'}
                                    onClick={() => doSearchPlant('FAVORITES', selectedPlant, undefined, undefined, true)}>
                                    <i className={'hidden'}>이동</i>
                                </button>
                            </div>
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> 협력사
                            </span>
                            <div className={'text-item'}>
                                <p>{vendor?.HQ_CD ? '['.concat(String(vendor?.HQ_CD), ']') : ''}<br/>{vendor?.HQ_NM}</p>
                                <button type={'button'} className={'btn-link'}
                                    onClick={() => doSearchVendor('FAVORITES', vendorList || [], selectedVendor)}>
                                    <i className={'hidden'}>이동</i>
                                </button>
                            </div>
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> 부품명
                            </span>
                            <div>
                                <p>{part?.PART_CD ? '['.concat(String(part?.PART_CD), ']') : ''}<br/><strong>{part?.PART_NM}</strong>
                                </p>
                                <button type={'button'} className={'btn-link'} onClick={() => doSearchPart('FAVORITES', [], selectedPart, undefined,
                                        undefined,
                                        division ? division.DIVISION_CD ? String(division.DIVISION_CD) : undefined : undefined,
                                        plant ? plant.PLANT_CD ? String(plant.PLANT_CD) : undefined : undefined,
                                        vendor ? vendor.HQ_CD ? String(vendor.HQ_CD) : undefined : undefined,
                                    )}>
                                    <i className={'hidden'}>이동</i>
                                </button>
                            </div>
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> 부품명(PSIS용)
                            </span>
                            <div>
                                <p>
                                    {
                                        part ? <span style={{
                                            background: part?.HQ_INSP_YN && 'Y' === part?.HQ_INSP_YN ? '#00215F' : '#5200FF',
                                            display: 'inline-flex',
                                            height: '1.7rem',
                                            minWidth: '8rem',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            whiteSpace: 'nowrap',
                                            borderRadius: '1rem',
                                            color: '#fff',
                                            fontSize: '0.5rem'
                                        }}>{part?.HQ_INSP_YN && 'Y' === part?.HQ_INSP_YN ? getDomain('DOMAIN4594') : getDomain('DOMAIN4472')}</span> : null
                                    }
                                    {part?.PART_CD ? '['.concat(String(part?.PART_CD), ']') : ''}
                                    <br/><strong>{part?.PART_NM}</strong>
                                </p>
                                <button type={'button'} className={'btn-link'} onClick={() => doSearchPsisPart('FAVORITES', psisPartList || [], selectedPsisPart, {
                                        onSelect: selectSecondVendor
                                    }
                                )}>
                                    <i className={'hidden'}>이동</i>
                                </button>
                            </div>
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> Line
                            </span>
                            <div className={'text-item'}>
                                <p>{line?.LINE_CD ? '['.concat(String(line?.LINE_CD), ']') : ''}<br/>{String(line?.LINE_NM || '').replace(regexPlant, '$2')}</p>
                                <button type={'button'} className={'btn-link'} onClick={() => doSearchLine('RADIO', lineList || [], selectedLine)}>
                                    <i className={'hidden'}>이동</i>
                                </button>
                            </div>
                        </li>
                        <li className={'form-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> 생산일자
                            </span>
                            <div className={'datepicker-wrap'}>
                                <input type={'date'} required={true} pattern={'\d{4}-\d{2}-\d{2}'} defaultValue={'2023-09-17'}/>
                            </div>
                        </li>
                        <li className="form-item type-date">
                            <span className="title">
                                <strong className="required">*</strong> 생산일자(기간)
                            </span>
                            <div className="type-date-in">
                                <div className="radio-item">
                                    <label><input type={'radio'} name={'inputDate'} defaultChecked={true}/>입고일</label>
                                    <label><input type={'radio'} name={'inputDate'}/>판정일</label>
                                </div>
                                <div className="datepicker-wrap">
                                    <span className="date-item">
                                        <input type={'date'} required={true} pattern={'\d{4}-\d{2}-\d{2}'} defaultValue={'2023-09-17'}/>
                                        {/*<button type={'button'} className="icon-btn-date"><i className="hidden">날짜선택</i></button>*/}
                                    </span>
                                </div>
                                <div className="datepicker-wrap">
                                    <span className="date-item">
                                    <input type={'date'} required={true} pattern={'\d{4}-\d{2}-\d{2}'} defaultValue={'2023-09-17'}/>
                                    {/*<button type={'button'} className="icon-btn-date"><i className="hidden">날짜선택</i></button>*/}
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li className={'form-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> Lot No.
                            </span>
                            <div>
                                <input type={'text'} placeholder={'Lot No. Limit : 20'}/>
                            </div>
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> Status
                            </span>
                            <div>
                                <p>All</p>
                                <button type={'button'} className={'btn-link'} onClick={() => {
                                }}>
                                    <i className={'hidden'}>이동</i>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className={'btn-area bottom'}>
                    <button type={'button'} className={'button-primary'} onClick={() => pageMove('/sample/qmv/list', {
                        division: division,
                        plant: plant,
                        vendor: vendor,
                        part: part,
                        line: line
                    })}>출하검사 성적서 조회/수정
                    </button>
                </div>
            </div>
        </>
    );
};