import React from 'react';
import {
    HistoryType,
    NexacroJsonType,
    NexacroJsonUnitType,
    SessionValuesType,
    StorageType
} from '../components/model/SqciTypes';
import moment from 'moment';
import {useLocation} from 'react-router-dom';
import {STORAGE_NAME} from '../components/CommonConstants';

const useStorage = () => {
    const location = useLocation();

    const setStorage = (type: StorageType, key: string, value?: any, expire?: number): void => {
        switch(type) {
            case 'SESSION':
                sessionStorage.setItem(key, JSON.stringify(value || {}));
                break;
            case 'LOCAL':
                if(expire) {
                    localStorage.setItem(key, JSON.stringify({
                        storageExpireDate: moment().add(expire, 'day'),
                        storageData: value
                    }));
                } else {
                    localStorage.setItem(key, JSON.stringify({
                        storageData: value
                    }));
                }
                break;
            default:
                break;
        }
    };

    const getStorage = <T extends NexacroJsonType | NexacroJsonUnitType | SessionValuesType | HistoryType | string | boolean>(type: StorageType, key: string): T | undefined => {
        switch(type) {
            case 'SESSION':
                return JSON.parse(sessionStorage.getItem(key) || '{}');
                break;
            case 'LOCAL':
                const result = JSON.parse(localStorage.getItem(key) || '{}');
                if(result.storageExpireDate && moment().isAfter(moment(result.storageExpireDate))) {
                    clearStorage('LOCAL', key);
                    return undefined;
                } else {
                    return result.storageData;
                }
                break;
        }
    };

    const clearStorage = (type: StorageType, key: string) => {
        switch(type) {
            case 'SESSION':
                sessionStorage.removeItem(key);
                break;
            case 'LOCAL':
                localStorage.removeItem(key);
                break;
            default:
                break;
        }
    };

    const getDocumentHistory = (key: string) => {
        const sessionHistory = JSON.parse(sessionStorage.getItem(STORAGE_NAME.HISTORY) || '{}');
        return sessionHistory ? sessionHistory[location.pathname] ? sessionHistory[location.pathname][key] : undefined : undefined;
    };

    return {
        setStorage,
        getStorage,
        clearStorage,
        getDocumentHistory
    }
};

export default useStorage;