import React, {useEffect} from 'react';
import usePageMove from '../hooks/usePageMove';
import Footer from './common/Footer';
import {useDataset} from '../components/contexts/CommonContext';
import {ROUTER_MENU_CODE} from '../components/Routers';
import useGlobalData from '../hooks/useGlobalData';
import {useSearchParams} from 'react-router-dom';

export default function Main() {
    const {pageMove} = usePageMove();
    const {sessionValues, dataset, language} = useDataset();
    const {getMenuName} = useGlobalData();

    const {authMfa, setAuthMfa, setSessionValues, setAuthSession} = useDataset();
    const [searchParam, setSearchParam] = useSearchParams();
    useEffect(() => {
        if(searchParam && searchParam.get('jwtTokenResponse')) {
            setAuthMfa(true);
        }
    }, []);

    return (
        <>
            <div className={'container main'}>
                <div className={'main-conts'}>
                    <div className={'heading-group'}>
                        <h1>SQCI</h1>
                        <p>Supplier Quality<br/>Control Innovation</p>
                    </div>
                    <section className={'menu-wrap'}>
                        <article>
                            <h2>{getMenuName(ROUTER_MENU_CODE.QMV)}</h2>
                            <ul className={'menu-list'}>
                                <li>
                                    <button type={'button'} style={{wordBreak: 'keep-all', color: '#000000'}} onClick={() => pageMove(process.env.REACT_APP_URL_QMV_ADD || '')}>
                                        <i><img src={'/images/main-a-icon1.png'} alt={''}/></i>{getMenuName(ROUTER_MENU_CODE.QMV_ADD)}
                                    </button>
                                </li>
                                <li>
                                    <button type={'button'} style={{wordBreak: 'keep-all', color: '#000000'}} onClick={() => pageMove(process.env.REACT_APP_URL_QMV_SEARCH || '')}>
                                        <i><img src={'/images/main-a-icon2.png'} alt={''}/></i>{getMenuName(ROUTER_MENU_CODE.QMV_SEARCH)}
                                    </button>
                                </li>
                            </ul>
                        </article>
                        {
                            2 <= (sessionValues?.WORK_GROUP_CD || 0) && 7 >= (sessionValues?.WORK_GROUP_CD || 0) ? <article>
                                <h2>{getMenuName(ROUTER_MENU_CODE.QMS)}</h2>
                                <ul className={'menu-list'}>
                                    <li>
                                        <button type={'button'} style={{wordBreak: 'keep-all', color: '#000000'}} onClick={() => pageMove(process.env.REACT_APP_URL_QMS || '')}>
                                            <i><img src={'/images/main-b-icon1.png'} alt={''}/></i>{getMenuName(ROUTER_MENU_CODE.QMS_M_INCM_INSP_LIST)}
                                        </button>
                                    </li>
                                    <li>
                                        <button type={'button'} style={{wordBreak: 'keep-all', color: '#000000'}} onClick={() => pageMove(process.env.REACT_APP_URL_QMS_SEARCH || '')}>
                                            <i><img src={'/images/main-b-icon2.png'} alt={''}/></i>{getMenuName(ROUTER_MENU_CODE.QMS_SEARCH)}
                                        </button>
                                    </li>
                                    { 7 == (sessionValues?.WORK_GROUP_CD || 0) ?
                                    <li>
                                        <button type={'button'} style={{wordBreak: 'keep-all', color: '#000000'}} onClick={() => pageMove(process.env.REACT_APP_URL_QMS_TEST || '')}>
                                            <i><img src={'/images/main-b-icon2.png'} alt={''}/></i>{getMenuName(ROUTER_MENU_CODE.QMS_TEST_SEARCH)}
                                        </button>
                                    </li> : null
                                    }
                                </ul>
                            </article> : null
                        }
                        <article>
                            <h2>{getMenuName(ROUTER_MENU_CODE.PSIS)}</h2>
                            <ul className={'menu-list'}>
                                <li>
                                    <button type={'button'} style={{wordBreak: 'keep-all', color: '#000000'}} onClick={() => pageMove(process.env.REACT_APP_URL_PSIS_ADD || '')}>
                                        <i><img src={'/images/main-c-icon1.png'} alt={''}/></i>{getMenuName(ROUTER_MENU_CODE.PSIS_INSP_TARGET_REG)}
                                    </button>
                                </li>
                                <li>
                                    <button type={'button'} style={{wordBreak: 'keep-all', color: '#000000'}} onClick={() => pageMove(process.env.REACT_APP_URL_PSIS_INPUT || '')}>
                                        <i><img src={'/images/main-c-icon2.png'} alt={''}/></i>{getMenuName(ROUTER_MENU_CODE.PSIS_INSP_RESULT_REG)}
                                    </button>
                                </li>
                                <li>
                                    <button type={'button'} style={{wordBreak: 'keep-all', color: '#000000'}} onClick={() => pageMove(process.env.REACT_APP_URL_PSIS_INFO || '')}>
                                        <i><img src={'/images/main-c-icon3.png'} alt={''}/></i>{getMenuName(ROUTER_MENU_CODE.PSIS_INSP_RESULT_LIST)}
                                    </button>
                                </li>
                            </ul>
                        </article>
                    </section>
                </div>
                <Footer/>
            </div>
        </>
    );
};