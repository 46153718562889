import React, {useCallback, useEffect, useState} from 'react';
import {SqciException} from '../components/exception/CommonException';
import {AxiosError} from 'axios';
import {useComponent} from '../components/contexts/ComponentContext';
import usePageMove from './usePageMove';
import useAxios, {AXIOS_HEADER} from './useAxios';
import {useDataset} from '../components/contexts/CommonContext';
import {ExceptionAfterAction} from '../components/model/SqciTypes';
import useGlobalData from './useGlobalData';
import {upperCase} from 'lodash';
import {STORAGE_NAME} from '../components/CommonConstants';
import moment from 'moment/moment';

interface SqciErrorType<T extends Error> {
    error?: T;
    setError: (error?: T) => void;
}

const SqciError: SqciErrorType<any> = {
    error: undefined,
    setError: (error?: Error | unknown) => undefined
}

export const useError = () => {
    const {setLoader, setDialog} = useComponent();
    const {pageMove} = usePageMove();
    const {sendAxios} = useAxios();
    const {setSessionValues, dataset, sessionValues, setAuthMfa, setAuthSession} = useDataset();

    const [error, setError] = useState(SqciError.error);

    const throwException = useCallback((error?: Error | unknown) => setError(error), [error]);

    const handleAction = (action?: ExceptionAfterAction) => {
        setLoader(false);
        if('LOGOUT' === action) {
            sendAxios('/common/identity/logout.do', 'GET', AXIOS_HEADER.GET)
            .catch(e => {
                console.error(e);
                // return new Promise(() => {});
            })
            .finally(() => {
                setAuthSession(false);
                setAuthMfa(false);
                setSessionValues();
                // pageMove(process.env.REACT_APP_URL_MAIN || '/', {
                //     slideDirectionFrom: 'left'
                // });
                // window.location.href = process.env.REACT_APP_URL_LOGIN || '/login';
            });
        } else if('REFRESH' === action) {
            // pageMove(0, {
            //     slideDirectionFrom: 'left'
            // });
            window.location.href = process.env.REACT_APP_URL_MAIN || '/';
        } else if('BACK' === action) {
            pageMove(-1, {
                slideDirectionFrom: 'left'
            });
        } else if('EXPIRED' === action) {
            pageMove(process.env.REACT_APP_URL_RESET_PASSWORD || '');
        } else if('NONE' === action) {
        } else {
            setAuthSession(false);
            setAuthMfa(false);
            setSessionValues();
            // window.location.href = process.env.REACT_APP_URL_LOGIN || '/login';
        }
    };

    useEffect(() => {
        if(error) {
            if(error instanceof SqciException) {
                let errorMessage = error.error.message || '';

                if(sessionValues && Object.entries(sessionValues).length > 0) {
                    const storageData = JSON.parse(localStorage.getItem(STORAGE_NAME.LANGUAGE) || '{}');
                    if(dataset && dataset.gds_message && Object.entries(dataset.gds_message).length > 0) {
                        const message = dataset.gds_message.find(item => item.MSG_ID === error.error.message);
                        if(message) {
                            const langCode = upperCase(storageData.storageData || 'KO');
                            const language = 'MSG_NM_'.concat(langCode === 'VN' ? 'VI' : langCode);
                            errorMessage = String(message[language] || '').replace(/\\n/gi, '<br/>');
                        }
                    } else {
                        errorMessage = 'An error occurred. Please try again later.';
                    }
                } else {
                    errorMessage = errorMessage.startsWith('DOMAIN') || errorMessage.startsWith('MSG') ? 'An error occurred. Please try again later.' : errorMessage;
                }

                switch(error.error.type) {
                    case 'PAGE':
                        pageMove(process.env.REACT_APP_URL_EXCEPTION || '/error', {
                            ...error.error,
                            slideDirectionFrom: 'left'
                        });
                        break;
                    case 'ALERT':
                        setDialog({
                            show: true,
                            type: 'ALERT',
                            text: errorMessage,
                            confirm: () => {
                                handleAction(error.error.action);
                            }
                        });
                        break;
                    case 'CONFIRM':
                        setDialog({
                            show: true,
                            type: 'CONFIRM',
                            text: errorMessage,
                            confirm: () => {
                                handleAction(error.error.action);
                            }
                        });
                        break;
                    default:
                        break;
                }
            } else if(error instanceof AxiosError) {
                setDialog({
                    show: true,
                    type: 'ALERT',
                    text: error.message,
                    confirm: () => {
                        handleAction('REFRESH');
                    }
                });
            } else {
                console.error(error);
            }
        }
    }, [throwException]);

    return {
        throwException,
        handleAction
    };
};