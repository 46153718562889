/**************************************************************************************************
 * 01. 업무구분	    : P-SIS > 검사결과 조회 > 공정검사 상세
 * 02. 프로그램ID	: PsisInspResultInfoProcess.tsx
 * 03. 프로그램명	    : P-SIS > 검사결과 조회 > 공정검사 상세
 * 04. 화면설명	    : P-SIS > 검사결과 조회 > 공정검사 상세
 * 05. 작성일		: 2023.10.04
 * 06. 작성자		: YGY
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-10-04     YGY    최초 작성
 **************************************************************************************************
 */
/******************************************************************************************
 *                                        변경이력                                         *
 *----------   ----------   ------------   ------------------------------------------------*
 * 변경번호     변경일자       변경자                        변경내용                      *
 *----------   ----------   ------------   ------------------------------------------------*

 ******************************************************************************************/

import React, {useEffect, useState} from 'react';
import useAxios, {AXIOS_HEADER} from '../../../hooks/useAxios';
import {useError} from '../../../hooks/useException';
import {useLocation, useOutletContext} from 'react-router-dom';
import {useComponent} from "../../../components/contexts/ComponentContext";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import {CSSTransition} from 'react-transition-group';
import {floor, toNumber} from "lodash";
import usePageMove from "../../../hooks/usePageMove";
import {STORAGE_NAME} from "../../../components/CommonConstants";
import {v4 as uuidv4} from 'uuid';
import useGlobalData from "../../../hooks/useGlobalData";
import {ComponentHistoryType} from "../../../components/model/SqciTypes";
import ImageViewer from "../input/ImageViewer";
import CounterViewerInfo from "./CounterViewerInfo";

/**
 * 공정검사 검사결과 입력
 * @constructor
 */
export default function PsisInspResultInfoProcess() {
    const {sendAxios} = useAxios();
    const {setDialog} = useComponent();
    const {throwException} = useError();
    const location = useLocation();
    const {pageMove} = usePageMove();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const [inspectList, setInspectList] = useState(new Array<any>());
    const [detailResultList, setDetailResultList] = useState(new Array<any>());
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
    const [resultList, setResultList] = useState(new Array<any>());
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [showImage, setShowImage] = useState<boolean>(false);
    const [showCounter, setShowCounter] = useState<boolean>(false);
    const [imageFileId, setImageFileId] = useState(String);
    const [titleNm, setTitleNm] = useState(String);
    const [counterNum, setCounterNum] = useState(Number);
    const [counterMap, setCounterMap] = useState<any>();
    const [focusTab, setFocusTab] = useState(Number);
    const [inspCnt, setInspCnt] = useState<Number>(0);
    const [showInspViewer, setShowInspViewer] = useState<boolean>(false);
    const [pictureFile, setPictureFile] = useState<File>();
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
    const [gMinHeight, setGMinHeight] = useState(String);

    useEffect(() => {
        if ((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            let sMinHeight = window.innerHeight - 201.6;
            setGMinHeight(String(sMinHeight).concat("px"));
            setInspectList(location.state.ds_inspect);
            let inspCnt = toNumber(location.state.ds_inspect[0].INSP_CNT);
            sendAxios('/common/nexacro/selectPsisDetailResultList.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_search: [{
                    LANG: gv_locale,
                    INSP_TARGET_CD: location.state.ds_inspect[0].INSP_TARGET_CD,
                    INSP_CNT: inspCnt,
                    HQ_INSP_YN: location.state.ds_inspect[0].HQ_INSP_YN
                }]
            })
                .then(response => {
                    let sResultList = new Array<any>();
                    let countIdx: number = 0;
                    setDetailResultList(response?.data.ds_detailResultList || []);
                    for (const ds_res of response?.data.ds_detailResultList || []) {
                        for (let i = 1; i <= toNumber(inspCnt); i++) {
                            sResultList.push({
                                countIdx: countIdx++,
                                INSP_TARGET_CD: ds_res["INSP_TARGET_CD"],
                                INSP_CD: ds_res["INSP_CD"],
                                INSP_NM: ds_res["INSP_NM"],
                                INSP_TYPE: ds_res["INSP_TYPE"],
                                NUM_YN: ds_res["NUM_YN"],
                                seqVal: ds_res["SEQ"], //검사항목 seq
                                SEQ: i, //테스트 수
                                OCR_YN: ds_res["T_" + i + "_OCR_YN"],
                                ocrList: [],
                                selectOcr: undefined,
                                MEAS_VAL: ds_res["NUM_YN"] === 'Y' ? ds_res["T_" + i + "_MEAS_VAL"] !== undefined ? floor(toNumber(ds_res["T_" + i + "_MEAS_VAL"]), ds_res["POINT"]).toFixed(ds_res["POINT"]) : undefined : undefined,
                                MEAS_RESULT: ds_res["T_" + i + "_MEAS_RESULT"],
                                FILE_ID: ds_res["T_" + i + "_FILE_ID"],
                                FILE_NM: ds_res["T_" + i + "_FILE_NM"],
                                FILE_SIZE: ds_res["T_" + i + "_FILE_SIZE"],
                                QPOINT_FILE_ID: ds_res["QPOINT_FILE_ID"]
                            });
                        }

                    }
                    setInspCnt(inspCnt);
                    setFocusTab(inspCnt);
                    setResultList(sResultList);
                    setCounterNum(countIdx);
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }
    }, [enteredComponent, exitedComponent]);

    /**
     * 이미지 컴포넌트 Close
     */
    const closeImage = () => {
        setShowImage(false);
        setImageFileId("");
    };

    /**
     * 대책서 컴포넌트 Close
     */
    const closeCounter = () => {
        setShowCounter(false);
    };

    /**
     * swipe(Detail) 페이지로 이동
     * @param e
     * @param inspIdx
     */
    const callbackSwipe = (e: React.MouseEvent<HTMLAnchorElement>, inspIdx: number) => {
        e.preventDefault();
        setSelectedIndex(inspIdx - 1);
        setShowDetail(true);
    };

    /**
     * 리스트 페이지로 이동
     * 리스트 페이지로 갈때, detailResultList 값을 다시 세팅함.
     */
    const callbackClose = () => {

        setShowDetail(false);
    };


    /**
     * 탭선택
     * @param idx
     */
    const selectTab = (idx: number) => {
        setFocusTab(idx);
    };

    /**
     * fileId를 가지고서, 이미지 뷰어 처리
     * @param fileId
     */
    const imageViewerCallback = (fileId: string, title: string) => {
        setShowImage(true);
        setImageFileId(fileId);
        setTitleNm(title);
    };

    useEffect(() => {
        if (pictureFile) {
            setShowInspViewer(true);
        }
    }, [pictureFile]);


    /**
     * 대책서 작성 팝업 open
     */
    const counterMeasCallback = (inspTargetCd: string, inspCd: string, inspType: string) => {

        let counterData: any = {
            inspTargetCd: inspTargetCd,
            inspCd: inspCd,
            inspType: inspType

        }
        setCounterMap(counterData);
        setShowCounter(true);
    };

    return (
        <>
            {(!showDetail && !showImage && !showCounter && !showInspViewer) && (
                <div className={'container'}>
                    <div className={'card-wrap single'}>
                        <ul className={'card-list'}>
                            {
                                inspectList.map((item, index) => {
                                    return <li className={'card-item'} key={'cardView_'.concat(String(index))}>
                                        <CardMainView data={item}/>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className={'padding-box'}>
                        <div className={'result-state'}>
                            {
                                inspectList.map((item, index) => {
                                    return <span key={uuidv4()}
                                                 className={item.INSP_RESULT === 'P' ? 'result pass' : item.INSP_RESULT === 'F' ? 'result fail' : 'result'}>{item.INSP_RESULT === 'P' ? 'PASS' : item.INSP_RESULT === 'F' ? 'FAIL' : '대기'}</span>
                                })
                            }
                        </div>
                        <div className={'tabs-wrap type2'}>
                            <ul className={'tab-list'}>
                                {
                                    Array(inspCnt || 1).fill(0).map((_, i) => (
                                        <li className={focusTab === (i + 1) ? 'on' : ''}
                                            key={'inspCntTabList'.concat(String(i))}>
                                            <button type={'button'} onClick={() => selectTab((i + 1))}>#{i + 1}</button>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className={'table-wrap'}>
                            <table>
                                <colgroup>
                                    <col style={{width: '2.4rem'}}/>
                                    <col style={{width: '5rem'}}/>
                                    <col/>
                                    <col style={{width: '4.2rem'}}/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th scope={'col'}>No</th>
                                    <th scope={'col'}>{getDomain('DOMAIN4744')}</th>
                                    <th scope={'col'}>{getDomain('DOMAIN4745')}</th>
                                    <th scope={'col'}>{getDomain('DOMAIN3355')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    resultList.filter((x) => x.SEQ == focusTab).map((item, index) => {
                                        return <InspTableView data={item} key={'inspTableView'.concat(String(index))}/>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={'btn-area bottom'}>
                        <button type={'button'} className={'button-primary'}
                                onClick={() => pageMove(-1)}>{getDomain('DOMAIN4555')}
                        </button>
                    </div>
                </div>
            )}

            <CSSTransition in={showDetail} classNames={showDetail ? 'right' : 'left'} timeout={500} unmountOnExit>
                <div className={'pop-layer'} style={{display: 'block', zIndex: 200}}>
                    <div className={'wrap'}>
                        <header>
                            <h1>{getDomain('DOMAIN4517')}</h1>
                            <span className={'btn-wrap right'}>
                                    <button type={'button'} className={'button-close'} onClick={() => callbackClose()}>
                                        <i className={'hidden'}>{getDomain('DOMAIN0173')}</i>
                                    </button>
                                </span>
                        </header>
                        <div className={'container-noheader'}>
                            <div className="comm-slide-wrap" style={{
                                display: 'block',
                                // backgroundColor: 'white',
                                width: '100%',
                                height: 'unset',
                                overflowY: 'auto'
                            }}>
                                <div className={'swiper-pagination'} style={{
                                    width: '100% !important',
                                    paddingBottom: '2rem'
                                }}></div>

                                {/*23.11.06 네비게이션 기능 추가*/}
                                <div className={'swiper-next-nopage'}></div>
                                <div className={'swiper-prev-nopage'}></div>

                                <Swiper
                                    modules={[Pagination, Navigation]}
                                    initialSlide={selectedIndex || 0}
                                    pagination={{
                                        clickable: true,
                                        dynamicBullets: true,
                                        el: '.swiper-pagination'
                                    }}
                                    className={'swiper mySwiper'}
                                    navigation={{
                                        nextEl: ".swiper-next-nopage",
                                        prevEl: ".swiper-prev-nopage",
                                    }}
                                >
                                    {
                                        detailResultList.map((item, index) => {
                                            return <SwiperSlide style={{minHeight: gMinHeight}}
                                                                key={'inspMainSwiperSlide'.concat(item.SEQ).concat(String(index))}>
                                                <InspMainList data={item} key={uuidv4()}/>
                                            </SwiperSlide>
                                        })
                                    }
                                </Swiper>
                            </div>
                            <div className="btn-area bottom">
                                <button type="button" className="button-primary"
                                        onClick={() => callbackClose()}>{getDomain('DOMAIN0173')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
            {/*)}*/}
            <CSSTransition in={showImage} classNames={showImage ? 'right' : 'left'} timeout={500} unmountOnExit>
                <ImageViewer title={titleNm} imageFileId={imageFileId} onClose={closeImage}/>
            </CSSTransition>
            <CSSTransition in={showCounter} classNames={showCounter ? 'right' : 'left'} timeout={500} unmountOnExit>
                <CounterViewerInfo title={getDomain('DOMAIN4488')} counterMap={counterMap} onClose={closeCounter}
                />
            </CSSTransition>
        </>
    );

    /**
     * 카드뷰 동적 생성 처리
     * @param data
     * @param callback
     * @constructor
     */
    function CardMainView({data, callback}: { data: any, callback?: () => void }) {
        return (
            <>
                <div className={'item'} role={'button'}>
                    <section>
                        <div className={'label-wrap'}>
                            {data.HQ_INSP_YN === 'Y' && (
                                <i className={'label-type17'}>{data.HQ_INSP_YN_NM}</i>
                            )}
                            {data.HQ_INSP_YN === 'N' && (
                                <i className={'label-type16'}>{data.HQ_INSP_YN_NM} ({data.INSP_CNT})</i>
                            )}
                        </div>
                        <div className={'title-wrap'}>
                            <span>[{data.PLANT_CD}] [{data.PART_CD}]</span>
                            <strong><a style={{'textDecorationLine': 'underline', 'color': 'blue'}}
                                       onClick={() => imageViewerCallback(data.PART_FILE_ID, getDomain('DOMAIN4748'))}>{data.PART_NM.split(';', 1)}</a></strong>
                            {/*<button type={'button'} className={'btn-gallary'}*/}
                            {/*        style={{verticalAlign:'middle'}}*/}
                            {/*        onClick={() => imageViewerCallback(data.PART_FILE_ID)}><i*/}
                            {/*    className={'hidden'}>갤러리</i></button>*/}

                        </div>
                    </section>
                    <ul className={'date-list'}>
                        {
                            <li>
                                <em style={{width: '50%'}}>
                                    <a style={{'textDecorationLine': 'underline', 'color': 'blue'}}
                                       onClick={() => imageViewerCallback(data.LINE_FILE_ID, 'LINE'.concat(' ', getDomain('DOMAIN4748')))}>[{data.LINE_CD}] {data.LINE_NM}</a>
                                    {/*[{data.LINE_CD}] {data.LINE_NM}*/}
                                    {/*<button type={'button'} className={'btn-gallary'}*/}
                                    {/*        style={{verticalAlign:'middle'}}*/}
                                    {/*        onClick={() => imageViewerCallback(data.LINE_FILE_ID)}><i*/}
                                    {/*    className={'hidden'}>갤러리</i></button>*/}
                                </em>
                                <span style={{width: '50%'}}>{data.INSERT_DT_EXPR}</span>
                            </li>
                        }
                        {
                            <li>
                                <em style={{width: '50%'}}>{data.SPEC_CNT_EXPR}</em>
                                <span
                                    style={{width: '50%'}}>{getDomain('DOMAIN4730')} {data.TARGET_CNT.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            </li>
                        }
                    </ul>
                </div>
            </>
        );
    };


    /**
     * 검사항목 테이블 동적 생성 처리
     * @param data
     * @param callback
     * @constructor
     */
    function InspTableView({data, callback}: { data: any, callback?: () => void }) {
        return (
            <>
                <tr key={uuidv4()}>
                    <td><a onClick={(e) => callbackSwipe(e, toNumber(data.seqVal))}>{data.seqVal}</a></td>
                    <td><a onClick={(e) => callbackSwipe(e, toNumber(data.seqVal))}>{data.INSP_TYPE}</a></td>
                    <td className={'left'}><a onClick={(e) => callbackSwipe(e, toNumber(data.seqVal))}>[{data.INSP_CD}] {data.INSP_NM}</a>
                    </td>
                    <td><a onClick={(e) => callbackSwipe(e, toNumber(data.seqVal))}>
                        {(data.MEAS_RESULT === undefined) && (
                            // <span className={'label-state wait'}>{getDomain('DOMAIN4566')}</span>
                            <span className={'label-state wait'}>STBY</span>
                        )}
                        {(data.MEAS_RESULT === 'OK') && (
                            <span className={'label-state ok'}>OK</span>
                        )}
                        {(data.MEAS_RESULT === 'NG') && (
                            <span className={'label-state ng'}>NG</span>
                        )}
                    </a>
                    </td>
                </tr>
            </>
        );
    };

    /**
     * 검사항목 메인 리스트
     * @param data
     * @param callback
     * @constructor
     */
    function InspMainList({data}: { data: any, callback?: () => void }) {
        return (
            <>
                <div className={'swiper-wrapper'}>
                    <div className={'swiper-slide'}>
                        <ul className={'contents-table-list'}>
                            <li>
                                <div className={'col'}>
                                    <span className={'title'} style={{
                                        wordBreak: 'break-word',
                                        textAlign: 'center'
                                    }}>{getDomain('DOMAIN4745')}</span>
                                    <span className={'item-wrap'}>
                                    [{data.INSP_CD}] {data.INSP_NM}
                                        <button type={'button'} className={'btn-gallary'}
                                                style={{verticalAlign: 'middle'}}
                                                onClick={() => imageViewerCallback(data.QPOINT_FILE_ID, getDomain('DOMAIN4585'))}><i
                                            className={'hidden'}>갤러리</i></button>
                                </span>
                                </div>
                            </li>
                            <li>
                                <div className={'col'}>
                                    <span className={'title'} style={{
                                        wordBreak: 'break-word',
                                        textAlign: 'center'
                                    }}>{getDomain('DOMAIN4567')}</span>
                                    <span className={'item-wrap center'} style={{justifyContent:"center"}}>{data.INSP_TYPE}</span>
                                </div>
                                <div className={'col'}>
                                    <span className={'title'} style={{
                                        wordBreak: 'break-word',
                                        textAlign: 'center'
                                    }}>{getDomain('DOMAIN4749')}</span>
                                    <span className={'item-wrap center'} style={{justifyContent:"center"}}>{data.EQUIP_NM}</span>
                                </div>
                            </li>
                            <li>
                                <div className={'col'}>
                                    <span className={'title'} style={{
                                        wordBreak: 'break-word',
                                        textAlign: 'center'
                                    }}>LSL</span>
                                    <span className={'item-wrap right'} style={{
                                        wordBreak: 'break-word'
                                    }}>{data.NUM_YN === 'Y' && data.LSL !== undefined ? Number(data.LSL).toFixed(data.POINT) : data.LSL}</span>
                                </div>
                                <div className={'col'}>
                                    <span className={'title'} style={{
                                        wordBreak: 'break-word',
                                        textAlign: 'center'
                                    }}>USL</span>
                                    <span className={'item-wrap right'} style={{
                                        wordBreak: 'break-word'
                                    }}>{data.NUM_YN === 'Y' && data.USL !== undefined ? Number(data.USL).toFixed(data.POINT) : data.USL}</span>
                                </div>
                            </li>
                            <li>
                                <div className={'col'}>
                                    <span className={'title'} style={{
                                        wordBreak: 'break-word',
                                        textAlign: 'center'
                                    }}>CL</span>
                                    <span className={'item-wrap right'} style={{
                                        wordBreak: 'break-word'
                                    }}>{data.NUM_YN === 'Y' && data.CL !== undefined ? Number(data.CL).toFixed(data.POINT) : data.CL}</span>
                                </div>
                                <div className={'col'}>
                                    <span className={'title'} style={{
                                        wordBreak: 'break-word',
                                        textAlign: 'center'
                                    }}>{getDomain('DOMAIN4428')}</span>
                                    <span className={'item-wrap center'}
                                          style={{wordBreak: 'break-word', justifyContent:'center'}}>{data.UNIT}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={'photo-wrap'}>
                    <h2 className={'h2-title'}>{getDomain('DOMAIN4588')}</h2>
                    {
                        resultList.filter((x) => x.INSP_CD === data.INSP_CD && x.SEQ == focusTab).map((t, i) => (
                            <div className={'btn-shoot-wrap'} key={uuidv4()}
                                 onClick={() => imageViewerCallback(t.FILE_ID, getDomain('DOMAIN4748'))}>
                                <p className={'text-file'}>
                                    <em>{t.FILE_NM}</em>
                                    <span>{floor(toNumber(t.FILE_SIZE) / (1024 * 1024), 2)}MB</span>
                                </p>
                            </div>
                        ))
                    }
                </div>
                <div className={'result-box'}>
                    <h2 className={'h2-title'}>{getDomain('DOMAIN4633')}</h2>
                    <div className={'table-wrap type2'}>
                        <table>
                            <colgroup>
                                <col/>
                                <col/>
                                <col/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th scope={'col'}></th>
                                <th scope={'col'}>{getDomain('DOMAIN2612')}</th>
                                <th scope={'col'}></th>
                            </tr>
                            </thead>
                            {data.NUM_YN === 'Y' && (
                                <tbody>
                                {
                                    resultList.filter((x) => x.INSP_CD === data.INSP_CD && x.SEQ == focusTab).map((t, i) => (
                                        <tr key={uuidv4()}>
                                            <td className={'center'}>
                                                {(t.OCR_YN === 'Y') && (
                                                    <button type={'button'} className={'btn-ocr'}><i
                                                        className={'hidden'}>barcode</i></button>
                                                )}
                                            </td>
                                            <td className={'center'}>{t.MEAS_VAL}</td>
                                            <td className={'center'}>
                                                {(t.MEAS_RESULT === 'OK') && (
                                                    <span className={'label-state ok'}>OK</span>
                                                )}
                                                {(t.MEAS_RESULT === 'NG') && (
                                                    <span className={'label-state ng'}>NG</span>
                                                )}

                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            )
                            }
                            {data.NUM_YN === 'N' && (
                                <tbody>
                                {
                                    resultList.filter((x) => x.INSP_CD === data.INSP_CD && x.SEQ == focusTab).map((t, i) => (
                                        <tr>
                                            <td className={'center'}>
                                            </td>
                                            <td className={'center'}>{t.MEAS_RESULT}</td>
                                            <td className={'center'}>
                                                {(t.MEAS_RESULT === 'OK') && (
                                                    <span className={'label-state ok'}>OK</span>
                                                )}
                                                {(t.MEAS_RESULT === 'NG') && (
                                                    <span className={'label-state ng'}>NG</span>
                                                )}

                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            )
                            }
                        </table>
                    </div>
                    {data.INSP_RESULT === 'NG' && (
                        <div className={'dashed-box'} style={{padding: '1.5rem 0 0'}}>
                            <h2 className={'h2-title'}>{getDomain('DOMAIN4573')}</h2>
                            <div className={'result-file'}>
                                <div className={'title'}>{getDomain('DOMAIN4574')}</div>
                                <div className={'file-name'}>
                                    {
                                        resultList.filter(result => result.INSP_CD === data.INSP_CD && result.MEAS_RESULT === 'NG').length == 0 && (
                                            <button type={'button'} className={'button-primary sm write'} style={{
                                                inlineSize: 'fit-content'
                                            }}>{getDomain('DOMAIN4582')}</button>
                                        )
                                    }
                                    {
                                        resultList.filter(result => result.INSP_CD === data.INSP_CD && result.MEAS_RESULT === 'NG').length > 0 && (
                                            <button type={'button'} className={'button-secondary sm write'}
                                                    style={{backgroundColor: '#3478F5', inlineSize: 'fit-content'}}
                                                    onClick={() => counterMeasCallback(data.INSP_TARGET_CD, data.INSP_CD, data.INSP_TYPE)}>
                                                {data.COUNTER_CNT > 0 ? getDomain('DOMAIN4735') : getDomain('DOMAIN4582')}
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    };
};