import React, {useEffect, useState} from 'react';
import {NexacroJsonUnitType, SelectBoxType} from "../../../../components/model/SqciTypes";
import {useDataset} from "../../../../components/contexts/CommonContext";
import useHtmlElement from "../../../../hooks/useHtmlElement";
import {STORAGE_NAME} from "../../../../components/CommonConstants";
import useGlobalData from "../../../../hooks/useGlobalData";
import * as gfn from "../../../../components/utils/CommonUtils";
import {CSSTransition} from "react-transition-group";
import PopFiles from "../../../common/popFiles";
import PopChangePoint from "../../../common/popChangePoint";
import PopEcnList from "./PopEcnList";

export default function PartInspectionInformation(
        {state, ds_Part, ds_Btn02, ds_SpecFile, ds_ecnList, css_btn_Attach, fn_changDs} :
        {
            state: string,
            ds_Part: NexacroJsonUnitType[],
            ds_Btn02: NexacroJsonUnitType[],
            ds_SpecFile: NexacroJsonUnitType[],
            ds_ecnList: NexacroJsonUnitType[],
            css_btn_Attach: boolean,
            fn_changDs:(datasetName: string, dataset: Array<NexacroJsonUnitType>) => void
        }
    ) {
    const {dataset} = useDataset();
    const {useSelectBox} = useHtmlElement();
    const {getGlobalSetting, getDomain} = useGlobalData();

    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);

    // Traceability : 20220615 최순홍 RiskLot 추가
    // "div_Main_div_Part_CheckBox00_onchanged" 대체
    const [timerCnt, setTimerCnt] = useState(0);
    const [cssTimerCnt, setCssTimerCnt] = useState("");
    const [filePop, setFilePop] = useState(false);          // "SPEC 첨부파일" 팝업 // "fn_specAttach" 대체
    const [changePop, setChangePop] = useState(false);      // "변경점 확인" 팝업 / "fn_ecnInfoSelect" 대체
    const [ecnListPop, setEcnListPop] = useState(false);    // "ECN No" 팝업

    const partTypes = useSelectBox('partType');
    const riskLotTypes = useSelectBox('riskLotType');

    useEffect(() => {
        partTypes.initOptions(() => {
            let returnList: SelectBoxType[] = [];

            const filter = (dataset?.ds_CommonCode || []).filter(f => {
                return f.CATEGORY === "SQCI000094" && f.LANG === gv_locale;
            });

            for (const f of filter || []) {
                returnList.push({
                    value: String(f.CD),
                    text: String(f.CDNM)
                })
            }
            return returnList;
        });
        riskLotTypes.initOptions(() => {
            let returnList: SelectBoxType[] = [];

            const filter = (dataset?.ds_CommonCode || []).filter(f => {
                return f.CATEGORY === "SQCI000285" && f.LANG === gv_locale && f.FILLER1 === '2';
            });

            for (const f of filter || []) {
                returnList.push({
                    value: String(f.CD),
                    text: String(f.CDNM)
                })
            }
            return returnList;
        });
    }, []);

    // QMV_P_Out_InspectVndRcrdDetailReg_ontimer 기능 대체
    useEffect(() => {
        if(css_btn_Attach) {
            let temp_timerCnt = timerCnt;
            let timer = setTimeout(() => {
                if (temp_timerCnt < 10) {
                    temp_timerCnt++;
                    setTimerCnt(temp_timerCnt);
                }
            }, 1000);

            if (temp_timerCnt > 10) {
                return () => {
                    clearTimeout(timer)
                }
            }
        }

    }, [timerCnt]);

    const partHandleClick = (e :any, columnN :string) => {
        let index = 0;
        let copyArray = [...ds_Part];

        if(columnN === "RISK_LOT_YN") {
            let tarVal = e.target.checked ? 'Y' : 'N';
            if(tarVal === "Y"){
                copyArray[index]['RISK_LOT_YN'] = 'Y';
            }else{
                copyArray[index]['RISK_LOT_YN'] = 'N';
                copyArray[index]['RISK_LOT_TYPE'] = '';
                copyArray[index]['RISK_LOT_MEMO'] = '';
            }
            fn_changDs('ds_Part', copyArray);
        }
    };

    const partHandleChange = (e: any, columnN :string) => {
        let index = 0;
        let copyArray = [...ds_Part];
        copyArray[index][columnN] = e.target.value;
        fn_changDs('ds_Part', copyArray);
    };

    const partListHandleChange = (arr: any[]) => {
        let index = 0;
        let copyArray = [...ds_Part];
        for(let a=0;a<arr.length;a++) {
            copyArray[index][arr[a]['text']] = arr[a]['value'];
        }
        fn_changDs('ds_Part', copyArray);
    };

    // div_Main_div_Part_btn_EcnNo_onclick 대체
    const chgModPartMemoFuc = (chgModPartMemo:string) => {
        let index = 0;
        let copyArray = [...ds_Part];
        copyArray[index]["MOD_PART_MEMO"] = chgModPartMemo;
        fn_changDs('ds_Part', copyArray);
    }

    // div_Main_div_Part_cbo_PartType_onitemchanged 대체
    const chgNewPartType = (e:any) => {
        let newPartType = e.target.value;
        let arr = [];
        arr.push({
            value: newPartType,
            text: 'NEW_PART_TYPE'
        });
        if(newPartType != 'C'){
            arr.push({
                value: '',
                text: 'MOD_PART_MEMO'
            });
        }

        // "fn_setInspGb" 대체
        let inspGb = "";
        if(newPartType == "N"){
            inspGb = "1";
        }else if(newPartType == "C") {
            inspGb = "2";
        }else if(newPartType == "O") {
            inspGb = "3";
        }
        arr.push({
            value: inspGb,
            text: 'INSP_GB'
        });
        partListHandleChange(arr);
    };

    return (
        <>
            {state == 'INFO' ?
                <div className={'contents-table-wrap width-type1'} style={{display: 'block'}}>
                    <ul className={'contents-table-list'}>
                        <li>
                            {/*23.09.22 버튼 너비 동일하게 변경 처리*/}
                            <div className={'title'}>{getDomain('DOMAIN2607')}</div>   {/*관련정보*/}
                            <div className={'item-wrap'}>
                                <span className={'item-btn-wrap'}>
                                    <button
                                        type={'button'}
                                        className={'button-secondary'}
                                        disabled={!gfn.gfn_n_stringToBoolean(ds_Btn02[1]["BTN_ENABLE"])}
                                        style={{width : '106px'}}
                                        onClick={() => setFilePop(true)}
                                    >{getDomain('DOMAIN4627')}</button>   {/*SPEC 첨부파일*/}
                                    <button
                                        type={'button'}
                                        className={'button-secondary'}
                                        disabled={!gfn.gfn_n_stringToBoolean(ds_Btn02[0]["BTN_ENABLE"])}
                                        style={{color: (!css_btn_Attach ? 'none' : timerCnt%2==0 ? 'red' : 'blue'), width : '106px'}}
                                        onClick={() => setChangePop(true)}
                                    >{getDomain('DOMAIN4628')}</button>   {/*변경점 확인*/}
                                </span>
                            </div>
                        </li>
                        <li>
                            <div className={'title'}>
                                <span>
                                    <strong className={'required'}>*</strong> {getDomain('DOMAIN1279')} {/*자재구분*/}
                                </span>
                            </div>
                            <div className={'item-wrap'}>
                                <select value={ds_Part[0]["NEW_PART_TYPE"]||''}
                                        onChange={
                                            e => chgNewPartType(e)
                                        }
                                >
                                    {partTypes.options}
                                </select>
                            </div>
                        </li>
                        <li>
                            <div className={'title'}>
                                <span>
                                    <strong className={'required'}>*</strong> {getDomain('DOMAIN0816')} {/*ECN No.*/}
                                </span>
                            </div>
                            <div className={'item-wrap'}>
                                <span className={ds_Part[0]['NEW_PART_TYPE'] != 'C' ? 'search-item disabled' : 'search-item'}>
                                    <input
                                        type={'text'}
                                        placeholder=""
                                        value={ds_Part[0]["MOD_PART_MEMO"]||''}
                                        readOnly={true}
                                    />
                                    <button
                                        type={'button'}
                                        className={'icon-btn-search'}
                                        disabled={ds_Part[0]['NEW_PART_TYPE'] != 'C'}
                                        onClick={() => setEcnListPop(true)}
                                    ><i className={'hidden'}>검색</i></button>
                                </span>
                            </div>
                        </li>
                        <li>
                            <div className={'title'} dangerouslySetInnerHTML={{__html: getDomain('DOMAIN4614')||''}}>
                                {/*Risk Lot<br/>사전 신고*/}
                            </div>
                            <div className={'item-wrap'}>
                                <label htmlFor={'check'} className={'check-wrap'} style={{fontSize:'1.4rem'}}>
                                    <input type={'checkbox'} id={'check'} checked={(ds_Part[0]['RISK_LOT_YN']||'') === 'Y' ? true : false}
                                           onChange={e => partHandleClick(e, 'RISK_LOT_YN')}/> {getDomain("DOMAIN4041")}
                                    {/*※ Risk Lot 사전신고 체크 시 유형 및 메모 필수 입력*/}
                                </label>
                            </div>
                        </li>
                        <li>
                            <div className={'title'} dangerouslySetInnerHTML={{__html: "<span> <strong class='required'>*</strong> "+getDomain('DOMAIN4615')||''+"</span>"}}></div> {/*Risk Lot<br/>사전 신고 유형*/}
                            <div className={'item-wrap'}>
                                <select
                                    disabled={(ds_Part[0]['RISK_LOT_YN']||'') === 'Y' ? false : true}
                                    value={ds_Part[0]['RISK_LOT_TYPE']||''}
                                    onChange={e => partHandleChange(e, 'RISK_LOT_TYPE')}
                                >
                                    <option value={""}>==</option>
                                    {riskLotTypes.options}
                                </select>
                            </div>
                        </li>
                        <li>
                            <div className={'title'} dangerouslySetInnerHTML={{__html : "<span> <strong class='required'>*</strong> "+getDomain('DOMAIN4757')||''+"</span>"}}></div> {/*Risk Lot<br/>메모*/}
                            <div className={'item-wrap'}>
                                <textarea
                                    disabled={(ds_Part[0]['RISK_LOT_YN']||'') === 'Y' ? false : true}
                                    defaultValue={(ds_Part[0]['RISK_LOT_YN']||'') === 'Y' ? ds_Part[0].RISK_LOT_MEMO || '' : ''}
                                    onBlur={e => partHandleChange(e, 'RISK_LOT_MEMO')}></textarea>
                            </div>
                        </li>
                    </ul>
                </div>
            :
                <div className={'contents-table-wrap width-type1'} style={{display: 'block'}}>
                    <ul className={'contents-table-list'}>
                        <li>
                            {/*23.09.22 버튼 너비 동일하게 변경 처리*/}
                            <div className={'title'}>{getDomain('DOMAIN2607')}</div>   {/*관련정보*/}
                            <div className={'item-wrap'}>
                                <span className={'item-btn-wrap'}>
                                    <button
                                        type={'button'}
                                        className={'button-secondary'}
                                        disabled={!gfn.gfn_n_stringToBoolean(ds_Btn02[1]["BTN_ENABLE"])}
                                        style={{width : '106px'}}
                                        onClick={() => setFilePop(true)}
                                    >{getDomain('DOMAIN4627')}</button>   {/*SPEC 첨부파일*/}
                                    <button
                                        type={'button'}
                                        className={'button-secondary'}
                                        disabled={!gfn.gfn_n_stringToBoolean(ds_Btn02[0]["BTN_ENABLE"])}
                                        style={{color: (!css_btn_Attach ? 'none' : timerCnt%2==0 ? 'red' : 'blue'), width : '106px'}}
                                        onClick={() => setChangePop(true)}
                                    >{getDomain('DOMAIN4628')}</button>   {/*변경점 확인*/}
                                </span>
                            </div>
                        </li>
                        <li>
                            <div className={'title'}>
                                <span>
                                    <strong className={'required'}>*</strong> {getDomain('DOMAIN1279')} {/*자재구분*/}
                                </span>
                            </div>
                            <div className={'item-wrap'}>
                                <>
                                    {ds_Part[0]["NEW_PART_TYPE"]}
                                </>
                            </div>
                        </li>
                        <li>
                            <div className={'title'}>
                                <span>
                                    <strong className={'required'}>*</strong> {getDomain('DOMAIN0816')} {/*ECN No.*/}
                                </span>
                            </div>
                            <div className={'item-wrap'}>
                                <span className={'search-item disabled'}>
                                    <input
                                        type={'text'}
                                        placeholder=""
                                        value={ds_Part[0]["MOD_PART_MEMO"]||''}
                                        readOnly={true}
                                    />
                                </span>
                            </div>
                        </li>
                        <li>
                            <div className={'title'} dangerouslySetInnerHTML={{__html: getDomain('DOMAIN4614')||''}}>
                                {/*Risk Lot<br/>사전 신고*/}
                            </div>
                            <div className={'item-wrap'}>
                                <label htmlFor={'check'} className={'check-wrap'} style={{fontSize:'1.4rem'}}>
                                    <input type={'checkbox'} id={'check'} checked={(ds_Part[0]['RISK_LOT_YN']||'') === 'Y' ? true : false}
                                           onChange={e => partHandleClick(e, 'RISK_LOT_YN')}
                                           disabled={true}
                                    /> {getDomain("DOMAIN4041")}
                                    {/*※ Risk Lot 사전신고 체크 시 유형 및 메모 필수 입력*/}
                                </label>
                            </div>
                        </li>
                        <li>
                            <div className={'title'} dangerouslySetInnerHTML={{__html: "<span> <strong class='required'>*</strong> "+getDomain('DOMAIN4615')||''+"</span>"}}></div> {/*Risk Lot<br/>사전 신고 유형*/}
                            <div className={'item-wrap'}>
                                <>
                                    {
                                        (dataset?.ds_CommonCode||[])
                                            .filter(f => f.CATEGORY === "SQCI000285" && f.LANG === gv_locale && f.FILLER1 === '2' && f.CD === ds_Part[0]["RISK_LOT_TYPE"])
                                            .map((i, index) => {
                                                return <React.Fragment key={i.CD}>{i.CDNM}</React.Fragment>;
                                            })
                                    }
                                </>
                            </div>
                        </li>
                        <li>
                            <div className={'title'} dangerouslySetInnerHTML={{__html : "<span> <strong class='required'>*</strong> "+getDomain('DOMAIN4757')||''+"</span>"}}></div>
                            <div className={'item-wrap'}>
                                <textarea
                                    disabled={true}
                                    defaultValue={(ds_Part[0]['RISK_LOT_YN']||'') === 'Y' ? ds_Part[0].RISK_LOT_MEMO || '' : ''}
                                    onBlur={e => partHandleChange(e, 'RISK_LOT_MEMO')}></textarea>
                            </div>
                        </li>
                    </ul>
                </div>
            }

            <CSSTransition in={filePop} classNames={filePop ? 'right' : 'left'} timeout={500} unmountOnExit>
                <PopFiles title={getDomain('DOMAIN4627')} ds_SpecFile={ds_SpecFile} onClose={() => setFilePop(false)}/>
                {/*SPEC 첨부파일*/}
            </CSSTransition>
            <CSSTransition in={changePop} classNames={changePop ? 'right' : 'left'} timeout={500} unmountOnExit>
                <PopChangePoint title={getDomain('DOMAIN4628')} list={ds_ecnList} onClose={() => setChangePop(false)}/>
                {/*변경점 확인*/}
            </CSSTransition>
            <CSSTransition in={ecnListPop} classNames={ecnListPop ? 'right' : 'left'} timeout={500} unmountOnExit>
                <PopEcnList title={getDomain('DOMAIN4628')} list={ds_ecnList.filter(item => item.STATUS == '1' || item.STATUS == '5' || (item.STATUS == '2' && (item.MESAS_RESULT_CD == 'B' || item.MESAS_RESULT_CD == 'R')))} onClose={(chgModPartMemo:string) => {setEcnListPop(false);chgModPartMemoFuc(chgModPartMemo)}}/>
                {/*ECN No.조회*/}
            </CSSTransition>
        </>
    );
};