/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > 입고검사 조회 > 그룹 판정
 * 02. 프로그램ID : QmsInspGroup.tsx
 * 03. 프로그램명 : 검사대상 및 결과입력 리스트
 * 04. 화면설명 : 입고검사 검사대상 그룹 판정
 * 05. 작성일 : 2023.08.24
 * 06. 작성자 : 채보람
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-08-24     채보람    최초 작성
 **************************************************************************************************
 */

import React, {useEffect, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import {CSSTransition} from 'react-transition-group';
import Picture from '../common/Picture';
import * as gfn from "../../components/utils/CommonUtils";
import {useDataset} from "../../components/contexts/CommonContext";
import {toNumber} from "lodash";
import {requestParser, responseParser} from "../../components/utils/NexacroParseUtil";
import useAxios, {AXIOS_HEADER} from "../../hooks/useAxios";
import {CardViewType, NexacroJsonType, NexacroJsonUnitType} from "../../components/model/SqciTypes";
import {useError} from "../../hooks/useException";
import {useComponent} from "../../components/contexts/ComponentContext";
import useGlobalData from "../../hooks/useGlobalData";
import {gfn_isNull} from "../../components/utils/CommonUtils";
import {STORAGE_NAME} from "../../components/CommonConstants";
import InfiniteCardView from "../common/InfiniteCardView";

export default function QmsInspGroup({ds_ListSelect, onClose}: {ds_ListSelect: any, onClose: (selectedLotList?: {[K: string]: string | boolean}[]) => void}) {
    const {sendAxios} = useAxios();
    const {setDialog} = useComponent();
    const {getMessage, getDomain} = useGlobalData();
    const {getSdpSysDate} = useGlobalData();
    const {dataset, sessionValues} = useDataset();
    const {getGlobalSetting} = useGlobalData();
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);

    const [memo, setMemo] = useState(String);
    const [measResult, setMeasResult] = useState(String);
    const [grDate, setGrDate] = useState(ds_ListSelect['GR_DATE']);
    const [invNo, setInvNo] = useState(ds_ListSelect['INV_NO']);
    const [inspRadio, setInspRadio] = useState(String);
    const [ds_GroupList, setDs_GroupList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_GroupList2, setDs_GroupList2] = useState(new Array<NexacroJsonUnitType>());
    const [ds_ResultGroupList, setDs_ResultGroupList] = useState(new Array<NexacroJsonUnitType>());
    const [selectedCard, setSelectedCard] = useState<CardViewType>();
    const [closedCard, setClosedCard] = useState<CardViewType>();
    const [lotList, setLotList] = useState<{[K: string]: string | boolean}[]>();
    const [expectedLotList, setExpectedLotList] = useState<{[K: string]: string | boolean}[]>();
    const {throwException} = useError();

    const dataLotList = [{}];
    const dataExpectedLotList = [{}];

    let vds_ListSelect: any = [];

    useEffect(() => {
        form_onload();
    }, []);

    /* form_onload */
    function form_onload() {
        // console.log("입고검사 > 입고검사 조회 > 그룹 판정");
        // console.log("ds_ListSelect");
        // console.log(ds_ListSelect);
        // console.log("입고검사 > 입고검사 조회 > 그룹 판정");

        /* 화면 설정 초기화*/
        vds_ListSelect.push(ds_ListSelect);

        if (ds_ListSelect['DIVISION_CD'] == "Q") {
            setInspRadio("1");

            fn_search("1"); // 무선일땐 기준입고시간 1시간 이내
        } else {
            setInspRadio("0");

            fn_search("0"); // 무선아닐땐 당일 입고 기준
        }
    }

    /* 그룹판정을 위한 리스트 조회(입고검사 목록 콘트롤러 재사용 IS_GROUP 으로 구분) */
    function fn_search(dateType: string) { //dateType 0 : 당일입고, 1 : 한시간 이내 입고
        let date = ds_ListSelect['SUP_RECEIVE_DATE'].replaceAll("-", "");
        let grDateTime = ds_ListSelect['GR_DATE'].replaceAll("-" ,"") + (typeof(ds_ListSelect['GR_TIME']) != 'undefined' ? ds_ListSelect['GR_TIME'].replaceAll (":", "") : '');

        const requestJson: NexacroJsonType = {
            ds_Search: [{
                RECORDS: "99999999",
                RECORDSOFFSET: "0",
                SEARCH_TYPE: "EQ",
                DIVISION_CD: ds_ListSelect['DIVISION_CD'],
                TYPE: "02",
                FROM_DATE: date,
                TO_DATE: date,
                HQ_CD: ds_ListSelect['HQ_CD'],
                CATEGORY: "",
                PART_CD: ds_ListSelect['PART_CD'],
                INSP_STATUS: "Y", // VOC 34 재검사는 그룹판정 대상이 아니다
                INSP_GB: "",
                DMR_STAGE: "",
                SUB_STATUS: "",
                INV_TYPE: "",
                LONG_TERM_FLAG: "false", //장기재고 표시 여부
                LANG: gv_locale,
                IS_GROUP: "Y",
                LOT_ID: ds_ListSelect['LOT_ID'],
                DATE_TYPE: dateType,
                GR_DATE_TIME: grDateTime
            }],
            ds_Plant: [{
                PLANT: ds_ListSelect['PLANT_CD']
            }]
        };

        sendAxios('/common/nexacro/QMS_M_Incm_InspListDataSelect.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            let vds_GroupList: any = response?.data.ds_List||[];
            let vds_GroupList2: any = response?.data.ds_List||[];

            //기준인보이스 기본 체크
            vds_GroupList = vds_GroupList.filter((item: any) => (item['GR_DATE'] == grDate && item['LOT_ID'] != null) && item['IV_STATUS'] != '2').sort((item: any) => {
                if (item['INV_NO'] == invNo) {
                    return 0
                } else {
                    return 1
                }
            });

            vds_GroupList2 = vds_GroupList2.filter((item: any) => (item['GR_DATE'] == null || item['GR_DATE'] == '' || item['LOT_ID'] == null || item['LOT_ID'] == '') && item['IV_STATUS'] != '2'); // 입고일 없는것도 포함

            let tempDataLotList: any[] = [];
            let tempDataExpectedLotList: any[] = [];

            for (let i = 0; i < vds_GroupList.length; i++) {
                let selectedFlag = false;

                if (ds_ListSelect['INV_NO'] == vds_GroupList[i]['INV_NO']) {
                    selectedFlag = true;
                }

                tempDataLotList.push({
                    id: i,
                    selected: selectedFlag,
                    key1: "I/V No : " + vds_GroupList[i]['INV_NO'],
                    key2: vds_GroupList[i]['SUP_RECEIVE_DATE'],
                    key3: getDomain('DOMAIN4645') + " : " + vds_GroupList[i]['GR_DATE'] + " " + gfn.gfn_isNull(vds_GroupList[i]['GR_TIME']) ? '' : vds_GroupList[i]['GR_TIME'],
                    key4: getDomain('DOMAIN2415') + " : " + vds_GroupList[i]['GR_QTY'],
                    key5: getDomain('DOMAIN4671') + " : " + vds_GroupList[i]['SUB_STATUS_NM'] + "(" + vds_GroupList[i]['SUBMIT_CNT'] + ")",
                    key6: getDomain('DOMAIN4598') + " : " + vds_GroupList[i]['IV_STATUS_NM']
                });
            }

            setLotList(tempDataLotList);

            for (let i = 0; i < vds_GroupList2.length; i++) {
                tempDataExpectedLotList.push({
                    id: i,
                    selected: false,
                    key1: "I/V No : " + vds_GroupList2[i]['INV_NO'],
                    key2: vds_GroupList2[i]['SUP_RECEIVE_DATE'],
                    key3: getDomain('DOMAIN4645') + " : " + vds_GroupList2[i]['GR_DATE'] + " " + gfn.gfn_isNull(vds_GroupList2[i]['GR_TIME']) ? '' : vds_GroupList2[i]['GR_TIME'],
                    key4: getDomain('DOMAIN2415') + " : " + vds_GroupList2[i]['GR_QTY'],
                    key5: getDomain('DOMAIN4671') + " : " + vds_GroupList2[i]['SUB_STATUS_NM'] + "(" + vds_GroupList2[i]['SUBMIT_CNT'] + ")",
                    key6: getDomain('DOMAIN4598') + " : " + vds_GroupList2[i]['IV_STATUS_NM']
                });
            }

            setExpectedLotList(tempDataExpectedLotList);

            setDs_GroupList(vds_GroupList);
            setDs_GroupList2(vds_GroupList2);
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    const handleSetData = () => {
        setLotList(dataLotList);
        setExpectedLotList(dataExpectedLotList);

        fn_search(inspRadio);
    };

    useEffect(() => {
        setLotList(dataLotList);
        setExpectedLotList(dataExpectedLotList);
    }, []);

    const selectedLotData = (lot: {[K: string]: string | boolean}) => {
        if (ds_ListSelect['INV_NO'] == ds_GroupList[Number(lot.id)]['INV_NO']) {
            return;
        }

        setLotList(lotList?.map(item => {
            if(item.id === lot.id) {
                item.selected = !item.selected;
            }
            return item;
        }));
    };

    const selectedExpectedLotList = (lot: {[K: string]: string | boolean}) => {
        setExpectedLotList(expectedLotList?.map(item => {
            if(item.id === lot.id) {
                item.selected = !item.selected;
            }
            return item;
        }));
    };

    const handleSelected = (sendResult: boolean) => {
        const selectedLotList = lotList?.filter(item => item.selected);
        const selectedExpectedLotList = expectedLotList?.filter(item => item.selected);

        let tempSelectedLotList: any[] = [];

        for (let i = 0; i < ds_GroupList.length; i++) {
            for (let j = 0; j < (selectedLotList||[]).length; j++) {
                if (String((selectedLotList||[])[j]['key1']).replace('I/V No : ', '') == ds_GroupList[i]['INV_NO']) {
                    tempSelectedLotList.push(ds_GroupList[i]);
                }
            }
        }

        for (let i= 0 ; i < ds_GroupList2.length; i++) {
            for (let j = 0; j < (selectedExpectedLotList||[]).length; j++) {
                if (String((selectedExpectedLotList||[])[j]['key1']).replace('I/V No : ', '') == ds_GroupList2[i]['INV_NO']) {
                    tempSelectedLotList.push(ds_GroupList2[i]);
                }
            }
        }

        if(sendResult) {
            onClose([
                ...tempSelectedLotList || []
            ]);
        } else {
            onClose();
        }
    };

    /* 라디오 박스 변경 시 */
    function radioChange(e: any) {
        setInspRadio(e.target.value);
    }

    return (
        <>
            <div className={'pop-layer'} style={{display: 'block'}}>
                <div className={'wrap'}>
                    <header>
                        <h1>{getDomain('DOMAIN4664')}</h1>  {/*동일 Lot 그룹판정*/}
                        <span className={'btn-wrap right'}>
                            <button type={'button'} className={'button-close'} onClick={() => handleSelected(false)}>
                                <i className={'hidden'}>{getDomain('DOMAIN0173')}</i>  {/*닫기*/}
                            </button>
                        </span>
                    </header>
                    <div className={'container-noheader'}>
                        <div className={'lot-wrap'}>
                            <div className={'contents-table-wrap lot'}>
                                <h2 className={'h2-title'}>{getDomain('DOMAIN2068')}</h2>  {/*기본정보*/}
                                <ul className={'contents-table-list'}>
                                    <li>
                                        <div className={'title'}>{getDomain('DOMAIN0372')}</div>  {/*협력사*/}
                                        <div className={'item-wrap'}>[{ds_ListSelect['HQ_CD']}] {ds_ListSelect['HQ_NM']}</div>
                                    </li>
                                    <li>
                                        <div className={'title'} dangerouslySetInnerHTML={{__html:getDomain('DOMAIN4596')}}></div>  {/*부품명*/}
                                        <div className={'item-wrap'}>[{ds_ListSelect['PART_CD']}] {ds_ListSelect['PART_NM']}</div>
                                    </li>
                                    <li>
                                        <div className={'item-wrap'}>
                                                <label htmlFor="radio1">
                                                    <input type={'radio'} id={'radio1'} name={'radio'} value={"1"} defaultChecked={true} onChange={radioChange} /> {getDomain("DOMAIN3646")}
                                                </label>
                                                <label htmlFor="radio2" style={{paddingLeft:'2rem'}}>
                                                    <input type={'radio'} id={'radio2'} name={'radio'} value={"0"} onChange={radioChange} /> {getDomain("DOMAIN3647")}
                                                </label>
                                        </div>
                                    </li>
                                </ul>
                                <div className={'btn-area'}>
                                    <button type={'button'} className={'button-line'} onClick={handleSetData}>{getDomain('DOMAIN2181')}</button>  {/*검색*/}
                                </div>
                            </div>
                            <div className={'lot-list-wrap'}>
                                <h2 className={'h2-title'}>{getDomain('DOMAIN4665')}</h2>  {/*동일 Lot List*/}
                                <ul className={'card-list lot-type'}>
                                    {
                                        lotList?.map(item => {
                                            return <li key={uuidv4()} className={'card-item'} role={'button'} onClick={() => selectedLotData(item)} style={{
                                                border: item.selected !== undefined && item.selected ? '0.1rem solid #3478F5' : '0.1rem solid #D7D7D7'
                                            }}>
                                                <div className={'item'} role={'button'}>
                                                    <div className={'lot-list-head'}>
                                                        <em>{item.key1}</em>
                                                        <span>{item.key2}</span>
                                                    </div>
                                                    <ul className={'lot-list'}>
                                                        <li>{item.key3}</li>
                                                        <li>{item.key4}</li>
                                                        <li>{item.key5}</li>
                                                        <li>{item.key6}</li>
                                                    </ul>
                                                </div>
                                            </li>
                                        })
                                    }
                                </ul>
                                <h2 className={'h2-title'}>{getDomain('DOMAIN4666')}</h2>  {/*동일 Lot 예정 List*/}
                                <ul className={'card-list lot-type'}>
                                    {
                                        expectedLotList?.map(item => {
                                            return <li key={uuidv4()} className={'card-item'} role={'button'} onClick={() => selectedExpectedLotList(item)} style={{
                                                border: item.selected !== undefined && item.selected ? '0.1rem solid #3478F5' : '0.1rem solid #D7D7D7'
                                            }}>
                                                <div className={'item'} role={'button'}>
                                                    <div className={'lot-list-head'}>
                                                        <em>{item.key1}</em>
                                                        <span>{item.key2}</span>
                                                    </div>
                                                    <ul className={'lot-list'}>
                                                        <li>{item.key3}</li>
                                                        <li>{item.key4}</li>
                                                        <li>{item.key5}</li>
                                                        <li>{item.key6}</li>
                                                    </ul>
                                                </div>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className={'btn-area bottom'}>
                            <button type={'button'} className={'button-primary'} onClick={() => handleSelected(true)}>{getDomain('DOMAIN4667')}</button>  {/*선택완료*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};