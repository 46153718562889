import React, {useEffect, useRef, useState} from 'react';
import SampleBasicInformation from './info/basic/SampleBasicInformation';
import SamplePartInspectionInformation from './info/part/SamplePartInspectionInformation';
import SampleQmvDetailInspectList from './info/detailed/SampleQmvDetailInspectList';
import SampleDefectiveContent from './info/defect/SampleDefectiveContent';
import SampleJudgmentResult from './info/judgment/SampleJudgmentResult';
import {CSSTransition} from 'react-transition-group';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
import {ComponentHistoryType, TabNavigationType} from '../../components/model/SqciTypes';
import {useComponent} from '../../components/contexts/ComponentContext';
import {useError} from '../../hooks/useException';
import useGlobalData from '../../hooks/useGlobalData';
import {useLocation, useOutletContext} from 'react-router-dom';

export default function SampleQmvInfo() {
    const {sendAxios} = useAxios();
    const {setDialog} = useComponent();
    const {throwException} = useError();
    const {getMessage} = useGlobalData();

    const [tabList, setTabList] = useState<TabNavigationType[]>([
        {name: 'basicInformation', selected: true, direction: 'right', element: <SampleBasicInformation state={{}} nextTab={(name: string) => handleNextTab(name)}/>},
        {name: 'partInspectionInformation', selected: false, direction: 'right', element: <SamplePartInspectionInformation state={{name: '??????'}} nextTab={(name: string) => handleNextTab(name)}/>},
        {name: 'detailedInspectionInformation', selected: false, direction: 'right', element: <SampleQmvDetailInspectList state={{}} nextTab={(name: string) => handleNextTab(name)}/>},
        {name: 'defectiveContent', selected: false, direction: 'right', element: <SampleDefectiveContent state={{}} nextTab={(name: string) => handleNextTab(name)}/>},
        {name: 'judgmentResult', selected: false, direction: 'right', element: <SampleJudgmentResult state={{}} nextTab={(name: string) => handleNextTab(name)}/>}
    ]);
    const [focusTab, setFocusTab] = useState<TabNavigationType>(tabList[0]);
    const [showTab, setShowTab] = useState<boolean>(false);

    const location = useLocation();
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
    useEffect(() => {
        if((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            sendAxios('/common/nexacro/selectLotTempDetailInfo.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_Search: [{
                    REINSP_NUM: '0',
                    DataSetRowType: '0',
                    LOT_ID: 'P103A3KC20191010-32545',
                    LANG: 'KO',
                    SMARTQ_YN: 'N',
                    LOT_NO: null
                }]
            })
            .then(response => {
                tabList.map(tab => {
                    if(tab.name === 'partInspectionInformation') {
                        tab.element = <SamplePartInspectionInformation state={response?.data.ds_Part} nextTab={(name: string) => handleNextTab(name)}/>;
                    }
                    return tab;
                });
            })
            .catch(e => {
                console.error(e);
                throwException(e);
                return new Promise(() => {});
            });
        }
    }, [enteredComponent, exitedComponent]);

    const selectTab = (name: string) => {
        setShowTab(false);

        let preTab: string | undefined = undefined;
        setTabList(tabList.map(tab => {
            if(tab.selected) {
                preTab = tab.name;
            }

            if(tab.name === name) {
                if(preTab) {
                    tab.direction = 'right';
                } else {
                    tab.direction = 'left';
                }
                tab.selected = true;
            } else {
                tab.selected = false;
            }
            return tab;
        }));
    };

    useEffect(() => {
        setShowTab(true);
        setFocusTab(tabList.find(tab => tab.selected) || tabList[0]);
    }, [tabList]);

    const handleNextTab = (name: string) => {
        const selectedIndex = tabList.map((tab, index) => {
            if(tab.name === name) {
                return index;
            } else {
                return 0;
            }
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        if(selectedIndex < tabList.length - 1) {
            selectTab(tabList[selectedIndex + 1].name);
        } else {
            setDialog({
                show: true,
                type: 'ALERT',
                text: getMessage('MSG01546')
            });
        }
    };

    // 포커싱된 tab을 따라, tab 스크롤 이동
    const scrollRef = useRef<HTMLUListElement>(null);
    useEffect(() => {
        if(scrollRef?.current) {
            const scrollMove = tabList.findIndex(tab => tab.selected) || 0;
            scrollRef.current.scrollTo({left: 0 + (scrollRef.current.children[0].clientWidth * scrollMove), top: 0, behavior: 'smooth'});
        }
    }, [focusTab]);

    return (
        <>
            <div className={'container'}>
                <div className={'tabs-wrap'}>
                    <ul className={'tab-list'} ref={scrollRef}>
                        <li className={focusTab.name === 'basicInformation' ? 'on' : ''}><button type={'button'} onClick={() => selectTab('basicInformation')}>기본정보</button></li>
                        <li className={focusTab.name === 'partInspectionInformation' ? 'on' : ''}><button type={'button'} onClick={() => selectTab('partInspectionInformation')}>부품검사정보</button></li>
                        <li className={focusTab.name === 'detailedInspectionInformation' ? 'on' : ''}><button type={'button'} onClick={() => selectTab('detailedInspectionInformation')}>세부검사정보</button></li>
                        <li className={focusTab.name === 'defectiveContent' ? 'on' : ''}><button type={'button'} onClick={() => selectTab('defectiveContent')}>불량내용</button></li>
                        <li className={focusTab.name === 'judgmentResult' ? 'on' : ''}><button type={'button'} onClick={() => selectTab('judgmentResult')}>판정결과</button></li>
                    </ul>
                </div>
                <CSSTransition in={showTab} classNames={focusTab.direction} timeout={500} unmountOnExit>{focusTab.element}</CSSTransition>
            </div>
        </>
    );
};