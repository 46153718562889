import React, {useEffect, useState} from 'react';
import {CardViewType} from '../../components/model/SqciTypes';
import {v4 as uuidv4} from 'uuid';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useComponent} from '../../components/contexts/ComponentContext';
import {SyncLoader} from 'react-spinners';
import useGlobalData from '../../hooks/useGlobalData';

export default function InfiniteCardView({listCount, getData, multiSelection, onSelect, onClose}: {
    listCount: number;
    getData: (start: number, end: number) => Promise<CardViewType[]>;
    multiSelection?: boolean;
    onSelect?: (card?: CardViewType[]) => void;
    onClose?: (card: CardViewType) => Promise<CardViewType | undefined>;
}) {
    const {setDialog} = useComponent();
    const {getDomain, getMessage} = useGlobalData();

    const [limit, setLimit] = useState<{
        count: number;
        getNext: boolean;
    }>({
        count: listCount,
        getNext: true
    });
    const [scrollList, setScrollList] = useState<CardViewType[]>();
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [selectedCard, setSelectedCard] = useState<CardViewType[]>();

    useEffect(() => {
        if(limit.getNext) {
            (async () => {
                const start = (limit.count - listCount) + 1;
                const end = limit.count;
                const list = await getData(start, end);
                setScrollList([
                    ...scrollList || [],
                    ...list
                ]);
            })();
        }
    }, [limit]);

    useEffect(() => {
        if(scrollList) {
            if(scrollList.length < limit.count - listCount || scrollList.length < (limit.count - 1)) {
                setHasMore(false);
            }
        }
    }, [scrollList]);

    const getNextData = () => {
        setLimit({
            count: limit.count + listCount,
            getNext: true
        });
    };

    const selectCard = (card: CardViewType) => {
        setScrollList(scrollList?.map(item => {
            if(item.id === card.id) {
                item.selected = !item.selected;
            } else {
                if(multiSelection !== undefined && !multiSelection) {
                    item.selected = false;
                }
            }
            return item;
        }));

        setSelectedCard(scrollList?.filter(item => item.selected));
    };

    useEffect(() => {
        if(onSelect) {
            onSelect(selectedCard);
        }
    }, [selectedCard]);

    const closeCard = (card: CardViewType) => {
        const closeIndex = scrollList?.findIndex(item => item.id === card.id);
        const closeItem = scrollList && closeIndex !== undefined && closeIndex >= 0 ? scrollList[closeIndex] : undefined;
        if(closeIndex !== undefined && closeIndex >= 0 && closeItem) {
            setDialog({
                show: true,
                type: 'CONFIRM',
                text: closeItem.title.name.concat(' ', getMessage('MSG00276')),
                confirmText: getDomain('DOMAIN0229'),
                cancelText: getDomain('DOMAIN3497'),
                confirm: () => {
                    if(onClose) {
                        onClose(card).then(resolve => {
                            if(resolve) {
                                scrollList?.splice(closeIndex, 1);

                                setLimit({
                                    count: limit.count - 1,
                                    getNext: false
                                });
                            }
                        });
                    }
                }
            });
        }
    };

    return (
        <>
            <div id={'infiniteScrollWrap'} className={'card-wrap'}>
                <InfiniteScroll
                    dataLength={scrollList?.length || 0}
                    hasMore={hasMore}
                    next={getNextData}
                    scrollableTarget="infiniteScrollWrap"
                    loader={<SyncLoader
                        loading={hasMore}
                        color={'#9ba7bb'}
                        speedMultiplier={1}
                        size={7}
                        margin={2}
                        cssOverride={{
                            paddingBottom: '1.5rem',
                            textAlign: 'center'
                        }}/>
                    }>
                    <ul className={'card-list'}>
                        {
                            scrollList?.map(data => {
                                return <li className={'card-item'} key={uuidv4()} style={{
                                    // border: data.id === selectedCard?.id ? '0.1rem solid #3478F5' : '0.1rem solid #D7D7D7'
                                    border: data.selected !== undefined && data.selected ? '0.3rem solid #3478F5' : '0.3rem solid #D7D7D7'
                                }}>
                                    <div className={'item'}>
                                        <div role={'button'} onClick={() => selectCard !== undefined && (data.useSelect === undefined || data.useSelect === true) ? selectCard(data) : {}}>
                                            <section>
                                                <div className={'label-wrap'}>
                                                    {
                                                        data.label.map((label, index) => {
                                                            return <i key={uuidv4()} className={label.style}>{label.name}</i>;
                                                        })
                                                    }
                                                </div>
                                                <div className={'title-wrap'}>
                                                    <span>{data.title.category}</span>
                                                    <strong>{data.title.name}</strong>
                                                </div>
                                            </section>
                                            <ul className={'date-list'}>
                                                {
                                                    data.state.map((state, index) => {
                                                        return <li key={uuidv4()}>
                                                            <em>{state.left}</em>
                                                            <span>{state.right}</span>
                                                        </li>;
                                                    })
                                                }
                                                {
                                                    data.extensionData ? <li style={{
                                                        width: '100%',
                                                        display: 'inline-block',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden'
                                                    }}>{data.extensionData}</li> : null
                                                }
                                            </ul>
                                        </div>
                                        {
                                            onClose !== undefined && (data.useClose === undefined || data.useClose === true) ? <button type={'button'} className={'button-delete'} onClick={() => closeCard(data)}>
                                                <i className={'hidden'}>{getDomain('DOMAIN0229')}</i>
                                            </button> : null
                                        }
                                    </div>
                                </li>
                            })
                        }
                    </ul>
                </InfiniteScroll>
            </div>
        </>
    );
};