import React, {useEffect, useState} from 'react';
import {CardViewType} from '../../components/model/SqciTypes';
import usePageMove from '../../hooks/usePageMove';
import {useDataset} from '../../components/contexts/CommonContext';
import {v4 as uuidv4} from 'uuid';
import InfiniteCardView from '../common/InfiniteCardView';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
import useGlobalData from '../../hooks/useGlobalData';

export default function SampleQmvList() {
    const {pageMove} = usePageMove();
    const {dataset} = useDataset();
    const {sendAxios} = useAxios();
    const {getSdpSysDate} = useGlobalData();

    const [infiniteScroll, setInfiniteScroll] = useState<CardViewType[]>(dataset ? dataset['ds_plantList'].map((item, index) => {
        return {
            id: uuidv4(),
            label: [{
                name: String(index),
                style: 'label-type1'
            }, {
                name: String(item.DIVISION_CD),
                style: 'label-type2'
            }],
            title: {
                category: String(item.PLANT_CD),
                name: String(item.PLANT_NM)
            },
            state: [{
                left: 'DIVISION_NM',
                right: String(item.DIVISION_NM)
            }],
            extensionData: 'DMR 123-12323-QWEWQEQW-43242-QWDGFG-1331313',
            useClose: true,
            useSelect: true
        }
    }) : []);

    const getData = async (start: number, end: number) => {
        // TODO DB 조회 후 리스트 리턴
        return await infiniteScroll.slice(start - 1, end);
    };

    const selectedCard = (card?: CardViewType[]) => {
        console.log(card);
    };

    const closedCard = async (card: CardViewType): Promise<CardViewType | undefined> => {
        console.log(card);
        /*
        삭제 여부를 parent component에서 판별 후 삭제 처리 하기 위한 로직 추가
        1. 삭제 시, DB처리 후 매개변수로 전달 받은 값 리턴
        2. 삭제 하지 않은 경우, undefined 리턴
        * 아래 샘플에 사용된 setTimeout은 awati 동작을 확인하기 위함. 실제 DB처리 로직 수행 후 Promise로 리턴
         */

        // sample 1
        // return new Promise(resolve => {
        //     setTimeout(() => {
        //         // resolve(card);
        //         resolve(undefined);
        //     }, 2000);
        // });

        // return await new Promise(() => undefined);

        // sample 2
        return await getSdpSysDate().then(() => card);
    };

    return (
        <>
            <div className={'container'}>
                <InfiniteCardView listCount={10} getData={(start: number, end: number) => getData(start, end)} multiSelection={true} onSelect={selectedCard} onClose={closedCard}/>
                <div className={'btn-area bottom'}>
                    <button type={'button'} className={'button-primary'} onClick={() => pageMove('/sample/qmv/info')}>출하검사 성적서 조회/수정</button>
                </div>
            </div>
        </>
    );
};