import React, {useEffect, useState} from 'react';
import InfiniteCardView from "../common/InfiniteCardView";
import usePageMove from "../../hooks/usePageMove";
import {
    CardViewType,
    ComponentHistoryType,
    NexacroJsonType,
    NexacroJsonUnitType
} from "../../components/model/SqciTypes";
import {v4 as uuidv4} from "uuid";
import {useDataset} from "../../components/contexts/CommonContext";
import {useLocation, useOutletContext} from "react-router-dom";
import * as gfn from "../../components/utils/CommonUtils";
import {useComponent} from "../../components/contexts/ComponentContext";
import {STORAGE_NAME} from "../../components/CommonConstants";
import useGlobalData from "../../hooks/useGlobalData";
import useAxios, {AXIOS_HEADER} from "../../hooks/useAxios";
import {useError} from "../../hooks/useException";
export default function QmsList() {
    const {pageMove} = usePageMove();
    const {sendAxios} = useAxios();
    const {dataset} = useDataset();
    const location = useLocation();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const {setDialog} = useComponent();

    const [selectedCardDataIdx, setSelectedCardDataIdx] = useState(-1);
    const [ds_Spec, setDs_Spec] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Defect, setDs_Defect] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Ecn, setDs_Ecn] = useState(new Array<NexacroJsonUnitType>());
    const [ds_SubmitInspList, setDs_SubmitInspList] = useState(new Array<NexacroJsonUnitType>());

    const [ds_List, setDs_List] = useState(new Array<NexacroJsonUnitType>());
    const {throwException} = useError();

    let gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    let fv_InspResultNo = "";   //선행검사 번호
    let fv_preInspFlag = "";

    let temp_ds_SearchDetail: NexacroJsonUnitType[] = [{}];
    let temp_ds_Spec: NexacroJsonUnitType[] = [{}];
    let temp_ds_Defect: NexacroJsonUnitType[] = [{}];
    let temp_ds_Ecn: NexacroJsonUnitType[] = [{}];

    const [infiniteScroll, setInfiniteScroll]= useState<CardViewType[]>([]);

    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
    const [isComponentLoaded, setIsComponentLoaded] = useState<boolean>(false);
    useEffect(() => {
        if((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            setIsComponentLoaded(true);
        }
    }, [enteredComponent, exitedComponent]);

    const selectedCard = (card?: CardViewType[]) => {
        if (card == undefined || card.length == 0) {
            setSelectedCardDataIdx(-1);

            return;
        }

        let id = card ? card[0].id : '';

        if(id !== '') {
            setSelectedCardDataIdx(Number(infiniteScroll.findIndex((item, index) => item.id === id)));

            fn_SearchSubmitInsp(Number(infiniteScroll.findIndex((item, index) => item.id === id)));
        }
    };

    const getData = async (start: number, end: number) => {
        let temp_ds_Search: NexacroJsonUnitType[] = location.state?.ds_Search;
        temp_ds_Search[0]['RECORDS'] = String(end);
        temp_ds_Search[0]['RECORDSOFFSET'] = String(start);

        if(gfn.gfn_isNull(temp_ds_Search)){
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG01526"), /*"올바르지 않은 경로입니다."*/
                confirm: () => {
                    pageMove(-1);
                }
            });

            // pageMove(-1);
            return [];
        }

        let requestJson:NexacroJsonType = {
            ds_Search : temp_ds_Search,
            ds_Plant : ''||[]
        }

        if (temp_ds_Search[0]['INV_NO'] == undefined || temp_ds_Search[0]['INV_NO'] == '') {
            requestJson = {
                ds_Search   : temp_ds_Search,
                ds_Plant    : [{PLANT: location.state?.plant.PLANT_CD}]
            }
        }

        // return infiniteScroll.slice(start, end);
        return await sendAxios('/common/nexacro/QMS_M_InspRcrdListDataSelect.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson).then(response => {
            let temp_ds_List:NexacroJsonUnitType[] = response?.data.ds_List||[];

            if(temp_ds_List.length == 0 && start === 1){
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage('MSG00930'), //검색결과가 없습니다.
                    confirm: () => {
                        pageMove(-1);
                    }
                });
                // pageMove(process.env.REACT_APP_URL_QMS_SEARCH || '');
                return [];
            }

            let copyArray = [...ds_List];
            setDs_List([
                ...copyArray || [],
                ...response?.data.ds_List||[]
            ]);

            let cardL:CardViewType[] = (temp_ds_List||[]).map((item:NexacroJsonUnitType, index:number) => {
                let label_udCd = item.UD_CD == "A" ? 'label-type2' : 'label-type7';

                let label_newPartType = 'label-type3';

                if (item.NEW_PART_TYPE == 'O') { // 양산자재
                    label_newPartType = 'label-type8';
                } else if (item.NEW_PART_TYPE == 'N') { // 신규초물
                    label_newPartType = 'label-type2';
                } else if (item.NEW_PART_TYPE == 'C') { // 변경초물
                    label_newPartType = 'label-type2';
                }

                /* inspStatusStyle */
                let inspStatusStyle = 'label-type3';

                if (item.INSP_STATUS == 'Y') { // (Random) 검사대상
                    inspStatusStyle = 'label-type8';
                } else if (item.INSP_STATUS == 'N') { // (Random) 입고검사 Skip
                    inspStatusStyle = 'label-type7';
                } else if (item.INSP_STATUS == 'T') { // (Target) 검사대상
                    inspStatusStyle = 'label-type8';
                } else if (item.INSP_STATUS == 'S') { // (Target) 입고검사 Skip
                    inspStatusStyle = 'label-type7';
                } else if (item.INSP_STATUS == 'R') { // 재검사
                    inspStatusStyle = 'label-type10';
                }

                let title_category = "[" + gfn.gfn_toString(item.PLANT_CD) + "][" +  gfn.gfn_toString(item.HQ_CD) + "][" + gfn.gfn_toString(item.PART_CD) + "]";
                let title_name = gfn.gfn_toString(item.PART_NM);

                let state_invNo = "I/V No : "+gfn.gfn_toString(item.INV_NO);      // I/V No
                let state_supReceiveDate = gfn.gfn_toString(item.SUP_RECEIVE_DATE);   // 인보이스 발행일

                let state_inspLotNo = "Insp No : "+gfn.gfn_toString((item.INSP_LOT_NO ? item.INSP_LOT_NO : ''));    // Insp No
                let state_grDate = gfn.gfn_toString(item.GR_DATE);    // 입고일

                let state_grQty = getDomain('DOMAIN2415') + " : " + gfn.fn_comma(item.GR_QTY);    //입고수량
                let state_inspUserOne_secInspDt = gfn.gfn_toString(item.INSP_USER_ONE) + "(" +gfn.gfn_toString(item.SEC_INSP_DT) + ")";   // 검사일

                let cardD:CardViewType = {
                    id: uuidv4(),
                    label: [{
                        name: String(item.UD_CD_NM),
                        style: label_udCd
                    }, {
                        name: String(item.NEW_PART_TYPE_NM),
                        style: label_newPartType
                    }, {
                        name: String(item.INSP_STATUS_NM),
                        style: inspStatusStyle
                    }],
                    title: {
                        category: title_category,
                        name: String(title_name)
                    },
                    state: [
                        {
                            left: state_invNo,
                            right: String(state_supReceiveDate)
                        },
                        {
                            left: state_inspLotNo,
                            right: String(state_grDate)
                        },
                        {
                            left: state_grQty,
                            right: state_inspUserOne_secInspDt
                        }
                    ],
                    extensionData: "DMR : " + String(item.DMR_DETAIL),
                    useSelect: true
                }
                return cardD;
            });
            setInfiniteScroll([
                ...infiniteScroll || [],
                ...cardL
            ]);
            return cardL;
        }).catch(e => {
            return [{
                id: '',
                label: [{
                    name: '',
                    style: ''
                }],
                title: {
                    category: '',
                    name: ''
                },
                state: [{
                    left: '',
                    right: ''
                }]
            }];
        });
    };

    // 선행검사 성적서 각각 Spec 검색
    function fn_selectSpecAndDetail()
    {
        // 조회조건 설정
        var sLocale     = gv_locale ? gv_locale : "EN"; //gv_locale;

        temp_ds_SearchDetail[0]["LANG"] = sLocale;
        temp_ds_SearchDetail[0]["INSP_RESULT_NO"] = ds_List[selectedCardDataIdx]['INSP_RESULT_NO'];
        temp_ds_SearchDetail[0]["TMP_YN"] = "N";

        // "qmsPreInspListSelectSpecAndDetailPreInsp"
        return sendAxios('/common/nexacro/QMS_M_PreInspListSelectSpecAndDetail.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_SearchDetail   : temp_ds_SearchDetail
        }).then(response => {
            temp_ds_Spec = response?.data.ds_Spec||[];
            setDs_Spec(temp_ds_Spec);
            // Spec 별 측정결과
            fn_selectDefectDetail();
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    // 선행검사 성적서  Defect 검색
    function fn_selectDefectDetail()
    {
        // 조회조건 설정
        var sLocale     = gv_locale ? gv_locale : "EN"; //gv_locale;

        temp_ds_SearchDetail[0]["LANG"] = sLocale;
        temp_ds_SearchDetail[0]["INSP_RESULT_NO"] = ds_List[selectedCardDataIdx]['INSP_RESULT_NO'];
        temp_ds_SearchDetail[0]["TMP_YN"] = "N";

        // "qmsPreInspListSelectDefectDetailPreInsp"
        return sendAxios('/common/nexacro/QMS_M_PreInspListSelectDefectDetail.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_SearchDetail   : temp_ds_SearchDetail
        }).then(response => {
            temp_ds_Defect = response?.data.ds_Defect||[];
            setDs_Defect(temp_ds_Defect);
            // Defect 목록
            fn_selectEcnDetail();//VOC C23040007089  Start 2023.05.12 huimei.zhu 협력사 자체변경점 등급 기준 등록
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /** VOC C23040007089  Start 2023.05.12 huimei.zhu 협력사 자체변경점 등급 기준 등록 **/
    function fn_selectEcnDetail()
    {
        // 조회조건 설정
        var sLocale     = gv_locale ? gv_locale : "EN"; //gv_locale;

        temp_ds_SearchDetail[0]["LANG"] = sLocale;

        //"qmsPreInspListSelectEcnDetailPreInsp"
        return sendAxios('/common/nexacro/QMS_M_PreInspListSelectEcnDetail.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_SearchDetail   : temp_ds_SearchDetail
        }).then(response => {
            temp_ds_Ecn = response?.data.ds_Ecn||[];
            setDs_Ecn(temp_ds_Ecn);
            if(fv_preInspFlag == "Y"){
                // 선행검사 팝업 호출한다
            }else{
                fn_IncmInspDetailPopOpen(selectedCardDataIdx);	// 입고검사 팝업 호출한다
            }
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    // 입고검사 성적서 팝업 호출
    function fn_IncmInspDetailPopOpen(row:number)
    {
        let temp_ds_ListSelect = ds_List.filter((item, index) => index == row);

        let param = new Map();
        param.set("pv_flag", "DETAIL");
        param.set("pv_data", temp_ds_ListSelect);
        param.set("pv_spec", temp_ds_Spec);
        param.set("pv_defect", temp_ds_Defect);
        param.set("pv_ecnDetail", temp_ds_Ecn);
        param.set("pv_ds_SubmitInspList", ds_SubmitInspList);

        pageMove(process.env.REACT_APP_URL_QMS_DETAIL || '', {params: param});
    }

    function grd_List_oncellclick()
    {
        if(selectedCardDataIdx == -1){
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage('MSG00518'),
            });
            return;
        }

        if(ds_List[selectedCardDataIdx]["PRE_INSP_YN"] == "O") {
            // 선행검사 성적서 팝업 호출
        }else{
            // 입고검사 성적서 팝업 호출
            fv_InspResultNo = gfn.gfn_tostring(ds_List[selectedCardDataIdx]["INSP_RESULT_NO"]);

            temp_ds_SearchDetail[0]["DIVISION_CD"] = ds_List[selectedCardDataIdx]["DIVISION_CD"];
            temp_ds_SearchDetail[0]["PLANT_CD"] = ds_List[selectedCardDataIdx]["PLANT_CD"];     /** VOC 03 Start 2019.12.02 김형준 - Spec 정보 조회시 Plant_Cd 값 Setting  **/
            temp_ds_SearchDetail[0]["HQ_CD"] = ds_List[selectedCardDataIdx]["HQ_CD"];           /** VOC C23040007089  Start 2023.05.04 huimei.zhu 협력사 자체변경점 등급 기준 등록 **/
            temp_ds_SearchDetail[0]["PART_CD"] = ds_List[selectedCardDataIdx]["PART_CD"];

            fv_preInspFlag = "N";
            fn_selectSpecAndDetail();
        }
    }

    /* 제출된 성적서 현황 호출 */
    function fn_SearchSubmitInsp(idx: number) {
        const requestJson: NexacroJsonType = {
            ds_SearchInsp: [{
                HQ_CD: ds_List[idx]["HQ_CD"],
                PART_CD: ds_List[idx]["PART_CD"],
                INV_NO: ds_List[idx]["INV_NO"]
            }]
        };

        sendAxios('/common/nexacro/QMS_M_Incm_SubmitInspDataSelect.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            setDs_SubmitInspList(response?.data.ds_SubmitInspList);
        }).catch(e => {
            throwException(e);
                return new Promise(() => {
            });
        });
    }

    return (
        <>
            <div className={'container'}>
                {
                    isComponentLoaded ? <InfiniteCardView
                        listCount={10}
                        getData={(start: number, end: number) => getData(start, end)}
                        multiSelection={false}
                        onSelect={selectedCard}
                    /> : null
                }
                <div className={'btn-area bottom'}>
                    <button type={'button'} className={'button-primary'} onClick={() => grd_List_oncellclick()}>
                        {getDomain('DOMAIN4607')} {/*입고검사 결과조회*/}
                    </button>
                </div>
            </div>
        </>
    );
};