/**************************************************************************************************
 * 01. 업무구분	    : P-SIS > 검사결과 조회 > 검사결과 리스트
 * 02. 프로그램ID	: PsisInspResultInfoList.tsx
 * 03. 프로그램명	    : P-SIS > 검사결과 조회 > 검사결과 리스트
 * 04. 화면설명	    : P-SIS > 검사결과 조회 > 검사결과 리스트
 * 05. 작성일		: 2023.10.04
 * 06. 작성자		: YGY
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-10-04     YGY    최초 작성
 **************************************************************************************************
 */
/******************************************************************************************
 *                                        변경이력                                         *
 *----------   ----------   ------------   ------------------------------------------------*
 * 변경번호     변경일자       변경자                        변경내용                      *
 *----------   ----------   ------------   ------------------------------------------------*

 ******************************************************************************************/

import React, {useEffect, useState} from 'react';
import {useDataset} from '../../../components/contexts/CommonContext';
import useAxios, {AXIOS_HEADER} from '../../../hooks/useAxios';
import {useError} from '../../../hooks/useException';
import useHtmlElement from '../../../hooks/useHtmlElement';
import usePageMove from "../../../hooks/usePageMove";
import {useComponent} from "../../../components/contexts/ComponentContext";
import {useLocation, useOutletContext} from 'react-router-dom';
import useGlobalData from "../../../hooks/useGlobalData";
import {STORAGE_NAME} from "../../../components/CommonConstants";
import {ComponentHistoryType} from "../../../components/model/SqciTypes";


/**
 * 대상 부품 조회 에서 입고/공정 검사 건수 조회시에 보여지는 카드뷰 페이지
 * @constructor
 */
export default function PsisInspResultInfoList() {
    const {dataset, sessionValues} = useDataset();
    const {sendAxios} = useAxios();
    const {throwException} = useError();
    const {pageMove} = usePageMove();
    const {useSelectBox, useInput} = useHtmlElement();
    const {setDialog} = useComponent();
    const location = useLocation();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const [inspTargetCd, setInspTargetCd] = useState(String);
    const [hqInspYn, setHqInspYn] = useState(String);
    const [inspectList, setInspectList] = useState(new Array<any>());
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();

    useEffect(() => {
        if ((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            sendAxios('/common/nexacro/selectTargetPartListByMobile.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_search: [{
                    LANG: gv_locale,
                    RECORDSOFFSET: '0',
                    RECORDS: '1000',
                    STATUS: '02',
                    PLANT_CD: location.state.plants === 'all' ? '' : location.state.plants.PLANT_CD,
                    HQ_CD: location.state.hqCd.HQ_CD,
                    HQ_INSP_YN: location.state.hqInspYn == 'A' ? '' : location.state.hqInspYn,
                    INSERT_DT: location.state.insertDt,
                }]
            })
                .then(response => {
                    setInspectList(response?.data.ds_inspectResultList || []);
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }
    }, [enteredComponent, exitedComponent]);

    /**
     * 클릭시, 아이템이 선택 되고, css가 active상태로 변경
     * @param sInspTargetCd
     */
    const callbackItemClick = (sInspTargetCd: string, sHqInspYn: string) => {
        setInspTargetCd(sInspTargetCd);
        setHqInspYn(sHqInspYn);
    };


    /**
     * 상세 페이지 callback 이벤트
     */
    const callbackDetail = () => {
        if (inspTargetCd === '') {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage('MSG00518'),
                confirm: () => {
                }
            });
        } else {
            let sMovePage = process.env.REACT_APP_URL_PSIS_INFO;
            // 입고, 공정에 따라 보여지는 페이지를 다르게 하기 위함.
            const ds_inspect = inspectList.filter((inspect) => inspect.INSP_TARGET_CD === inspTargetCd);
            if (hqInspYn === 'Y') {
                sMovePage = sMovePage + '/PsisInspResultInfoIncoming';
            } else {
                sMovePage = sMovePage + '/PsisInspResultInfoProcess';
            }
            pageMove(sMovePage || '', {
                ds_inspect: ds_inspect
            });
        }
    };

    return (
        <>
            <div className={'container'}>
                <div className={'card-wrap'}>
                    <ul className={'card-list'}>
                        {
                            inspectList.map((item, index) => {
                                return <li className={'card-item'}
                                           style={{border: inspTargetCd !== item?.INSP_TARGET_CD && item?.INSP_RESULT === 'P' ? '0.3rem solid #D7D7D7' : inspTargetCd !== item?.INSP_TARGET_CD && item?.INSP_RESULT === 'F' ? '0.3rem solid #F5A4A4FF' : (inspTargetCd === item?.INSP_TARGET_CD && item?.INSP_RESULT === 'P') ? '0.3rem solid #3478F5' : (inspTargetCd === item?.INSP_TARGET_CD && item?.INSP_RESULT === 'F') ? '0.3rem solid #FF005C' : '0.3rem solid #D7D7D7'}}
                                           key={'cardView_'.concat(String(index))}
                                           onClick={() => callbackItemClick(item.INSP_TARGET_CD, item.HQ_INSP_YN)}>
                                    <CardViewList data={item}/>
                                </li>
                            })
                        }
                    </ul>
                </div>
                <div className={'btn-area bottom'}>
                    <button type={'button'} className={'button-primary'}
                            disabled={inspTargetCd !== '' ? false : true}
                            onClick={() => callbackDetail()}>{getDomain('DOMAIN0312')}
                    </button>
                </div>
            </div>
        </>
    );

    /**
     * 카드뷰 동적 생성 처리
     * @param data
     * @param callback
     * @constructor
     */
    function CardViewList({data}: { data: any }) {
        return (
            <>
                <div className={'item'} role={'button'}>
                    <section>
                        <div className={'label-wrap'}>
                            {data.HQ_INSP_YN === 'Y' && (
                                <i className={'label-type17'}>{data.HQ_INSP_YN_NM}</i>
                            )}
                            {data.HQ_INSP_YN === 'N' && (
                                <i className={'label-type16'}>{data.HQ_INSP_YN_NM} ({data.INSP_CNT})</i>
                            )}
                        </div>
                        <div className={'title-wrap'}>
                            {data.HQ_INSP_YN === 'Y' && (
                                <span>[{data.PLANT_CD}] [{data.PART_CD}] [{data.HQ_CD_2ND}]</span>
                            )}
                            {data.HQ_INSP_YN === 'N' && (
                                <span>[{data.PLANT_CD}] [{data.PART_CD}]</span>
                            )}
                            <strong>{data.PART_NM ? data.PART_NM.split(';', 1) : ''}</strong>
                        </div>
                    </section>
                    <ul className={'date-list'}>
                        {
                            <li>
                                <em style={{width: '50%'}}>[{data.LINE_CD}] {data.LINE_NM}</em>
                                <span style={{width: '50%'}}>{data.INSERT_DT_EXPR}</span>
                            </li>
                        }
                        {
                            <li>
                                <em style={{width: '50%'}}>{data.SPEC_CNT_EXPR}</em>
                                <span
                                    style={{width: '50%'}}>{getDomain('DOMAIN4730')} {data.TARGET_CNT.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            </li>
                        }
                    </ul>
                </div>
            </>
        );
    };
};