import React, {useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {STORAGE_NAME} from '../components/CommonConstants';
import useStorage from './useStorage';
import {CheckBoxType, HtmlElementType, SelectBoxType} from '../components/model/SqciTypes';

const useHtmlElement = () => {
    const {setStorage, getStorage, clearStorage} = useStorage();

    const getInitValue = (type: HtmlElementType, currentPath: string, selectorName?: string, initValue?: string) => {
        const historyState = getStorage<any>('SESSION', STORAGE_NAME.HISTORY);
        if(historyState[currentPath] && selectorName && historyState[currentPath][selectorName]) {
            return historyState[currentPath][selectorName];
        } else if(initValue) {
            return initValue;
        } else {
            return undefined;
        }
    };

    const useInput = (intiName: string, initValue?: string, initPlaceholder?: string) => {
        const location = useLocation();
        const [name, setName] = useState(intiName);
        const [value, setValue] = useState(getInitValue('INPUT', location.pathname, intiName, initValue) || '');
        const [placeholder, setPlaceholder] = useState(initPlaceholder);

        const handleChange = useCallback((event?: React.ChangeEvent<HTMLInputElement>) => {
            if(event) {
                setValue(event.target.value);
            } else {
                setValue('');
            }
        }, [value]);

        return {
            name,
            value,
            placeholder,
            onChange: handleChange
        }
    };

    const useSelectBox = (intiName: string, optionList?: () => SelectBoxType[], initValue?: string) => {
        const location = useLocation();
        const [options, setOptions] = useState<SelectBoxType[] | undefined>(optionList);
        const [value, setValue] = useState<string | undefined>(initValue);

        const initOptions = useCallback((optionList: () => SelectBoxType[]) => {
            setOptions(optionList);
        }, [options]);

        useEffect(() => {
            let selectValue = getInitValue('SELECT', location.pathname, intiName, initValue);
            if(!selectValue) {
                selectValue = options && options.length > 0 ? options[0].value : '';
            }
            setValue(selectValue);
        }, [options]);

        const handleChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
            setValue(event.target.value);
        }, [value]);

        return {
            name: intiName,
            value: value,
            onChange: handleChange,
            initOptions: initOptions,
            options: (options || []).map(option => {
                return <option key={option.value} value={option.value}>{option.text}</option>
            })
        };
    };

    const useCheckBox = (initName: string, itemList: () => CheckBoxType[]) => {
        const [items, setItems] = useState<CheckBoxType[]>(itemList);
        const [checked, setChecked] = useState<string[] | undefined>();

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if(event.target.checked) {
                setChecked([
                    ...checked || '',
                    event.target.value
                ]);
            } else {
                setChecked(checked?.filter(item => item !== event.target.value));
            }
        };

        return {
            checked: checked,
            items: (items || []).map((item, index) => {
                const wrapKey = initName.concat('_wrap_', String(index));
                const inputKey = initName.concat('_input_', String(index));
                const labelKey = initName.concat('_label_', String(index));

                return <span className="check-item" key={wrapKey}>
                    <label className={'custom-checkbox'} key={labelKey}>
                        <input type={'checkbox'} key={inputKey} name={initName} defaultChecked={item.checked} defaultValue={item.value} onChange={handleChange}/> {item.text}
                    </label>
                </span>;
            })
        };
    };

    const useRadio = (initName: string, itemList: () => CheckBoxType[]) => {
        const [items, setItems] = useState<CheckBoxType[]>(itemList);
        const [checked, setChecked] = useState<string | undefined>();

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if(event.target.checked) {
                setChecked(event.target.value);
            }
        };

        useEffect(() => {
            if(items && items.length > 0) {
                setChecked(items.find(item => item.checked)?.value || undefined);
            }
        }, [items]);

        return {
            checked: checked,
            items: (items || []).map((item, index) => {
                const wrapKey = initName.concat('_wrap_', String(index));
                const inputKey = initName.concat('_input_', String(index));
                const labelKey = initName.concat('_label_', String(index));

                return <li key={wrapKey}>
                    <label key={labelKey}>
                        {item.text}
                        <input type={'radio'} key={inputKey} name={initName} defaultChecked={item.checked} defaultValue={item.value} onChange={handleChange}/>
                    </label>
                </li>;
            })
        };
    };

    return {
        useInput,
        useSelectBox,
        useCheckBox,
        useRadio
    };
};

export default useHtmlElement;