import React, {useEffect, useState} from 'react';
import {JSEncryptRSAKey} from 'jsencrypt/lib/JSEncryptRSAKey';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
import jstz from 'jstz';
import {useError} from '../../hooks/useException';
import useHtmlElement from '../../hooks/useHtmlElement';
import {ComponentHistoryType, RsaModel} from '../../components/model/SqciTypes';
import usePageMove from '../../hooks/usePageMove';
import useStorage from '../../hooks/useStorage';
import {useComponent} from '../../components/contexts/ComponentContext';
import Footer from '../common/Footer';
import useGlobalData from '../../hooks/useGlobalData';
import {STORAGE_NAME} from '../../components/CommonConstants';
import {useLocation, useOutletContext} from 'react-router-dom';
import {useDataset} from '../../components/contexts/CommonContext';
import {isMobile, isAndroid, isIOS} from 'react-device-detect';
import cookie from 'react-cookies';
import {SQCI_EXCEPTION, SqciException} from '../../components/exception/CommonException';

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[]
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed'
        platform: string
    }>
    prompt(): Promise<void>
}

export default function Login() {
    const {sendAxios} = useAxios();
    const {throwException} = useError();
    const {useInput, useSelectBox} = useHtmlElement();
    const {pageMove} = usePageMove();
    const {setStorage, getStorage, clearStorage} = useStorage();
    const {setDialog, setLoader} = useComponent();
    const {initAllGlobalData, setGlobalSetting, getGlobalSetting} = useGlobalData();
    const {authMfa, setAuthMfa, authSession, setAuthSession, setSessionValues} = useDataset();

    const [jSEncryptRSAKey, setJSEncryptRSAKey] = useState<JSEncryptRSAKey>();

    const [saveId, setSaveId] = useState<boolean>(getStorage<string>('LOCAL', 'saveId') === undefined ? false : true);
    const userId = useInput('userId', getStorage<string>('LOCAL', 'saveId'), 'ID');
    const pass = useInput('pass', undefined, 'Password');
    const clientTimezone = useInput('clientTimezone', jstz.determine().name(), '');
    const userLang = useSelectBox('userLang', () => {
        return [
            {value: 'KO', text: 'Korea'},
            {value: 'EN', text: 'English'},
            {value: 'CH', text: 'China'},
            {value: 'VN', text: 'Vietnam'},
            {value: 'ES', text: 'Spain'}
        ]
    });

    const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);

    const getRsaKey = () => {
        sendAxios('/common/identity/anonymous/SQCI3/mobileLoginPage.do', 'GET', AXIOS_HEADER.GET)
        .then(response => {
            const rsaModel: RsaModel = response?.data;
            const jSEncryptRSAKey = new JSEncryptRSAKey();
            jSEncryptRSAKey.setPublic(rsaModel.publicKeyModulus || '', rsaModel.publicKeyExponent || '');
            setJSEncryptRSAKey(jSEncryptRSAKey);
        })
        .catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(false);
    const [waitRsaKeyInterval, setWaitRsaKeyInterval] = useState<NodeJS.Timer>();
    useEffect(() => {
        if(isFirstLoad && !waitRsaKeyInterval && !jSEncryptRSAKey) {
            setWaitRsaKeyInterval(setInterval(() => {
                getRsaKey();
            }, 3000));
        } else if(isFirstLoad && waitRsaKeyInterval && jSEncryptRSAKey) {
            clearInterval(waitRsaKeyInterval);
        }

        return () => clearInterval(waitRsaKeyInterval);
    }, [jSEncryptRSAKey, isFirstLoad, waitRsaKeyInterval]);

    const location = useLocation();
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
    useEffect(() => {
        if((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            (async () => {
                await sendAxios('/common/portal/sdpMain.do', 'GET', AXIOS_HEADER.GET)
                .then(response => {
                    if(response?.request.responseURL.endsWith('/common/SQCI3/')) {
                        if('true' === process.env.REACT_APP_USE_MFA || 'true' === process.env.REACT_APP_USE_MFA_BIO) {
                            if(authMfa !== undefined && authMfa === true) {
                                // MFA 인증 성공
                                setLoader(true);
                                initAllGlobalData().then(() => {
                                    setLoader(false);
                                });
                                setGlobalSetting(STORAGE_NAME.LANGUAGE, getGlobalSetting<string>(STORAGE_NAME.LANGUAGE) || 'KO');
                                setGlobalSetting(STORAGE_NAME.OCR, getGlobalSetting<boolean>(STORAGE_NAME.OCR) === true ? true : false);
                            } else {
                                // MFA 인증 실패
                                if(authSession !== undefined && authSession === true) {
                                    setDialog({
                                        show: true,
                                        type: 'ALERT',
                                        text: 'MFA authentication has not been completed.\nplease try again.',
                                        confirm: () => {
                                            sendAxios('/common/identity/logout.do', 'GET', AXIOS_HEADER.GET)
                                            .catch(e => console.log(e))
                                            .finally(() => {
                                                setAuthSession(false);
                                                setAuthMfa(false);
                                                setSessionValues();
                                                // window.location.href = process.env.REACT_APP_URL_MAIN || '/';
                                            });
                                        }
                                    });
                                }
                            }
                        } else {
                            setLoader(true);
                            initAllGlobalData().then(() => {
                                setLoader(false);
                            });
                            setGlobalSetting(STORAGE_NAME.LANGUAGE, getGlobalSetting<string>(STORAGE_NAME.LANGUAGE) || 'KO');
                            setGlobalSetting(STORAGE_NAME.OCR, getGlobalSetting<boolean>(STORAGE_NAME.OCR) === true ? true : false);
                        }
                    }
                })
                .catch(checkSessionException => {
                    if(checkSessionException instanceof SqciException && checkSessionException.error === SQCI_EXCEPTION.COMMON_ERROR) {
                        cookie.remove('lastActivityTime', {path: '/common'});
                        cookie.remove('LANGUAGE', {path: '/common'});
                        cookie.remove('returnPath', {path: '/common'});
                        cookie.remove('JSESSIONID', {path: '/'});
                        throwException(new SqciException(SQCI_EXCEPTION.DESTROYED_SESSION));
                    } else {
                        setIsFirstLoad(true);
                        getRsaKey();
                    }

                    return new Promise(() => {});
                });
            })();
        }

        const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
            event.preventDefault();
            setDeferredPrompt(event);
        };
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt as any);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt as any);
        };
    }, [enteredComponent, exitedComponent]);

    const installApp = () => {
        if(deferredPrompt) {
            setDialog({
                show: true,
                type: 'CONFIRM',
                text: 'Would you like to add a shortcut?',
                confirm: () => {
                    deferredPrompt?.prompt();
                    deferredPrompt?.userChoice.then(choiceResult => {
                        setDeferredPrompt(null);
                    });
                }
            });
        } else {
            setDialog({
                show: true,
                type: 'ALERT',
                text: 'Cannot add shortcut.'
            });
        }
    };

    useEffect(() => {
        if(saveId) {
            setStorage('LOCAL', 'saveId', userId.value, 7);
        } else {
            clearStorage('LOCAL', 'saveId');
        }
    }, [saveId, userId.value]);

    const doLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const form: HTMLFormElement = event.currentTarget;

        if(jSEncryptRSAKey && userId.value && pass.value) {
            sendAxios('/common/identity/anonymous/SQCI3/login.do', 'GET', AXIOS_HEADER.GET, {
                loginId: jSEncryptRSAKey.encrypt(userId.value),
                passwordHash: jSEncryptRSAKey.encrypt(pass.value),
                user_id: '',
                pass: '',
                userLang: userLang.value,
                clientTimezone: clientTimezone.value,
                isMobile: true
            })
            .then((response) => {
                if(response?.request.responseURL.endsWith('/common/SQCI3/')) {
                    setAuthSession(true);

                    /*FIXME 특정 계정 MFA 비활성 할 경우, 아래 주석 코드 사용. 233 ~ 243 라인 주석 처리*/
                    /* MFA 예외 계정 조회 */
                    sendAxios('/common/nexacro/selectMfaIgnore.do', 'POST', AXIOS_HEADER.NEXACRO, null)
                    .then((response) => {
                        if(response?.data.ds_res.length > 0) {
                            setAuthMfa(true);
                            setLoader(true);
                            initAllGlobalData().then(() => {
                                setLoader(false);
                            });
                            setGlobalSetting(STORAGE_NAME.LANGUAGE, getGlobalSetting<string>(STORAGE_NAME.LANGUAGE) || 'KO');
                            setGlobalSetting(STORAGE_NAME.OCR, getGlobalSetting<boolean>(STORAGE_NAME.OCR) === true ? true : false);
                        } else {
                            if('true' === process.env.REACT_APP_USE_MFA  || 'true' === process.env.REACT_APP_USE_MFA_BIO) {
                                form.submit();
                            } else {
                                setAuthMfa(true);
                                setLoader(true);
                                initAllGlobalData().then(() => {
                                    setLoader(false);
                                });
                                setGlobalSetting(STORAGE_NAME.LANGUAGE, getGlobalSetting<string>(STORAGE_NAME.LANGUAGE) || 'KO');
                                setGlobalSetting(STORAGE_NAME.OCR, getGlobalSetting<boolean>(STORAGE_NAME.OCR) === true ? true : false);
                            }
                        }
                    }).catch(e => {
                        throwException(e);
                        return new Promise(() => {
                        });
                    });

                    // if('true' === process.env.REACT_APP_USE_MFA) {
                    //     form.submit();
                    // } else {
                    //     setAuthMfa(true);
                    //     setLoader(true);
                    //     initAllGlobalData().then(() => {
                    //         setLoader(false);
                    //     });
                    //     setGlobalSetting(STORAGE_NAME.LANGUAGE, getGlobalSetting<string>(STORAGE_NAME.LANGUAGE) || 'KO');
                    //     setGlobalSetting(STORAGE_NAME.OCR, getGlobalSetting<boolean>(STORAGE_NAME.OCR) === true ? true : false);
                    // }
                }
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {});
            });
        } else {
            setDialog({
                type: 'ALERT',
                show: true,
                text: 'You must enter ID and Password.',
                confirm: () => {
                    // userId.onChange();   //확인시 아이디가 없어지지않게 _ 20241007 KJS
                    pass.onChange();
                    getRsaKey();
                }
            });
        }
    };

    return (
        <>
            <div className={'container login'}>
                <div className={'login-header'}>
                    {
                        isMobile && isAndroid && deferredPrompt ?
                            <button type={'button'} className={'btn-home-m'} style={{backgroundColor: '#3478F5'}}
                                onClick={installApp}><i className="hidden">홈</i></button> : null
                    }
                </div>
                <div className={'login-conts'}>
                    <div className={'heading-group'}>
                        <h1>SQCI</h1>
                        <p>Supplier Quality Control Innovation</p>
                    </div>
                    <fieldset>
                        <form method={'get'} encType={'application/x-www-form-urlencoded'}
                            action={'true' === process.env.REACT_APP_USE_MFA_BIO ? (process.env.REACT_APP_HTTP_BASE_URL || '').concat('/common/identity/anonymous/SQCI3/mfaBioAuthRequest.do') 
                            : (process.env.REACT_APP_HTTP_BASE_URL || '').concat('/common/identity/anonymous/SQCI3/mfaAuthRequest.do')}
                            onSubmit={doLogin}>
                            <input type={'hidden'} name={'verificationUrl'}
                                defaultValue={'true' === process.env.REACT_APP_USE_MFA_BIO ? (process.env.REACT_APP_HTTP_BASE_URL || '').concat('/common/identity/anonymous/SQCI3/mobileMfaBioAuthVerification.do') 
                                : (process.env.REACT_APP_HTTP_BASE_URL || '').concat('/common/identity/anonymous/SQCI3/mobileMfaAuthVerification.do')}/>
                            <input type={'hidden'} name={'adFlag'} value={'0'}/>
                            <input type={'text'} {...userId} disabled={jSEncryptRSAKey ? false : true}/>
                            <input type={'password'} {...pass} disabled={jSEncryptRSAKey ? false : true}/>
                            <div className={'divide-wrap'}>
                                <span className="check-item">
                                    <label className={'custom-checkbox'}>
                                        <input type={'checkbox'}
                                            defaultChecked={saveId}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSaveId(event.target.checked)}/> Save ID
                                    </label>
                                </span>
                                <a onClick={() => pageMove(process.env.REACT_APP_URL_RESET_PASSWORD || '')}>Forget your password?</a>
                            </div>
                            <input type={'submit'} value={'Login'}/>
                        </form>
                    </fieldset>
                    <p className={'info-text'}>
                        Please contract Service Desk for login issues.<br/>
                        Service Desk : 82-1800-3131<br/>
                        Contract us : sqci.sec@samsung.com
                    </p>
                </div>
                <Footer lang={userLang.value}/>
            </div>
        </>
    );
}