/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > Test
 * 02. 프로그램ID : QmsMTestList.tsx
 * 03. 프로그램명 : 
 * 04. 화면설명 : 
 * 05. 작성일 : 2023.11.06
 * 06. 작성자 : 
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 * 
 **************************************************************************************************
 */

 import React, {useEffect, useState} from 'react';
 import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
 import {NexacroJsonType, NexacroJsonUnitType, RsaModel, SearchInfoType} from '../../components/model/SqciTypes';
 import useHtmlElement from '../../hooks/useHtmlElement';
 import {Link, useNavigate} from "react-router-dom";
 import {useDataset} from "../../components/contexts/CommonContext";
 import {useError} from "../../hooks/useException";
 import usePageMove from '../../hooks/usePageMove';
 import {SelectBoxType} from '../../components/model/SqciTypes';
 import useDatePicker from '../../hooks/useDatePicker';
 import {useSearch} from '../../hooks/useSearch';
 import useGlobalData from '../../hooks/useGlobalData';
 import * as gfn from "../../components/utils/CommonUtils";
 import useStorage from "../../hooks/useStorage";
 import {useComponent} from "../../components/contexts/ComponentContext";
 import {STORAGE_NAME} from "../../components/CommonConstants";
 import Scanner from "../common/Scanner";
 import {CSSTransition} from "react-transition-group";
 
 const regexPlant: RegExp = /^(\[\w+\])(.*)$/gi;
 
 export default function QmsMTestList() {
     const navigate = useNavigate();
     const {dataset, sessionValues} = useDataset();
     const {sendAxios} = useAxios();
     const {throwException} = useError();
     const {pageMove} = usePageMove();
     const {useInput, useSelectBox} = useHtmlElement();
     const {getDocumentHistory} = useStorage();
 
     const {getSdpVendorList, getSdpPartList, getGlobalSetting, getMessage, getDomain} = useGlobalData();
     const {doSearch, searchComponent, doSearchDivision, doSearchPlant, doSearchVendor, doSearchPart} = useSearch();
     const {setDialog} = useComponent();
 
     // (1:협력사, 2:사업, 3:사업, 4:법인검사자, 5:법, 6:전사, 7:관리자, 8:IT담당자)
     let fv_isDivisionQ = dataset?.gds_user[0]["DIVISION_CD"] == "Q" || dataset?.gds_role[0]["WORK_GROUP_CD"] == 7 ? true : false;//보람보람
 
     let temp_ds_Search: NexacroJsonUnitType[] = [{}];
     const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
 
     const [vendorList, setVendorList] = useState<NexacroJsonUnitType[]>();
     const [division, setDivision] = useState<NexacroJsonUnitType>(getDocumentHistory('division'));
     const [plant, setPlant] = useState<NexacroJsonUnitType>(getDocumentHistory('plant'));
     console.log('2222',getDocumentHistory('division'), getDocumentHistory('plant'))
     const [vendor, setVendor] = useState<NexacroJsonUnitType>(getDocumentHistory('vendor'));
 
     const [ds_search, setDs_search] = useState<NexacroJsonUnitType[]>();
 
     const [valid, setValid] = useState(false);
 
 
     let temp_ds_invStatus: NexacroJsonUnitType[] = [];
 
     const selectedDivision = (value: any) => {
         setPlant({});
         setVendor({});
         setDivision(value);
     };

     const selectedPlant = (value: any) => {
        console.log(value)
        setVendor({});
        setPlant(value);
    };

 
     useEffect(() => {
         if(
             !gfn.gfn_isNull(division?.DIVISION_CD)
             && !gfn.gfn_isNull(plant?.PLANT_CD)    
         ){
             setValid(true);
         } else {
             setValid(false);
         }
     }, [division, plant]);

     useEffect(() => {
        if(division?.DIVISION_CD && plant?.PLANT_CD) {
            getSdpVendorList({
                ds_Search: [{
                    DIVISION_CD: division?.DIVISION_CD,
                    PLANT_CD: plant?.PLANT_CD
                }]
            })
                .then(response => {
                    if(dataset?.gds_role[0]["WORK_GROUP_CD"] == 1) {
                        setVendorList(response?.data.ds_vendorList.filter((vendor: NexacroJsonUnitType) => vendor.HQ_CD === sessionValues?.HQ_CD));
                    }else {
                        setVendorList(response?.data.ds_vendorList
                            .filter((vendor: NexacroJsonUnitType) => vendor.PLANT_CD === plant?.PLANT_CD));
                    }
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {});
                });
        }
    }, [division, plant]);
 

     function form_onload(){
         fn_isDivisionQ("SEARCH");
     }
 

     function fn_isDivisionQ(flag:string)
     {
         if (!fv_isDivisionQ)
         {
             return;
         }
 
         if (flag == "SEARCH")
         {
        
         }
     };
 
     // 조회
     function fn_tranSearch(){

         if(false){
         }else {
                 temp_ds_Search[0]["DIVISION_CD"] = division?.DIVISION_CD;
         }
 
         temp_ds_Search[0]["LANG"] = gv_locale ? gv_locale : "EN"; //gv_locale;
         setDs_search(temp_ds_Search);
 
         let requestJson:NexacroJsonType = {
            ds_search : temp_ds_Search,
            ds_plantList : [{PLANT_CD: plant?.PLANT_CD}],
            ds_divList : [{DIVISION_CD: division?.DIVISION_CD}]
         }
 
         pageMove(process.env.REACT_APP_URL_QMS_TEST_SEARCH || '', {
             division: division,
             plant: plant,
             requestJson:requestJson
         });
 
     }
     console.log("111111111111",plant)
     return (
         <>
             {searchComponent}
             <div className={'container'}>
                 <div className={'contents-wrap'} style={{minHeight:'60rem'}}>
                     <ul className={'reports-list'}>
                         <li className={'text-item'}>
                             <span className={'title'}>
                                 <strong className={'required'}>*</strong> {getDomain('DOMAIN0222')} {/*사업부*/}
                             </span>
                             <div role={'button'} onClick={() => doSearchDivision('RADIO', selectedDivision,undefined)}>
                                 <p>{division?.DIVISION_NM}</p>
                                 <button type={'button'} className={'btn-link'}>
                                     <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                 </button>
                             </div>
                         </li>
                         <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN2426')} {/*Plant*/}
                            </span>
                            <div role={'button'} onClick={() => doSearchPlant('RADIO', selectedPlant, undefined,[{
                                DIVISION_CD: gfn.gfn_tostring(division?.DIVISION_CD)
                            }])}>
                                <p>{plant?.PLANT_CD ? '['.concat(String(plant?.PLANT_CD), ']') : ''}<br/>{String(plant?.PLANT_PROD_NM || '').replace(regexPlant, '$2')}</p>
                                <button type={'button'} className={'btn-link'}>
                                    <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                </button>
                            </div>
                        </li>
                        </ul>
                 </div>
                 <div className={'btn-area bottom'}>
                     <button type={'button'} className={'button-primary'}
                             disabled={!valid} onClick={() => fn_tranSearch()}>
                         {getDomain('DOMAIN0312')} {/*조회*/}
                     </button>
                 </div>
             </div>
         </>
     );
 };