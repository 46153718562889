/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > 입고검사 결과조회
 * 02. 프로그램ID : QMS_M_Incm_InspList.tsx
 * 03. 프로그램명 : 입고검사 > 입고검사대상 및 결과입력
 * 04. 화면설명 : 입고검사 결과조회
 * 05. 작성일 : 2023.09.07
 * 06. 작성자 : 조은
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-09-07     조은    최초 작성
 **************************************************************************************************
 */

import React, {useEffect, useState} from 'react';
import {NexacroJsonType, NexacroJsonUnitType} from '../../components/model/SqciTypes';
import {useSearch} from '../../hooks/useSearch';
import useStorage from "../../hooks/useStorage";
import useDatePicker from '../../hooks/useDatePicker';
import {useDataset} from "../../components/contexts/CommonContext";
import useGlobalData from "../../hooks/useGlobalData";
import {useError} from "../../hooks/useException";
import {STORAGE_NAME} from "../../components/CommonConstants";
import {useLocation} from "react-router-dom";
import useAxios, {AXIOS_HEADER} from "../../hooks/useAxios";
import usePageMove from "../../hooks/usePageMove";
import * as gfn from "../../components/utils/CommonUtils";
import {useComponent} from "../../components/contexts/ComponentContext";
import Scanner from "../common/Scanner";
import {CSSTransition} from "react-transition-group";

const regexPlant: RegExp = /^(\[\w+\])(.*)$/gi;

export default function QmsSearch() {
    const {getDocumentHistory} = useStorage();
    const {searchComponent, doSearchDivision, doSearchPlant, doSearchVendor, doSearchPart} = useSearch();
    const {getSdpVendorList, getSdpPartList, getGlobalSetting, getMessage, getDomain} = useGlobalData();
    const {sessionValues, confirmHistoryBack, dataset} = useDataset();
    const {throwException} = useError();

    const location = useLocation();
    const {sendAxios} = useAxios();
    const {pageMove} = usePageMove();
    const {setDialog} = useComponent();

    // const datePickerFrom = useDatePicker('SINGLE', undefined, {single: {selected : fn_dateToString_N("D", -7) + "000000"}});
    // const datePickerTo = useDatePicker('SINGLE', undefined, {single: {selected : fn_dateToString_Z(new Date) + "000000"}});
    const [datePickerFromWithDash, setDatePickerFromWithDash] = useState(getDocumentHistory('datePickerFromWithDash') ? getDocumentHistory('datePickerFromWithDash') : fn_dateToString_N("D", -7, true));
    const [datePickerToWithDash, setDatePickerToWithDash] = useState(getDocumentHistory('datePickerToWithDash') ? getDocumentHistory('datePickerToWithDash') : fn_dateToString_Z(new Date, true));

    const [vendorList, setVendorList] = useState<NexacroJsonUnitType[]>();
    const [partList, setPartList] = useState<NexacroJsonUnitType[]>();

    //검색조건 저장 KJS
    const {setStorage, getStorage, clearStorage} = useStorage();
    const getSearchCondition:any = getStorage('LOCAL', location.pathname);

    const [division, setDivision] = useState<NexacroJsonUnitType>();
    const [plant, setPlant] = useState<NexacroJsonUnitType>();
    const [vendor, setVendor] = useState<NexacroJsonUnitType>();
    const [part, setPart] = useState<NexacroJsonUnitType>(getDocumentHistory('part'));

    const [ds_Search, setDs_Search] = useState<NexacroJsonUnitType[]>();
    const [type, setType] = useState(gfn.gfn_isNull(getDocumentHistory('type')) ? "01" : getDocumentHistory('type'));   // 기본값 "입고일"
    const [inv_no, setInv_no] = useState(getDocumentHistory('inv_no'));   // Invoice No.

    const [qrScannerPop, setQrScannerPop] = useState(false);

    const [valid, setValid] = useState(false);

    let gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    let temp_ds_Search: NexacroJsonUnitType[] = [{}];

    // (1:협력사, 2:사업, 3:사업, 4:법인검사자, 5:법, 6:전사, 7:관리자, 8:IT담당자) 
    let fv_isDivisionQ = dataset?.gds_user[0]["DIVISION_CD"] == "Q" || dataset?.gds_role[0]["WORK_GROUP_CD"] == 7 ? true : false;//보람보람

    // 일주일 전으로 날짜 세팅
    function fn_dateToString_Z(date:Date, dashFlag: boolean){
        let year = date.getFullYear();
        let month = ("0" + (1 + date.getMonth())).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);

        let rv = year + month + day;

        if (dashFlag) {
            rv = year + "-" + month + "-" + day;
        }

        return rv;
    }

    // 일주일 전으로 날짜 세팅
    function fn_dateToString_N(gb: string, diff: number, dashFlag: boolean){
        let nowDt = new Date;

        let diffY = 0;
        let diffM = 0;
        let diffD = 0;

        if (gb == "Y") {
            diffY = diff;
        } else if (gb == "M") {
            diffM = diff;
        } else if (gb == "D") {
            diffD = diff;
        }

        let nDate = new Date(nowDt.getFullYear() + diffY, nowDt.getMonth() + diffM, nowDt.getDate() + diffD);

        return fn_dateToString_Z(nDate, dashFlag);
    }

    useEffect(() => {
        //get storage _KJS
        if(!gfn.gfn_isNull(getSearchCondition)) {
            setDivision(getSearchCondition['division']);
            setPlant(getSearchCondition['plant']);
            setVendor(getSearchCondition['hqCd']);
        }
        
        form_onload();
    },[]);

    useEffect(() => {
        if(division?.DIVISION_CD && plant?.PLANT_CD) {
            getSdpVendorList({
                ds_Search: [{
                    DIVISION_CD: division?.DIVISION_CD,
                    PLANT_CD: plant?.PLANT_CD
                }]
            })
            .then(response => {
                if(dataset?.gds_role[0]["WORK_GROUP_CD"] == 1) {
                    setVendorList(response?.data.ds_vendorList.filter((vendor: NexacroJsonUnitType) => vendor.HQ_CD === sessionValues?.HQ_CD));
                }else {
                    setVendorList(response?.data.ds_vendorList
                        .filter((vendor: NexacroJsonUnitType) => vendor.PLANT_CD === plant?.PLANT_CD));
                }
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {});
            });
        }
    }, [division, plant]);

    // useEffect(() => {
    //     if(division?.DIVISION_CD && plant?.PLANT_CD && vendor?.HQ_CD) {
    //         getSdpPartList({
    //             ds_search: [{
    //                 SRCH_TYPE: 'C',
    //                 RECORDSOFFSET: '0',
    //                 RECORDS: '100000',
    //                 EXTINCTION: '0',
    //                 CATEGORY2: '',
    //                 CATEGORY1: '',
    //                 HQ_CD: vendor.HQ_CD,
    //                 POP_TYPE: 'D',
    //                 LANG: getGlobalSetting<string>(STORAGE_NAME.LANGUAGE)
    //             }],
    //             ds_plantList: [{
    //                 PLANT_CD: plant?.PLANT_CD
    //             }],
    //             ds_divList: [{
    //                 DIVISION_CD: division?.DIVISION_CD
    //             }]
    //         })
    //         .then(response => {
    //             setPartList(response?.data.ds_list);
    //         })
    //         .catch(e => {
    //             throwException(e);
    //             return new Promise(() => {});
    //         });
    //     }
    // }, [division, plant, vendor]);

    useEffect(() => {
        if(
            (!gfn.gfn_isNull(division?.DIVISION_CD)
            && !gfn.gfn_isNull(plant?.PLANT_CD)
            // && !gfn.gfn_isNull(vendor?.HQ_CD)
            // && !gfn.gfn_isNull(part?.PART_CD)
            )
            || !gfn.gfn_isNull(inv_no)
        ){
            setValid(true);
        } else {
            setValid(false);
        }
    }, [division, plant, vendor, part, inv_no]);

    const selectedDivision = (value: any) => {
        setPlant({});
        setVendor({});
        setPart({});
        setInv_no('');
        setDivision(value);
    };

    const selectedPlant = (value: any) => {
        setVendor({});
        setPart({});
        setInv_no('');
        setPlant(value);
    };

    const selectedVendor = (value: any) => {
        setPart({});
        setInv_no('');
        setVendor(value);
    };

    const selectedPart = (value: any) => {
        setInv_no('');
        setPart(value);
    };

    function changeEvent(e:any, dataName:string)
    {
        if(dataName === "type"){
            setType(e.target.value);
        }
    }

    function blurEvent(e:any, dataName:string)
    {
        if(dataName == "inv_no"){
            setInv_no(e.target.value);
        }
    }

    function form_onload()
    {
        //타 화면에서 해당 화면을 호출 시 파라미터가 있을 경우 아래와 같이 처리함
        //FromDate ~ ToDate 를 이용하여 조회 처리함
        // 23.09.08 react수정) 아직 모바일에서는 다른 페이지에서 현재 페이지 호출하는 경우 존재하지 않음
        // if(!this.gfn_isNull(this.getOwnerFrame().pArgs)){
        if(false){}
        else{
            //기간 기본 일주일 설정
            // 23.09.13 react수정) datepicker 따로 설정
        }

        /** VOC 41 Start : 2019.09.05 채보람 Smart Inspection Data 연계 입고 Lot 자동판정 **/
        /** 무선사업부일 경우 검색 조건 추가 **/
        // fn_isDivisionQ("SEARCH");
        /** VOC 41 End   : 2019.09.05 채보람 Smart Inspection Data 연계 입고 Lot 자동판정 **/

        //popup div 초기화
        // 23.09.08 react수정) 모바일에서는 메모 작성 팝업 존재하지 않음
        // this.popMemo.form.div_btnMemo.form.fn_init(this, this.ds_BtnMemo, "right");
    };

    /* 입고검사 대상목록 조회 */
    function fn_tranSearch()
    {
        /*if(gfn.gfn_isNull(part?.PART_CD)){
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG09001").replace("{0}",getDomain("DOMAIN2025")),//부품명
                confirm: () => {

                }
            });
            return;
        }
        if(gfn.gfn_isNull(inv_no)){
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG09001").replace("{0}",getDomain("DOMAIN4591")),//Invoice No
                confirm: () => {

                }
            });
            return;
        }*/
        // 23.09.08 react수정) 모바일에서는 페이징 처리 하지 않음
        /*if(this.fv_excelFlag == "N"){
            this.ds_Search.setColumn(0, "RECORDS", this.fv_Records * (this.fv_Page + 1));
            this.ds_Search.setColumn(0, "RECORDSOFFSET", this.fv_RecordsOffset +1 );
        }else{
            this.ds_Search.setColumn(0, "RECORDS", "99999999");
            this.ds_Search.setColumn(0, "RECORDSOFFSET", 0);
        }*/
        //외부에서 강제 조회 시 처리를 위해 아래와 같이 구분한다.
        // 23.09.08 react수정) 현재 타화면에서 넘어오는 로직 존재하지 않아 하단 진행하지 않음
        // if(!this.gfn_isNull(pArgs)){

        // let startDt = new Date(datePickerFromWithDash); //to_date
        // let endtDt = new Date(datePickerToWithDash);
        // let diff = Math.abs(startDt.getTime() - endtDt.getTime());
        // diff = Math.ceil(diff / (1000 * 60 * 60 * 24));
        //
        // if (diff > 30) {
        //     setDialog({
        //         show: true,
        //         type: 'ALERT',
        //         text: getMessage("MSG00737") // 조회기간은 최대 30일 입니다.
        //     });
        //
        //     return;
        // }

        let sPartCd = part?.PART_CD == undefined ? "" : String(part?.PART_CD);
        let sSearchType = "EQ";

        if (sPartCd.length >= 4 && sPartCd.length <= 10) {
            //라이크 검색
            sSearchType = "LIKE";
        }

        if(false){
        }else{
            temp_ds_Search[0]["TYPE"] = type;

            if (!inv_no) {
                temp_ds_Search[0]["FROM_DATE"] = datePickerFromWithDash.replaceAll('-', '').replaceAll('.', '');
                temp_ds_Search[0]["TO_DATE"] = datePickerFromWithDash.replaceAll('-', '').replaceAll('.', '');
                temp_ds_Search[0]["DIVISION_CD"] = division?.DIVISION_CD;
                temp_ds_Search[0]["HQ_CD"] = vendor?.HQ_CD;
                temp_ds_Search[0]["PART_CD"] = part?.PART_CD;
                temp_ds_Search[0]["SEARCH_TYPE"] = sSearchType;
            } else {
                temp_ds_Search[0]["FROM_DATE"] = fn_dateToString_N("Y", -1, false);
                temp_ds_Search[0]["TO_DATE"] = fn_dateToString_Z(new Date, false);
                temp_ds_Search[0]["DIVISION_CD"] = "";
                temp_ds_Search[0]["HQ_CD"] = "";
                temp_ds_Search[0]["PART_CD"] = "";
                temp_ds_Search[0]["INV_NO"] = inv_no;
                temp_ds_Search[0]["SEARCH_TYPE"] = "EQ";
            }

            fn_isDivisionQ("SEARCHPARAM");
        }

        temp_ds_Search[0]["LANG"] = gv_locale ? gv_locale : "EN"; //gv_locale;
        setDs_Search(temp_ds_Search);

        pageMove(process.env.REACT_APP_URL_QMS_LIST || '', {
            division: division,
            plant: plant,
            vendor: vendor,
            part: part,
            type: type,
            inv_no: inv_no,
            datePickerFromWithDash: datePickerFromWithDash,
            datePickerToWithDash: datePickerFromWithDash,
            ds_Search: temp_ds_Search
        });
        //set storage
        setStorage('LOCAL', location.pathname, {
            division: division,
            plant: plant,
            hqCd: vendor
        }, 7);

    }

    /** VOC 41 Start : 2019.09.05 채보람 Smart Inspection Data 연계 입고 Lot 자동판정 **/
    /** 무선사업부일 경우 로직 함수 **/
    /**
     SEARCH -> Smart Inspection 검색 조건 추가
     SEARCHPARAM -> 검색 조건 추가
     **/
    function fn_isDivisionQ(flag:string)
    {
        if (!fv_isDivisionQ)
        {
            return;
        }

        if (flag == "SEARCH")
        {
            // 23.09.08 react수정) 화면에 "Smart Inspection" / "2배수 대상 여부" 없음
        }
        else if (flag == "SEARCHPARAM")
        {
            temp_ds_Search[0]["IS_DIV_Q"] = "Y";
            // 23.09.08 react수정) 화면에 "Smart Inspection" / "2배수 대상 여부" 없음
            // this.ds_Search.setColumn(0, "SMART_INSP_YN", this.fv_arrSearch["div_smtInsp"].form.fn_getValue());
            // this.ds_Search.setColumn(0, "DOUBLE_TARGET_YN", this.fv_arrSearch["div_doubleYn"].form.fn_getValue());
        }
    }

    // 사진 스캔
    function fn_scnnerOpen()
    {
        // if(gfn.gfn_isNull(vendor?.HQ_CD)){
        //     setDialog({
        //         type: 'ALERT',
        //         show: true,
        //         text: getMessage("MSG09001").replace("{0}",getDomain("DOMAIN0372")),//협력사
        //         confirm: () => {
        //
        //         }
        //     });
        //     return;
        // }
        setQrScannerPop(true);
    }

    // 사진 스캔 이후 작업
    async function fn_scanner(result:string){
        if (result.length > 24) { /* 물품표 */
            let tempInvNo = result.substr(23);

            if (tempInvNo.substr(0, 1) == '-') {
                tempInvNo = tempInvNo.substr(1);
            }

            setInv_no(tempInvNo);
        } else { /* 인보이스 */
            await selectInvoiceHqList(result.substr(0, 4)).then((response) => {
                let res = response?.data.ds_invoiceHqList;
                let invoiceNo = "";

                for (let i = 0; i < res.length; i++) {
                    if (invoiceNo.length == 0 || invoiceNo.length > result.replace(res[i]['HQ_CD'], '').length) {
                        invoiceNo = result.replace(res[i]['HQ_CD'], '');
                    }
                }

                if (invoiceNo.substr(0, 1) == '-') {
                    invoiceNo = invoiceNo.substr(1);
                }

                setInv_no(invoiceNo);
            }).catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
        }
        initSearchParam();
        // result = result.replace(gfn.gfn_tostring(vendor?.HQ_CD),"");
        // setInv_no(result);
    }
    function initSearchParam(){
        //Invoice 입력된 후 사업부, Plant 초기화
        // setDivision({DIVISION_CD : ''});
        // setPlant({PLANT_CD: ''});
        setVendor({HQ_CD: ''});
        setPart({PART_CD: ''});
        //날짜도?? 날짜는 Invoice 1년전으로 하네
        // setDatePickerFromWithDash(fn_dateToString_N("Y", -1, true))
        // setDatePickerToWithDash(fn_dateToString_Z(new Date, true))
    }
    
    /* 협력사 목록 조회 */
    function selectInvoiceHqList(hqCd: string) {
        const requestJson: NexacroJsonType = {
            ds_search: [{
                HQ_CD: hqCd
            }]
        };

        return sendAxios('/common/nexacro/selectInvoiceHqList.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson);
    }

    /* Invoice No. 입력 시 */
    function changeInvNo(e: any) {
        initSearchParam();
        setInv_no(e.target.value.toUpperCase());
    }

    /* 달력 교체 시 */
    function changeDatePicker(e: any, dateFromTo: string) {
        if (dateFromTo == 'F') {
            setDatePickerFromWithDash(e.target.value);
        } else {
            setDatePickerToWithDash(e.target.value);
        }
    }

    /* 부품명 클릭 시 */
    function searchPartClick() {
        let messageList: string[] = [];

        if(!division || !division.DIVISION_CD) {
            messageList.push(getDomain('DOMAIN0222'));
        }
        if(!plant || !plant.PLANT_CD) {
            messageList.push(getDomain('DOMAIN2426'));
        }
        if(!vendor || !vendor.HQ_CD) {
            messageList.push(getDomain('DOMAIN0372'));
        }

        if (messageList.length != 0) {
            setDialog({
                show: true,
                type: 'ALERT',
                text: messageList.join('/').concat(getMessage('MSG01542')),
            });

            return;
        }

        doSearchPart('FAVORITES', partList || [], selectedPart, undefined,
            undefined,
            division ? division.DIVISION_CD ? String(division.DIVISION_CD) : undefined : undefined,
            plant ? plant.PLANT_CD ? String(plant.PLANT_CD) : undefined : undefined,
            vendor ? vendor.HQ_CD ? String(vendor.HQ_CD) : undefined : undefined,
        );
    }

    return (
        <>
            {searchComponent}
            <div className={'container'}>
                <div className={'contents-wrap'}>
                    <ul className={'reports-list'}>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN0222')} {/*사업부*/}
                            </span>
                            {
                                ((
                                    2 === (sessionValues?.WORK_GROUP_CD || 0)  ||
                                    3 === (sessionValues?.WORK_GROUP_CD || 0) ||
                                    4 === (sessionValues?.WORK_GROUP_CD || 0) ||
                                    5 === (sessionValues?.WORK_GROUP_CD || 0)
                                ) ? //시업부관리자 또는 법인검사자
                                    <div role={'button'} onClick={() => doSearchDivision('RADIO', selectedDivision,undefined, [{
                                                    DIVISION_CD: gfn.gfn_tostring(sessionValues?.DIVISION_CD)
                                            }])}>
                                                <p>{division?.DIVISION_NM}</p>
                                                <button type={'button'} className={'btn-link'}>
                                            <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                        </button>
                                    </div>
                                    :
                                    <div role={'button'} onClick={() => doSearchDivision('RADIO', selectedDivision,undefined)}>
                                                <p>{division?.DIVISION_NM}</p>
                                                <button type={'button'} className={'btn-link'}>
                                            <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                        </button>
                                    </div>
                                )
                            }

                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN2426')} {/*Plant*/}
                            </span>
                            <div role={'button'} onClick={() => doSearchPlant('RADIO', selectedPlant, undefined,[{
                                DIVISION_CD: gfn.gfn_tostring(division?.DIVISION_CD)
                            }])}>
                                <p>{plant?.PLANT_CD ? '['.concat(String(plant?.PLANT_CD), ']') : ''}<br/>{String(plant?.PLANT_PROD_NM || '').replace(regexPlant, '$2')}</p>
                                <button type={'button'} className={'btn-link'}>
                                    <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                </button>
                            </div>
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                {/*<strong className={'required'}>*</strong>*/} {getDomain('DOMAIN0372')} {/*협력사*/}
                            </span>
                            <div className={'text-item'} role={'button'} onClick={() => doSearchVendor('FAVORITES', vendorList || [], selectedVendor)}>
                                <p>{vendor?.HQ_CD ? '['.concat(String(vendor?.HQ_CD), ']') : ''}<br/>{vendor?.HQ_NM}</p>
                                <button type={'button'} className={'btn-link'}>
                                    <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                </button>
                            </div>
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                {/*<strong className={'required'}>*</strong>*/} {getDomain('DOMAIN2025')} {/*부품명*/}
                            </span>
                            <div className={'item-wrap ellipsis1'} role={'button'} onClick={() => searchPartClick()}>
                                <p className={'item-wrap ellipsis1'}>{part?.PART_CD ? '['.concat(String(part?.PART_CD), ']') : ''}<br/><strong className={'item-wrap ellipsis1'}>{part?.PART_NM}</strong></p>
                                <button type={'button'} className={'btn-link'}>
                                    <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                </button>
                            </div>
                        </li>
                        <li className={'form-item type-date'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN4592')} {/*기간검색*/}
                            </span>
                            <div className={'type-date-in'}>
                                <div className={'radio-item'}>
                                    {
                                        (dataset?.ds_CommonCode || [])
                                            .filter(f => f.CATEGORY === "SQCI000196" && f.LANG === gv_locale && (f.CD === "01" || f.CD === "03"))
                                            .map((item, index) => {
                                                return <React.Fragment key={index}>
                                                    <label htmlFor={gfn.gfn_tostring(item.CD)}>
                                                        <input
                                                            type={"radio"}
                                                            id={gfn.gfn_tostring(item.CD)}
                                                            value={gfn.gfn_tostring(item.CD)}
                                                            checked={type === item.CD}
                                                            onChange={(e) => changeEvent(e, 'type')}
                                                        />{item.CDNM}
                                                    </label>
                                                </React.Fragment>
                                            })
                                    }
                                </div>
                                <div>
                                    <div className={'datepicker-wrap'}>
                                        {/*{datePickerFrom?.datePicker}*/}
                                        <span className={'date-item'}>
                                            <input type={"date"} style={{WebkitAppearance:"none"}} pattern={'\d{4}-\d{2}-\d{2}'} defaultValue={datePickerFromWithDash} onChange={(e) => changeDatePicker(e, 'F')} />
                                        </span>
                                    </div>
                                    {/*<div className={'datepicker-wrap'}>*/}
                                    {/*    /!*{datePickerTo?.datePicker}*!/*/}
                                    {/*    <span className={'date-item'}>*/}
                                    {/*        <input type={"date"} style={{WebkitAppearance:"none"}} pattern={'\d{4}-\d{2}-\d{2}'} defaultValue={datePickerToWithDash} onChange={(e) => changeDatePicker(e, 'T')} />*/}
                                    {/*    </span>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </li>
                        <li className={'form-item barcode'}>
                            <span className={'title'}>
                                {getDomain('DOMAIN2414')} {/*Invoice No.*/}
                            </span>
                            <div className={'barcode-item'}>
                                <input type={'text'}
                                       placeholder={''}
                                       value={inv_no}
                                       onChange={changeInvNo}
                                       // readOnly={true}
                                       // defaultValue={inv_no}
                                       // onBlur={(e) => blurEvent(e, 'inv_no')}
                                />
                                <button type={"button"} className={"btn-barcode"} onClick={() => fn_scnnerOpen()}>
                                    <i className={"hidden"}>{getDomain('DOMAIN0235')}</i>  {/*선택*/}
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className={'btn-area bottom'}>
                    <button type={'button'} className={'button-primary'} disabled={!valid} onClick={() => fn_tranSearch()}>
                        {getDomain('DOMAIN4607')} {/*입고검사 결과조회*/}
                    </button>
                </div>
            </div>
            <CSSTransition in={qrScannerPop} classNames={qrScannerPop ? 'right' : 'left'} timeout={500} unmountOnExit>
                <Scanner onClose={() => setQrScannerPop(false)} scanResult={(result) => fn_scanner(gfn.gfn_tostring(result))}/>
            </CSSTransition>
        </>
    );
};