import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Navigation, EffectFade} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import {v4 as uuidv4} from 'uuid';
import useGlobalData from "../../hooks/useGlobalData";

export default function Picture({pathList, onClose}: {pathList: string[], onClose: () => void}) {
    const {getDomain} = useGlobalData();
    return (
        <>
            <div className={'pop-layer'} style={{display: 'block'}}>
                <div className={'wrap'}>
                    <header>
                        <h1>{getDomain('DOMAIN2049')}</h1> {/*사진*/}
                        <span className={'btn-wrap right'}>
                        <button type={'button'} className={'button-close'} onClick={onClose}>
                            <i className={'hidden'}>{getDomain('DOMAIN0173')}</i>   {/*닫기*/}
                        </button>
                        </span>
                    </header>
                    <div className={'container-noheader'}>
                        <div className={'comm-slide-wrap'} style={{
                            display: 'block',
                            // position: 'absolute',
                            backgroundColor: 'white',
                            width: '100%',
                            height: 'unset'
                        }}>
                            <div className={'swiper-pagination swiper-pagination-img'} style={{
                                width: '100% !important',
                                paddingBottom: '2rem'
                            }}></div>
                            <Swiper
                                modules={[Pagination, Navigation, EffectFade]}
                                pagination={{
                                    dynamicBullets: true,
                                    el: '.swiper-pagination-img',
                                    clickable: true
                                }}
                                spaceBetween={30}
                                navigation={true}
                                initialSlide={0}
                                className={'swiper mySwiper'}>
                                {
                                    pathList.map(img => {
                                        return <SwiperSlide key={uuidv4()}>
                                            <div className={'image-wrap'} style={{
                                                marginTop: 'unset',
                                                overflowY: 'unset',
                                                padding: 'unset'
                                            }}>
                                                <img src={img} alt={''} style={{width: '100%'}}/>
                                            </div>
                                        </SwiperSlide>;
                                    })
                                }
                            </Swiper>
                        </div>
                        <div className={'btn-area bottom'}>
                            <button type={'button'} className={'button-primary'} onClick={onClose}>{getDomain('DOMAIN0173')}</button>   {/*닫기*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};