/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > 입고검사 성적서 (부품검사정보)
 * 02. 프로그램ID : QmsPartInspectionInformation.tsx
 * 03. 프로그램명 : 입고검사 성적서 작성 (부품검사정보 탭)
 * 04. 화면설명 : 입고검사 성적서 작성
 * 05. 작성일 : 2023.08.24
 * 06. 작성자 : 채보람
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-08-24     채보람    최초 작성
 **************************************************************************************************
 */

import React, {useEffect, useState} from 'react';
import {NexacroJsonType, NexacroJsonUnitType, SelectBoxType} from "../../../../components/model/SqciTypes";
import useAxios, {AXIOS_HEADER} from "../../../../hooks/useAxios";
import useGlobalData from "../../../../hooks/useGlobalData";
import {STORAGE_NAME} from "../../../../components/CommonConstants";
import useHtmlElement from "../../../../hooks/useHtmlElement";
import { gfn_isNull } from '../../../../components/utils/CommonUtils';
import PopChangePoint from "../../../common/popChangePoint";
import {CSSTransition} from "react-transition-group";
import PopFiles from "../../../common/popFiles";
import QmsPopIssuePartList from "./QmsPopIssuePartList";
import {v4 as uuidv4} from "uuid";
import usePageMove from "../../../../hooks/usePageMove";
import {useComponent} from "../../../../components/contexts/ComponentContext";
import {useError} from "../../../../hooks/useException";

export default function QmsPartInspectionInformation({state, nextTab}: {state: any, nextTab: (name: string) => void}) {
    const {getGlobalSetting, getDomain, getMessage} = useGlobalData();
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const {useSelectBox, useInput} = useHtmlElement();
    const {pageMove} = usePageMove();
    const {setDialog} = useComponent();
    const {throwException} = useError();

    // state.params.get('pv_data')
    const {sendAxios} = useAxios();
    const [ds_Part, setDs_Part] = useState(state.params.get('pv_data'));
    const [ds_popChngPoingHList, setDs_popChngPoingHList] = useState(state.params.get('pv_ecn'));
    const [ds_Ecn, setDs_Ecn] = useState(state.params.get('pv_ecnDetail'));
    const [ds_IssuePartList, setDs_IssuePartList] = useState(state.params.get('pv_ds_IssuePartList'));
    const [ds_VerificationType, setDs_VerificationType] = useState(new Array<NexacroJsonUnitType>());
    const [hq_list, setHq_list] = useState(new Array<NexacroJsonUnitType>()); // 협력사 목록
    const [ds_EcnReqInfo, setDs_EcnReqInfo] = useState(new Array<NexacroJsonUnitType>()); // 협력사 목록
    const [filePop, setFilePop] = useState(false);          // "SPEC 첨부파일" 팝업 // "fn_specAttach" 대체
    const [changePop, setChangePop] = useState(false);      // "변경점 확인" 팝업
    const [issuePartPop, setIssuePartPop] = useState(false);      // "실패사례 확인" 팝업
    const [ds_SpecFile, setDs_SpecFile] = useState(state.params.get('pv_vds_SpecFile'));
    const [ds_SubmitInspList, setDs_SubmitInspList] = useState(state.params.get('pv_ds_SubmitInspList'));
    const [ds_chngPoingHList, setDs_chngPoingHList] = useState(state.params.get('pv_ds_chngPoingHList'));

    const verificationType = useSelectBox('verificationType');
    const [sChgTyp, setSChgTyp] = useState(String);
    const [sEcnNo, setSEcnNo] = useState(String);
    const [sGrade, setSGrade] = useState(String);
    const [sVerificationType, setSVerificationType] = useState(String);
    const [sL1Category, setSL1Category] = useState(String);
    const [sL2Category, setSL2Category] = useState(String);
    const [sL3Category, setSL3Category] = useState(String);
    const [issuePartDesc, setIssuePartDesc] = useState("N");
    const [issueDisabled, setIssueDisabled] = useState(true);
    const [changeDisabled, setChangeDisabled] = useState(true);
    const [timerCnt, setTimerCnt] = useState(0);

    let vds_Part = state.params.get('pv_data');
    let vds_SpecFile: any = [];

    useEffect(() => {
        // console.log("부품검사정보 탭");
        // console.log("state.params ↓");
        // console.log(state.params);
        // console.log("부품검사정보 탭");
        form_onload();
    }, []);

    useEffect(() => {
        let temp_timerCnt = timerCnt;
        let timer = setTimeout(()=>{
            if(temp_timerCnt < 10){
                temp_timerCnt++;
                setTimerCnt(temp_timerCnt);
            }
        }, 1000);

        if(temp_timerCnt > 10){
            return ()=>{ clearTimeout(timer) }
        }

    }, [timerCnt]);

    /* form_onload */
    function form_onload() {
        /* ATTACH_FILE_ID -> FILE_ID 에도 넣기 */
        initDs_SpecFile();

        /* 협력사 자체변경점 등급 기준 등록 */
        fn_selectVerficationType();

        /* 변경점 확인 세팅 */
        initDs_popChngPoingHList();

        /* 실패사례 세팅 */
        initDs_IssuePartList();

        /* 협력사 자체변경점 정보 세팅 */
        initDs_Ecn();
    }

    /* ATTACH_FILE_ID -> FILE_ID 에도 넣기 */
    function initDs_SpecFile() {
        let copyDs = [...ds_SpecFile];

        for (let i = 0; i < copyDs.length; i++) {
            copyDs[i]['FILE_ID'] = copyDs[i]["ATTACH_FILE_ID"];
        }

        setDs_SpecFile(copyDs);
    }

    /* 변경점 확인 세팅 */
    function initDs_popChngPoingHList() {
        if (ds_popChngPoingHList == undefined || ds_popChngPoingHList.length == 0) {
            return;
        }

        let copyDs = [...ds_popChngPoingHList];

        for (let i = 0; i < ds_popChngPoingHList.length; i++) {
            let yyyy = "";
            let mm = "";
            let dd = "";
            let ecrDate = String(ds_popChngPoingHList[i]['ECR_END_DATE']);
            let stockDate = String(ds_popChngPoingHList[i]['STOCK_PLAN_DATE']);

            if (ecrDate != undefined && ecrDate != null && ecrDate != "") {
                yyyy = ecrDate.substr(0,4);
                mm = ecrDate.substr(4,2);
                dd = ecrDate.substr(6,2);

                copyDs[i]['ECR_END_DATE_NM'] = yyyy+"-"+mm+"-"+dd;
                setDs_popChngPoingHList(copyDs);
            } else {
                copyDs[i]['ECR_END_DATE_NM'] = "";
                setDs_popChngPoingHList(copyDs);
            }

            if (stockDate != undefined && stockDate != null && stockDate != "") {
                yyyy = stockDate.substr(0,4);
                mm = stockDate.substr(4,2);
                dd = stockDate.substr(6,2);

                copyDs[i]['STOCK_PLAN_DATE_NM'] = yyyy+"-"+mm+"-"+dd;
                setDs_popChngPoingHList(copyDs);
            } else {
                copyDs[i]['STOCK_PLAN_DATE_NM'] = "";
                setDs_popChngPoingHList(copyDs);
            }
        }

        setChangeDisabled(false);
    }

    /* 실패사례 세팅 */
    function initDs_IssuePartList() {
        if (ds_IssuePartList == undefined || ds_IssuePartList.length == 0) {
            return;
        }

        let copyDs = [...ds_IssuePartList];

        for (let i = 0; i < ds_IssuePartList.length; i++) {
            copyDs[i]['DIVISION_NM'] = ds_Part[0]["DIVISION_NM"];
            setDs_IssuePartList(copyDs);
        }

        setIssuePartDesc("Y");
        setIssueDisabled(false);
    }

    /* 협력사 자체변경점 정보 세팅 */
    function initDs_Ecn() {
        if (String(state.params.get('pv_flag')) == "DETAIL") {
            if (ds_Ecn == undefined || ds_Ecn.length == 0) {
                return;
            }

            setSChgTyp(ds_Ecn[0]['CHG_TYP_NM']);
            setSEcnNo(ds_Ecn[0]['ECN_NO']);
            setSGrade(ds_Ecn[0]['GRADE']);
            setSVerificationType(ds_Ecn[0]['VERIFICATION_TYPE_NM']);
            setSL1Category(ds_Ecn[0]['L1_CATEGORY_NM']);
            setSL2Category(ds_Ecn[0]['L2_CATEGORY_NM']);
            setSL3Category(ds_Ecn[0]['L3_CATEGORY_NM']);
        } else {
            let pvVdsEcnReqInfo = state.params.get('pv_vds_EcnReqInfo');

            if (pvVdsEcnReqInfo.length == 0) {
                return;
            }

            setDs_EcnReqInfo(pvVdsEcnReqInfo);
            setSChgTyp(pvVdsEcnReqInfo[0]['CHD_TYPE_NM']);
            setSEcnNo(pvVdsEcnReqInfo[0]['ECN_NO']);
            setSGrade(pvVdsEcnReqInfo[0]['GRADE']);
            setSVerificationType(pvVdsEcnReqInfo[0]['VERIFICATION_CODE']);
            setSL1Category(pvVdsEcnReqInfo[0]['L1_CATEGORY_NM']);
            setSL2Category(pvVdsEcnReqInfo[0]['L2_CATEGORY_NM']);
            setSL3Category(pvVdsEcnReqInfo[0]['L3_CATEGORY_NM']);
        }
    }

    /* 협력사 자체변경점 등급 기준 등록 */
    function fn_selectVerficationType() {
        vds_Part[0]['LANG'] = gv_locale;

        let copyDs = [...ds_Part];
        copyDs[0]['LANG'] = gv_locale;
        setDs_Part(copyDs);

        state.setDataset("ds_Part", ds_Part);

        const requestJson: NexacroJsonType = {
            ds_Part: ds_Part
        };

        sendAxios('/common/nexacro/selectVerficationType.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            setDs_VerificationType(response?.data.ds_VerificationType);

            verificationType.initOptions(() => {
                let returnList: SelectBoxType[] = [];

                for (const f of response?.data.ds_VerificationType || []) {
                    returnList.push({
                        value: String(f.CODE_ID),
                        text: String(f.CODE_NM)
                    })
                }

                return returnList;
            });
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* 출하 성적서 화면 이동 */
    function incmSubmitInspPop(lotId: string, reinspNum: string) {
        setDialog({
            type: 'CONFIRM',
            show: true,
            text: getMessage('MSG01522'),
            confirm: () => {
                let param = new Map();
                param.set('pv_flag','UPD');
                param.set('pv_menuId01', 'QMV_M_Out_InspectList');
                param.set('pv_lotId', lotId);
                param.set('pv_reinspNum', reinspNum);
                param.set('pv_newPartType', ds_Part[0]['NEW_PART_TYPE']);
                param.set('pv_smartqYn', 'N');
                param.set('pv_ecnList', ds_chngPoingHList);

                pageMove(process.env.REACT_APP_URL_QMV_DETAIL || '',{
                    PARAM: param
                });
            }
        });
    }

    /* 측정결과 입력 시 */
    const changeInspVal = (e: any) => {
        setSVerificationType(e.target.value);

        /* 변경값 저장 */
        setInspValData(e);
    };

    /* 변경값 useState 적용 */
    function setInspValData(e: any) {
        let copyArray = [...ds_EcnReqInfo];
        copyArray[0][e.target.name] = e.target.value;
        setDs_EcnReqInfo(copyArray);
        state.setDataset("ds_EcnReqInfo", copyArray);
    }

    return (
        <>
            <div className={'contents-table-wrap width-type1'} style={{display: 'block'}}>
                <ul className={'contents-table-list'}>
                    {
                        state.params.get('pv_flag') == 'INS'
                        ?
                            <>
                                <li key={uuidv4()}>
                                    <div className={'title'}>{getDomain('DOMAIN2607')}{/*관련정보*/}</div>
                                    <div className={'item-wrap'}>
                                    <span className={'item-btn-wrap'}>
                                        <button type={'button'} style={{minWidth: '109px'}}
                                                className={'button-secondary'} disabled={changeDisabled}
                                                onClick={() => setChangePop(true)}>{getDomain('DOMAIN2394')}{/*변경점 확인*/}</button>
                                        <button type={'button'} className={'button-secondary'} disabled={issueDisabled}
                                                style={{color: issueDisabled ? '' : (timerCnt % 2 == 0 ? 'red' : 'blue'), minWidth: '109px'}}
                                                onClick={() => setIssuePartPop(true)}>{getDomain('DOMAIN4648')}{/*실패사례*/}</button>
                                        <button type={'button'} style={{minWidth: '109px'}}
                                                className={'button-secondary'}
                                                disabled={ds_SpecFile.length == 0 || ds_SpecFile[0]['FILE_ID'] == undefined}
                                                onClick={() => setFilePop(true)}>{getDomain('DOMAIN2988')}{/*SPEC 첨부파일*/}</button>
                                    </span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'title'}>{getDomain('DOMAIN4649')}{/*이슈자재*/}</div>
                                    <div className={'item-wrap'}>{issuePartDesc}</div>
                                </li>
                            </>
                        :
                            null
                    }
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN1279')} {/*자재구분*/}</div>
                        <div className={'item-wrap'}>{ds_Part[0]['NEW_PART_TYPE_NM']}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN4650')}</div>  {/*협력사 성적서*/}
                        <div className={'item-wrap'} style={{display: 'unset', paddingLeft: '1rem'}}>
                            {
                                ds_SubmitInspList != undefined && ds_SubmitInspList != null && ds_SubmitInspList != '' && ds_SubmitInspList.length != 0
                                ?
                                    ds_SubmitInspList.map((item: any) => {
                                        return <div key={uuidv4()}><button type="button" className={'type-underline'} onClick={() => incmSubmitInspPop(item.LOT_ID, item.REINSP_NUM)}>{item.LOT_NO}</button></div>
                                    })
                                :
                                    <div style={{paddingLeft:'0.46rem'}}>{state.params.get("pv_data")[0]['SUB_STATUS_NM']}</div>
                            }
                        </div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN4651')}</div>  {/*Risk 사전신고*/}
                        <div className={'item-wrap'}>{ds_Part[0]['RISK_LOT_TYPE_NM']}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN4757').replace('<br/>', ' ')}</div>{/*Risk Lot 메모*/}
                        <div className={'item-wrap'}>{ds_Part[0]['RISK_LOT_MEMO']}</div>
                    </li>
                </ul>

                <h2 className={'h2-title'}>{getDomain('DOMAIN4652')}</h2>  {/*협력사 자체변경점 정보*/}
                <ul className={'contents-table-list'}>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN3296')}</div>  {/*변경유형*/}
                        <div className={'item-wrap'}>{sChgTyp}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN4653')}</div>  {/*변경점 No.*/}
                        <div className={'item-wrap'}>{sEcnNo}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN4654')}</div>  {/*변경등급*/}
                        <div className={'item-wrap'}>{sGrade}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN4655')}</div>  {/*변경점검증유형*/}
                        <div className={'item-wrap'}>
                            {
                                String(state.params.get('pv_flag')) == "DETAIL"
                                ?
                                    sVerificationType
                                :
                                    <select key={uuidv4()} disabled={changeDisabled} onChange={(e) => changeInspVal(e)} value={sVerificationType} name={'VERIFICATION_CODE'}>
                                        <option value={''}>==</option>
                                            {verificationType.options}
                                    </select>
                            }
                        </div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN4656')}</div>  {/*변경:대분류*/}
                        <div className={'item-wrap'}>{sL1Category}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN4657')}</div>  {/*변경:중분류*/}
                        <div className={'item-wrap'}>{sL2Category}</div>
                    </li>
                    <li>
                        <div className={'title'}>{getDomain('DOMAIN4658')}</div>  {/*변경:소분류*/}
                        <div className={'item-wrap'}>{sL3Category}</div>
                    </li>
                </ul>
            </div>

            <CSSTransition in={filePop} classNames={filePop ? 'right' : 'left'} timeout={500} unmountOnExit>
                <PopFiles title={getDomain('DOMAIN2988')} ds_SpecFile={ds_SpecFile} onClose={() => setFilePop(false)}/>
            </CSSTransition>
            <CSSTransition in={changePop} classNames={changePop ? 'right' : 'left'} timeout={500} unmountOnExit>
                <PopChangePoint title={getDomain('DOMAIN2394')} list={ds_popChngPoingHList} onClose={() => setChangePop(false)}/>
            </CSSTransition>
            <CSSTransition in={issuePartPop} classNames={issuePartPop ? 'right' : 'left'} timeout={500} unmountOnExit>
                <QmsPopIssuePartList title={getDomain('DOMAIN4659')} list={ds_IssuePartList} onClose={() => setIssuePartPop(false)}/>
            </CSSTransition>

            {
                state.params.get('pv_flag') == 'INS'
                ?
                    <div key={uuidv4()} className={'btn-area bottom'}>
                        {
                            ds_Part[0]['GROUP_YN'] == "Y"
                                ?
                                    ''
                                :
                                    <button type={'button'} className={'button-line'} onClick={() => state.fn_tempSaveFile()}>{getDomain('DOMAIN1888')}{/*임시저장*/}</button>
                        }
                        <button type={'button'} className={'button-primary'} onClick={() => nextTab('qmsPartInspectionInformation')}>{getDomain('DOMAIN3849')}{/*다음*/}</button>
                    </div>
                :
                    <div key={uuidv4()} className={'btn-area bottom'}>
                        <button type={'button'} className={'button-primary'} onClick={() => pageMove(-1)}>{getDomain('DOMAIN4555')}{/*목록으로 이동*/}</button>
                    </div>
            }
        </>
    );
};