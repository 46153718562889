import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Footer from '../common/Footer';
import {useError} from '../../hooks/useException';
import {useComponent} from '../../components/contexts/ComponentContext';
import useGlobalData from '../../hooks/useGlobalData';
import {useDataset} from '../../components/contexts/CommonContext';

export default function ErrorPage() {
    const location = useLocation();
    const {handleAction} = useError();
    const {setLoader} = useComponent();
    const {getDomain, getMessage} = useGlobalData();
    const {sessionValues} = useDataset();

    useEffect(() => {
        setLoader(false);
    }, []);

    return (
        <>
            <div className={'container login'}>
                <div className={'login-conts'}>
                    <div className={'heading-group'}>
                        <h1>SQCI</h1>
                        <p>Supplier Quality Control Innovation</p>
                    </div>
                    <fieldset className={'error-wrap'}>
                        <p className={'error-text'} dangerouslySetInnerHTML={{__html: (location.state?.message || '').startsWith('DOMAIN') ? getDomain(location.state?.message) : (location.state?.message || '').startsWith('MSG') ? getMessage(location.state?.message) : location.state?.message || 'Unknown Error'}}></p>
                        <input type={'button'} defaultValue={sessionValues && Object.entries(sessionValues).length > 0 ? getDomain('DOMAIN0385') : 'Confirm'} onClick={() => handleAction(location.state?.action)}/>
                    </fieldset>
                </div>
                <Footer/>
            </div>
        </>
    );
}