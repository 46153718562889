import React, {useCallback, useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {NexacroJsonUnitType} from "../../components/model/SqciTypes";
import useAxios, {AXIOS_HEADER} from "../../hooks/useAxios";
import {useError} from "../../hooks/useException";
import * as gfn from "../../components/utils/CommonUtils";
import useGlobalData from "../../hooks/useGlobalData";

export default function PopFiles({title, ds_SpecFile, onClose}: {title: string, ds_SpecFile: NexacroJsonUnitType[], onClose: () => void}) {
    const {sendAxios} = useAxios();
    const {throwException} = useError();
    const {getDomain} = useGlobalData();

    const [list, setList] = useState(new Array<NexacroJsonUnitType>());

    function downloadFile(fileId: string, fileSeq: string){
        const link = document.createElement('a');
        link.href = process.env.REACT_APP_HTTP_BASE_URL+"/common/nexacro/commonFileDownload.do?fileId="+fileId+"&fileSeq="+fileSeq;
        link.style.display = 'none';
        link.click();
        link.remove();
    }

    useEffect(() => {
        fn_attachFile_N("ds_attachFile_N",ds_SpecFile[0]["ATTACH_FILE_ID"] as string);
    }, []);

    // "fn_attachFileSearch" 대체
    function fn_attachFile_N(datasetName :string, file_id:string){
        if(!gfn.gfn_isNull(file_id)) {
            return sendAxios('/common/nexacro/commonSelectFileList.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_Search: [{
                    FILE_ID: file_id
                }]
            })
                .then(response => {
                    if ("ds_attachFile_N" == datasetName) {
                        setList(response?.data.ds_List || []);
                    }
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }else{
            return;
        }
    }


    return (
        <>
            <div className={'pop-layer'} style={{display: 'block'}}>
                <div className={'wrap'}>
                    <header>
                        <h1>{title}</h1>
                        <span className={'btn-wrap right'}>
                            <button type={'button'} className={'button-close'} onClick={onClose}>
                                <i className={'hidden'}>{getDomain('DOMAIN0173')} {/*닫기*/}</i>
                            </button>
                        </span>
                    </header>
                    <div className={'container'}>
                        <div className={'contents-table-wrap'}>
                            <ul className={'contents-table-list'}>
                                <li>
                                    <div className={'file-title'}>{getDomain('DOMAIN4624')}</div>   {/*첨부파일*/}
                                    <div className={'item-wrap'}>
                                        <ul className={'file-list'}>
                                            {
                                                list.map(item => {
                                                    return <li key={uuidv4()}>
                                                        <div className={'file-item'} onClick={() => downloadFile(
                                                            gfn.gfn_tostring(item.FILE_ID),
                                                            gfn.gfn_tostring(item.FILE_SEQ)
                                                        )}>
                                                            <em>{item.FILE_NAME}</em>
                                                            <span>{gfn.fn_getFileSizeDisplay(item.FILE_SIZE)+"KB"}</span>
                                                        </div>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className={'btn-area bottom'}>
                            <button type={'button'} className={'button-primary'} onClick={onClose}>{getDomain('DOMAIN0173')} {/*닫기*/}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};