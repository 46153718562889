import React, {useEffect, useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import Scanner from '../common/Scanner';
import QPointPicture from './QPointPicture';
import {useComponent} from '../../components/contexts/ComponentContext';
import useDatePicker from '../../hooks/useDatePicker';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
import useGlobalData from '../../hooks/useGlobalData';
import {v4 as uuidv4} from 'uuid';

export default function Test() {
    const [qrScannerPop, setQrScannerPop] = useState(false);
    const [qPoint, setQPoint] = useState(false);
    const [qPointFilePath, setQPointFilePath] = useState<string>();
    const {setDialog} = useComponent();
    const {sendAxios} = useAxios();
    const {getMessage, getDomain} = useGlobalData();

    const qPointPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.files) {
            setQPointFilePath(URL.createObjectURL(event.target.files[0]));
        }
    };

    useEffect(() => {
        if(qPointFilePath) {
            setQPoint(true);
        }
    }, [qPointFilePath]);

    const singlePicker = useDatePicker('SINGLE', {
        time: {
            show: true
        }
    }, {
        single: {
            selected: '20230814142000'
        }
    });
    const rangePicker = useDatePicker('RANGE', undefined, {
        range: {
            start: '20230812142402',
            end: '20230912142402'
        }
    });

    const getPlantTreeList = () => {
        // sendAxios('/common/nexacro/selectPlantList.do', 'POST', AXIOS_HEADER.NEXACRO, null)
        // sendAxios('/common/nexacro/selectPlantList.do', 'POST', AXIOS_HEADER.NEXACRO, {
        //     ds_Search: [{
        //         DIVISION_CD: sessionValues?.DIVISION_CD,
        //         HQ_CD: sessionValues?.HQ_CD
        //     }]
        // })
        sendAxios('/common/nexacro/selectPlantList.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Search: []
        })
        .then(response => {
            console.log(response);
        })
        .catch(e => console.error(e));
    };

    const dynamicList = ['11', '22', '33', '44', '55'];
    const [dynamic, setDynamic] = useState<string[]>(dynamicList);
    const dynamicRef = useRef<any>([]);
    const handleDynamic = () => {
        const currentValues = Array.from<HTMLInputElement>(dynamicRef.current).map(ref => ref.value);
        setDynamic(currentValues);
    };
    const handleDynamicKeyEvent = (index: number) => {
        if(index < dynamicList.length - 1) {
            (dynamicRef.current[index + 1] as HTMLInputElement).focus();
        } else {
            alert();
        }
    };

    return (
        <>
            <input type={'button'} defaultValue={'QR Scanner'} onClick={() => setQrScannerPop(true)}/>
            <CSSTransition in={qrScannerPop} classNames={qrScannerPop ? 'right' : 'left'} timeout={500} unmountOnExit>
                <Scanner onClose={() => setQrScannerPop(false)} scanResult={(result) => {
                }}/>
            </CSSTransition>
            <br/><br/>
            <h4>Q-Point 사진</h4>
            <input type={'file'} onChange={qPointPicture} accept={'image/*'} capture={'environment'}/>
            <CSSTransition in={qPoint} classNames={qPoint ? 'right' : 'left'} timeout={500} unmountOnExit>
                <QPointPicture fileId={'QMV0030472073'} shootingFilePath={qPointFilePath}
                    onClose={() => setQPoint(false)}/>
            </CSSTransition>
            <br/><br/>
            <input type={'button'} defaultValue={'Dialog'} onClick={() => {
                setDialog({
                    show: true,
                    type: 'CONFIRM',
                    text: getMessage('MSG01540'),
                    confirm: () => console.log(getMessage('MSG01540'))
                });
            }}/>
            <br/><br/>
            <h4>selectedDate : {singlePicker?.selectedDate}</h4>
            {singlePicker?.datePicker}
            <h4>startDate : {rangePicker?.startDate}</h4>
            <h4>endDate : {rangePicker?.endDate}</h4>
            {rangePicker?.datePicker}
            <br/><br/>
            <input type={'button'} defaultValue={'get plant tree'} onClick={() => getPlantTreeList()}/>
            <br/><br/>
            <input type={'date'} required={true} pattern={'\d{4}-\d{2}-\d{2}'} defaultValue={'2023-09-17'}/>
            <br/><br/>
            {
                dynamic.map((item, index) => {
                    return <div key={'dynamic_'.concat(String(index))}>
                        <input type={'text'}
                            defaultValue={item}
                            inputMode={'decimal'}
                            ref={element => dynamicRef.current[index] = element}
                            onChange={handleDynamic}
                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                if(event.key === 'Enter') {
                                    handleDynamicKeyEvent(index);
                                }
                            }}
                            onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                                dynamicRef.current[index].value = Number(event.target.value).toFixed(2);
                                handleDynamic();
                            }}
                        />
                        <span>{Number(dynamic[index]) > 100 ? 'OK' : 'NOK'}</span>
                    </div>
                })
            }
            {dynamic.map(item => <p key={uuidv4()}>{item}</p>)}
        </>
    );
};