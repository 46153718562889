import React from 'react';
import moment from 'moment';
import * as jose from 'jose';
import {v4 as uuidv4} from 'uuid';
import {useSubmit} from 'react-router-dom';

export default function MfaJwtTest() {

    const createJwt = async () => {
        const secretText = process.env.REACT_APP_MFA_JWT_SECRET_KEY || '';
        const issuedAt = moment().utc(false).unix();
        const expirationTime = moment().add(1, 'day').utc(false).unix();

        const jwt = await new jose.SignJWT({
            sys: process.env.REACT_APP_MFA_SYSTEM_ID,
            req: uuidv4(),
            uid: 'sjlim3.id',
            name: '',
            rtn: 'https://www.samsungsqciedu.com:8443/common/identity/anonymous/SQCI3/mfaAuthVerification.do'
        })
        .setProtectedHeader({
            alg: 'HS256',
            typ: 'JWT'
        })
        .setSubject('')
        .setNotBefore(issuedAt)
        .setExpirationTime(expirationTime)
        .setIssuedAt(issuedAt)
        .sign(jose.base64url.decode(secretText));

        console.log(jwt);
    }

    const submit = useSubmit();
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        for(const key of formData.keys()) {
            console.log(key, formData.get(key));
        }

        submit(event.currentTarget, {
            method: 'get',
            action: 'http://localhost:8080/common/identity/anonymous/SQCI3/mfaAuthRequest.do',
            encType: 'application/x-www-form-urlencoded'
        });
    };

    return (
        <>
            <form method={'get'} action={'http://localhost:8080/common/identity/anonymous/SQCI3/mfaAuthRequest.do'}>
                {/*<input type={'text'} name={'userId'} defaultValue={'seongjun1.lim@stage.samsung.com'}/>*/}
                <input type={'text'} name={'userId'} defaultValue={'sjlim3.id'}/>
                <button type={'submit'}>form submit</button>
            </form><br/><br/>

            <button type={'button'} onClick={createJwt}>GENERATE REACT JWT</button>
        </>
    );
};