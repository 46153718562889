/**************************************************************************************************
 * 01. 업무구분	    : P-SIS > 검사결과 입력 > 대책서 입력
 * 02. 프로그램ID	: CounterViewer.tsx
 * 03. 프로그램명	    : P-SIS > 검사결과 입력 > 대책서 입력
 * 04. 화면설명	    : P-SIS > 검사결과 입력 > 대책서 입력
 * 05. 작성일		: 2023.10.04
 * 06. 작성자		: YGY
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-10-04     YGY    최초 작성
 **************************************************************************************************
 */
/******************************************************************************************
 *                                        변경이력                                         *
 *----------   ----------   ------------   ------------------------------------------------*
 * 변경번호     변경일자       변경자                        변경내용                      *
 *----------   ----------   ------------   ------------------------------------------------*

 ******************************************************************************************/

import React, {useEffect, useRef, useState} from 'react';
import useAxios, {AXIOS_HEADER} from '../../../hooks/useAxios';
import {useError} from '../../../hooks/useException';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {requestParser, responseParser} from "../../../components/utils/NexacroParseUtil";
import {floor, toNumber} from "lodash";
import {useComponent} from "../../../components/contexts/ComponentContext";
import {useDataset} from "../../../components/contexts/CommonContext";
import useGlobalData from "../../../hooks/useGlobalData";
import imageCompression from "browser-image-compression";
import {useLocation, useOutletContext} from "react-router-dom";
import {ComponentHistoryType} from "../../../components/model/SqciTypes";

export default function CounterViewer({title, counterMap, onSave, onClose}: {
    title: string,
    counterMap: any,
    onSave: (inspCd: string) => void,
    onClose: () => void
}) {
    const {sendAxios} = useAxios();
    const location = useLocation();
    const {setDialog} = useComponent();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const {dataset, sessionValues} = useDataset();
    const {throwException} = useError();
    const [counterDetailMap, setCounterDetailMap] = useState<any>();
    const [titleNm, setTitleNm] = useState(String);
    const [counterFileList, setCounterFileList] = useState(new Array<any>());
    const [counterFileMap, setCounterFileMap] = useState<any>();
    const attachRef = useRef<HTMLInputElement>(null);
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();


    useEffect(() => {
        if ((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            setTitleNm(title);
            sendAxios('/common/nexacro/selectPsisCounterPlanList.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_search: [{
                    INSP_TARGET_CD: counterMap.inspTargetCd,
                    INSP_TYPE: counterMap.inspType,
                    INSP_CD: counterMap.inspCd
                }]
            })
                .then(response => {
                    let ds_map: any = {
                        INSP_TARGET_CD: counterMap.inspTargetCd,
                        INSP_TYPE: counterMap.inspType,
                        INSP_CD: counterMap.inspCd,
                        PHENOMENON: '',
                        CAUSE: '',
                        COUNTERPLAN: '',
                        FILE_ID: '',
                        INSERT_BY: sessionValues?.USER_ID,
                    }
                    for (const ds_res of response?.data.ds_counterPlan || []) {
                        ds_map = {
                            INSP_TARGET_CD: ds_res["INSP_TARGET_CD"],
                            INSP_TYPE: ds_res["INSP_TYPE"],
                            INSP_CD: ds_res["INSP_CD"],
                            PHENOMENON: ds_res["PHENOMENON"],
                            CAUSE: ds_res["CAUSE"],
                            COUNTERPLAN: ds_res["COUNTERPLAN"],
                            FILE_ID: ds_res["FILE_ID"],
                            INSERT_BY: sessionValues?.USER_ID
                        }
                    }
                    setCounterDetailMap(ds_map);
                    if (ds_map.FILE_ID != '') {
                        setCounterFileMap({
                            fileId: ds_map.FILE_ID,
                            prevFileId: '',
                            fileState: 'S'
                        })
                    }
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }
    }, [enteredComponent, exitedComponent]);


    /**
     * fileState : S (신규 등록), U(수정)
     */
    useEffect(() => {
        if (counterFileMap !== undefined) {
            sendAxios('/common/nexacro/commonSelectFileList.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_Search: [{
                    FILE_ID: counterFileMap?.fileState === 'S' ? counterFileMap?.fileId : counterFileMap?.prevFileId
                }]
            })
                .then(response => {
                    setCounterFileList(response?.data.ds_List || []);
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }
    }, [counterFileMap]);


    /**
     * 첨부파일 callback
     */
    const attachCallback = () => {
        attachRef.current?.click();
    };

    /**
     * 대책서 파일 업로드, 멀티 파일 업로드 change event
     * @param e
     */
    const attachChangeCallback = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const formData = new FormData();
        const options = {
            maxWidthOrHeight: 1920, //width
            initialQuality: 0.8 //quality
        }
        let maxSize = 30 * 1024 * 1024; //* 30MB 사이즈 제한
        let fileSize = 0;
        if (e.target.files?.length != undefined) {
            for (let i = 0; i < e.target.files?.length; i++) {
                if (e.target.files[i].type.match('image')) {
                    const compressedFile = await imageCompression(e.target.files[i], options);
                    const resizeFile = new File([compressedFile], e.target.files[i].name, {type: e.target.files[i].type});
                    formData.append('file' + i, resizeFile);
                    fileSize = fileSize + resizeFile.size;
                } else { 
                    formData.append('file' + i, e.target.files[i]);
                    fileSize = fileSize + e.target.files[i].size;
                }
                if(fileSize >= maxSize){
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG01296").replace("0", "30MB"),
                    });
                    return;
                }
            }

            formData.append("workType", "PSIS");

            let inputDatasets: any = {
                ds_input: [{}],
                ds_fileInfo: [{
                    MODULE_ID: "PSIS"
                }],
                ds_remove: [{}]
            }
            formData.append("inputDatasets", String(requestParser(inputDatasets)));

            sendAxios('/common/nexacro/commonFileupload.do', 'POST', AXIOS_HEADER.FORM, formData)
                .then(response => {
                    for (const ds_res of responseParser(response?.data)?.ds_output || []) {
                        if (ds_res.ERR_CODE === "SUCESS") {
                            if (ds_res.ERR_MSG !== undefined) {
                                let fileMap: any = {
                                    fileId: ds_res.ERR_MSG,
                                    prevFileId: '',
                                    fileState: 'S'
                                }
                                setCounterFileMap(fileMap);
                            }
                        }
                    }
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }

    };

    /**
     * 대책서 첨부파일 삭제
     */
    const counterFileDelCallback = (fileItem: any) => {
        const formData = new FormData();
        let inputDatasets: any = {
            ds_input: [{}],
            ds_fileInfo: [{
                MODULE_ID: "PSIS"
            }],
            ds_remove: [{
                FILE_ID: fileItem.FILE_ID,
                FILE_SEQ: fileItem.FILE_SEQ,
                FILE_PATH_NAME: fileItem.FILE_PATH_NAME,
                DB_SRCH: "S",
            }]
        }
        formData.append("inputDatasets", String(requestParser(inputDatasets)));

        sendAxios('/common/nexacro/commonFileupload.do', 'POST', AXIOS_HEADER.FORM, formData)
            .then(response => {
                for (const ds_res of responseParser(response?.data)?.ds_output || []) {
                    if (ds_res.ERR_CODE === "SUCESS") {
                        if (ds_res.ERR_MSG !== undefined) {
                            let fileMap: any = {
                                fileId: ds_res.ERR_MSG,
                                prevFileId: fileItem.FILE_ID,
                                fileState: 'U'
                            }
                            setCounterFileMap(fileMap);
                        }
                    }
                }
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
    };

    /**
     * 대책서 작성시 map에 처리
     * @param e
     * @param dataSeq
     * @param counterIdx
     * @param point
     */
    const inputCallback = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        const {id, value} = e.target;
        setCounterDetailMap({...counterDetailMap, [id]: value});
    };

    /**
     * 대책서 저장
     */
    const saveCallBack = () => {
        let isValid: boolean = true;
        let msg: string = '';
        if (counterDetailMap.PHENOMENON === '' || counterDetailMap.PHENOMENON === undefined) {
            isValid = false;
            msg = getDomain('DOMAIN4489');
        } else if (counterDetailMap.CAUSE === '' || counterDetailMap.CAUSE === undefined) {
            isValid = false;
            msg = getDomain('DOMAIN2579');
        } else if (counterDetailMap.COUNTERPLAN === '' || counterDetailMap.COUNTERPLAN === undefined) {
            isValid = false;
            msg = getDomain('DOMAIN4761');
        }

        if (isValid) {
            sendAxios('/common/nexacro/mergePsisCounterPlan.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_counterPlan: [{
                    ...counterDetailMap,
                    FILE_ID: counterFileMap?.fileId !== undefined ? counterFileMap?.fileState === 'S' ? counterFileMap?.fileId : counterFileMap?.prevFileId : counterDetailMap.FILE_ID
                }]
            })
                .then(response => {
                    for (const ds_res of response?.data.ds_res || []) {
                        if (ds_res.CODE === "SUCESS") {
                            onSave(counterMap.inspCd);
                        } else {

                        }
                    }
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        } else {
            msg = getMessage("MSG09001").replace("{0}", msg);
            setDialog({
                type: 'ALERT',
                show: true,
                text: msg,
                confirm: () => {
                    return;
                }
            });
        }
    };


    return (
        <>
            <div className={'pop-layer'} style={{display: 'block', zIndex: 300, overflowY: 'auto'}}>
                <div className={'wrap'}>
                    <header>
                        <h1>{titleNm}</h1>
                        <span className={'btn-wrap right'}>
                        <button type={'button'} className={'button-close'} onClick={onClose}><i
                            className={'hidden'}>닫기</i></button>
                     </span>
                    </header>
                    <div className={'container'}>
                        {
                            counterDetailMap != undefined && (
                                <ul className={'contents-table-list form'}>
                                    <li>
                                        <div className={'title'} style={{width: '20%', wordBreak: 'break-word'}}><strong
                                            className="required">*</strong>{getDomain('DOMAIN4489')}</div>
                                        <div className={'item-wrap type2'}><textarea id={'PHENOMENON'}
                                                                                     defaultValue={counterDetailMap.PHENOMENON}
                                                                                     onBlur={e => inputCallback(e)}></textarea>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={'title'} style={{width: '20%', wordBreak: 'break-word'}}><strong
                                            className="required">*</strong>{getDomain('DOMAIN2579')}</div>
                                        <div className={'item-wrap type2'}><textarea id={'CAUSE'}
                                                                                     defaultValue={counterDetailMap.CAUSE}
                                                                                     onBlur={e => inputCallback(e)}></textarea>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={'title'} style={{width: '20%', wordBreak: 'break-word'}}><strong
                                            className="required">*</strong>{getDomain('DOMAIN4761')}</div>
                                        <div className={'item-wrap type2'}><textarea id={'COUNTERPLAN'}
                                                                                     defaultValue={counterDetailMap.COUNTERPLAN}
                                                                                     onBlur={e => inputCallback(e)}></textarea>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={'title'}
                                             style={{width: '20%', wordBreak: 'break-word'}}>{getDomain('DOMAIN4750')}</div>
                                        <div className={'item-wrap'}>
                                            <input type={'file'} multiple={true} ref={attachRef} accept={"*"}
                                                   style={{display: "none"}} onChange={e => attachChangeCallback(e)}/>
                                            <button type={'button'} className={'button-primary sm upload'}
                                                    onClick={attachCallback}>{getDomain('DOMAIN4572')}
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            )
                        }
                        {
                            counterFileList.length > 0 && (
                                <ul className="contents-table-list" style={{borderTop: "0rem"}}>
                                    <li>
                                        <div className="item-wrap">
                                            <ul className="file-list">
                                                {
                                                    counterFileList.map((item, index) => {
                                                        return <li key={'file'.concat(String(index))}>
                                                            <div className="file-item">
                                                                <em>{item.FILE_NAME}</em>
                                                                <span>{floor(toNumber(item.FILE_SIZE) / (1024 * 1024), 2)}MB</span>
                                                            </div>
                                                            <div className={'btn-item'}>
                                                                <button type={'button'} className={'button-delete'}
                                                                        onClick={() => counterFileDelCallback(item)}
                                                                ><i className={'hidden'}>{getDomain('DOMAIN0229')}</i>
                                                                </button>
                                                            </div>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            )
                        }
                    </div>
                    <div className={'btn-area bottom'}>
                        <button type={'button'} className={'button-primary'}
                                onClick={() => saveCallBack()}>{getDomain('DOMAIN4575')}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
};