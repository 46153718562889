import React, {useEffect, useRef, useState} from 'react';
import useHtmlElement from '../../hooks/useHtmlElement';
import useGlobalData from '../../hooks/useGlobalData';
import {STORAGE_NAME} from '../../components/CommonConstants';
import {useDataset} from '../../components/contexts/CommonContext';
import usePageMove from '../../hooks/usePageMove';

export default function Setting({onClose}: {onClose: () => void}) {
    const {useRadio} = useHtmlElement();
    const {getGlobalSetting, setGlobalSetting, getDomain} = useGlobalData();
    const {setLanguage} = useDataset();
    const {pageMove} = usePageMove();

    const language = useRadio('language', () => {
        return [
            {checked: getGlobalSetting<string>(STORAGE_NAME.LANGUAGE) === 'KO' ? true : false, text: 'Korean', value: 'KO'},
            {checked: getGlobalSetting<string>(STORAGE_NAME.LANGUAGE) === 'EN' ? true : false, text: 'English', value: 'EN'},
            {checked: getGlobalSetting<string>(STORAGE_NAME.LANGUAGE) === 'CH' ? true : false, text: 'Chinese', value: 'CH'},
            {checked: getGlobalSetting<string>(STORAGE_NAME.LANGUAGE) === 'VN' ? true : false, text: 'Vietnamese', value: 'VN'}
            // , {checked: getGlobalSetting<string>(STORAGE_NAME.LANGUAGE) === 'ES' ? true : false, text: 'Spainish', value: 'ES'}
        ];
    });
    const [ocr, setOcr] = useState<boolean>(getGlobalSetting<boolean>(STORAGE_NAME.OCR) || false);

    useEffect(() => {
        const loadOcr = getGlobalSetting<boolean>(STORAGE_NAME.OCR);
        setOcr(loadOcr === undefined ? true : loadOcr);
    }, []);

    // useEffect(() => {
    //     if(ocr !== undefined) {
    //         setGlobalSetting(STORAGE_NAME.OCR, ocr);
    //     }
    // }, [ocr]);
    //
    // useEffect(() => {
    //     if(language.checked) {
    //         setLanguage(language.checked);
    //     }
    // }, [language.checked]);

    const handleOcr = () => {
        setOcr(!ocr);
    };

    const handleClose = () => {
        if(language.checked !== getGlobalSetting<string>(STORAGE_NAME.LANGUAGE) || ocr !== getGlobalSetting<boolean>(STORAGE_NAME.OCR)) {
            pageMove(process.env.REACT_APP_URL_MAIN || '');
        }

        if(ocr !== undefined) {
            setGlobalSetting(STORAGE_NAME.OCR, ocr);
        }

        if(language.checked) {
            setLanguage(language.checked);
        }

        onClose();
    };

    return (
        <>
            <div className={'pop-layer'} style={{display: 'block'}}>
                <div className={'wrap'}>
                    <header>
                        <span className={'btn-wrap left'}>
                            <button type={'button'} className={'button-back'} onClick={onClose}>
                                <i className={'hidden'}>뒤로 이동</i>
                            </button>
                        </span>
                        <h1>Setting</h1>
                    </header>
                    <div className={'container'}>
                        <div className={'setting-list-wrap'}>
                            <h2 className={'h2-title'}>Language</h2>
                            <ul className={'setting-list'}>
                                {[...language.items]}
                            </ul>
                            {
                                'true' === process.env.REACT_APP_SHOW_OCR_CONTROL ? <div className={'qcr-switch-wrap'}>
                                    <h2 className={'h2-title'}>{getDomain('DOMAIN4694')} {/*OCR 기능*/}</h2>
                                    <input id={'switch'} type={'checkbox'} defaultChecked={ocr} onClick={handleOcr}/>
                                    <label className={'button-switch'} htmlFor={'switch'}></label>
                                </div> : null
                            }
                        </div>
                        <div className={'btn-area bottom'}>
                            <button type={'button'} className={'button-primary'} onClick={handleClose}>{getDomain('DOMAIN0173')} {/*닫기*/}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};