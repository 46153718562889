/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > 입고검사 성적서 (세부검사정보)
 * 02. 프로그램ID : QmsDetailInspectList.tsx
 * 03. 프로그램명 : 입고검사 성적서 작성 (세부검사정보 탭)
 * 04. 화면설명 : 입고검사 성적서 작성
 * 05. 작성일 : 2023.08.24
 * 06. 작성자 : 채보람
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-08-24     채보람    최초 작성
 **************************************************************************************************
 */

import React, {useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import QmsDetailInspectDetail from './QmsDetailInspectDetail';
import useGlobalData from "../../../../hooks/useGlobalData";
import {STORAGE_NAME} from "../../../../components/CommonConstants";
import useAxios, {AXIOS_HEADER} from "../../../../hooks/useAxios";
import {NexacroJsonType, NexacroJsonUnitType} from "../../../../components/model/SqciTypes";
import {gfn_isNull} from "../../../../components/utils/CommonUtils";
import usePageMove from "../../../../hooks/usePageMove";
import {useError} from "../../../../hooks/useException";

export default function QmsDetailInspectList({state, nextTab}: {state: any, nextTab: (name: string) => void}) {
    const list = state.params.get('pv_spec');

    const {getGlobalSetting, setGlobalSetting} = useGlobalData();
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const {sendAxios} = useAxios();
    const {getDomain} = useGlobalData();
    const {pageMove} = usePageMove();
    const {throwException} = useError();

    const [showSwiper, setShowSwiper] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
    const [ds_Spec, setDs_Spec] = useState(state.params.get('pv_spec'));
    const [ds_Part, setDs_Part] = useState(state.params.get('pv_data'));
    const [ds_Defect, setDs_Defect] = useState(state.params.get('pv_defect'));
    const [pv_flag, setPv_flag] = useState(String(state.params.get('pv_flag')));
    const [pv_tmp, setPv_tmp] = useState(String(state.params.get('pv_tmp')));

    const [ds_serialRev, setDs_serialRev] = useState(new Array<NexacroJsonUnitType>());
    const [ds_grdColList, setDs_grdColList] = useState(state.ds_grdColList);

    let fv_maxSamCnt = 0;
    let fv_Records         = 50;       //목록갯수
    let fv_Page            = 0;        //페이지번호
    let fv_RecordsOffset   = 0;        //시작rownum
    let fv_TotalCount      = 0;        //전체데이터갯수
    let fv_PageCount       = 0;        //실제표출페이지갯수

    let vds_DefectGroup: any = [];
    let vds_DefectSymptom2: any = [];
    let vds_searchSerial: any = [];
    let vds_serialRev: any = [];
    let vds_grdColList: any = [];
    let vds_Spec = state.params.get('pv_spec');
    let vds_Defect = state.params.get('pv_defect');

    const goDetail = (index: number) => {
        setShowSwiper(false);
        setSelectedIndex(index);
    };

    const closeDetail = (data: any) => {
        state.setDataset("ds_Spec", data);

        setShowSwiper(false);
        setSelectedIndex(undefined);
    };

    useEffect(() => {
        if(selectedIndex !== undefined) {
            setShowSwiper(true);
        }
    }, [selectedIndex]);

    useEffect(() => {
        form_onload();
    }, []);

    /* form_onload */
    async function form_onload() {
        // console.log("세부검사정보 탭");
        // console.log("state.params ↓");
        // console.log(state.params.get('pv_spec'));
        // console.log("세부검사정보 탭");

        if (ds_Spec.length == 0)
        {
            return;
        }

        /* Serial No 조회 추가 */
        await fn_searchSerial().then((response) => {
            setDs_serialRev(response?.data.ds_serialRev);
            vds_serialRev = response?.data.ds_serialRev;
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });

        /* 데이터셋에 샘플측정 결과 컬럼 생성 */
        fn_creatDataset();
    }

    function fn_searchSerial() {
        vds_searchSerial = [];

        let inspResultNo = ds_Part[0]['INSP_RESULT_NO'];

        if (inspResultNo == undefined || inspResultNo == null || inspResultNo == "")
        {
            inspResultNo = ds_Part[0]['INSP_RESULT_NO_TMP'];
        }

        vds_searchSerial.push({
            INSP_RESULT_NO: inspResultNo
        });

        const requestJson: NexacroJsonType = {
            ds_searchSerial: vds_searchSerial
        };

        return sendAxios('/common/nexacro/QMS_M_Incm_InspListSelectSerialNo.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson);
    }

    /* 데이터셋에 샘플측정 결과 컬럼 생성 */
    function fn_creatDataset() {
        let nMaxSam = 0;
        let nSam = 0;

        for (let i = 0; i < ds_Spec.length; i++) {
            nSam = ds_Spec[i]['SAM_CNT'];

            if (nMaxSam < Number(nSam)) {
                nMaxSam = Number(nSam);
            }
        }

        fv_maxSamCnt = nMaxSam;

        for (let j = 0; j < vds_Spec.length; j++) {
            let inspTotalCnt = 0;

            for (let i = 0; i < Number(vds_Spec[j]['SAM_CNT']); i++) {
                let tempColNmMeas = "MEAS_VAL_" + (i + 1);
                vds_Spec[j][tempColNmMeas] = (vds_Spec[j][tempColNmMeas] == undefined ? "" : vds_Spec[j][tempColNmMeas]);

                let tempColNmResult = "RESULT_YN_" + (i + 1);
                vds_Spec[j][tempColNmResult] = (vds_Spec[j][tempColNmResult] == undefined ? "" : vds_Spec[j][tempColNmResult]);

                // 자동 ok 처리 삭제
                // if (vds_Spec[j]['NUM_YN'] == "N" && (vds_Spec[j][tempColNmMeas] == undefined || vds_Spec[j][tempColNmMeas] == "")) {
                //     vds_Spec[j][tempColNmMeas] = "1";
                //     vds_Spec[j][tempColNmResult] = "1";
                // }

                if (vds_Spec[j][tempColNmMeas] != undefined && vds_Spec[j][tempColNmMeas] != "") {
                    inspTotalCnt++;
                }
            }

            vds_Spec[j]['INSP_TOTAL_CNT'] = inspTotalCnt;

            if (vds_Spec[j]['MEAS_DEFECT_CNT'] == '-') {
                vds_Spec[j]['MEAS_DEFECT_CNT'] = '0';
            }

            if (Number(vds_Spec[j]['SAM_CNT']) == inspTotalCnt && vds_Spec[j]['MEAS_DEFECT_CNT'] == '0') {
                vds_Spec[j]['MEAS_RSLT'] = "Y";
            }
        }

        for (let i = 0; i < nMaxSam; i++) {
            // this.ds_Spec.addColumn( "MEAS_VAL_"+(i+1), "string", 256);
            // this.ds_Spec.addColumn( "RESULT_YN_"+(i+1), "string", 256);
            // this.ds_grdColList.addRow();
            // this.ds_grdColList.setColumn(i,"COL_NAME","#"+(i+1));
            // this.ds_grdColList.setColumn(i,"BIND_COL","MEAS_VAL_"+(i+1));
            // vds_grdColList[i]['COL_NAME'] = "#" + (i + 1);
            // vds_grdColList[i]['BIND_COL'] = "MEAS_VAL_" + (i + 1);

            // temp_ds_Spec[nSpecRow]["MEAS_VAL_"+nCol] = sNumYnS;
            //
            // let aaa = "MEAS_VAL_"+(i+1);
            // setDs_Spec({
            //     ...ds_Spec[i],
            //     aaa: ""
            // })

            // vds_Spec[i]["MEAS_VAL_" + (i + 1)] = "";
            // vds_Spec[i]["RESULT_YN_" + (i + 1)] = "";

            // for (let j = 0; j < vds_Spec.length; j++) {
            //     if (Number(vds_Spec[j]['SAM_CNT']) < nMaxSam) {
            //         let tempColNm = "MEAS_VAL_" + (i + 1);
            //         vds_Spec[j][tempColNm] = "";
            //     }
            // }

            vds_grdColList.push({
                COL_NAME: "#" + (i + 1),
                BIND_COL: "MEAS_VAL_" + (i + 1)
            });

            /** VOC 28 Start : 2019.07.16 채보람 검사성적서 항목 SN 입력칸 추가 **/
            /** Serial No 저장으로 인하여 ds_grdColList 추가 **/
            if (ds_serialRev.length > 0)
            {
                let revSerialNo = ds_serialRev[i]['SERIAL_NO'];
                let revSerNo = Number(ds_serialRev[i]['SERNO']) - 1;

                if (revSerialNo == undefined || revSerialNo == null || revSerialNo == "")
                {
                    revSerialNo = null;
                }

                vds_grdColList[revSerNo]['SERIAL_NO'] = revSerialNo;
            }
            /** VOC 28 End   : 2019.07.16 채보람 검사성적서 항목 SN 입력칸 추가 **/
        }

        setDs_Spec(vds_Spec);
        setDs_grdColList(vds_grdColList);

        state.setFv_maxSamCnt(fv_maxSamCnt);
        state.setDataset("ds_grdColList", vds_grdColList);
    }

    return (
        <>
            <div className={'table-wrap'} style={{display: 'block'}}>
                <table>
                    <colgroup>
                        <col style={{width: '3rem'}}/>
                        <col/>
                        <col style={{width: '4.8rem'}}/>
                        <col style={{width: '4.8rem'}}/>
                        <col style={{width: '4.8rem'}}/>
                        <col style={{width: '4.2rem'}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope={'col'}>{getDomain('DOMAIN4608')} {/*No*/}		</th>
                            <th scope={'col'}>{getDomain('DOMAIN4758')} {/*검사항목*/}	</th>
                            <th scope={'col'}>{getDomain('DOMAIN2056')} {/*샘플수*/}		</th>
                            <th scope={'col'}>{getDomain('DOMAIN4619')} {/*검사수*/}		</th>
                            <th scope={'col'}>{getDomain('DOMAIN2057')} {/*불량수*/}		</th>
                            <th scope={'col'}>{getDomain('DOMAIN4620')} {/*판정*/}		</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        list.map((item: any, index: number) => {
                            return <tr
                                key={'tr_'.concat(String(index))}
                                className={item.ESS_YN === 'N' ? 'bg-color-y' : item.ESS_YN === 'Y' ? 'bg-color-p' : ''}
                                onClick={() => goDetail(index)}>
                                <td key={'td_0_'.concat(String(index))}>{index + 1}</td>
                                <td key={'td_1_'.concat(String(index))} className={'left'}><a>{item.PARA_NM}</a></td>
                                <td key={'td_2_'.concat(String(index))} className={'right'}>{item.SAM_CNT}</td>
                                <td key={'td_3_'.concat(String(index))} className={'right'}>{item.INSP_TOTAL_CNT}</td>
                                <td key={'td_4_'.concat(String(index))} className={'right'}>{item.MEAS_DEFECT_CNT}</td>
                                <td key={'td_5_'.concat(String(index))}>
                                    <span key={'span_'.concat(String(index))} className={item.MEAS_RSLT === 'Y' || item.SAM_CNT == '0' ? 'label-state ok' : item.MEAS_RSLT === 'N' ? 'label-state ng' : 'label-state wait'}>{item.MEAS_RSLT == 'Y' || item.SAM_CNT == '0' ? 'OK' : item.MEAS_RSLT == 'N' ? 'NG' : 'Wait'}</span>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
            {
                state.params.get('pv_flag') == 'INS'
                ?
                    <div className={'btn-area bottom'}>
                        {
                            ds_Part[0]['GROUP_YN'] == "Y"
                                ?
                                    ''
                                :
                                    <button type={'button'} className={'button-line'} onClick={() => state.fn_tempSaveFile()}>{getDomain('DOMAIN1888')}{/*임시저장*/}</button>
                        }
                        <button type={'button'} className={'button-primary'} onClick={() => nextTab('qmsDetailInspectList')}>{getDomain('DOMAIN3849')}{/*다음*/}</button>
                    </div>
                :
                    <div className={'btn-area bottom'}>
                        <button type={'button'} className={'button-primary'} onClick={() => pageMove(-1)}>{getDomain('DOMAIN4555')}{/*목록으로 이동*/}</button>
                    </div>
            }
            <CSSTransition in={showSwiper} classNames={showSwiper ? 'right' : 'left'} timeout={500} unmountOnExit>
                <QmsDetailInspectDetail list={list}
                                        ds_grdColList={ds_grdColList}
                                        onClose={closeDetail}
                                        selected={selectedIndex}
                                        pv_flag={pv_flag}
                                        ds_AllSample={state.params.get('pv_allSample')}
                                        ds_Aql={state.params.get('pv_aql')}
                                        ds_List={state.params.get('pv_data')}
                                        setFv_maxSamCnt={state.setFv_maxSamCnt}
                                        ds_Defect={vds_Defect}
                                        ds_DefectDelete={state.ds_DefectDelete}
                                        setDataset={state.setDataset}
                />
            </CSSTransition>
        </>
    );
};